import axios from "axios";
import {APIURL} from "../config/config";


export const getBranchs = async () => {
    // company_setting_id
    try{
        let result = await axios.get(APIURL + '/branch')
        return result.data
    }catch (e) {
        console.log('error: ', e)
        return null
    }
}