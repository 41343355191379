import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import MaskedInput from "react-maskedinput";
import axios from "axios";
import {APIURL} from "../../../config/config";
moment.locale('th');

export  default class SalesClaimAdd extends Component {
  constructor (props) {
    super(props);
    //this.handleOnSubmitCustomer.bind(this);
    this.state = {
      dateClaim: new Date(),
      user: JSON.parse(this.props.user),
      car_sell: this.props.car_sell,
      //
      claim1_list1: '1.\n2.\n3.',
      claim1_list2: '4.\n5.\n6.',
      claim2: '',
      claim3: '',
      claim_name: new Date(),
      claim_mobile: '',
      is_car_search: false,
      car_lists: [],
      car_search: '',
      is_car_select: false,
      car_id: null,
      car_select: {},
    };
    this.handleDateClaim = this.handleDateClaim.bind(this);
    this.handleDateClaimName = this.handleDateClaimName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchCarSell = this.searchCarSell.bind(this);
    this.onCarSelect = this.onCarSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if(this.props.form_status === 'edit') {
      this.setEditToForm();
    }
  }

  setEditToForm() {
    // console.log(this.props.claim_edit)
    this.setState({
      claim1_list1: this.props.claim_edit.claim1_list1,
      claim1_list2: this.props.claim_edit.claim1_list2,
      claim2: this.props.claim_edit.claim2,
      claim3: this.props.claim_edit.claim3,
      claim_name: new Date(this.props.claim_edit.claim_name),
      dateClaim: new Date(this.props.claim_edit.claim_date),
      claim_mobile: this.props.claim_edit.claim_mobile
    });
  }

  handleDateClaim(date) {
    this.setState({
      dateClaim: date
    });
  }

  handleDateClaimName(date) {
    this.setState({
      claim_name: date
    });
  }

  handleChange(event) {
    let targetName = event.target.name;
    let value = event.target.value;
    let line = '';
    // console.log(targetName);
    // console.log(value);
    if(targetName === 'claim1_list1'){
      line = value.split('\n')
      // console.log('line='+line.length)
      if(line.length>3){
        this.setState({
          line_error: true
        });
        return;
      }
      this.setState({
        claim1_list1: value,
        line_error: false
      });
    }else if(targetName === 'claim1_list2'){
      line = value.split('\n')
      if(line.length>3){
        this.setState({
          line_error: true,
        });
        return;
      }
      this.setState({
        claim1_list2: value,
        line_error: false
      });
    }else if(targetName === 'claim2'){
      this.setState({
        claim2: value
      });
    }else if(targetName === 'claim3'){
      this.setState({
        claim3: value
      });
    }
    /*else if(targetName === 'claim_name'){
      this.setState({
        claim_name: value
      });
    }*/
    else if(targetName === 'claim_mobile'){
      this.setState({
        claim_mobile: value
      });
    }

  }

  searchCarSell(e) {
    e.preventDefault()
    let value = e.target.value
    this.setState({
      car_search: value
    }, () => {
      if (value.length >= 3) {
        axios.get(APIURL + '/car_sell/search_debt/'+value)
            .then(res => {
              this.setState({
                car_lists: res.data,
                is_car_search: true
              })
            }).catch(error => {
          console.log('error: ', error)
          this.setState({
            car_lists: [],
            is_car_search: false
          })
        })
      }
    })
  }

  onCarSelect(car) {
    // let customer_id = car.customer_id
    let car_id = car.car_id

    this.setState({
      is_car_select: true,
      is_car_search: false,
      car_sell: car,
      car_id: car_id
    })
  }

  onSubmit(e) {
    e.preventDefault();
    let is_search_car = this.props.is_search_car
    let car_sell = this.state.car_sell
    if(is_search_car === true) {
      // call to CarClaimList
      this.props.onSubmit(e, is_search_car, car_sell)
    } else {
      this.props.onSubmit(e)
    }
  }


  render(){

    let is_search_car = this.props.is_search_car

    let search_car_list = this.state.car_lists.map((car,index)=>{

      let car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
          car.car_license_plate_new : car.car_license_plate_old
      let province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
          car.province_name_new : car.province_name_old

      return(
          <tr key={index}>
            <td className="text-left">
              {car.car_name}
            </td>
            <td className="text-left">
              ทะเบียน: {car_license_plate} &nbsp;{province_name}
            </td>
            <td>
              <button
                  onClick={()=>this.onCarSelect(car)}
                  type="button"
                  className="btn btn-primary"
              >
                <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
              </button>
            </td>
          </tr>
      )
    })

    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={this.onSubmit}>
          <div className="card-header">
            <strong className="text-title">
              {this.props.form_status==='add'? 'บันทึก ปล่อยรถ/การเคลม' : 'แก้ไข ปล่อยรถ/การเคลม'}
              </strong>
            <div className="float-right text-title">พนักงานขาย: {this.state.user.fullname}</div>
          </div>
          <div className="card-block">

            {
              is_search_car === true ? (
                  <div className="row mb-2">
                    <div className="col-6 input-group ">
                      <input onChange={this.searchCarSell}
                             name="car_search"
                             placeholder="ค้นหาตามทะเบียนรถ เฉพาะรถที่ขายแล้ว (อย่างน้อย 3 ตัวอักษร)"
                             className="form-control"
                             size="16"
                             type="text"/>

                      <span className="input-group-btn ">
                                                        <button className="btn btn-sm btn-success"
                                                                type="button"
                                                        >
                                                            ค้นหา
                                                        </button>
                                                    </span>
                    </div>

                    <div className={ this.state.is_car_search ? " col-12 pl-2" : ' hidden' }>
                      <table className="table table-striped ml-2">
                        <tbody>
                        {search_car_list}
                        </tbody>
                      </table>
                    </div>

                    {/*<div className={this.state.is_car_select ? 'col-12 mt-2' : ' hidden'}>
                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-3"><strong> ชื่อรถ </strong></label>
                        <div className="col-9">
                          <p className="text-justify text-success">
                            <strong> {this.state.car_select.car_name} </strong>
                          </p>
                        </div>
                      </div>
                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-3"><strong> ทะเบียน </strong></label>
                        <div className="col-9">
                          <p className="text-justify text-success">
                            <strong>
                              { this.state.car_select.car_license_plate_new !== '' &&  this.state.car_select.car_license_plate_new !==  this.state.car_select.car_license_plate_old ?
                                  this.state.car_select.car_license_plate_new : this.state.car_select.car_license_plate_old}
                              &nbsp;
                              {this.state.car_select.car_license_plate_new !== '' &&  this.state.car_select.car_license_plate_new !==  this.state.car_select.car_license_plate_old ?
                                  this.state.car_select.province_name_new : this.state.car_select.province_name_old}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>*/}

                  </div>
              ) : null
            }



            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="date_claim">วันที่ออกใบงาน</label>
                <DatePicker
                  selected={this.state.dateClaim}
                  onChange={this.handleDateClaim}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="claim_date"
                  name="claim_date"
                  placeholder="วันที่ออกใบงาน"
                />
              </div>

              <div className="form-group col-sm-6">
                <label htmlFor="claim_name">วันที่ลูกค้ารับรถ</label>
                {/*<input
                  className="form-control"
                  id="claim_name"
                  name="claim_name"
                  placeholder="ลูกค้ารับรถ"
                  value={this.state.claim_name}
                  onChange={this.handleChange}
                />*/}
                <DatePicker
                  selected={this.state.claim_name}
                  onChange={this.handleDateClaimName}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="claim_name"
                  name="claim_name"
                  placeholder="วันที่ลูกค้ารับรถ"
                />
              </div>

              <div className="form-group col-6">
                <label htmlFor="claim_mobile">เบอร์โทรศัพท์</label>

                <input
                  className="form-control hidden"
                  id="claim_mobile_old-bk"
                  name="claim_mobile_old-bk"
                  placeholder="เบอร์โทร"
                  // value={this.state.claim_mobile}
                  // onChange={this.handleChange}
                />

                <MaskedInput
                    mask="111-111-1111"
                    className="form-control"
                    id="claim_mobile"
                    name="claim_mobile"
                    placeholder="เบอร์โทร"
                    value={this.state.claim_mobile}
                    onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">
                  รถยี่ห้อ: {this.state.car_sell.car_brand_name}</label>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">
                  รุ่น: {this.state.car_sell.car_model_name}
                </label>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">
                  สี: {this.state.car_sell.car_color_name}
                </label>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">ทะเบียน: {this.state.car_sell.car_license_plate_new}</label>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="claim_mobile">รายการ</label>
                <textarea
                  id="claim1_list1"
                  name="claim1_list1"
                  className="form-control"
                  value={this.state.claim1_list1}
                  onChange={this.handleChange}
                  rows="8"
                  />
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="claim_mobile">&nbsp;</label>
                <textarea
                  id="claim1_list2"
                  name="claim1_list2"
                  className="form-control"
                  value={this.state.claim1_list2}
                  onChange={this.handleChange}
                  rows="8"
                  />
              </div>
            </div>

            <div className="row hidden">
              <div className="form-group col-sm-12">
                <label htmlFor="claim_mobile">เคลมที่ 2</label>
                <textarea
                  id="claim2"
                  name="claim2"
                  className="form-control hidden"
                  value={this.state.claim2}
                  onChange={this.handleChange}
                  />
              </div>
            </div>

            <div className="row hidden">
              <div className="form-group col-sm-12">
                <label htmlFor="claim_mobile">เคลมที่ 3</label>
                <textarea
                  id="claim3"
                  name="claim3"
                  className="form-control hidden"
                  value={this.state.claim3}
                  onChange={this.handleChange}
                  />
              </div>
            </div>

          </div>

          <div className={this.state.line_error? "row" : "hidden"}>
            <div className="form-group col-sm-12">
              <label className="text-danger">**พิมพ์ได้ไม่เกิน 3 บรรทัดต่อช่อง </label>
            </div>
          </div>

          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onToggle}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className={this.props.form_status==='add'? "btn btn-sm btn-primary" :  "btn btn-sm btn-warning" }>
              <i className="fa fa-save"/>&nbsp;
              {this.props.form_status==='add'? 'บันทึก' : 'แก้ไข'}
            </button>
            <input
              type="hidden"
              id="status"
              name="status"
              value={this.props.claim_status}
            />
          </div>

        </form>
      </div>
    );
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    marginLeft: 8,
    marginRight: 8
  },
  btnWidth: {
    width: 170,
  }
}