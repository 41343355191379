import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { APIURL, WEBURL } from '../../config/config'

const color1 = '#20baa5'
const color2 = '#4feeda'

function CurrentCarSummary() {

  const [brands, setBrands] = useState([])
  const [structures, setStructures] = useState([])

  useEffect(() => {

    async function loadSummaryBrand() {
      let results = await axios.get(APIURL+'/report_car/brands')
      setBrands(results.data)
    }

    async function loadSummaryStructure() {
      let results = await axios.get(APIURL+'/report_car/structures')
      setStructures(results.data)
    }

    loadSummaryBrand()
    loadSummaryStructure()

  }, [])

  function genSummaryBrands() {


    if(brands.length === 0) {
      return null
    }

    let brandRender = brands.map((brand, i) => {

      let name = brand.car_brand_name
      let count = brand.brand_count
      const target = WEBURL+ '#/cars/list/1?type=summary&by=brand&value=' + brand.car_brand_id+'&name='+name+'&count='+count

      return (
        <div key={i}
             className="card mb-1 mt-1 p-2 pr-3 pl-3"
             style={{background: `linear-gradient(to right,  ${color1} 0%,${color2} 100%)`, minHeight: 50, width: 380, borderRadius: 10}}
        >
          <div className="row mt-1">
            <div className="col-6 text-white font-lg">
              {brand.car_brand_name} ({brand.brand_count})
            </div>
            <div className="col-6 text-right">
              <a href={target}
                 className="btn btn-primary" style={{borderRadius: 20}} >
                ดูรายละเอียด
              </a>
            </div>
          </div>
        </div>
      )
    })

    return brandRender
  }

  function genSummaryStructures() {


    if(structures.length === 0) {
      return null
    }

    let structureRender = structures.map((structure, i) => {

      let name = structure.car_structure_name
      let count = structure.structure_count
      const target = WEBURL+ '#/cars/list/1?type=summary&by=structure&value=' + structure.car_structure_id+'&name='+name+'&count='+count

      return (
        <div key={i}
             className="card mb-1 mt-1 p-2 pr-3 pl-3"
             style={{background: `linear-gradient(to right,  ${color1} 0%,${color2} 100%)`, minHeight: 50, width: 410, borderRadius: 10}}
        >
          <div className="row mt-1">
            <div className="col-8 text-white font-lg">
              {structure.car_structure_name} ({structure.structure_count})
            </div>
            <div className="col-4 text-right">
              <a href={target} className="btn btn-primary" style={{borderRadius: 20}} >
                ดูรายละเอียด
              </a>
            </div>
          </div>
        </div>
      )
    })

    return structureRender
  }

  return (
    <>
    <div className="col-lg-6">
      <div className="card">
        <div className="card-header">
          <strong className="text-title">สรุปยี่ห้อรถ</strong>
        </div>
        <div className="card-block">
          <div className="row">
            <div className="col-12">
              {genSummaryBrands()}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="card">
        <div className="card-header">
          <strong className="text-title">สรุปประเภทรถ</strong>
        </div>
        <div className="card-block">
          <div className="row">
            <div className="col-12">
              {genSummaryStructures()}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default CurrentCarSummary
