import React, { Component } from 'react'
import {AlertSuccess, AlertWarning} from "../../Alert/Alert";
import axios from "axios";
import {APIURL} from "../../../config/config";
import Switch from "react-switch";

export default class CarModelForm extends Component{
    constructor (props){
        super(props)
        this.state = {
            car_sub_model_name: ''

        }
        this.handleOnSubmitCarSubModel = this.handleOnSubmitCarSubModel.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.actionTypeSub !== this.props.actionTypeSub || prevProps.car_sub_model_name !== this.props.car_sub_model_name ){
            // console.log(this.props.actionTypeSub)
            if (this.props.actionTypeSub === 'แก้ไข'){
                this.setState({
                    car_sub_model_name : this.props.car_sub_model_name
                })
            }else {
                this.setState({
                    car_sub_model_name : ''
                })
            }
        }
    }

    handleOnSubmitCarSubModel(event) {
        event.preventDefault()
        let car_sub_model_name = event.target.car_sub_model_name.value
        let car_model_id = this.props.car_model_id
        let car_brand_id = this.props.car_brand_id
        let actionTypeSub = this.props.actionTypeSub

        if (actionTypeSub === 'บันทึก') {
            event.target.car_sub_model_name.value = ""

            let sendData = {
                car_sub_model_name,
                car_model_id: car_model_id,
                car_brand_id: car_brand_id
            }

            // console.log('sendData SubModel=', sendData)

            axios.post(APIURL + '/car_sub_model', sendData)
                .then(res => {
                    // console.log(res.data)
                    if (res.data === 'success') {
                        AlertSuccess('เพิ่มรุ่นย่อยรถเรียบร้อยแล้ว')
                        this.props.onReloadCarSubModel(car_model_id)
                        // this.props.onReloadCarModel(car_brand_id)
                        this.setState({
                            car_sub_model_name: ''
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }else {

            let car_sub_model_update_id = this.props.car_sub_model_id

            axios.put(APIURL + '/car_sub_model/updatesubmodel', {
                car_brand_id: car_brand_id,
                car_model_id : car_model_id,
                car_sub_model_id : car_sub_model_update_id,
                car_sub_model_name: car_sub_model_name,
            })
                .then( res => {
                    if(res.status===200) {
                        AlertWarning('แก้ไขรุ่นย่อยเรียบร้อยแล้ว')
                        this.props.onReloadCarSubModel(car_model_id)
                        this.setState({
                            car_sub_model_name: '',
                            //actionType : 'บันทึก'
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }


    render(){
        let carSubModelList;

        if (this.props.car_sub_model_list.length === 0){
            carSubModelList = <tr><td colSpan={3} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
        } else {
            carSubModelList = this.props.car_sub_model_list.map((brand_model, index) => {
                return (
                    <tr key={index}>
                        <td className="text-center">{ index+1 }</td>

                        <td className="text-center">
                            { brand_model.car_sub_model_name } <br/>
                            สถานะ :  { brand_model.status === 'show'? 'ใช้งาน':'ไม่ใช้งาน'} <br/><br/>
                            <Switch height = {20} width ={48}
                                    onChange={(checked)=>{
                                        this.props.onChangeSwitchSubModel(checked, brand_model)
                                    }}
                                    checked={brand_model.status === 'show'} />
                        </td>

                        <td className="text-center">
                            <button className="btn btn-sm btn-warning btn-md-width mb-2 mr-2"
                                    style={styles.button}
                                    onClick={() => this.props.onCarSubModelUpdate(brand_model)}

                            >
                                <i className="fa fa-edit"/> แก้ไข
                            </button>
                        </td>
                    </tr>
                )
            });
        }

        return (
            <div className="card card-accent-success">
                <div className="card-header card-customer">
                    <strong className="text-title">ข้อมูลรุ่นย่อย</strong>
                </div>

                <div className="col-sm-12 mt-3">
                    <label htmlFor="car_model_name">รุ่นรถที่เลือก</label>

                    <input
                        type="text"
                        name="car_model_name"
                        value={this.props.car_model_name || ''}
                        style={styles.inputAdd}
                        className="form-control"
                        readOnly
                        placeholder="รุ่นรถที่เลือก"
                    />
                </div>

                <form action="" method="post" onSubmit={this.handleOnSubmitCarSubModel}>
                    <div className="col-sm-12 mt-3">
                        <label htmlFor="add_car_sub_model">{this.props.actionTypeSub === "แก้ไข" ? "แก้ไขรุ่นย่อย" : "เพิ่มรุ่นย่อย"}</label>
                        &nbsp;&nbsp;
                        <input
                            type="text"
                            id="car_sub_model_name"
                            name="car_sub_model_name"
                            value={this.state.car_sub_model_name}
                            style={styles.inputAdd}
                            className="form-control"
                            placeholder="รุ่นย่อยรถใหม่"
                            onChange={(e)=>{
                                this.setState({
                                    car_sub_model_name:e.target.value
                                })
                            }}
                        />

                        &nbsp;&nbsp;&nbsp;

                        <button type="submit" className={this.props.actionTypeSub==="แก้ไข"? "btn btn-warning" : "btn btn-primary"}>
                            <i className={this.props.actionTypeSub === "แก้ไข" ? "icon-pencil" : "icon-plus"}/> {this.props.actionTypeSub}</button>
                    </div>
                </form>

                <div className="card-block">
                    <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                            <th className="text-center" style={{width: '8%'}}>ลำดับ</th>
                            <th className="text-center">ชื่อรุ่นย่อย</th>
                            <th className="text-center" style={{width: '10%'}}>จัดการ</th>
                        </tr>
                        </thead>
                        <tbody>
                        { carSubModelList }
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },

    inputSearch: {
        borderRadius:5,
        display: 'inline'
    },
    inputAdd: {
        marginLeft: 15,
        width: 200,
        borderRadius:5,
        display: 'inline'
    }
}
//export  default BranchForm;
