import React , { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { format } from 'date-fns'
import { APIURL } from '../../config/config'
import { AlertMessage } from '../Alert/Alert'

const localizer = momentLocalizer(moment)
require ("react-big-calendar/lib/css/react-big-calendar.css");

class CustomerFollowupCalendar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      followups: [],
      calendars: []
    }
    this.loadCustomerFollowup = this.loadCustomerFollowup.bind(this)
    this.getContent = this.getContent.bind(this)
    this.eventStyleGetter = this.eventStyleGetter.bind(this)
  }

  componentDidMount () {
    this.loadCustomerFollowup()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.isReload !== this.props.isReload) {
      if(this.props.isReload) {
        this.loadCustomerFollowup()
      }
    }
  }

  loadCustomerFollowup() {
    let username = this.props.user.user
    let type = this.props.user.type

    let uri = ''
    if(type !== 'admin') {
      uri = APIURL+'/customer/followup/last/'+username;
      // uri = APIURL+'/customer/followup/last/admin';
    } else {
      uri = APIURL+'/customer/followup/last/admin';
    }

    axios.get(uri).then(res => {

      let followups = res.data
      let calendars = followups.map((followup, index) => {
        // followup_times  followup_detail followup_next
        // let followup_times = followup.followup_times
        // let followup_detail = followup.followup_detail
        // let customer_mobile = followup.customer_mobile
        let followup_next = followup.followup_next
        let customer_fullname = followup.customer_name+' '+followup.customer_lastname

        let content = this.getContent(followup)

        let title = 'ชื่อ : '+customer_fullname+'\n'
        title += 'หัวข้อติดตาม : '+followup_next

        let calendar = {
          title: title,
          start: followup.followup_date,
          end: followup.followup_date,
          hex_color: '5EBA7D',
          content: content,
        }
        return calendar
      })
      this.setState({
        followups: res.data,
        calendars: calendars
      }, () => this.props.onStopReload())
    })
  }

  getContent(followup) {
    let followup_times = followup.followup_times
    let followup_detail = followup.followup_detail
    let followup_next = followup.followup_next
    let customer_fullname = followup.customer_name+' '+followup.customer_lastname
    let customer_mobile = followup.customer_mobile
    let contact_date = format(new Date(followup.contact_date), 'dd/MM/yyyy')
    let followup_date = format(new Date(followup.followup_date), 'dd/MM/yyyy')

    // contact_date followup_date

    let content = '<table cellpadding="10"><tbody>'
    content += '<tr><td class="text-left font-sm"> ครั้งที่ : </td><td class="text-left font-sm">'+followup_times+'</td></tr>'
    content += '<tr><td class="text-left font-sm"> วันที่ติดต่อ : </td><td class="text-left font-sm">'+contact_date+'</td></tr>'
    content += '<tr><td class="text-left font-sm"> วันที่นัดหมาย : </td><td class="text-left font-sm">'+followup_date+'</td></tr>'
    content += '<tr><td class="text-left font-sm"> รายละเอียด : </td><td class="text-left font-sm">'+followup_detail+'</td></tr>'
    content += '<tr><td class="text-left font-sm" width="120px"> หัวข้อติดตาม : </td><td class="text-left font-sm">'+followup_next+'</td></tr>'
    content += '<tr><td class="text-left font-sm"> ลูกค้า : </td><td class="text-left font-sm">'+customer_fullname+'</td></tr>'
    content += '<tr><td class="text-left font-sm"> โทร. : </td><td class="text-left font-sm">'+customer_mobile+'</td></tr>'
    content += '</tbody></table>'

    return content

  }

  eventStyleGetter(event, start, end, isSelected) {
    let backgroundColor = '#' + event.hex_color;
    let style = {
      backgroundColor: backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style: style
    };
  }

  render () {

    return (
      <div>
        <Calendar
          localizer={localizer}
          selectable
          style={{height: '960px' , backgroundColor:'#ffffff'}}
          events={this.state.calendars}
          defaultView='month'
          defaultDate={new Date()}
          onSelectEvent={event => AlertMessage(event.content)}
          eventPropGetter={(this.eventStyleGetter)}
        />
      </div>
    )
  }
}

export default CustomerFollowupCalendar
