import React, {Component} from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import {format, lastDayOfMonth } from "date-fns";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import {APIURL, IMAGE_NOPHOTO_URL, PORT} from "../../../config/config";
import {AlertMessage, AlertWarning} from "../../Alert/Alert";
import Loading from "../../Loading";
import { Modal, ModalBody } from "reactstrap";
import PreviewImage from "../../../views/PreviewImage";

const localizer = momentLocalizer(moment)
require ("react-big-calendar/lib/css/react-big-calendar.css");

class CarReservationCalendar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            date_start: new Date(),
            date_end: new Date(),
            branch_id: '',
            branches: [],
            car_book_list: [],
            preview_image_select: false,
            preview_image_url: '',
            slip_image1: IMAGE_NOPHOTO_URL,
            slip_image2: IMAGE_NOPHOTO_URL,
            slip_key1: '',
            slip_key2: '',
            car_sell_pay_selected: {},
            toggle_bill_print: false,
            book_number: '',
            volume_number: '',
            car_sell_pay_id: '',
            car_sell_pay_count: 0,
            car_book_refund: 0,
            book_comment_refund: null,
            toggle_change_car: false,
            calendars: []
        }

        this.handleDateStart = this.handleDateStart.bind(this);
        this.handleDateEnd = this.handleDateEnd.bind(this);
        this.loadBranchData = this.loadBranchData.bind(this);
        this.loadCarBookOutCarDate = this.loadCarBookOutCarDate.bind(this);
        this.toggleSlip = this.toggleSlip.bind(this);
        this.getContent = this.getContent.bind(this);
        this.eventStyleGetter = this.eventStyleGetter.bind(this);
    }

    componentDidMount() {
        // this.loadBranchData()
        let today = new Date()
        let start = format(today, 'yyyy-MM-01')
        let end = format(lastDayOfMonth(today), 'yyyy-MM-dd')

        this.setState({
            date_start : new  Date(start),
            date_end : new  Date(end),
        }, () => {
            this.loadCarBookOutCarDate()
        })
    }

    toggleSlip(){
        this.setState({
            toggle_slip: !this.state.toggle_slip
        })
    }

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    loadCarBookOutCarDate() {
        if(this.state.date_start > this.state.date_end){
            AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
        let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
        // let branch_id = this.state.branch_id
        let username = this.props.user.user
        let branch_id = ''

        const data = {
            start_date,
            end_date,
            branch_id,
            username
        }

        let api_url = APIURL + '/car_reservation/outcar_date'

        axios.post(api_url, data)
            .then(res => {
                let car_book_list = res.data

                let calendars = car_book_list.map((car_book, index) => {
                    let customer_fullname = car_book.customer_name+' '+car_book.customer_lastname
                    const car_license_plate = car_book.car_license_plate_new !== '' &&  car_book.car_license_plate_new !==  car_book.car_license_plate_old ? car_book.car_license_plate_new : car_book.car_license_plate_old
                    const province_name = car_book.car_license_plate_new !== '' &&  car_book.car_license_plate_new !==  car_book.car_license_plate_old ? car_book.province_name_new : car_book.province_name_old
                    let car_license = car_license_plate+' '+province_name

                    let content = this.getContent(car_book)

                    let title = 'ชื่อ : '+customer_fullname+'\n ทะเบียน : '+car_license
                    let customer_outcar_date = car_book.customer_outcar_date

                    let calendar = {
                        title: title,
                        start: customer_outcar_date,
                        end: customer_outcar_date,
                        hex_color: '5EBA7D',
                        content: content,
                    }
                    return calendar
                })

                this.setState({
                    car_book_list: car_book_list,
                    calendars: calendars
                })
            }).catch(error => {
            console.log('พอข้อผิดพลาด: ' + error.message)
            this.setState({car_book_list: []})
        })
    }

    getContent(car_book) {

        let customer_fullname = car_book.customer_name+' '+car_book.customer_lastname
        const car_license_plate = car_book.car_license_plate_new !== '' &&  car_book.car_license_plate_new !==  car_book.car_license_plate_old ? car_book.car_license_plate_new : car_book.car_license_plate_old
        const province_name = car_book.car_license_plate_new !== '' &&  car_book.car_license_plate_new !==  car_book.car_license_plate_old ? car_book.province_name_new : car_book.province_name_old
        let car_license = car_license_plate+' '+province_name
        let customer_outcar_date = format(new Date(car_book.customer_outcar_date), 'dd/MM/yyyy')
        let booking_time = format(new Date(car_book.booking_time), 'dd/MM/yyyy')
        let customer_mobile = car_book.customer_mobile
        let profile_from = car_book.profile_from
        let profile_name = car_book.profile_name

        let content = '<table cellpadding="10"><tbody>'
        content += '<tr><td class="text-right font-lg"> วันที่ออกรถ : </td><td class="text-left font-lg">'+customer_outcar_date+'</td></tr>'
        content += '<tr><td class="text-right font-lg"> วันที่จอง : </td><td class="text-left font-lg">'+booking_time+'</td></tr>'
        content += '<tr><td class="text-right font-lg"> ชื่อลูกค้า : </td><td class="text-left font-lg">'+customer_fullname+'</td></tr>'
        content += '<tr><td class="text-right font-lg"> เบอร์โทร : </td><td class="text-left font-lg">'+customer_mobile+'</td></tr>'
        content += '<tr><td class="text-right font-lg"> ทะเบียน : </td><td class="text-left font-lg">'+car_license+'</td></tr>'
        content += '<tr><td class="text-right font-lg"> ลูกค้าจาก : </td><td class="text-left font-lg">'+profile_from+'</td></tr>'
        content += '<tr><td class="text-right font-lg"> โปรไฟล์ลูกค้า : </td><td class="text-left font-lg">'+profile_name+'</td></tr>'
        content += '</tbody></table>'
        return content
    }

    saveCarImage(e,urlImage){
        e.preventDefault();
        let a = document.createElement('a');
        a.href = urlImage;
        a.target = '_blank';
        a.download = urlImage;
        a.click();
        a.remove();
        return false;
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    eventStyleGetter(event, start, end, isSelected) {
        let backgroundColor = '#' + event.hex_color;
        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
        };
        return {
            style: style
        };
    }


    render() {

        /*let car_branch_list = this.state.branches.map((branch, index) => {
            return (
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })*/


        return (
            <>
                <Loading isLoading={this.state.isLoading} />

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>

                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="card card-accent-info">
                            <div className="card-header">
                                <strong className="text-title"> ปฏิทินวันที่ออกรถ </strong>
                            </div>

                            <div className="card-block">
                                <div className="row col-12">
                                    <div className="mt-1 ml-2">
                                        วันที่ออกรถ
                                    </div>
                                    <div className="form-group col-2 ml-2">
                                        <DatePicker
                                            selected={this.state.date_start}
                                            onChange={this.handleDateStart}
                                            dateFormat="yyyy-MM-dd"
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="form-group col-2">
                                        <DatePicker
                                            selected={this.state.date_end}
                                            onChange={this.handleDateEnd}
                                            dateFormat="yyyy-MM-dd"
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>

                                    {/*<div className="form-group col-2">
                                        <select className="form-control"
                                                id="branch_id"
                                                name="branch_id"
                                                onChange={(e) => {
                                                    this.setState({
                                                        branch_id: e.target.value
                                                    })
                                                }}
                                        >
                                            <option value=''> ตามสาขา </option>
                                            {car_branch_list}
                                        </select>
                                    </div>*/}

                                    <div className="col-2 ml-4 mr-1">
                                        <button type="button" style={{width: 80}}
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.loadCarBookOutCarDate() }  > ตกลง
                                        </button>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <Calendar
                                            localizer={localizer}
                                            selectable
                                            style={{height: '960px' , backgroundColor:'#ffffff'}}
                                            events={this.state.calendars}
                                            defaultView='month'
                                            defaultDate={new Date()}
                                            onSelectEvent={event => AlertMessage(event.content)}
                                            eventPropGetter={(this.eventStyleGetter)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    },
    fileInputWrapper: {
        position: 'relative',
        overflow: 'hidden',
        height: 180,
        width: 200,
    },
    btnFileInput: {
        height: 180,
        width: 200,
        display: 'inline-block',
        backgroundColor: "#D2DDE2",
        backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
        backgroundRepeat: "repeat-x",
        color: "#ffffff",
        textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
    },
    btnFileInputImage:{
        position: 'absolute',
        top: 0,
        left: 0,
        height: 180,
        width: 200,
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        right: 0,
        opacity: 0,
        height: 180,
        width: 200,
        cursor: 'pointer'
    }
}

export default CarReservationCalendar
