/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import EmployeeListRow from '../../../components/Employee/EmployeeListRow'
import EmployeeForm from '../../../components/Employee/EmployeeForm'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { connect } from 'react-redux'
import { AlertSuccess } from '../../Alert/Alert'

class EmployeeList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            employee_id: 0,
            action: "บันทึก",
            employee: '',
            user: {},
            user_type: '',
          branchs: [],
        }

        this.updateEmployee = this.updateEmployee.bind(this)
        this.addEmployee = this.addEmployee.bind(this)
        this.loadData = this.loadData.bind(this)
      this.loadBranchData = this.loadBranchData.bind(this)
    }

  componentWillMount () {
    // this.mounted = true;
    //const queryString = require('query-string');
    var user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    })
  }

    componentDidMount() {
        // if(this.state.load_data)
        this.loadData()
        this.loadBranchData()
    }

    toggleDanger() {
        this.setState({
            danger: !this.state.danger
        })
    }

    toggleDangerDelete(id) {

        axios.delete(APIURL + '/employee/' + id).then(res => {
            if (res.status === 200) {
                this.loadData()
                this.setState({action: "บันทึก"});
            }
        })
    }

    deleteEmployee(employee) {
        this.setState({employee_id: employee.employee_id, employee:employee})
        this.toggleDanger()
    }

    editEmployee(employee) {
        //console.log(employee)
        this.setState({
            action: "แก้ไข",
            employee: employee,
            employee_id: employee.employee_id
        });
    }

    loadData() {
      axios.get(APIURL + '/employee/')
        .then(res => {
          // console.log(res.data);
          if(res.status === 200) {
            this.setState({lists: res.data, load_data: false, employee_id: 0, danger: false, employee:{}})
          }
        }).catch(error => {
          // console.log(error);
          this.setState({lists: [], load_data: true, employee_id: 0, danger: false})
      })
    }


  loadBranchData() {
    // axios.get(APIURL + '/branch/')
    //   .then(res => {
    //     console.log(res.data)
    //     this.setState({branchs: res.data})
    //   }).catch(error => {
    //   this.setState({branchs: []})
    // })
  }


    addEmployee (event){
        event.preventDefault()
        // let that = this ;
        let employee_name = event.target.employee_name.value
        let employee_lastname = event.target.employee_lastname.value
        let employee_address = event.target.employee_address.value
        let employee_tel = event.target.employee_tel.value
        let employee_position = event.target.employee_position.value
        let employee_startwork = event.target.employee_startwork_text.value
        let employee_salary = event.target.employee_salary.value
        //let employee_commissions = event.target.employee_commissions.value
        let employee_card_id = event.target.employee_card_id.value
        let employee_code = event.target.employee_code.value
        let branch_id = event.target.branch_id.value

        if(employee_name===''){
            return ;
        }
        event.target.reset();
        event.target.employee_name.value = ""
        event.target.employee_lastname.value = ""
        event.target.employee_address.value = ""
        event.target.employee_tel.value = ""
        event.target.employee_position.value = ""
        event.target.employee_startwork.value = ""
        event.target.employee_salary.value = ""
        //event.target.employee_commissions.value = ""
        event.target.employee_card_id.value = ""
        event.target.employee_code.value = ""
        event.target.branch_id.value = ""

        axios.post(APIURL + '/employee/', {
            employee_name: employee_name,
            employee_lastname: employee_lastname,
            employee_address : employee_address,
            employee_tel: employee_tel,
            employee_position : employee_position,
            employee_startwork : employee_startwork,
            employee_salary: employee_salary,
            //employee_commissions : employee_commissions,
            employee_card_id : employee_card_id,
            employee_code : employee_code,
            status_field: 'show',
            username: 'admin',
          branch_id: branch_id
        })
        .then( res => {
            // console.log(response)
            if(res.status === 200) {
               this.loadData()
               this.setState({employee: {}, employee_id: 0, action: "บันทึก"})
            }
        })
        .catch( res => {
            //console.log(error)
        })
    }

    updateEmployee(event){
        event.preventDefault()

        let employee_id = this.state.employee_id;
        let employee_name = event.target.employee_name.value
        let employee_lastname = event.target.employee_lastname.value
        let employee_address = event.target.employee_address.value
        let employee_tel = event.target.employee_tel.value
        let employee_position = event.target.employee_position.value
        let employee_startwork = event.target.employee_startwork_text.value
        let employee_salary = event.target.employee_salary.value
        //let employee_commissions = event.target.employee_commissions.value
        let employee_card_id = event.target.employee_card_id.value
        let employee_code = event.target.employee_code.value
        let branch_id = event.target.branch_id.value

      let username = this.state.user.user
      // console.log('username=', username)
      // return

        if(employee_name === ''){
            return ;
        }

        event.target.employee_name.value = ""
        event.target.employee_lastname.value = ""
        event.target.employee_address.value = ""
        event.target.employee_tel.value = ""
        event.target.employee_position.value = ""
        event.target.employee_startwork.value = ""
        event.target.employee_salary.value = ""
        //event.target.employee_commissions.value = ""
        event.target.employee_card_id.value = ""
        event.target.employee_code.value = ""
        event.target.branch_id.value = ""


        axios.put(APIURL + '/employee/'+employee_id, {
            employee_id : employee_id,
            employee_name: employee_name,
            employee_lastname: employee_lastname,
            employee_address : employee_address,
            employee_tel: employee_tel,
            employee_position : employee_position,
            employee_startwork : employee_startwork,
            employee_salary: employee_salary,
            //employee_commissions : employee_commissions,
            employee_card_id : employee_card_id,
            employee_code : employee_code,
            status_field: 'show',
            username: username,
           branch_id: branch_id
        })
            .then( res => {
              // console.log(response)
              if(res.status === 200) {
                AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว')
                this.loadData()
                this.setState({employee: {}, employee_id: 0, action: "บันทึก"})
              }
              //that.togglePrimaryEmployeeEdit();
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

  onSearch(){
    var search_text = this.refs.input_search.value

    if(search_text==='') return;

    axios.get(APIURL + '/employee/search/'+search_text)
      .then(res => {
        if(res.status === 200) {
          this.setState({lists: res.data, load_data: false, employee_id: 0, danger: false})
        }
      }).catch(error => {
          this.setState({lists: [], load_data: true, employee_id: 0, danger: false})
    })

  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadData()
  }

  render() {
        //let employee = this.employee;
        return (
            <div className="card">
                <div className="card-block" style={styles.overflowY}>
                    <div className="row">

                        <div className="col-sm-7">
                            <div className="card card-accent-info">
                                <div className="card-header">
                                    <strong className="text-title">ข้อมูลพนักงาน</strong>
                                    <input
                                      onChange={this.onSearch.bind(this)}
                                      ref="input_search"
                                      style={styles.inputSearch}
                                      className="form-control"
                                      placeholder="ค้นหาตามชื่อ หรือ เบอร์โทร"
                                      type="text"
                                    />&nbsp;&nbsp;&nbsp;

                                    <button className="btn btn-head-bar"
                                            style={styles.buttonHeight}
                                            onClick={this.onRefresh.bind(this)}

                                    >
                                        <i className="icon-refresh"/>
                                    </button>
                                </div>
                                <div className="card-block" style={styles.overflowY}>
                                  <EmployeeListRow
                                    employee_list={this.state.lists}
                                    employeeCallbacks={
                                    {
                                      delete: this.deleteEmployee.bind(this),
                                      edit: this.editEmployee.bind(this),
                                    }
                                   }
                                    user_type={this.state.user_type}
                                  />
                                  {/* <ul className="pagination">
                                   <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                                   <li className="page-item active">
                                   <a className="page-link" href="#">1</a>
                                   </li>
                                   <li className="page-item"><a className="page-link" href="#">2</a></li>
                                   <li className="page-item"><a className="page-link" href="#">3</a></li>
                                   <li className="page-item"><a className="page-link" href="#">4</a></li>
                                   <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                   </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <EmployeeForm
                                onEmployeeSubmit={
                                    {
                                        add: this.addEmployee.bind(this),
                                        update: this.updateEmployee.bind(this)
                                    }
                                }
                                actionType={this.state.action}
                                employee={this.state.employee}
                                user_type={this.state.user_type}

                            />
                        </div>
                    </div>

                    <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
                           className={'modal-danger'}>
                         <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบข้อมูล</ModalHeader>
                         <ModalBody>
                               <strong> คุณต้องการลบข้อมูล {this.state.employee.employee_name} ใช่หรือไม่ ?? </strong>
                               <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                         </ModalBody>
                         <ModalFooter>
                            <Button color="primary"
                                 onClick={this.toggleDangerDelete.bind(this, this.state.employee_id)}>ตกลง</Button>{' '}
                            <Button color="secondary" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>
                         </ModalFooter>
                    </Modal>

                </div>
            </div>
        );
  }
}

const styles = {
    inputSearch: {
        marginLeft: 15,
        width: 250,
        borderRadius:5,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        borderRadius:5,
        display: 'inline',
        marginTop: '-0.35rem'
    },

    overflowY: {
        overflow: 'scroll',
    }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(EmployeeList)
