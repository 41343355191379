import React from 'react'
import Lottie from "react-lottie";
import {defaultLoadingOptions} from "../utils/loadingOption";

const Loading = ({isLoading}) => {
  return (
    isLoading ? <div className="overlay">
      <div className="vertical-center text-center">
        <Lottie options={defaultLoadingOptions} height={140} width={140} />
      </div>
    </div> : <div></div>
  )
}

export default Loading
