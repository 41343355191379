import React,{Component} from 'react';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import NumberFormat from "react-number-format";
import {connect} from "react-redux";
import {checkGroupPermission} from "../../../services/grouppermission";
import { AlertSuccess } from '../../Alert/Alert';

// const port = window.env.PORT;
// import DatePicker from "react-datepicker";
// import moment from "moment";

class CarDepositRefund extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user : {},
            carDeposit : {isChange : false},
            isExist : false,
            amount_refund : '',
            is_refund : 0,
            isFirstLoad: true,
            car_id: null
        }

        this.loadCarDepositRefund = this.loadCarDepositRefund.bind(this)
        this.handleOnAmountRefund = this.handleOnAmountRefund.bind(this)
        this.updateDepositRefund = this.updateDepositRefund.bind(this)
    }

    componentDidMount() {
        this.mounted = true
        this.loadCarDepositRefund()
    }

    componentWillMount () {
        this.setState({
            user: JSON.parse(this.props.user)
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.car_id !== this.props.car_id) {
            this.setState({
                car_id: nextProps.car_id,
                carDeposit : {isChange : false},
                isExist : false,
                isFirstLoad: true,
                amount_refund: '',
            },() =>  this.loadCarDepositRefund())
        }
    }

    loadCarDepositRefund() {
        if(this.mounted) {
            // console.log('===loadCarDepositRefund car_id=' + this.props.car.car_id)
            let car_id = this.props.car_id
            let uri =  APIURL + '/car_deposit_refund/'+car_id;
            axios.get(uri)
                .then(res => {
                       let isExist = res.data.length === 0 ?  false : true
                       let carDeposit = res.data.length === 0 ?  {isChange : false} : {...res.data[0], isChange : false}

                        this.setState({
                            carDeposit: carDeposit,
                            isExist,
                            isFirstLoad: true,
                            is_refund : 0,
                        },()=>{
                            if(isExist){

                                this.setState({
                                    amount_refund : carDeposit.amount_refund,
                                    is_refund : carDeposit.is_refund,
                                })
                            }
                        })

                }).catch(error => {
                if(this.mounted) {
                    this.setState({ lists: [] })
                }
            })
        }
    }

    handleOnAmountRefund(){
        let uri =  APIURL + '/car_deposit_refund/amount'
        let data = {
            car_id : this.props.car_id,
            amount_refund : this.state.amount_refund,
            is_refund : this.state.is_refund,
            user_created : this.state.user.user,
        }
        //console.log(data)

        axios.post(uri, data)
            .then(res => {

                AlertSuccess('บันทึกเรียบร้อยแล้ว');
                // console.log(res.data)
                let carDeposit = {...res.data, isChange : false}
                let isExist = true
                this.setState({
                    carDeposit: carDeposit,
                    isExist,
                    isFirstLoad: true
                },()=>{
                    if(isExist){

                        this.setState({
                            amount_refund : carDeposit.amount_refund
                        })
                    }
                })

            })
            .catch(error => {
                console.log(error)
            })
    }

    updateDepositRefund(){
        let car_deposit_refund_id = this.state.carDeposit.car_deposit_refund_id
        let amount_refund = this.state.amount_refund
        let is_refund = this.state.is_refund

        let uri =  APIURL + '/car_deposit_refund/'+car_deposit_refund_id

        let data = {
            car_deposit_refund_id : car_deposit_refund_id,
            amount_refund : amount_refund,
            is_refund : is_refund,
            user_updated : this.state.user.user,
        }

        axios.put(uri, data)
            .then(res => {

                AlertSuccess('แก้ไขเรียบร้อยแล้ว');
                // console.log(res.data)
                let carDeposit = {
                    ...this.state.carDeposit,
                    amount_refund : amount_refund,
                    is_refund : is_refund,
                    user_updated : this.state.user.user,
                    isChange : false,
                }

                let isExist = true
                this.setState({
                    carDeposit: carDeposit,
                    isExist,
                    isFirstLoad: true
                },()=>{
                    if(isExist){

                        this.setState({
                            amount_refund : carDeposit.amount_refund
                        })
                    }
                })

            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const {isExist, carDeposit, isChange } = this.state

        return (

            <div>
                <div className="row mt-2">
                    <div className="col col-sm-12 col-md-12 col-lg-12 pb-2 text-left">
                        <strong>มัดจำเล่ม รอรับเงินคืน</strong>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col col-sm-12 col-md-12 col-lg-5">

                        {
                            checkGroupPermission(108, this.props.grouppermissions).created ||
                            checkGroupPermission(108, this.props.grouppermissions).modified ?(

                                <>
                                    <input type="text"
                                           className="form-control hidden "
                                           name="amount_refund"
                                           placeholder="ยอดเงินมัดจำ"
                                           ref="amount_refund"
                                           value={this.state.amount_refund}
                                           onChange={()=>{}}
                                    />
                                    <NumberFormat
                                        className="form-control text-right"
                                        thousandSeparator={true}
                                        prefix={'฿'}
                                        // placeholder="ยอดเงินมัดจำ"
                                        min={0}
                                        max={1000000000}
                                        step={1000}
                                        size={10}
                                        allowNegative={false}
                                        value={this.state.amount_refund}
                                        disabled={carDeposit.is_refund === 1 && this.state.is_refund === 1}
                                        onValueChange={(values) => {
                                            const {value} = values

                                            // add
                                            if(carDeposit.amount_refund === undefined ){

                                                let carDepositNew = {...carDeposit, isChange : false}
                                                if(value !== ''){
                                                    carDepositNew = {...carDeposit, isChange : true}
                                                }

                                                this.setState({amount_refund: value, carDeposit: carDepositNew}, () => {
                                                    // console.log('เงินมัดจำ add =' + value)

                                                })
                                            } else {
                                                // edit
                                                if(this.state.amount_refund !== '' && this.state.isFirstLoad === true ){
                                                    let carDepositNew = {...carDeposit, isChange : false}
                                                    this.setState({amount_refund: value, carDeposit: carDepositNew, isFirstLoad: false}, () => {
                                                        // console.log('เงินมัดจำ xx =' + value)
                                                        //this.handleOnChange()
                                                    })
                                                } else {
                                                    let carDepositNew = {...carDeposit, isChange : true}

                                                    if (Number(carDeposit.amount_refund) === Number(value)) {
                                                        carDepositNew = {...carDeposit, isChange : false}
                                                    }

                                                    // console.log('amount_refund:', Number(carDeposit.amount_refund))
                                                    // console.log('value:', Number(value))

                                                    this.setState({amount_refund: value, carDeposit: carDepositNew, isFirstLoad: false}, () => {
                                                        // console.log('เงินมัดจำ yy =' + value)
                                                        //this.handleOnChange()
                                                    })
                                                }
                                            }
                                        }}

                                    />
                                </>
                            ) : (
                                <NumberFormat
                                    className="form-control text-right"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    // placeholder="ยอดเงินมัดจำ"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    disabled={true}
                                    value={'000'}
                                    // value={this.state.amount_refund}
                                    /*onValueChange={(values) => {
                                        const {value} = values
                                        this.setState({amount_refund: value}, () => {
                                            console.log('เงินมัดจำ =' + value)
                                            //this.handleOnChange()
                                        })

                                    }}*/
                                />
                            )
                        }

                    </div>


                    <div className="form-group col col-sm-12 col-md-12 col-lg-3 text-left mt-2 align-items-center justify-content-center">
                        <input type="checkbox"
                               name={'is_refund'}
                               value={this.state.is_refund}
                               checked={this.state.is_refund === 1}
                               onChange={(e)=>{
                                   let value = e.target.value === '0' ? 1 : 0

                                  if(carDeposit.is_refund !== undefined){
                                      let carDepositNew = {...carDeposit, isChange : true}

                                      if (Number(carDeposit.is_refund) === Number(value) && Number(carDeposit.amount_refund) === Number(this.state.amount_refund)) {
                                          carDepositNew = {...carDeposit, isChange : false}
                                      }

                                      this.setState({
                                          is_refund : value,
                                          carDeposit : carDepositNew,
                                      })
                                  }else {
                                      this.setState({
                                          is_refund : value
                                      })
                                  }
                               }}
                        />
                        <span className="align-self-center text-center"> &nbsp;ได้รับเงินคืน</span>
                    </div>

                    {
                        isExist && carDeposit.isChange ? (
                            <div className="form-group col col-sm-12 col-md-12 col-lg-4 text-left">
                                <button
                                    onClick={() => this.updateDepositRefund() }
                                    type="button"
                                    className="btn btn-warning btn-sm btn-block"
                                > แก้ไข
                                </button>
                        </div>
                        ) : (!isExist && carDeposit.isChange ?
                            (
                                <div className="form-group col col-sm-12 col-md-12 col-lg-4 text-left">
                                    <button
                                        onClick={() => this.handleOnAmountRefund()}
                                        type="button"
                                        className="btn btn-primary btn-sm btn-block"
                                    > บันทึก
                                    </button>
                                </div>
                            )
                            : null
                        )
                    }
                    {/*<div className="form-group col col-sm-12 col-md-12 col-lg-4 text-left">
                        <button //onClick={() => this.handleOnClick('car_deposit_refund', car) // }
                            type="button"
                            className="btn btn-primary btn-sm btn-block"
                        > กดรับเงิน
                        </button>
                    </div>*/}
                </div>

            </div>


        )

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarDepositRefund);
