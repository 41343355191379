import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import axios from 'axios'
import { format } from 'date-fns'
import { APIURL } from '../../config/config'
import { AlertError, AlertSuccess, AlertWarning } from '../Alert/Alert'
import BDatePicker from '../BDatePicker'
import Swal from 'sweetalert2'
import { CustomerFollowupUtils } from './CustomerFollowupUtils'

class CustomerFollowupForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      customer_name: '',
      customer_lastname: '',
      customer_mobile: '',
      customer_note: '',
      followup_by: null,
      accounts: [],
      car_type_interest: '',
      car_type_interests: [
        {car_type: 'เก๋ง'},
        {car_type: 'กระบะ'},
        {car_type: 'SUV'},
        {car_type: 'ตอนเดียว'},
      ],
      customer_id: this.props.customer_id,
      //
      followup_id: this.props.followup_id,
      followup_times: null,
      contact_date: null,
      followup_date: null,
      followup_detail: null,
      followup_next: null,
      //
      is_reload: false,
      // customer_type lead , reject , cancel, complete
      customer_type: 'lead',
      customer_types: CustomerFollowupUtils.getCustomerTypes()

    }
    this.handleOnSubmitCustomer = this.handleOnSubmitCustomer.bind(this)
    this.handleOnSubmitCustomerFollowUp = this.handleOnSubmitCustomerFollowUp.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.loadAccountData = this.loadAccountData.bind(this)
    this.loadCustomerLead = this.loadCustomerLead.bind(this)
  }

  componentDidMount () {
    this.loadAccountData();

    let customer_id = this.props.customer_id
    if (customer_id !== null) {
      this.loadCustomerLead(customer_id)
    }else {
      //  followup_by:
      this.setState({
        followup_by: this.props.user.type === 'sale' ? this.props.user.user : this.props.user.user,
      })
    }

  }

  loadCustomerLead(customer_id) {
    let uri = APIURL+'/customer/'+customer_id;
    axios.get(uri).then(res => {
      let customer = res.data[0]
      //     "customer_name": "ศุภิสรา",
      //     "customer_lastname": "อินทรเกียรติ",
      //     "customer_mobile": "0981234567",
      //     "car_type_interest": "เก๋ง",
      //     "customer_note": "พนักงานเอกชน เงินเดือน 1222",
      //     "followup_by": "bosscar",
      this.setState({
        customer_name: customer.customer_name,
        customer_lastname: customer.customer_lastname,
        customer_mobile: customer.customer_mobile,
        car_type_interest: customer.car_type_interest,
        customer_note: customer.customer_note,
        followup_by: customer.followup_by,
        customer_id: customer.customer_id,
        customer_type: customer.customer_type,
      })
      console.log(customer)

    })
  }

  handleOnSubmitCustomer(e) {
    e.preventDefault()
    let username = this.props.user.user
    let type = this.props.user.type
    let customer_name = this.state.customer_name
    let customer_lastname = this.state.customer_lastname
    let customer_mobile = this.state.customer_mobile
    let car_type_interest = this.state.car_type_interest
    let customer_note = this.state.customer_note
    let customer_id = this.state.customer_id
    let customer_type = this.state.customer_type

    let followup_by = null
    if (type !== 'admin') {
      followup_by = username
    }else if (type === 'admin') {
      followup_by = this.state.followup_by
    }

    if (customer_name === '' || customer_lastname === '' || customer_mobile === '') {
      AlertWarning('กรุณากรอกข้อมูลลูกค้า\n ให้ครบถ้วน');
      return
    }

    if(followup_by === null || followup_by === '' ) {
      AlertWarning('กรุณาเลือกติดตามโดย');
      return
    }

    let data = {
      customer_name,
      customer_lastname,
      customer_mobile,
      car_type_interest,
      customer_note,
      followup_by,
      username,
      customer_type
    }

    if (customer_id === null) {
      let uri = APIURL+'/customer/followup/lead'
      axios.post(uri, data).then(res => {
        let result = res.data.result
        let customer_id = res.data.customer_id

        if(result === 'success') {
          AlertSuccess('บันทึกข้อมูลลูกค้า(Lead) เรียบร้อยแล้ว');
          this.setState({
            customer_id: customer_id,
            is_reload: true
          })
        }
      }).catch(error => {
        console.log('error:', error)
        this.setState({
          customer_id: null
        })
      })
    } else {
      let uri = APIURL+'/customer/followup/lead/'+customer_id
      let dataUpdate = {...data, customer_id: customer_id}

      axios.put(uri, dataUpdate).then(res => {
        let result = res.data.result
        if(result === 'success') {
          AlertSuccess('แก้ไขข้อมูลลูกค้า(Lead) เรียบร้อยแล้ว');
          this.setState({
            is_reload: true
          })
        }
      })
    }
  }

  handleOnSubmitCustomerFollowUp(e) {
    e.preventDefault();
    let customer_id = this.state.customer_id
    if (customer_id === null) {
      AlertError('กรุณาบันทึกข้อมูลลูกค้าก่อน');
      return
    }

    const {
      followup_id,
      followup_times,
      contact_date,
      followup_date,
      followup_detail,
      followup_next,
    } = this.state

    if (followup_times === 0 || contact_date === null || followup_date === null || followup_detail === null || followup_next === null) {
      AlertWarning('กรุณากรอกข้อมูลการติดต่อ\nให้ครบถ้วน');
      return
    }

    if (contact_date > followup_date) {
      AlertWarning('วันที่ไม่ถูกต้องกรุณาตรวจสอบ');
      return
    }

    let username = this.props.user.user
    let data = {
      customer_id,
      followup_times,
      contact_date,
      followup_date,
      followup_detail,
      followup_next,
      username
    }

    if (followup_id === null) {
      // add
      let uri = APIURL+'/customer/followup'
      axios.post(uri, data).then(res => {
        console.log(res.data)
        let result = res.data.result
        let followup_id = res.data.followup_id
        if(result === 'success') {
          AlertSuccess('บันทึกข้อมูลการติดต่อ\nเรียบร้อยแล้ว');
          this.setState({
            followup_id: followup_id,
            is_reload: true
          })
        }
      })
    } else {
      // edit
      let uri = APIURL+'/customer/followup/'+followup_id
      axios.put(uri, data).then(res => {
        console.log(res.data)
        let result = res.data.result
        if(result === 'success') {
          AlertSuccess('แก้ไขข้อมูลการติดต่อ\nเรียบร้อยแล้ว');
          this.setState({
            is_reload: true
          })
        }
      })
    }

  }

  onInputChange(e) {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }

  loadAccountData () {
    axios.get(APIURL + '/account/')
      .then(res => {
        this.setState({accounts: res.data})
      }).catch(error => {
      this.setState({accounts: []})
    })
  }

  render () {
    const {
      isOpen,
      user,
      admin_followup_by
    } = this.props

    let type = user.type

    const {
      accounts,
      car_type_interests
    } = this.state

    let account_list = accounts.map((account, index) => (
      <option key={index} value={account.user} >{account.user}</option>
    ));

    let car_type_interest_list = car_type_interests.map((car_type_interest, index) => (
      <option key={index} value={car_type_interest.car_type} >{car_type_interest.car_type}</option>
    ));

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.props.onToggle}
        className="modal-book-lg"
        backdrop={'static'}
      >
        <ModalBody>
          <div>
            <div className="row">
              <div className="col-lg-6">
                <form action="" method="post" onSubmit={this.handleOnSubmitCustomer}>
                <div className="card card-accent-primary">
                  <div className="card-header">
                    <strong>ข้อมูลลูกค้า(Lead)</strong> &nbsp;&nbsp;
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="form-group col-sm-6">
                        <label htmlFor="customer_name">ชื่อ</label>
                        <input type="text"
                               className="form-control"
                               name="customer_name"
                               placeholder=""
                               maxLength={50}
                               value={this.state.customer_name || ''}
                               onChange={this.onInputChange}
                        />
                      </div>
                      <div className="form-group col-sm-6">
                        <label htmlFor="customer_lastname">นามสกุล</label>
                        <input type="text"
                               className="form-control"
                               name="customer_lastname"
                               placeholder=""
                               maxLength={50}
                               value={this.state.customer_lastname || ''}
                               onChange={this.onInputChange}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>
                        <input type="text"
                               className="form-control"
                               name="customer_mobile"
                               placeholder=""
                               maxLength={20}
                               value={this.state.customer_mobile || ''}
                               onChange={this.onInputChange}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <label htmlFor="customer_note">รายละเอียดลูกค้า</label>
                        <textarea
                               className="form-control"
                               name="customer_note"
                               placeholder=""
                               value={this.state.customer_note || ''}
                               onChange={this.onInputChange}
                        />
                      </div>


                      <div className="form-group col-md-12">
                        <label htmlFor="car_type_interest">ประเภทรถที่สนใจ</label>
                        <select
                          className="form-control"
                          name="car_type_interest"
                          value={this.state.car_type_interest || ''}
                          onChange={(e) => {
                            let value = e.target.value
                            this.setState({
                              car_type_interest: value
                            })
                          }}
                        >
                          <option value=''>เลือกประเภทรถ</option>
                          {car_type_interest_list}
                        </select>
                      </div>


                      <div className="form-group col-md-6">
                        <label htmlFor="customer_type">สถานะ</label>
                        <select
                          className="form-control"
                          name="customer_type"
                          value={this.state.customer_type || ''}
                          onChange={(e) => {
                            let value = e.target.value

                            if (value !== 'lead'){
                              let customer_types = this.state.customer_types.filter(customer_type=>customer_type.customer_type === value)[0]
                              let customer_label = customer_types.customer_label
                              Swal.fire({
                                title: 'การเปลี่ยนสถานะเป็น '+customer_label + ' ลูกค้าจะไม่ถูกติดตามและไม่แจ้งเตือนในปฏิทินการติดตาม',
                                icon: 'warning',
                                showConfirmButton: true,
                                showCancelButton: true,
                              }).then((result) => {
                                if (result.value) {
                                  this.setState({
                                    customer_type: value
                                  })
                                }
                              })
                            } else {
                              this.setState({
                                customer_type: value
                              })
                            }
                          }}
                        >
                          {
                            this.state.customer_types.map((customer_type, index) => (
                              <option value={customer_type.customer_type} > {customer_type.customer_label} </option>
                            ))
                          }
                        </select>
                      </div>

                      {
                        type !== 'admin' ?
                          <div className="form-group col-sm-6">
                            <label htmlFor="customer_mobile">ติดตามโดย</label>
                            <input type="text"
                                   className="form-control"
                                   name="followup_by"
                                   placeholder=""
                                   maxLength={45}
                                   disabled={true}
                                   value={this.state.followup_by || ''}
                                   onChange={() => {}}
                            />
                          </div>
                          : null
                      }

                      {
                        type === 'admin' ?
                          <div className="form-group col-md-6">
                            <label htmlFor="followup_by">ติดตามโดย</label>
                            <select
                              className="form-control"
                              name="followup_by"
                              value={this.state.followup_by || ''}
                              onChange={(e) => {
                                let value = e.target.value
                                this.setState({
                                  followup_by: value
                                })
                              }}
                            >
                              <option value=''>เลือกผู้ติดตาม</option>
                              {account_list}
                            </select>
                          </div>
                          : null
                      }


                    </div>
                  </div>
                  <div className="card-footer text-right">
                    {
                      this.state.customer_id === null ?
                        <button type="submit"
                                className="btn btn-primary">
                          <i className="icon-check"></i>  บันทึกลูกค้า(Lead)
                        </button>
                        :
                        <button type="submit"
                                className="btn btn-warning">
                          <i className="icon-pencil"></i>  แก้ไขลูกค้า(Lead)
                        </button>
                    }

                  </div>
                </div>
                </form>
              </div>
              <div className="col-lg-6">
                <form action="" method="post" onSubmit={this.handleOnSubmitCustomerFollowUp}>
                  <div className="card card card-accent-success">
                  <div className="card-header">
                    <strong>ข้อมูลการติดต่อ</strong> &nbsp;&nbsp;
                  </div>
                  <div className="card-block">
                    <div className="row">
                      {/*<div className="form-group col-sm-12">
                        <label htmlFor="customer_name">ครั้งที่ติดต่อ</label>
                        <input type="number"
                               className="form-control"
                               name="followup_times"
                               placeholder=""
                               maxLength={2}
                               value={this.state.followup_times || 0}
                               onChange={this.onInputChange}
                        />
                      </div>*/}
                      <div className="form-group col-sm-12">
                        <BDatePicker
                          isDateFill={0}
                          name={'contact_date'}
                          value={this.state.contact_date}
                          label={'วันที่ติดต่อ'}
                          onUpdateDate={(buddhist_date, christ_date) => {
                            this.setState({
                              contact_date: christ_date
                            })
                          }}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <BDatePicker
                          isDateFill={0}
                          name={'followup_date'}
                          value={this.state.followup_date}
                          label={'วันที่นัดหมาย'}
                          onUpdateDate={(buddhist_date, christ_date) => {
                            this.setState({
                              followup_date: christ_date
                            })
                          }}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <label htmlFor="customer_note">รายละเอียดการติดต่อ</label>
                        <textarea
                          className="form-control"
                          name="followup_detail"
                          placeholder=""
                          value={this.state.followup_detail || ''}
                          onChange={this.onInputChange}
                        />
                      </div>
                      <div className="form-group col-sm-12">
                        <label htmlFor="customer_mobile">หัวข้อการติดตามครั้งถัดไป</label>
                        <input type="text"
                               className="form-control"
                               name="followup_next"
                               placeholder=""
                               maxLength={200}
                               value={this.state.followup_next || ''}
                               onChange={this.onInputChange}
                        />
                      </div>

                    </div>
                  </div>
                  <div className="card-footer text-right">

                    {
                       this.state.followup_id === null ?
                        <button type="submit"
                                className="btn btn-primary">
                          <i className="icon-check"></i>  บันทึกการติดตาม
                        </button>
                        :
                        <button type="submit"
                                className="btn btn-warning">
                          <i className="icon-pencil"></i>  แก้ไขการติดตาม
                        </button>
                    }

                  </div>

                </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-right">
                <button type="reset"
                        className="btn btn-sm btn-danger"
                        style={{width: 120}}
                        onClick={() => this.props.onToggle(this.state.is_reload) }
                >
                  <i className="fa fa-close"></i> ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
        {/*<ModalFooter>
          <Button color="secondary" onClick={() => this.props.onToggle() }>ยกเลิก</Button>
        </ModalFooter>*/}
      </Modal>
    )
  }
}

export default CustomerFollowupForm
