
export const CustomerFollowupUtils = {
  getFollowupStatusName(customer_type) {
    let followupStatusName = ''
    switch (customer_type) {
      case 'lead':
        followupStatusName = 'ติดตามลูกค้า';
        break;
      case 'reject':
        followupStatusName = 'ลูกค้ายกเลิก';
        break;
      case 'cancel':
        followupStatusName = 'ยกเลิกไม่ติดตาม';
        break;
      case 'complete':
        followupStatusName = 'ปิดการขายได้';
        break;
      default:
        followupStatusName = ''
    }
    return followupStatusName
  },
  getCustomerTypes() {
    let customer_types = [
      {customer_type: 'lead', customer_label: 'ติดตามลูกค้า'},
      {customer_type: 'reject', customer_label: 'ลูกค้ายกเลิก'},
      {customer_type: 'cancel', customer_label: 'ยกเลิกไม่ติดตาม'},
      {customer_type: 'complete', customer_label: 'ปิดการขายได้'},
    ]
    return customer_types
  },
  getClassBadge(customer_type) {
    let followupClassName = ''
    switch (customer_type) {
      case 'lead':
        followupClassName = 'badge badge-primary float-right';
        break;
      case 'reject':
        followupClassName = "badge badge-danger float-right";
        break;
      case 'cancel':
        followupClassName = "badge badge-warning float-right";
        break;
      case 'complete':
        followupClassName = 'badge badge-success float-right'
        break;
      default:
        followupClassName = 'badge badge-info float-right'
    }
    return followupClassName
  }
}
