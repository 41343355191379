/**
 * Created by Tukta on 19/7/2560.
 */
import React, { Component } from 'react'

export  default class CarFinanceListRow extends Component{
    render(){
        let carfinanceList = this.props.carfinance_list.map((value,index)=>{
            return (
                <tr className="text-center">
                    <td>{ value.carfinance_id }</td>
                    <td>{ value.carfinance_name }</td>
                    <td>{ value.carfinance_lastname }</td>
                    <td>{ value.carfinance_address }</td>
                    <td>{ value.carfinance_card_id }</td>
                    <td>{ value.carfinance_birth }</td>
                    <td>{ value.carfinance_mobile }</td>
                    <td className="text-right">
                        <button className="btn btn-sm btn-info">
                            <i className="icon-list"></i></button>
                        <button className="btn btn-sm btn-warning">
                            <i className="fa fa-edit"></i></button>
                    </td>
                </tr>
            )
        });

        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">ชื่อ</th>
                        <th className="text-center">นามสกุล</th>
                        <th className="text-center">ที่อยู่ปัจจุบัน</th>
                        <th className="text-center">เลขประจำตัวประชาชน</th>
                        <th className="text-center">ค้างชำระ/งวด</th>
                        <th className="text-center">เบอร์โทรศัพท์</th>
                        <th className="text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {carfinanceList}
                    </tbody>
                </table>
                {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active">
                        <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>*/}
            </div>
        )
    }
}