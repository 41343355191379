// carrepairbycenter
import React, { Component } from 'react';
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { save } from 'save-file'
import { NumberUtil } from '../../utils/number-util'
import { AlertWarning } from '../Alert/Alert'
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import NumberFormat from 'react-number-format';

class CarRepairByCenter extends Component{
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      carrepairbycenter: [],
      sum_car_repair: 0,
      activeTab: '1',
      center_list:[],
      center_id:'',
      sum_separate_car_repair: 0,
      carrepairseparatebycenter: [],

      // repairdetails: [],
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadRepairData = this.loadRepairData.bind(this)
    this.exportRepairData = this.exportRepairData.bind(this)
    this.onShowRepairDetail = this.onShowRepairDetail.bind(this)
    this.toggle = this.toggle.bind(this)
    this.loadRepairSeparateData = this.loadRepairSeparateData.bind(this)
    this.exportRepairSeparateData = this.exportRepairSeparateData.bind(this)

  }

  componentDidMount () {
    this.loadRepairData()
    this.loadCenterData()
    this.loadRepairSeparateData()
  }

  loadCenterData(){
    axios.get(APIURL + '/center')
        .then(res => {
          this.setState({center_list: res.data })
        }).catch(error => {
      // alert("error"+error)
      this.setState({center_list: []})
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadRepairData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: true
    })

    // carrepairbycenter
    // let url = APIURL + '/report/trading/'+start_date+'/'+end_date
    // let url = APIURL+'/expense/car_repair/bycar'
    let url = APIURL + '/reportrepair/carrepairbycenter/'+start_date+'/'+end_date
    axios.get(url)
      .then(res => {

        let carrepairbycenter = res.data

        let sum_car_repair = carrepairbycenter.reduce((acc, next) => acc +next.sum_car_repair , 0)
        sum_car_repair = parseFloat(sum_car_repair).toFixed(2)

        this.setState({
          isLoading: false,
          carrepairbycenter: carrepairbycenter,
          sum_car_repair: sum_car_repair
        })

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  exportRepairData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/reportrepair/carrepairbycenterexport/'+start_date+'/'+end_date)
      .then( async res => {
        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานสรุปซ่อมรถตามอู่_export_'+currentDatetime+'.xlsx'
        this.setState({
          isLoading: false
        })
        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  //แยกตามอู่
  exportRepairSeparateData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let center_id = this.state.center_id === '' ? 'all' : this.state.center_id

    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/reportrepair/carrepairseparatebycenterexport/'+start_date+'/'+end_date+'/'+center_id)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานสรุปซ่อมรถแยกตามอู่'+currentDatetime+'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)

        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  onShowRepairDetail(el, repair) {
    el.style.display = ''

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let center_id = repair.center_id
    // let center_name = repair.center_name

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    let url = APIURL + '/reportrepair/carrepairbycenterdetail/'
    let data = {
      start_date: start_date,
      end_date: end_date,
      center_id: center_id
    }

    axios.post(url, data)
      .then(res => {

        let repairdetails = res.data

        let content = '<td colspan="4">'
        content += '<table class="table table-striped">'

        let contentHeader = '<tr>'
        contentHeader += '<th style="width: 10px">&nbsp;</th>'
        contentHeader += '<th style="width: 250px">ชื่อรถ</th>'
        contentHeader += '<th style="width: 140px">วันที่ส่งซ่อม</th>'
        contentHeader += '<th style="width: 250px" class="text-center">รายการ</th>'
        contentHeader += '<th style="width: 10px">ราคา</th>'
        contentHeader += '</tr>'

        content += contentHeader


        let contentDetail = ''
        repairdetails.map((repair, index) => {
          let car_name = repair.car_name
          let car_license_plate_new = repair.car_license_plate_new
          let car_license_plate_old = repair.car_license_plate_old
          let car_repair_name = repair.car_repair_name
          let car_repair_send = moment(repair.car_repair_send).locale('th').format('LL')
          let car_repair_cost = NumberUtil.addCommas(String(repair.car_repair_cost))

          let car_license = car_license_plate_new === "" ? car_license_plate_old : car_license_plate_new

          contentDetail += '<tr>'
          contentDetail += '<td style="width: 10px">&nbsp;</td>'
          contentDetail += '<td>'+car_name+'<br/> ทะเบียน: ' +car_license+ '</td>'
          contentDetail += '<td>'+car_repair_send+'</td>'
          contentDetail += '<td class="text-center">'+car_repair_name+'</td>'
          contentDetail += '<td>'+car_repair_cost+'</td>'
          contentDetail += '</tr>'
        })

        content += contentDetail
        content += '</table></td>'

        el.innerHTML = content
        this.setState({
          isLoading: false,
          // repairdetails: repairdetails
        })

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  //ค่าซ่อมแยกตามอู่
  loadRepairSeparateData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const center_id = this.state.center_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      center_id: center_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/reportrepair/carrepairseparatebycenter/', data)
          .then(res => {

            let carrepairseparatebycenter = res.data

            let sum_separate_car_repair = carrepairseparatebycenter.reduce((acc, next) => acc +next.car_repair_cost , 0)
            sum_separate_car_repair = parseFloat(sum_separate_car_repair).toFixed(2)

            // console.log(res.data)

            this.setState({
              isLoading: false,
              carrepairseparatebycenter: carrepairseparatebycenter,
              sum_separate_car_repair:sum_separate_car_repair
            }, () => {
              // this.showBranchSummary()
            })

          }).catch(error => {
        console.log('error:', error)
      })
    })
  }

  render() {
    const { carrepairbycenter, sum_car_repair } = this.state
    const { carrepairseparatebycenter, sum_separate_car_repair } = this.state

    let center_list = this.state.center_list.map((center, index) => {
      return (
          <option key={index} value={center.center_id}>{center.center_name}</option>
      )
    })

    const emptyBody = <tr><td colSpan={6} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>
    const emptyBody2 = <tr><td colSpan={4} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>



    //แยกตามอู่
    const listRows = carrepairseparatebycenter.map((repair, index) => {

      return (
          <tr key={index}  style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
            <td className="text-center">{index+1}</td>
            <td className="text-left" style={{width: 100}}>{repair.center_name}</td>
            <td className="text-left" style={{width: 150}}>{repair.car_name} <br/> {repair.car_license_plate_new}</td>
            <td className="text-center" style={{width: 80}}>{moment(repair.car_repair_send).locale('th').format('LL')}</td>
            <td className="text-center" style={{width: 230}}>{repair.car_repair_name}</td>
            <td style={{width: 100, textAlign: 'center'}}>
              { NumberUtil.addCommas(String(repair.car_repair_cost))}
            </td>
          </tr>
      )
    })


    const carrepairbycenterlistRows = carrepairbycenter.map((repair, index) => (
        <React.Fragment key={index}>
          <tr key={index}  style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
            <td className="text-center">{index+1}</td>
            <td style={{width: 220}}>{repair.center_name}</td>
            <td style={{width: 120, textAlign: 'center'}}>{ NumberUtil.addCommas(String(repair.sum_car_repair))}</td>
            <td style={{textAlign: 'center'}}>
              <button className="btn btn-sm btn-primary mt-1 mb-1"
                      onClick={() => {
                        let tr_detail = "tr_"+index;
                        const el = document.getElementById(tr_detail);

                        let display = el.style.display

                        if (display === '') {
                          el.style.display = 'none'
                        } else {
                          this.onShowRepairDetail(el, repair)
                        }
                      }}
                      style={styles.button}
              >
                <i className="fa fa-list"/>
              </button>
            </td>
          </tr>
          <tr key={"tr_"+index} id={"tr_"+index} style={{display: 'none'}}>
            <td colSpan={4}> detail </td>
          </tr>
        </React.Fragment>
    ))

    const tableRows = carrepairseparatebycenter.length === 0 ? emptyBody : listRows
    const tableRowscarrepairbycenter = carrepairbycenter.length === 0 ? emptyBody2 : carrepairbycenterlistRows

    let sumRow = <tr><td colSpan={2} className="text-right"><strong>รวม</strong></td><td className="text-center"><strong>{NumberUtil.addCommas(String(sum_car_repair))}</strong></td><td></td></tr>

    return (

      <>
        <Loading isLoading={this.state.isLoading} />

        <div className="col-md-12">
          <Nav tabs>
            <NavItem>
              <NavLink
                  className={({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
              >
                <i className="icon-options-vertical"/> รายงานสรุปค่าซ่อมรถตามอู่
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                  className={({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
              >
                <i className="icon-paper-clip"/> รายงานสรุปค่าซ่อมรถแยกตามอู่
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <div className="card-block" style={styles.overflowY}>
                  <div className="">
                    <div className="card card-accent-primary">
                      <div className="card-header">
                        <div className="row col-12">
                          <div className="col-9 text-left">
                            <strong className="text-title">สรุปค่าซ่อมรถตามอู่</strong>
                          </div>

                          <div className="col-3 text-right">
                            <strong>
                              รวมค่าซ่อม&nbsp;
                              <NumberFormat
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  value={this.state.sum_car_repair}/>
                              &nbsp;บาท
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="card-block">

                        <div className="row mt-1">
                          <div className="col-1 mt-1 text-right">
                            จาก
                          </div>

                          <div className="col-2">
                            <DatePicker
                                selected={this.state.date_start}
                                onChange={this.handleDateStart}
                                dateFormat="yyyy-MM-dd"
                                type="text"
                                className="form-control"
                                placeholder=""
                            />
                          </div>

                          <div className="mt-1 text-right">
                            ถึง
                          </div>

                          <div className="col-2 ml-3">
                            <DatePicker
                                selected={this.state.date_end}
                                onChange={this.handleDateEnd}
                                dateFormat="yyyy-MM-dd"
                                type="text"
                                className="form-control d-block"
                                placeholder=""
                            />
                          </div>

                          <div className="d-flex mr-3">
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadRepairData()} > ตกลง </button>
                          </div>

                          {<div className="d-flex">
                            <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportRepairData()} > Excel </button>
                          </div>}
                        </div>

                        <div className="row mt-2">
                          <div className="col-12 mb-2">
                            {
                              moment(this.state.date_start).format('YYYY-MM-DD')  ===  moment(this.state.date_end).format('YYYY-MM-DD') ? (
                                  <span><strong>***ค่าใช้จ่ายซ่อมรถตามอู่ซ่อม วันที่ {moment(this.state.date_start).locale('th').format('LL')}</strong></span>
                              ) : (
                                  <span><strong>
                          ***ค่าใช้จ่ายซ่อมรถตามอู่ซ่อมจากวันที่ {moment(this.state.date_start).locale('th').format('LL')}
                                    &nbsp; ถึงวันที่ &nbsp;  {moment(this.state.date_end).locale('th').format('LL')} </strong>
                        </span>
                              )
                            }
                          </div>
                            <div className="col-12 mt-1">
                              <div className="col-12">
                                <table className="table table-responsive">
                            {/*<table className="table2" border={1} style={{border: 1, minHeight: 250, minWidth: 950}} >*/}
                              <thead>
                              <tr>
                                <th style={{width: 20}} className="text-center text-nowrap">ลำดับ</th>
                                <th style={{width: 100}} className="text-center text-nowrap">ศูนย์ซ่อมบำรุง</th>
                                <th style={{width: 100}} className="text-center text-nowrap">รวมค่าซ่อม</th>
                                <th style={{width: 40}} className="text-center text-nowrap">รายละเอียด</th>
                              </tr>
                              {/*<tr style={{height: 36}}>
                                <th style={{...styles.headerTable,...{width: 10}}} >ลำดับ</th>
                                <th style={{...styles.headerTable,...{minWidth: 220}}} >ศูนย์ซ่อมบำรุง</th>
                                <th style={{...styles.headerTable,...{width: 120}}} >รวมค่าซ่อม</th>
                                <th style={{...styles.headerTable,...{width: 40}}} > รายละเอียด
                                </th>
                              </tr>*/}
                              </thead>
                              <tbody>
                              { tableRowscarrepairbycenter }
                              </tbody>
                            </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </TabPane>

            <TabPane tabId="2">
              <div className="card-block" style={styles.overflowY}>
                <div className="">
                  <div className="card card-accent-primary">
                    <div className="card-header">
                      <div className="row col-12">
                        <div className="col-9 text-left">
                          <strong className="text-title">สรุปค่าซ่อมรถแยกตามอู่</strong>
                        </div>

                        <div className="col-3 text-right">
                          <strong>
                            รวมค่าซ่อม&nbsp;
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={this.state.sum_separate_car_repair}/>
                            &nbsp;บาท
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="card-block">

                      <div className="row mt-1">
                        <div className="col-1 mt-1 text-right">
                          จาก
                        </div>

                        <div className="col-2">
                          <DatePicker
                              selected={this.state.date_start}
                              onChange={this.handleDateStart}
                              dateFormat="yyyy-MM-dd"
                              type="text"
                              className="form-control"
                              placeholder=""
                          />
                        </div>

                        <div className="mt-1 text-right">
                          ถึง
                        </div>

                        <div className="col-2 ml-3">
                          <DatePicker
                              selected={this.state.date_end}
                              onChange={this.handleDateEnd}
                              dateFormat="yyyy-MM-dd"
                              type="text"
                              className="form-control d-block"
                              placeholder=""
                          />
                        </div>

                        <div className="form-group col-3">
                          <select className="form-control" id="center_id" name="center_id"
                                  onChange={(e) => {
                                    this.setState({
                                      center_id: e.target.value
                                    })
                                  }}
                          >
                            <option value=''> เลือกอู่ซ่อมรถ </option>
                            {center_list}
                          </select>
                        </div>

                        <div className="d-flex mr-3">
                          <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadRepairSeparateData()} > ตกลง </button>
                        </div>

                        <div className="d-flex">
                          <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportRepairSeparateData()} > Excel </button>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-12 mb-2">
                          {
                            moment(this.state.date_start).format('YYYY-MM-DD')  ===  moment(this.state.date_end).format('YYYY-MM-DD') ? (
                                <span><strong>***ค่าใช้จ่ายซ่อมรถแยกตามอู่ซ่อม วันที่ {moment(this.state.date_start).locale('th').format('LL')}</strong></span>
                            ) : (
                                <span><strong>
                          ***ค่าใช้จ่ายซ่อมรถแยกตามอู่ซ่อม {moment(this.state.date_start).locale('th').format('LL')}
                                  &nbsp; ถึงวันที่ &nbsp;  {moment(this.state.date_end).locale('th').format('LL')} </strong>
                        </span>
                            )
                          }
                        </div>
                        <div className="col-12 mt-1">
                          <div className="col-12">

                            <table className="table table-responsive">
                              <thead>
                              <tr>
                                <th style={{width: 20}} className="text-center text-nowrap">ลำดับ</th>
                                <th style={{width: 100}} className="text-center text-nowrap">ศูนย์ซ่อมบำรุง</th>
                                <th style={{width: 130}} className="text-center text-nowrap">ชื่อรถ</th>
                                <th style={{width: 80}} className="text-center text-nowrap">วันที่ส่งซ่อม</th>
                                <th style={{width: 230}} className="text-center text-nowrap">รายการซ่อม</th>
                                <th style={{width: 100}} className="text-center text-nowrap">ค่าซ่อม</th>
                              </tr>
                              </thead>
                              <tbody>
                              {tableRows}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </TabPane>


          </TabContent>

        </div>

      </>
    )

  }

}

const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairByCenter);
