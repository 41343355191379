import React , { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { Link } from 'react-router-dom'

class CustomerFollowupAdmin extends Component {
  constructor (props) {
    super(props);
    this.state = {
      followupSummary: [],
      followupUniqByName: []
    }
    this.loadFollowupSummary = this.loadFollowupSummary.bind(this)
  }

  componentDidMount () {
    this.loadFollowupSummary()
  }

  loadFollowupSummary() {
    let uri = APIURL+'/customer/followup/summary'
    axios.get(uri).then(res => {
      if (res.status === 200) {
        let data = res.data
        this.setState({
          followupSummary: data.followupSummary,
          followupUniqByName: data.followupUniqByName
        })
      }
    })
  }

  getCountByCustomerType(followupSummary, username, customer_type ) {
    let data = followupSummary.filter((followup)=> followup.followup_by===username && followup.customer_type ===customer_type)
    if(data.length === 0) {
      return 0
    }
    return  data[0].count_customer_type
  }

  render () {
    const {
      followupUniqByName,
      followupSummary
    } = this.state

      let rowEmpty = <tr><td colSpan={6} className="text-center text-danger">ไม่พบข้อมูล</td></tr>

    let followupData = followupUniqByName.map((followupName, index) => {
      let username = followupName.followup_by
      let fullname = followupName.fullname
      // lead , reject , cancel, complete
      let leadCount = this.getCountByCustomerType(followupSummary, username, 'lead')
      let rejectCount = this.getCountByCustomerType(followupSummary, username, 'reject')
      let cancelCount = this.getCountByCustomerType(followupSummary, username, 'cancel')
      let completeCount = this.getCountByCustomerType(followupSummary, username, 'complete')
      return <tr key={index}>
         <td> {fullname} : {username} </td>
         <td className="text-center bg-primary font-xl "> {leadCount} </td>
         <td className="text-center bg-danger font-xl"> {rejectCount} </td>
         <td className="text-center bg-warning font-xl"> {cancelCount} </td>
         <td className="text-center bg-success font-xl"> {completeCount} </td>
         <td className="text-center">
           <Link to={'/customer/followup/list/?followup_by=' + username} target='_blank'
                 type="button"
                 className="btn btn-info btn-sm btn-block"><i className="icon-list">
             &nbsp;ดูรายละเอียด</i>
           </Link>
         </td>
      </tr>
    })

    let followupRows =  followupUniqByName.length === 0 ? rowEmpty : followupData

    return (
      <div>
        <table className="table table-striped">
          <thead>
          <tr>
             <th className="text-center">ชื่อผู้ติดตาม/ชื่อผู้ใช้</th>
             <th className="text-center">ติดตามลูกค้า(Lead)</th>
             <th className="text-center">ลูกค้ายกเลิก(reject)</th>
             <th className="text-center">ยกเลิกไม่ติดตาม(cancel)</th>
             <th className="text-center">ปิดการขายได้(complete)</th>
             <th className="text-center">จัดการ</th>
          </tr>
          </thead>
          <tbody>
             {followupRows}
          </tbody>
        </table>
        <div>
          ลูกค้ายกเลิก: ลูกค้าแจ้งยกเลิกเอง เช่น ยังไม่ออกรถ <br/>
          ยกเลิกไม่ติดตาม: ทางเต็นท์ยกเลิกไม่ติดตาม เช่น ลูกค้าเครดิตไม่ผ่าน
        </div>
      </div>
    )
  }
}

export default CustomerFollowupAdmin
