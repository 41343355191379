/**
 * พิมพ์สัญญาขายรถ เงินสด
 * **/

import React, { Component } from 'react'
import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../Loading'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { DateUtil } from '../../utils/dateUtil'
import {APIURL, PORT} from '../../config/config'
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format'
import {AlertSuccess, AlertWarning} from '../Alert/Alert'
import { NumberUtil } from '../../utils/number-util'
import DatePickerIcon from "../DatePickerIcon";
import stringUtil from "../../utils/stringUtil";
import {format} from "date-fns";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class CarSalesContract2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contract_id: null,
      contract_no: '',
      write_at: '',
      // date
      startDateContract: new Date(),
      isOpenDateContract: false,
      contract_date_thai: null,
      contract_date: null,
      // end date
      seller_name: '', // ผู้ขาย
      car_name: '',
      car_license_plate: '',
      car_num_engines: '',
      car_number: '', // เลขตัวรถ
      car_year_name: '', // ปี
      car_color_name: '', // สี
      // ขายให้แก่
      buyer_name: '', // สี
      buyer_age: '',
      buyer_address: '',
      buyer_mobile: '',
      card_type: 'บัตรประชาชน',
      card_code: '',
      card_out_at: '',
      real_sell_price: 0,
      cash_pledge: 0,
      // date
      startPaidDueDate: new Date(),
      isOpenPaidDueDate: false,
      paid_due_date_thai: null,
      paid_due_date: null,
      // end date
      isExist: false,
      car_id: '',
      customer_id: '',
      car_sell_id: '',
      province_name : '',

      //วันที่ออกบัตร
      date_of_issue: null,
      date_of_issue_thai: null,
      startDateOfIssue: new Date(),
      isOpenDateOfIssue: false,

      //วันหมดอายุ
      expiration_date: null,
      expiration_date_thai: null,
      startExpirationDate: new Date(),
      isOpenExpirationDate: false,

      //วันที่ยอดที่ต้องชำระ ณ วันออกรถ
      amount_paid_date: null,
      amount_paid_date_thai: null,
      startAmountPaidDate: new Date(),
      isOpenAmountPaidDate: false,

      finance_rate: 0,
      sum_down: 0,
      transfer_duty: 0,
      inspection_fee: 0,
      class_insurance: '',
      class_insurance_amount: 0,
      act_tax_fee: 0,
      calculate_total: 0,
      less_deposit: 0,
      amount_paid_day: 0,
      note: '',
      insurance_pa: 0,
      insurance_pa_buy: '',

      car_name_new: '',
      car_brand_name: '', //
      car_model_name: '', //
      car_sub_model_name: '', //

    }

    this.handleDateContract = this.handleDateContract.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.loadCarContract = this.loadCarContract.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this);
    this.calOutstanding = this.calOutstanding.bind(this);
    this.handlePaidDueDate = this.handlePaidDueDate.bind(this);
    this.saveCarContract = this.saveCarContract.bind(this);
    this.printCarContract = this.printCarContract.bind(this);
    this.handleAmountPaidDate = this.handleAmountPaidDate.bind(this);
    this.handleDateOfIssue = this.handleDateOfIssue.bind(this);
    this.handleExpirationDate = this.handleExpirationDate.bind(this);

  }

  async componentDidMount () {
    this.mounted = true
    const queryString = require('query-string')
    let car_sell = queryString.parse(this.props.location.search)
    let car_sell_id = car_sell.car_sell_id

    await this.loadCompanySetting();
    await this.loadCarContract(car_sell_id);
    await this.loadCarSellById(car_sell_id);

  }

  componentWillUnmount() {
    this.mounted = false
    // window.removeEventListener('resize', this.updateWindowDimensions);
  }

  async loadCompanySetting () {
    await axios.get(APIURL + '/company_setting/1')
      .then(res => {
        let data = res.data
        let company_setting = data.company_setting
        if (company_setting !== null) {
          this.setState({
            company_setting: company_setting,
            seller_name: company_setting.company_name
          })
        }
      })
  }

  async loadCarContract (car_sell_id) {
    await axios.get(APIURL + '/contract/car_sell/' + car_sell_id)
      .then(res => {
        let data = res.data
        if(data.length === 1){
          let contract = data[0];
          // let contract_id = contract.contract_id
          // contract_id: contract_id
          let contract_date =  moment(contract.contract_date).format('YYYY-MM-DD')
          let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

          let paid_due_date =  moment(contract.paid_due_date).format('YYYY-MM-DD')
          let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

          let date_of_issue =  moment(contract.date_of_issue).format('YYYY-MM-DD')
          let date_of_issue_thai = date_of_issue === 'Invalid date' ? '' : DateUtil.convertLongDateThai(date_of_issue)

          let expiration_date =  moment(contract.expiration_date).format('YYYY-MM-DD')
          let expiration_date_thai = expiration_date === 'Invalid date' ? '' : DateUtil.convertLongDateThai(expiration_date)

          // console.log(contract.amount_paid_date, typeof contract.amount_paid_date)

          let amount_paid_date = contract.amount_paid_date === null ? null :  moment(contract.amount_paid_date).format('YYYY-MM-DD')
          let amount_paid_date_thai = contract.amount_paid_date === null ? null : DateUtil.convertLongDateThai(amount_paid_date)

          let province_name = contract.car_license_plate_new !== '' &&  contract.car_license_plate_new !==  contract.car_license_plate_old ? contract.province_name_new : contract.province_name_old

          let insurance_pa = contract.insurance_pa
          let insurance_pa_buy = contract.insurance_pa_buy



          this.setState({
            contract_date_thai: contract_date_thai,
            paid_due_date_thai: paid_due_date_thai,

            date_of_issue_thai: date_of_issue_thai,
            expiration_date_thai: expiration_date_thai,
            amount_paid_date_thai: amount_paid_date_thai,

            contract_id : contract.contract_id,
            contract_no : contract.contract_no,
            contract_date : contract.contract_date,
            contract_type : contract.contract_type,
            buyer_address : contract.buyer_address,
            buyer_age : contract.buyer_age,
            buyer_mobile : contract.buyer_mobile,
            buyer_name : contract.buyer_name,
            car_color_name : contract.car_color_name,
            car_id : contract.car_id,
            car_license_plate : contract.car_license_plate,
            car_name : contract.car_name,
            car_num_engines : contract.car_num_engines,
            car_number : contract.car_number,
            car_sell_id : contract.car_sell_id,
            car_year_name : contract.car_year_name,
            card_code : contract.card_code,
            card_out_at : contract.card_out_at,
            card_type : contract.card_type,
            cash_pledge : contract.cash_pledge,
            customer_id : contract.customer_id,
            paid_due_date : contract.paid_due_date,
            real_sell_price : contract.real_sell_price,
            seller_name : contract.seller_name,
            write_at : contract.write_at,
            province_name : province_name,

            rate_finance : contract.finance_rate,
            sum_down : contract.sum_down,
            transfer_duty : contract.transfer_duty,
            inspection_fee : contract.inspection_fee,
            class_insurance : contract.class_insurance,
            class_insurance_amount : contract.class_insurance_amount,
            act_tax_fee : contract.act_tax_fee,
            calculate_total : contract.calculate_total,
            less_deposit : contract.less_deposit,
            amount_paid_day : contract.amount_paid_day,
            note : contract.note,

            date_of_issue: contract.date_of_issue,
            expiration_date: contract.expiration_date,
            amount_paid_date: contract.amount_paid_date,
            insurance_pa: insurance_pa,
            insurance_pa_buy: insurance_pa_buy,

            car_brand_name : contract.car_brand_name,
            car_model_name : contract.car_model_name,
            car_sub_model_name : contract.car_sub_model_name,
          })
        }
      })
  }

  async loadCarSellById (car_sell_id) {

    if (this.state.contract_id !== null) {
      // มีรายการสัญญาแล้ว
      return
    }

    //โหลดข้อมูลตั้งต้น ตอนยังไม่กดบันทึกสัญญา
    await axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if(this.mounted) {
          let car_sell = res.data[0]
          let car_sell_date = car_sell.car_sell_date
          let date = new Date(car_sell_date)

          let car_name = car_sell.car_brand_name + ' ' + car_sell.car_model_name + ' ' + car_sell.car_sub_model_name
          //console.log('car_name==', car_name)
          let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
          let car_num_engines = car_sell.car_num_engines  // เลขเครื่องยนต์
          let car_number = car_sell.car_number // เลขตัวรถ
          let car_year_name = car_sell.car_year_name // ปี
          let car_color_name = car_sell.car_color_name // สี

          let customer_lastname = car_sell.customer_lastname
          let buyer_name = car_sell.customer_name +' ' + customer_lastname // ผู้ซื้อ
          let buyer_age = car_sell.customer_age // ผู้ซื้อ
          let buyer_address = car_sell.customer_address // ผู้ซื้อ
          let buyer_mobile = car_sell.customer_mobile // ผู้ซื้อ
          let card_code = car_sell.customer_card_id // ผู้ซื้อ

          let real_sell_price = car_sell.real_sell_price
          let car_id = car_sell.car_id
          let customer_id = car_sell.customer_id

          let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

          let rate_finance = car_sell.rate_finance
          let sum_down = car_sell.sum_down

          let insurance_pa_buy = car_sell.insurance_pa_buy
          let insurance_pa = car_sell.insurance_pa

          let car_brand_name = car_sell.car_brand_name
          let car_model_name = car_sell.car_model_name
          let car_sub_model_name = car_sell.car_sub_model_name

          if (car_sub_model_name.indexOf(car_model_name) !== -1) {
            // มีชื่อรุ่นใน รุ่นย่อย
            car_name = car_brand_name + ' ' + car_sub_model_name
          }else if (car_model_name.indexOf(car_brand_name) !== -1){
            car_name = car_model_name + ' ' + car_sub_model_name
          }

          this.setState({
            car_name,
            car_license_plate,
            car_num_engines,
            car_number,
            car_year_name,
            car_color_name,
            buyer_name,
            buyer_age,
            buyer_address,
            buyer_mobile,
            card_code,
            real_sell_price,
            car_id,
            customer_id,
            province_name,
            rate_finance,
            sum_down,
            insurance_pa_buy,
            insurance_pa,
            car_brand_name,
            car_model_name,
            car_sub_model_name,
            car_sell_id
          }, () => {
            this.loadDataCarSellPay(car_sell, car_id, customer_id)
          })
        }
      }).catch(error => {
        console.log ('error:', error)
      })
  }

  handleDateContract (date) {
    let contract_date =  moment(date).format('YYYY-MM-DD')
    let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

    this.setState({
      startDateContract: date,
      isOpenDateContract: false,
      contract_date: contract_date,
      contract_date_thai: contract_date_thai
    })
  }

  handlePaidDueDate (date) {
    let paid_due_date =  moment(date).format('YYYY-MM-DD')
    let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

    this.setState({
      startPaidDueDate: date,
      isOpenPaidDueDate: false,
      paid_due_date: paid_due_date,
      paid_due_date_thai: paid_due_date_thai
    })
  }

  handleAmountPaidDate (date) {
    let amount_paid_date =  moment(date).format('YYYY-MM-DD')
    let amount_paid_date_thai = DateUtil.convertLongDateThai(amount_paid_date)

    this.setState({
      startAmountPaidDate: date,
      isOpenAmountPaidDate: false,
      amount_paid_date: amount_paid_date,
      amount_paid_date_thai: amount_paid_date_thai
    })
  }

  handleDateOfIssue (date) {
    let date_of_issue =  moment(date).format('YYYY-MM-DD')
    let date_of_issue_thai = DateUtil.convertLongDateThai(date_of_issue)

    this.setState({
      startDateOfIssue: date,
      isOpenDateOfIssue: false,
      date_of_issue: date_of_issue,
      date_of_issue_thai: date_of_issue_thai
    })
  }

  handleExpirationDate (date) {
    let expiration_date =  moment(date).format('YYYY-MM-DD')
    let expiration_date_thai = DateUtil.convertLongDateThai(expiration_date)

    this.setState({
      startExpirationDate: date,
      isOpenExpirationDate: false,
      expiration_date: expiration_date,
      expiration_date_thai: expiration_date_thai
    })
  }

  handleOnChange(e) {
    let name = e.target.name;
    let val = e.target.value;

    this.setState({
      [name]: val
    })
  }

  calOutstanding() {

    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let outstanding = Number(real_sell_price) - cash_pledge

    // let rate_finance = this.state.rate_finance
    // rate_finance ไม่ใช้คำนวณยอดจ่าย
    let rate_finance = 0
    let sum_down = this.state.sum_down
    let transfer_duty = this.state.transfer_duty
    let inspection_fee = this.state.inspection_fee
    let class_insurance_amount = this.state.class_insurance_amount
    let act_tax_fee = this.state.act_tax_fee
    let insurance_pa = this.state.insurance_pa
    let calculate_total = Number(rate_finance) + Number(sum_down) + Number(transfer_duty) + Number(inspection_fee) + Number(class_insurance_amount) + Number(act_tax_fee) + Number(insurance_pa)
    let amount_paid_day = Number(calculate_total) - Number(cash_pledge)

    // console.log('ค่าโอน + ค่าอากร =', transfer_duty)
    // console.log('calculate_total = ', typeof calculate_total)

    amount_paid_day = amount_paid_day >= 0 ? amount_paid_day : 0


    this.setState({
      outstanding: outstanding,
      calculate_total : calculate_total,
      amount_paid_day : amount_paid_day,
    })
  }


  loadDataCarSellPay (car_sell, car_id, customer_id) {

    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' +car_id + '/' + customer_id)
      .then(res => {
        let car_sell_pay_list = res.data
        let sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)

        let real_sell_price = this.state.real_sell_price
        let outstanding = Number(real_sell_price) - sum_pay

        this.setState({
          cash_pledge: sum_pay,
          outstanding: outstanding
        })

      }).catch(error => {
      console.log(error)
    })
  }

  saveCarContract(action) {

    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai

    let date_of_issue = this.state.date_of_issue
    let expiration_date = this.state.expiration_date
    let amount_paid_date = this.state.amount_paid_date

    let date_of_issue_thai = this.state.date_of_issue_thai
    let expiration_date_thai = this.state.expiration_date_thai
    let amount_paid_date_thai = this.state.amount_paid_date_thai

    let finance_rate = this.state.rate_finance
    let sum_down = this.state.sum_down
    let transfer_duty = this.state.transfer_duty
    let inspection_fee = this.state.inspection_fee
    let class_insurance = this.state.class_insurance
    let class_insurance_amount = this.state.class_insurance_amount
    let act_tax_fee = this.state.act_tax_fee
    let calculate_total = this.state.calculate_total
    let less_deposit = this.state.cash_pledge
    let amount_paid_day = this.state.amount_paid_day
    let note = this.state.note
    let insurance_pa = this.state.insurance_pa

    if (contract_no === '') {
      AlertWarning('กรุณากรอกเลขสัญญา');
      return
    }

    if(write_at === '') {
      AlertWarning('กรุณากรอกเขียนที่');
      return
    }

    if (contract_date_thai === null) {
      AlertWarning('กรุณาเลือกวันที่ทำสัญญา');
      return
    }

    if (paid_due_date_thai === null) {
      AlertWarning('กรุณาเลือกวันที่ครบกำหนดชำระ');
      return
    }


    contract_date = contract_date === null ? null : format(new Date(contract_date), 'yyyy-MM-dd')
    date_of_issue = date_of_issue === null ? null :  format(new Date(date_of_issue), 'yyyy-MM-dd')
    expiration_date = expiration_date === null ? null :  format(new Date(expiration_date), 'yyyy-MM-dd')
    paid_due_date = paid_due_date === null ? null :  format(new Date(paid_due_date), 'yyyy-MM-dd')

    let car_name_new = this.state.car_brand_name + ' ' + this.state.car_model_name + ' ' + this.state.car_sub_model_name


    let data = {
      contract_no : contract_no,
      write_at : write_at,
      contract_date : contract_date,
      seller_name : seller_name,
      car_name : car_name,
      car_license_plate : car_license_plate,
      car_num_engines : car_num_engines,
      car_number : car_number,
      car_year_name : car_year_name,
      car_color_name : car_color_name,
      buyer_name : buyer_name,
      buyer_age : buyer_age,
      buyer_address : buyer_address,
      buyer_mobile : buyer_mobile,
      card_type : card_type,
      card_code : card_code,
      card_out_at : card_out_at,
      real_sell_price : real_sell_price,
      cash_pledge : cash_pledge,
      paid_due_date : paid_due_date,
      car_id : car_id,
      customer_id : customer_id,
      car_sell_id : car_sell_id,
      contract_type : contract_type,

      finance_rate : finance_rate,
      less_deposit : less_deposit,
      date_of_issue : date_of_issue,
      expiration_date : expiration_date,
      amount_paid_date : amount_paid_date,
      sum_down : sum_down,
      transfer_duty : transfer_duty,
      inspection_fee : inspection_fee,
      class_insurance : class_insurance,
      class_insurance_amount : class_insurance_amount,
      act_tax_fee : act_tax_fee,
      calculate_total : calculate_total,
      amount_paid_day : amount_paid_day,
      note : note,
      insurance_pa : insurance_pa,
      car_name_new : car_name_new,
    }

    this.setState({
      isLoading: true,
    }, () => {
      if (action === 'add') {
        let url = APIURL + '/contract';
        axios.post( url, data)
          .then(res => {
            if (res.status === 200) {
              let resData = res.data
              let contract_id = resData.contract_id;
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
                contract_id: contract_id
                // isExist: true,
              }, () => this.loadCarContract(car_sell_id))
            }
          }).catch(error => {
          console.log('error:', error)
        });
      } else if (action === 'edit') {
        let contract_id = this.state.contract_id
        let editData = {...data, contract_id: contract_id }
        let url = APIURL + '/contract';
        axios.put( url, editData)
          .then(res => {
            if (res.data === 'success') {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
              this.loadCarContract(car_sell_id);
              setTimeout(() => {
                this.setState({
                  isLoading: false,
                })
              }, 1200);

            }
          }).catch(error => {
          console.log('error:', error)
        });
      }

    });

  }

  printCarContract() {
    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name
    let buyer_age = this.state.buyer_age
    let buyer_address = this.state.buyer_address
    let buyer_mobile = this.state.buyer_mobile
    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at === '' ? '-' + ' ' + ' ' + ' ' + ' ' : this.state.card_out_at
    let real_sell_price = this.state.real_sell_price
    let cash_pledge = this.state.cash_pledge
    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let contract_type = 'sell' // sell, buy
    //
    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai
    let outstanding =  real_sell_price - cash_pledge

    let note = this.state.note
    let province_name = this.state.province_name

    let date_of_issue_thai = this.state.date_of_issue_thai === null || this.state.date_of_issue_thai === '' ? '-' : this.state.date_of_issue_thai
    let expiration_date_thai = this.state.expiration_date_thai === null || this.state.expiration_date_thai === '' ? '-' : this.state.expiration_date_thai
    let amount_paid_date_thai = this.state.amount_paid_date_thai === null ? '-' : this.state.amount_paid_date_thai

    let finance_rate = this.state.rate_finance
    let sum_down = this.state.sum_down
    let transfer_duty = this.state.transfer_duty
    let inspection_fee = this.state.inspection_fee
    let class_insurance = this.state.class_insurance
    let class_insurance_amount = this.state.class_insurance_amount
    let act_tax_fee = this.state.act_tax_fee
    let calculate_total = this.state.calculate_total
    let less_deposit = this.state.less_deposit
    let amount_paid_day = this.state.amount_paid_day
    //
    let insurance_pa = this.state.insurance_pa


    if (car_num_engines.length > 0) {
      car_num_engines = car_num_engines.toUpperCase()
    }

    if (car_number.length > 0) {
      car_number = car_number.toUpperCase()
    }

    let car_license = car_license_plate + ' ' + province_name

    let car_brand_name = this.state.car_brand_name
    let car_model_name = this.state.car_model_name
    let car_sub_model_name = this.state.car_sub_model_name

    let seller_name_pad = stringUtil.addSpacePaddingFill(seller_name, 42)
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 60)
    let car_num_engines_pad = stringUtil.addSpacePaddingFill(car_num_engines, 44)
    let car_number_pad = stringUtil.addSpacePaddingFill(car_number, 48)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license, 48)
    let car_year_name_pad = stringUtil.addSpacePaddingFill(car_year_name, 16)
    let car_color_name_pad = stringUtil.addSpacePaddingFill(car_color_name, 16)
    let buyer_name_pad = stringUtil.addSpacePaddingFill(buyer_name, 36)
    let buyer_age_pad = stringUtil.addSpacePaddingRightLeft(buyer_age, 8)
    let card_type_pad = stringUtil.addSpacePaddingFill(card_type, 20)
    let card_code_pad = stringUtil.addSpacePaddingRightLeft(card_code, 24)
    let card_out_at_pad = stringUtil.addSpacePaddingRightLeft(card_out_at, 24)
    let date_of_issue_thai_pad = stringUtil.addSpacePaddingRightLeft(date_of_issue_thai, 30)
    let expiration_date_thai_pad = stringUtil.addSpacePaddingRightLeft(expiration_date_thai, 30)
    let buyer_address_pad = stringUtil.addSpacePaddingFill(buyer_address, 76)
    let buyer_mobile_pad = stringUtil.addSpacePaddingFill(buyer_mobile, 26)
    let paid_due_date_thai_pad = stringUtil.addSpacePaddingRightLeft(paid_due_date_thai, 30)

    // let seller_name_pad = stringUtil.addSpacePaddingFill(seller_name, 42)
    let buyer_name_pad2 = stringUtil.addSpacePaddingFill(buyer_name, 40)
    let car_license_pad2 = stringUtil.addSpacePaddingFill(car_license, 40)

    let row_insurance_pa
      if(insurance_pa > 0){
        row_insurance_pa = {
          columns: [
            {
              width: '25%',
              text: 'ค่าประกันชีวิต PA',
              style: 'normal'
            },
            {
              text: ' = ' + ' ' + NumberUtil.addCommas(parseInt(insurance_pa, 10)) ,
              width: '25%',
              style: 'normal',
            },
            {
              width: '50%',
              style: 'normal',
              text:  '(ลงนาม)...............................................................................พยาน'
            },
          ],
        }
      }else {
        row_insurance_pa = null
      }


    // + '____เบอร์โทรศัพท์____'+ buyer_mobile+'____'

    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 30, 40, 0],
      content: [
        {
          columns: [
            { width: '100%', style: 'normal',
              text: '',
            },
          ],
          columnGap: 0,
          lineHeight:1.5
        },
        {
          table: {
            widths: [ '30%', '40%', '30%' ],
            body: [
              [
                {text: '', style: 'normal'},
                {
                  text: 'หนังสือสัญญาขายรถยนต์',
                  style: 'bodyhead'
                },
                {text: '', style: 'normal'},
              ],
              [

                {
                  columns: [
                    { width: 'auto', style: 'normalLeft', text: 'No. ',},
                    { width: 'auto', style: ['normalLeft', 'bottomLine'], text: contract_no,},
                  ],
                  alignment: 'left',
                  columnGap: 5
                },
               {
                  columns: [
                    { width: '*', style: 'normalRight', text: 'เขียนที่ ',},
                    { width: 'auto', style: ['normalRight', 'bottomLine'], text: write_at,},
                  ],
                  colSpan: 2,
                  alignment: 'right',
                  columnGap: 8
                },
                ''
              ],
              [
                {
                  columns: [
                    { width: '*', style: 'normalRight', text: 'วันที่ ',},
                    { width: 'auto', style: ['normalRight', 'bottomLine'], text: contract_date_thai,},
                  ],
                  colSpan: 3,
                  alignment: 'right',
                  columnGap: 8
                },
                '',
                ''
              ],
              [
                {text: '', style: 'normalRight', colSpan: 3},
                '',
                ''
              ],
            ],
          },
          layout: 'noBorders'
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 36, style: 'normal', text: 'ข้าพเจ้า',},
            { width: '29%', style: ['normal', 'bottomLine'], text: seller_name_pad,},
            { width: 58, style: 'normal', text: 'ตกลงขายรถ',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: car_name_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: 92, style: 'normal', text: 'หมายเลขเครื่องยนต์',},
            { width: '30%', style: ['normalLeft', 'bottomLine'], text: car_num_engines_pad,},
            { width: 72, style: 'normal', text: 'หมายเลขตัวถัง',},
            { width: '36%', style: ['normalLeft', 'bottomLine'], text: car_number_pad,},
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            { width: 88, style: 'normal', text: 'หมายเลขทะเบียน',},
            { width: '36%', style: ['normalLeft', 'bottomLine'], text: car_license_pad,},
            { width: 34, style: 'normal', text: 'ปี',},
            { width: '12%', style: ['normalLeft', 'bottomLine'], text: car_year_name_pad,},
            { width: 26, style: 'normal', text: 'สี',},
            { width: '12%', style: ['normalLeft', 'bottomLine'], text: car_color_name_pad,},
          ],
          columnGap: 0,
          lineHeight: 1.0
        },
        {
          columns: [
            {width: '100%', text: '.', style: 'normalWhite'},
          ],
          lineHeight: 0.5
        },
        {
          columns: [
            { width: 22, style: 'normal', text: ' ',},
            { width: 54, style: 'normal', text: 'ขายให้แก่',},
            { width: '26%', style: ['normalLeft', 'bottomLine'], text: buyer_name_pad,},

            { width: 32, style: 'normal', text: 'อายุ',},
            { width: '5%', style: ['normalLeft', 'bottomLine'], text: buyer_age_pad,},
            { width: 12, style: 'normal', text: 'ปี',},

            { width: 32, style: 'normal', text: 'บัตร',},
            { width: '14%', style: ['normalLeft', 'bottomLine'], text: card_type_pad,},

            { width: 38, style: 'normal', text: 'เลขที่',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: card_code_pad,},
          ],
          columnGap: 0,
          lineHeight: 1.0
        },
        {
          columns: [
            { width: 60, style: 'normal', text: 'ออกให้ ณ ',},
            { width: '16%', style: ['normalLeft', 'bottomLine'], text: card_out_at_pad,},

            { width: 74, style: 'normal', text: 'วันที่ออกบัตร',},
            { width: '22%', style: ['normalLeft', 'bottomLine'], text: date_of_issue_thai_pad,},

            { width: 74, style: 'normal', text: 'วันหมดอายุ',},
            { width: '22%', style: ['normalLeft', 'bottomLine'], text: expiration_date_thai_pad,},

          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [

            { width: 34, style: 'normal', text: 'ที่อยู่',},
            { width: '60%', style: ['normalLeft', 'bottomLine'], text: buyer_address_pad, },

            { width: 74, style: 'normal', text: 'เบอร์โทรศัพท์',},
            { width: '22%', style: ['normalLeft', 'bottomLine'], text: buyer_mobile_pad,},
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            /*{ width: '100%', style: 'normal',
              text: 'หมายเลข....' + card_code + '....' +
                ' ออกให้ ณ....' + card_out_at + '....',
            },*/
          ],
          columnGap: 0,
          lineHeight: 1.5
        },
        {
          columns: [
            { width: '30%',
              text: ' เป็นจำนวนเงินทั้งสิ้น', style: 'normal', marginTop: 10
            },
            { width: '20%', style: 'normalCenter', marginTop: 10,
              text: NumberUtil.addCommas(parseInt(real_sell_price, 10)),
            },
            { width: '5%', style: 'normal',marginTop: 10,
              text: 'บาท',
            },
            { width: '45%', style: 'normal', marginTop: 10,
              text: '('+ThaiBaht(real_sell_price)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '30%',
              text: ' ข้าพเจ้าได้รับเงินมัดจำไว้เป็นจำนวนเงิน', style: 'normal',
            },
            { width: '20%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(cash_pledge, 10)),
            },
            { width: '5%', style: 'normal',
              text: 'บาท',
            },
            { width: '45%', style: 'normal',
              text: '('+ThaiBaht(cash_pledge)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '30%',
              text: ' ผู้ซื้อยังคงค้างอีกเป็นจำนวนเงิน', style: 'normal',
            },
            { width: '20%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(outstanding, 10)),
            },
            { width: '5%', style: 'normal',
              text: 'บาท',
            },
            { width: '45%', style: 'normal',
              text: '('+ThaiBaht(outstanding)+')',
            },
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            { width: 'auto', style: 'normalLeft', text: 'ผู้ซื้อจะนำเงินมาชำระให้ครบจำนวนตามที่ตกลง หรือจัดเตรียมเอกสารเพื่อยื่นขอสินเชื่อ ภายในวันที่',},
            { width: 24, style: 'normal', text: ' ',},
            { width: 'auto', style: ['normalLeft', 'bottomLine'], text: paid_due_date_thai_pad,},
          ],
          columnGap: 0,
          lineHeight: 1
        },
        {
          columns: [
            {
              width: '100%',
              text: 'หากผู้ซื้อไม่นำเงินมาชำระให้ตามกำหนด และในกรณีผู้ซื้อทำสัญญาไม่ผ่านเนื่องจากติดเครดิตส่วนกลางโดยไม่ได้แจ้งให้ทราบล่วงหน้า ผู้ซื้อยินยอมให้ริบเงินมัดจำและคืนรถให้ทันทีในสภาพเรียบร้อยทุกประการ ถ้าหากเกิดความเสียหายใดๆ ก็ดี ผู้ซื้อยอมชดใช้ให้ทั้งสิ้น ตลอดทั้งคดีแพ่งและอาญา ทางผู้ขายไม่รับประกันเลขระยะทางของรถยนต์ (กิโลเมตร) ส่วนผู้ขายเมื่อได้รับเงินครบถ้วนตามสัญญาแล้วผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อทันที อากรแสตมป์ซื้อ-ขาย ผู้ซื้อเป็นผู้ออก จะได้ติดให้ครบถ้วน เมื่อวันทำการโอนทะเบียนยานพาหนะฯ ผู้ซื้อ และผู้ขายได้อ่านข้อความเข้าใจดีแล้ว และตกลงกันทั้งสองฝ่าย จึงได้ลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน',
              style: 'normal',
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 6, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normal',
              text: 'ยอดจัด (F) '
            },
            {
              width: '25%',
              style: 'normal',
              text:  ' = ' + ' ' + NumberUtil.addCommas(parseInt(finance_rate, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)...............................................................................ผู้ซื้อ'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normal',
              text: 'เงินดาวน์รถ '
            },
            {
              width: '25%',
              style: 'normal',
              text:  ' = ' + ' ' + NumberUtil.addCommas(parseInt(sum_down, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text: ''
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normal',
              text:  'ค่าโอน + ค่าอากร '
            },
            {
              width: '25%',
              style: 'normal',
              text:   transfer_duty === 0 || transfer_duty === null ? ' = ' + ' ' + '...........................' : ' = ' + ' ' + NumberUtil.addCommas(parseInt(transfer_duty, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)...............................................................................ผู้ขาย'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normal',
              text:  'ค่าตรวจ + ค่าจัด (F) '
            },
            {
              width: '25%',
              style: 'normal',
              text:  inspection_fee === 0 || inspection_fee === null ? ' = ' + ' ' + '...........................' : ' = ' + ' ' + NumberUtil.addCommas(parseInt(inspection_fee, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text: ''
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normal',
              text: class_insurance === 0 || class_insurance === null ? 'ค่าประกันภัยชั้น' + '.........' : 'ค่าประกันภัยชั้น'+ ' ' + class_insurance
            },
            {
              width: '25%',
              style: 'normal',
              text: class_insurance_amount === 0 || class_insurance_amount === null ? ' = ' + ' ' + '...........................' : ' = ' + ' ' + NumberUtil.addCommas(parseInt(class_insurance_amount, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)...............................................................................พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normal',
              text: 'ค่า พ.ร.บ. / ภาษีรถ '
            },
            {
              width: '25%',
              style: 'normal',
              text: act_tax_fee === 0 || act_tax_fee === null ? ' = ' + ' ' + '...........................' : ' = ' + ' ' + NumberUtil.addCommas(parseInt(act_tax_fee, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text:  ' '
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        row_insurance_pa,
        {
          columns: [
            {
              width: '25%',
              style: 'normalBold',
              text: 'รวมเงินทั้งสิ้น '
            },
            {
              width: '25%',
              style: 'normalBold',
              text: ' = ' + ' ' + NumberUtil.addCommas(parseInt(calculate_total, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text:  ''
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            {
              width: '25%',
              style: 'normalRed',
              text: 'หัก : เงินมัดจำรถไว้ '
            },
            {
              width: '25%',
              style: 'normalBold',
              text: ' = ' + ' ' + '( ' + NumberUtil.addCommas(parseInt(less_deposit, 10)) + ' )',
            },
            {
              width: '50%',
              style: 'normal',
              text:  ''
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 0]
        },

        {
          columns: [
            {
              width: '25%',
              style: 'normalBold',
              text: 'ยอดที่ต้องชำระ ณ วันออกรถ '
            },
            {
              width: '25%',
              style: 'normalBold',
              text: ' = ' + ' ' + NumberUtil.addCommas(parseInt(amount_paid_day, 10)),
            },
            {
              width: '50%',
              style: 'normal',
              text:  ' วันที่ ' + amount_paid_date_thai
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 0, 0, 5]
        },
        {
          table: {
            widths: [ '*' ],
            body: [
              [
                {
                  columns: [
                    { width: '0%', style: 'normal', text: '' },

                    { width: 64, style: 'normal', text: 'ข้าพเจ้าผู้ซื้อ',},
                    { width: '25%', style: ['normal', 'bottomLine'], text: buyer_name_pad2,},

                    { width: 78, style: 'normal', text: 'ได้รับรถยนต์ยี่ห้อ',},
                    { width: '42%', style: ['normal', 'bottomLine'], text: car_name_pad,},

                  ],
                  margin: [0, 0, 0, 0,],
                  border: [false, true, false, false],
                  //fillColor: '#cccccc',
                },
              ],
              [
                {
                  columns: [

                    { width: 88, style: 'normal', text: 'หมายเลขทะเบียน',},
                    { width: '30%', style: ['normalLeft', 'bottomLine'], text: car_license_pad2,},

                    {
                      width: 'auto',
                      style: 'normal',
                      text: 'ไปแล้ว อยู่ในสภาพพอใจทุกประการ ถ้าหากเกิดการเสียหายใดๆ ก็ดี'
                    }
                  ],
                  margin: [0, 0, 0, 0,],
                  border: [false, true, false, false],
                  //fillColor: '#cccccc',
                },
              ],
                [
                  {
                    columns: [
                      {
                        width: 'auto',
                        style: 'normal',
                        text: 'ผู้ซื้อยินยอมชดใช้ให้ทั้งสิ้นตลอดจนคดีแพ่งและอาญาใดๆ'
                      }
                    ],
                    margin: [0, 0, 0, 3,],
                    border: [false, true, false, false],
                  }
                ],
              [
                {
                  columns: [
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)..........................................................................ผู้ซื้อ'
                    },
                    {
                      width: '50%',
                      style: 'normal',
                      text: 'เบอร์โทรศัพท์.....................................................................'
                    },
                  ],
                  margin: [8, 4, 8, 3,],
                  border: [true, false, true, false],
                 // fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)..........................................................................ผู้ขาย'
                    },
                    {
                      width: '50%',
                      style: 'normal',
                      text: 'เบอร์โทรศัพท์.....................................................................'
                    },
                  ],
                  margin: [8, 0, 8, 3,],
                  border: [true, false, true, false],
                  //fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)..........................................................................พยาน'
                    },
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)...............................................................................พยาน'
                    },
                  ],
                  margin: [8, 0, 8, 6,],
                  border: [true, false, true, false],
                  //fillColor: '#cccccc',
                }
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: note === '' || note === null ?
                  'หมายเหตุ ................................................................................................................................................................................................................'
                  :
                  'หมายเหตุ ' + ' ' + note
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 5, 0, 0,]
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: note === '' || note === null ?
                  '.................................................................................................................................................................................................................................'
                  : ' '
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 5, 0, 0,]
        },
      ],
      styles: {
        bodyhead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        normal: {
          fontSize: 14,
        },
        normalBold: {
          fontSize: 14,
          bold: true,
        },
        normalRed: {
          fontSize: 14,
          color : '#ff0000',
          bold: true,
        },
        normalRight: {
          fontSize: 15,
          alignment: 'right',
        },
        normalWhite: {
          fontSize: 15,
          color : '#ffffff',
        },
        normalLeft: {
          fontSize: 15,
          alignment: 'left',
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center',
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        }
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    pdfMake.createPdf(docDefinition).open();

  }




  render() {
    let car_name_new = this.state.car_brand_name + ' ' + this.state.car_model_name + ' ' + this.state.car_sub_model_name

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <strong className="text-title">หนังสือสัญญาขายรถยนต์</strong>
            </div>

            <div className="card-block" style={{paddingLeft: 80, paddingRight: 80, paddingTop: 10}} >

              <div className="row" style={{lineHeight: 2.0}}>

                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td width={'30%'} style={styles.tdContentCenter} >
                        &nbsp;
                      </td>
                      <td style={styles.tdContentCenter}>
                       <strong style={{fontSize: 18}}> หนังสือสัญญาขายรถยนต์ </strong>
                      </td>
                      <td width={'30%'}>&nbsp;</td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  >
                        <div className="form-inline mt-5 mb-5">
                          No.
                          <input className="form-control ml-4"
                                 style={styles.inputDataNum}
                                 value={this.state.contract_no}
                                 onChange={(e) => {
                                   this.setState({
                                     contract_no: e.target.value
                                   })
                                 }}
                          />
                        </div>
                      </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >
                        <div className="form-inline mt-5 mb-3" style={{justifyContent: 'right' }}>
                          เขียนที่ <input className="form-control ml-4"
                                        style={styles.inputDataWriteAt}
                                        value={this.state.write_at}
                                        onChange={(e) => {
                                          this.setState({
                                            write_at: e.target.value
                                          })
                                        }}
                        />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  > &nbsp; </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight} >
                        <div className="mb-4 row" style={{justifyContent: 'right' }}>
                          วันที่ &nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 style={{width: 152}}
                                 value={this.state.contract_date_thai === null ? '' : this.state.contract_date_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>

                          {
                            this.state.isOpenDateContract && (
                              <DatePicker
                                selected={this.state.startDateContract}
                                onChange={this.handleDateContract}
                                dateFormat="yyyy-MM-dd"
                                inline
                              />
                            )
                          }
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <span style={{width: 130, textAlign: 'left'}} >ข้าพเจ้า</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="seller_name"
                                 value={this.state.seller_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 130, textAlign: 'center'}} >ตกลงขายรถยนต์ยี่ห้อ</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="car_name"
                                 value={this.state.car_name}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130, textAlign: 'left'}} >หมายเลขเครื่องยนต์</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="car_num_engines"
                                 value={this.state.car_num_engines}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 130}}  >หมายเลขตัวรถ</span>
                          <input className="form-control ml-1"
                                 style={styles.inputLong}
                                 name="car_number"
                                 value={this.state.car_number}
                                 onChange={this.handleOnChange}
                          />


                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130}} >หมายเลขทะเบียน</span>
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="car_license_plate"
                                 value={this.state.car_license_plate}
                                 onChange={this.handleOnChange}
                          />
                          <input className="form-control ml-2"
                                 style={{width: 140}}
                                 name="province_name"
                                 disabled
                                 value={this.state.province_name}
                              //onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50, textAlign: 'center'}}>ปี</span>&nbsp;&nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={styles.inputShort}
                                 name="car_year_name"
                                 value={this.state.car_year_name}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={4}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 30, textAlign: 'center'}}>สี</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-1"
                                 style={styles.inputShort}
                                 name="car_color_name"
                                 value={this.state.car_color_name}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    {/*{ขายให้แก่}*/}
                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 130, textAlign: 'left'}} >ขายให้แก่</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="buyer_name"
                                 value={this.state.buyer_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 30, textAlign: 'center'}} >อายุ</span>&nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={styles.inputShort}
                                 name="buyer_age"
                                 value={this.state.buyer_age}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={2}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50}}  >บัตร</span>
                          <input className="form-control"
                                 style={styles.inputShort }
                                 name="card_type"
                                 value={this.state.card_type}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130, textAlign: 'left'}}> เลขที่ </span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="card_code"
                                 value={this.state.card_code}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 100, textAlign: 'center'}}> ออกให้ ณ เขต</span>
                          <input className="form-control ml-2"
                                 style={styles.inputLong}
                                 name="card_out_at"
                                 value={this.state.card_out_at}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    {/*วันที่ออกบัตร วันหมดอายุ*/}
                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 138, textAlign: 'left'}}> วันที่ออกบัตร </span>
                          <input readOnly={true}
                                 className="form-control"
                                 name="date_of_issue_thai"
                                 style={{width: 160, height: 38}}
                                 value={this.state.date_of_issue_thai === null ? '' : this.state.date_of_issue_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateOfIssue}
                                onChange={this.handleDateOfIssue}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 123, textAlign: 'center'}}> วันหมดอายุ</span>
                          <input readOnly={true}
                                 className="form-control"
                                 name="expiration_date_thai"
                                 style={{width: 160, height: 38}}
                                 value={this.state.expiration_date_thai === null ? '' : this.state.expiration_date_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startExpirationDate}
                                onChange={this.handleExpirationDate}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 130, textAlign: 'left'}} >ที่อยู่</span>
                          <input className="form-control ml-2"
                                 style={{width: '45%'}}
                                 name="buyer_address"
                                 value={this.state.buyer_address}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 80, textAlign: 'center'}} >โทรศัพท์</span>
                          <input className="form-control ml-3"
                                 style={styles.inputLong}
                                 name="buyer_mobile"
                                 value={this.state.buyer_mobile}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <span style={{width: 250}} >เป็นจำนวนเงินทั้งสิ้น</span>
                         {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="real_sell_price"
                                 value={this.state.real_sell_price}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.real_sell_price}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({real_sell_price: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.real_sell_price)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 250}} >ข้าพเจ้าได้รับเงินมัดจำไว้เป็นจำนวนเงิน</span>

                         {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="cash_pledge"
                                 value={this.state.cash_pledge}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.cash_pledge}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({cash_pledge: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.cash_pledge)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 250}} >ผู้ซื้อยังคงค้างอีกเป็นจำนวนเงิน</span>
                          {/*<input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="outstanding"
                                 value={this.state.outstanding}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                            className="form-control ml-2"
                            style={styles.inputLong}
                            thousandSeparator={true}
                            prefix={''}
                            placeholder=""
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.outstanding}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({outstanding: value}, () => this.calOutstanding())
                            }}
                          />
                          <span style={{width: 30}} className="ml-2" >บาท</span>
                          <span className="ml-2" >({ThaiBaht(this.state.outstanding)})</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">

                            <span style={{width: 580}} >ผู้ซื้อจะนำเงินมาชำระให้ครบจำนวนตามที่ตกลง หรือจัดเตรียมเอกสารเพื่อยื่นขอสินเชื่อ ภายในวันที่</span>
                            <input readOnly={true}
                                   className="form-control ml-3"
                                   name="paid_due_date_thai"
                                   style={{width: 160, height: 38}}
                                   value={this.state.paid_due_date_thai === null ? '' : this.state.paid_due_date_thai}
                            />
                            <div style={styles.iconCalendar}>
                              <DatePicker
                                  selected={this.state.startPaidDueDate}
                                  onChange={this.handlePaidDueDate}
                                  dateFormat="yyyy-MM-dd"
                                  customInput={<DatePickerIcon />}
                              />
                            </div>

                            {
                              this.state.isOpenPaidDueDate && (
                                <DatePicker
                                  selected={this.state.startPaidDueDate}
                                  onChange={this.handlePaidDueDate}
                                  dateFormat="yyyy-MM-dd"
                                  inline
                                />
                              )
                            }
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span >
                            หากผู้ซื้อไม่นำเงินมาชำระให้ตามกำหนด และในกรณีผู้ซื้อทำสัญญาไม่ผ่านเนื่องจากติดเครดิตส่วนกลางโดยไม่ได้แจ้งให้ทราบล่วงหน้า
                            ผู้ซื้อยินยอมให้ริบเงินมัดจำและคืนรถให้ทันทีในสภาพเรียบร้อยทุกประการ ถ้าหากเกิดความเสียหายใดๆ ก็ดี ผู้ซื้อยอมชดใช้ให้ทั้งสิ้น ตลอดทั้งคดีแพ่งและอาญา
                            ทางผู้ขายไม่รับประกันเลขระยะทางของรถยนต์ (กิโลเมตร) ส่วนผู้ขายเมื่อได้รับเงินครบถ้วนตามสัญญาแล้วผู้ขายจะทำการโอนกรรมสิทธิ์รถคันนี้ให้แก่ผู้ซื้อทันที
                            อากรแสตมป์ซื้อ-ขาย ผู้ซื้อเป็นผู้ออก จะได้ติดให้ครบถ้วน เมื่อวันทำการโอนทะเบียนยานพาหนะฯ ผู้ซื้อ และผู้ขายได้อ่านข้อความเข้าใจดีแล้ว และตกลงกันทั้งสองฝ่าย จึงได้ลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยาน
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-3">
                          <table style={styles.table}>
                            <tbody>
                            <tr className="mt-3">
                              <td>
                                <span className="mr-5 font-weight-bold" style={{width: 250}}> ยอดจัด (F) </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.rate_finance}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({rate_finance: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>

                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <div className="mt-3"> (ลงนาม)________________________________________ผู้ซื้อ </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="mr-5" style={{width: 250}}> เงินดาวน์รถ </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.sum_down}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({sum_down: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>

                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <div className="mt-3"> (ลงนาม)________________________________________ผู้ขาย </div>
                              </td>
                            </tr>

                            <tr >
                              <td>
                                <span className="mr-5" style={{width: 250}}> ค่าโอน + ค่าอากร </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.transfer_duty}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({transfer_duty: value}, () => this.calOutstanding())
                                    }}
                                />
                            </td>

                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <div className="mt-3"> (ลงนาม)________________________________________พยาน </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="mr-5" style={{width: 250}}> ค่าตรวจ + ค่าจัด (F) </span>
                                &nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.inspection_fee}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({inspection_fee: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>

                              <td style={{...styles.tdContentLeft, width: '50%'}} >
                                <div className="mt-3 mb-2"> (ลงนาม)________________________________________พยาน </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span style={{width: 250}}> ค่าประกันภัยชั้น </span>
                                <input className="form-control ml-2"
                                       style={{width: 49}}
                                       name="class_insurance"
                                       value={this.state.class_insurance || ''}
                                       onChange={this.handleOnChange}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3 mb-2"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.class_insurance_amount}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({class_insurance_amount: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="mr-5" style={{width: 250}}> ค่า พ.ร.บ. / ภาษีรถ </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3 mb-2"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.act_tax_fee}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({act_tax_fee: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>
                            </tr>

                            {this.state.insurance_pa_buy !== null && this.state.insurance_pa_buy !== '' && this.state.insurance_pa_buy === 'PA ซื้อเอง' ?

                            <tr>
                              <td>
                              <span className="mr-5" style={{width: 250}}> ค่าประกันชีวิต PA</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                className="form-control ml-3 mb-2"
                                style={styles.inputLong}
                                thousandSeparator={true}
                                prefix={''}
                                placeholder=""
                                min={0}
                                max={1000000000}
                                step={1000}
                                size={10}
                                allowNegative={false}
                                value={this.state.insurance_pa}
                                onValueChange={(values) => {
                                const {value} = values
                                this.setState({insurance_pa: value}, () => this.calOutstanding())
                              }}
                                />
                                </td>
                              </tr>

                                : null
                            }

                            <tr>
                              <td>
                                <span className="mr-5" style={{width: 250}}> รวมเงินทั้งสิ้น </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3 mb-2"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.calculate_total}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({calculate_total: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="mr-5 text-danger" style={{width: 250}}> หัก : เงินมัดจำรถไว้ </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="ml-5"> = </span>
                                <NumberFormat
                                    className="form-control ml-3 mb-2"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.cash_pledge}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({cash_pledge: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <span className="text-success font-weight-bold" style={{width: 250}}> ยอดที่ต้องชำระ ณ วันออกรถ </span>
                                <span className="ml-5" > = </span>
                                <NumberFormat
                                    className="form-control ml-3 mb-2"
                                    style={styles.inputLong}
                                    thousandSeparator={true}
                                    prefix={''}
                                    placeholder=""
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.amount_paid_day}
                                    onValueChange={(values) => {
                                      const {value} = values
                                      this.setState({amount_paid_day: value}, () => this.calOutstanding())
                                    }}
                                />
                              </td>
                              <td>
                                <div className="row">
                                  <input readOnly={true}
                                         className="form-control"
                                         name="amount_paid_date"
                                         style={{width: 160, height: 38}}
                                         value={this.state.amount_paid_date_thai === null ? '' : this.state.amount_paid_date_thai}
                                  />
                                  <div style={styles.iconCalendar}>
                                    <DatePicker
                                        selected={this.state.startAmountPaidDate}
                                        onChange={this.handleAmountPaidDate}
                                        dateFormat="yyyy-MM-dd"
                                        customInput={<DatePickerIcon />}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td style={{width: '100%'}} colSpan={3}>
                        <div className="mt-3" style={{border: '1px solid #000', backgroundColor: '#ccc', width: '100%', minHeight: 250}} >
                          <table style={styles.table}>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                     <span style={{width: 150, textAlign: 'left'}} > ข้าพเจ้าผู้ซื้อ </span>
                                     <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           value={this.state.buyer_name}
                                           onChange={(e) => {}}
                                     />
                                    <span style={{width: 150, textAlign: 'center'}} > ได้รับรถยนต์ </span>
                                    <input className="form-control ml-2"
                                           style={styles.inputLong}
                                           name="car_name"
                                           value={this.state.car_name}
                                           onChange={(e) => {
                                             let val = e.target.value
                                             this.setState({
                                               car_name : val
                                             })
                                           }}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span style={{width: 150}} >หมายเลขทะเบียน</span>
                                    <input className="form-control ml-2"
                                           style={{width: 140}}
                                           name="car_license_plate"
                                           value={this.state.car_license_plate}
                                           onChange={this.handleOnChange}
                                    />
                                    <input className="form-control ml-2"
                                           style={{width: 140}}
                                           name="province_name"
                                           disabled
                                           value={this.state.province_name}
                                        //onChange={this.handleOnChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span> ไปแล้ว อยู่ในสภาพพอใจทุกประการ ถ้าหากเกิดการเสียหายใดๆ ก็ดี ผู้ซื้อยินยอมชดใช้ให้ทั้งสิ้นตลอดจนคดีแพ่งและอาญาใดๆ</span>
                                  </div>
                                </td>
                              </tr>
                              {/*<tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span> เวลา_________น. ในสภาพเรียบร้อยทุกประการหากเกิดการเสียหายใด ๆ ก็ดีหลังจากที่รับรถยนต์ ดังกล่าวมา</span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-2">
                                    <span> ข้าพเจ้าขอรับผิดชอบค่าเสียหาย และความผิดตามกฎหมายต่าง ๆ ทั้งสิ้น</span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="form-inline mt-0">&nbsp;</div>
                                </td>
                              </tr>*/}
                              <tr>
                                <td>
                                  <div className="form-inline mt-0">
                                    <table style={styles.table}>
                                      <tbody>
                                          <tr>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________ผู้ซื้อ </div>
                                            </td>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> เบอร์โทรศัพท์__________________________________</div>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________ผู้ขาย </div>
                                            </td>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> เบอร์โทรศัพท์__________________________________</div>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________พยาน </div>
                                            </td>
                                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                                              <div className="mt-3"> (ลงนาม)__________________________________พยาน </div>
                                            </td>
                                          </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>


                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 90, textAlign: 'left'}} > หมายเหตุ </span>
                          <input className="form-control"
                                 style={{width: '80%'}}
                                 name="note"
                                 value={this.state.note}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>

                  </tbody>
                </table>
              </div>

              <div className="col-md-12 text-right">
                <div className="card-block">

                  {
                    this.state.contract_id === null ? (
                      <button type="button"
                              className="btn btn-success btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.saveCarContract('add')}
                      >
                        <i className="icon-check"/>
                        &nbsp;บันทึก
                      </button>
                    ) : <button type="button"
                                className="btn btn-warning btn-md mr-2"
                                style={{width: 120}}
                                onClick={() => this.saveCarContract('edit')}
                    >
                      <i className="icon-pencil"/>
                      &nbsp;แก้ไข
                    </button>
                  }

                  {
                    this.state.contract_id === null ? null : (
                      <button type="button"
                              className="btn btn-primary btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.printCarContract()}
                      >
                        <i className="icon-printer"/>  พิมพ์
                      </button>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  table: {
    width: '100%',
    maxWidth: '100%'
  },
  tdContentCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tdContentRight: {
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'right',
  },
  tdContentLeft: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  tableTh:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableTdLeft:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  tableTdCenter:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '70px',
  },
  tableTdRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
  },
  tablePd:{
    paddingLeft: '50px',
    paddingRight: '50px',
    lineHeight: '2.2',
  },
  amountsText:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  amountsTextNoBorder:{
    maxWidth: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  amountsRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
    fontWeight: 'bold',
  },
  buttonHeight: {
    height: 32,
  },
  inputData:{
    borderRadius: '5px',
    width: '450px',
  },
  inputDataNum:{
    borderRadius: '5px',
    width: '100px'
  },
  inputDataWriteAt:{
    borderRadius: '5px',
    width: '180px'
  },
  inputShort: {
    borderRadius: '5px',
    width: '120px'
  },
  inputLong: {
    borderRadius: '1px',
    width: '220px'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarSalesContract2)
