/**
 * พิมพ์หลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว
 * **/

import React, { Component } from 'react'
import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../Loading'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { format } from 'date-fns'
import { DateUtil } from '../../utils/dateUtil'
import { APIURL } from '../../config/config'
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format'
import { AlertSuccess } from '../Alert/Alert'
import { NumberUtil } from '../../utils/number-util'
import DatePickerIcon from "../DatePickerIcon";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class CarReceiptOcpd extends Component {
  constructor (props) {
    super(props)
    this.state = {
      receipt_ocpd_id: null,
      car_id: '',
      customer_id: '',
      car_sell_id: '',
      isExist: false,
      seller_name: '', // ผู้ขาย
      seller_address: '', // ที่อยู่ผู้ประกอบธุรกิจ
      seller_name2: '', // ผู้มีอำนาจออกหลักฐานการรับเงิน
      seller_address2: '', // ที่อยู่ผู้มีอำนาจออกหลักฐานการรับเงิน
      possessor: '',
      ownership: '',
      car_brand_name : '',
      car_model_name : '',
      car_num_engines: '',
      car_year_name: '', // ปี
      car_color_name: '', // สี
      car_number: '', // เลขตัวรถ
      car_license_plate: '',
      car_miles: '',
      province_name : '',
      obligation: '',

      //วันที่ออกหลักฐานรับเงิน สคบ.
      startDateReceiptOcpd: new Date(),
      isOpenDateReceiptOcpd: false,
      receipt_date_ocpd: null,
      receipt_date_ocpd_thai: null,
      //วันที่ส่งมอบสำเนาสมุดคู่มือจดทะเบียนรถยนต์ (ชำระเงินบางส่วน)
      handbook_copy_date: null,
      handbook_copy_date_thai: null,
      startHandbookCopyDate: new Date(),
      isOpenHandbookCopyDate: false,
      //วันที่ส่งมอบรถยนต์ (ชำระเงินครบถ้วน)
      startDateCarDelivery: new Date(),
      isOpenDateCarDelivery: false,
      car_date_delivery: null,
      car_date_delivery_thai: null,
      //วันที่ส่งมอบสมุดคู่มือจดทะเบียนรถยนต์ตัวจริง (ชำระเงินครบถ้วน)
      handbook_date: null,
      handbook_date_thai: null,
      startHandbookDate: new Date(),
      isOpenHandbookDate: false,

      carBase64Img: '',
      sealBase64Img: '',

      //ผู้ซื้อ
      buyer_name: '',
      buyer_address: '',
      some_amount : 0,
      full_amount : 0,
      pay_type : 'ชำระบางส่วน',
      receipt_ocpd : null
    }


    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarSellById = this.loadCarSellById.bind(this);
    this.loadCarReceiptOcpd = this.loadCarReceiptOcpd.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this);
    this.calOutstanding = this.calOutstanding.bind(this);
    this.saveCarReceiptOcpd = this.saveCarReceiptOcpd.bind(this);
    this.printCarReceiptOcpd = this.printCarReceiptOcpd.bind(this);

    this.handleDateReceiptOcpd = this.handleDateReceiptOcpd.bind(this);
    this.handleHandbookCopyDate = this.handleHandbookCopyDate.bind(this);
    this.handleCarDeliveryDate = this.handleCarDeliveryDate.bind(this);
    this.handleHandbookDate = this.handleHandbookDate.bind(this);
    this.onPayTypeChanged = this.onPayTypeChanged.bind(this);
    this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this)
    this.loadCompanySealBase64 = this.loadCompanySealBase64.bind(this)
  }

  async componentDidMount () {
    this.mounted = true
    const queryString = require('query-string')
    let car_sell = queryString.parse(this.props.location.search)
    let car_sell_id = car_sell.car_sell_id

    this.setState({
      car_sell_id : car_sell_id
    },async ()=>{
      await this.loadCompanyLogoBase64();
      await this.loadCompanySetting();
      await this.loadCarReceiptOcpd(car_sell_id);
      await this.loadCarSellById(car_sell_id);
    })

  }

  componentWillUnmount() {
    this.mounted = false
    // window.removeEventListener('resize', this.updateWindowDimensions);
  }

  async loadCompanySetting () {
    await axios.get(APIURL + '/company_setting/1')
      .then(res => {
        let data = res.data
        let company_setting = data.company_setting
        if (company_setting !== null) {
          this.setState({
            company_setting: company_setting,
            seller_name: company_setting.company_name,
            seller_address: company_setting.company_address,
            seller_name2: company_setting.company_name,
            seller_address2: company_setting.company_address,
            possessor: company_setting.company_name,
          })
        }
      })
  }

  async loadCarReceiptOcpd (car_sell_id) {
    await axios.get(APIURL + '/receipt_ocpd/car_sell/' + car_sell_id)
      .then(res => {
        let data = res.data
        if(data.length === 1){
          let ocpd = data[0];

          let receipt_date_ocpd =  moment(ocpd.receipt_date_ocpd).format('YYYY-MM-DD')
          let receipt_date_ocpd_thai = DateUtil.convertLongDateThai(receipt_date_ocpd)

          let handbook_copy_date = ''
          let handbook_copy_date_thai = ''
          let startHandbookCopyDate = null
          if(ocpd.handbook_copy_date !== null && ocpd.handbook_copy_date !== '0000-00-00'){
            handbook_copy_date =  format(new Date( ocpd.handbook_copy_date),'yyyy-MM-dd')
            handbook_copy_date_thai = DateUtil.convertLongDateThai(handbook_copy_date)
            startHandbookCopyDate = new Date(handbook_copy_date)
          }

          let car_date_delivery = ''
          let car_date_delivery_thai = ''
          let startDateCarDelivery = null
          if(ocpd.car_date_delivery !== null && ocpd.car_date_delivery !== '0000-00-00'){
            car_date_delivery =  format(new Date( ocpd.car_date_delivery),'yyyy-MM-dd')
            car_date_delivery_thai = DateUtil.convertLongDateThai(car_date_delivery)
            startDateCarDelivery = new Date(car_date_delivery)
          }


          let handbook_date =  ''
          let handbook_date_thai = ''
          let startHandbookDate = null
          if(ocpd.handbook_date !== null && ocpd.handbook_date !== '0000-00-00'){
            // handbook_date =  moment(ocpd.handbook_date).format('YYYY-MM-DD')
            handbook_date =  format(new Date( ocpd.handbook_date),'yyyy-MM-dd')

            handbook_date_thai = DateUtil.convertLongDateThai(handbook_date)
            startHandbookDate = new Date(handbook_date)
          }

          //console.log(ocpd.pay_type)

          this.setState({
            receipt_ocpd : ocpd,
            receipt_ocpd_id : ocpd.receipt_ocpd_id,
            pay_type : ocpd.pay_type,
            buyer_name : ocpd.buyer_name,
            buyer_address : ocpd.buyer_address,
            car_id : ocpd.car_id,
            car_sell_id : ocpd.car_sell_id,
            customer_id : ocpd.customer_id,

            car_date_delivery: car_date_delivery,
            car_date_delivery_thai: car_date_delivery_thai,
            startDateCarDelivery: startDateCarDelivery,

            receipt_date_ocpd: ocpd.receipt_date_ocpd,
            receipt_date_ocpd_thai: receipt_date_ocpd_thai,

            handbook_copy_date: handbook_copy_date,
            handbook_copy_date_thai: handbook_copy_date_thai,
            startHandbookCopyDate: startHandbookCopyDate,

            handbook_date: handbook_date,
            handbook_date_thai: handbook_date_thai,
            startHandbookDate: startHandbookDate,

            seller_name : ocpd.seller_name,
            seller_address : ocpd.seller_address,
            seller_name2 : ocpd.seller_name2,
            seller_address2 : ocpd.seller_address2,
            car_num_engines : ocpd.car_num_engines,
            car_year_name : ocpd.car_year_name,
            car_color_name : ocpd.car_color_name,
            car_license_plate : ocpd.car_license_plate,
            car_number : ocpd.car_number,
            car_miles : ocpd.car_miles,
            car_brand_name : ocpd.car_brand_name,
            car_model_name : ocpd.car_model_name,
            province_name : ocpd.province_name,
            possessor : ocpd.possessor,
            ownership : ocpd.ownership,
            obligation : ocpd.obligation,
            some_amount : ocpd.some_amount,
            full_amount : ocpd.full_amount,
          })
        }
      })
  }

  async loadCarSellById (car_sell_id) {

    if (this.state.receipt_ocpd_id !== null) {
      // มีรายการหลักฐานการรับเงินแล้ว
      return
    }

    await axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if(this.mounted) {
          let car_sell = res.data[0]
          let car_sell_date = car_sell.car_sell_date
          let date = new Date(car_sell_date)
          let customer_lastname = car_sell.customer_lastname
          let buyer_name = car_sell.customer_name +' ' + customer_lastname // ผู้ซื้อ
          let buyer_address = car_sell.customer_address // ผู้ซื้อ
          let car_id = car_sell.car_id
          let customer_id = car_sell.customer_id
          let car_num_engines = car_sell.car_num_engines // เลขเครื่องยนต์
          let car_year_name = car_sell.car_year_name // ปี
          let car_color_name = car_sell.car_color_name // สี
          let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
          let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old
          let car_number = car_sell.car_number // เลขตัวรถ
          let car_miles = car_sell.car_miles // ระยะทาง
          let car_brand_name = car_sell.car_brand_name // ยี่ห้อ
          let car_model_name = car_sell.car_model_name // รุ่น
          let car_lastname_owner_old = car_sell.car_lastname_owner_old // รุ่น
          let car_name_owner_old = car_sell.car_name_owner_old // รุ่น
          let ownership = car_sell.car_name_owner_old +' ' + car_lastname_owner_old // ผู้ถือกรรมสิทธิ์

          this.setState({
            car_num_engines,
            car_year_name,
            car_color_name,
            car_license_plate,
            car_number,
            car_miles,
            buyer_name,
            buyer_address,
            car_id,
            customer_id,
            car_brand_name,
            car_model_name,
            province_name,
            ownership,
            car_sell_id
          }, () => {
            this.loadDataCarSellPay(car_sell, car_id, customer_id)
          })
        }
      }).catch(error => {
        console.log ('error:', error)
      })
  }

  handleDateReceiptOcpd (date) {
    let receipt_date_ocpd =  moment(date).format('YYYY-MM-DD')
    let receipt_date_ocpd_thai = DateUtil.convertLongDateThai(receipt_date_ocpd)

    this.setState({
      startDateReceiptOcpd: date,
      isOpenDateReceiptOcpd: false,
      receipt_date_ocpd: receipt_date_ocpd,
      receipt_date_ocpd_thai: receipt_date_ocpd_thai
    })
  }

  handleHandbookCopyDate (date) {
    let handbook_copy_date =  moment(date).format('YYYY-MM-DD')
    let handbook_copy_date_thai = DateUtil.convertLongDateThai(handbook_copy_date)

    this.setState({
      startHandbookCopyDate: date,
      isOpenHandbookCopyDate: false,
      handbook_copy_date: handbook_copy_date,
      handbook_copy_date_thai: handbook_copy_date_thai
    })
  }

  handleCarDeliveryDate(date) {
    let car_date_delivery =  moment(date).format('YYYY-MM-DD')
    let car_date_delivery_thai = DateUtil.convertLongDateThai(car_date_delivery)

    this.setState({
      startDateCarDelivery: date,
      isOpenDateCarDelivery: false,
      car_date_delivery: car_date_delivery,
      car_date_delivery_thai: car_date_delivery_thai
    })
  }

  handleHandbookDate (date) {
    let handbook_date =  moment(date).format('YYYY-MM-DD')
    let handbook_date_thai = DateUtil.convertLongDateThai(handbook_date)

    this.setState({
      startHandbookDate: date,
      isOpenHandbookDate: false,
      handbook_date: handbook_date,
      handbook_date_thai: handbook_date_thai
    })
  }

  handleOnChange(e) {
    let name = e.target.name;
    let val = e.target.value;

    this.setState({
      [name]: val
    })
  }

  calOutstanding() {
    let some_amount = this.state.some_amount
    let full_amount = this.state.full_amount
    this.setState({
      //outstanding: outstanding
    })
  }

  onPayTypeChanged(e) {
    //console.log(e.currentTarget.value)
    let pay_type = e.currentTarget.value

    if(pay_type === 'ชำระครบถ้วน' && this.state.receipt_ocpd_id === null){
      let startDateCarDelivery = new Date()
      let car_date_delivery =  moment().format('YYYY-MM-DD')
      let car_date_delivery_thai = DateUtil.convertLongDateThai(car_date_delivery)

      let startHandbookDate = new Date()
      let handbook_date =  moment().format('YYYY-MM-DD')
      let handbook_date_thai = DateUtil.convertLongDateThai(handbook_date)

      let startHandbookCopyDate = new Date()
      let handbook_copy_date =  moment().format('YYYY-MM-DD')
      let handbook_copy_date_thai = DateUtil.convertLongDateThai(handbook_copy_date)


      this.setState({
        pay_type: pay_type,
        startDateCarDelivery : startDateCarDelivery,
        car_date_delivery : car_date_delivery,
        car_date_delivery_thai : car_date_delivery_thai,

        startHandbookDate : startHandbookDate,
        handbook_date : handbook_date,
        handbook_date_thai : handbook_date_thai,

        some_amount : 0,
        handbook_copy_date: '',
        handbook_copy_date_thai: '',

      })

    }else if(pay_type === 'ชำระบางส่วน' && this.state.receipt_ocpd_id === null){
      this.setState({
        pay_type: pay_type,
        car_date_delivery : '',
        car_date_delivery_thai : '',

        handbook_date : '',
        handbook_date_thai : '',

        full_amount : 0,

      })
    }else if(pay_type === 'ชำระครบถ้วน' && this.state.receipt_ocpd_id !== null){
      let receipt_ocpd = this.state.receipt_ocpd

      let handbook_date =  ''
      let handbook_date_thai = ''
      let startHandbookDate = null
      if(receipt_ocpd.handbook_date !== null && receipt_ocpd.handbook_date !== '0000-00-00'){
        handbook_date =  moment(receipt_ocpd.handbook_date).format('YYYY-MM-DD')
        handbook_date_thai = DateUtil.convertLongDateThai(handbook_date)
        startHandbookDate = new Date(handbook_date)
      }

      let car_date_delivery =  ''
      let car_date_delivery_thai = ''
      let startDateCarDelivery = null
      if(receipt_ocpd.car_date_delivery !== null && receipt_ocpd.car_date_delivery !== '0000-00-00'){
        car_date_delivery =  moment(receipt_ocpd.car_date_delivery).format('YYYY-MM-DD')
        car_date_delivery_thai = DateUtil.convertLongDateThai(car_date_delivery)
        startDateCarDelivery = new Date(car_date_delivery)
      }

      let full_amount = ''
      let some_amount = ''

      //let receipt_ocpd = this.state.receipt_ocpd

      let handbook_copy_date =  ''
      let handbook_copy_date_thai = ''
      let startHandbookCopyDate = null
      if(receipt_ocpd.handbook_copy_date !== null && receipt_ocpd.handbook_copy_date !== '0000-00-00'){
        handbook_copy_date =  moment(receipt_ocpd.handbook_copy_date).format('YYYY-MM-DD')
        handbook_copy_date_thai = DateUtil.convertLongDateThai(handbook_copy_date)
        startHandbookCopyDate = new Date(handbook_copy_date)
      }

      this.setState({
        pay_type: pay_type,
        car_date_delivery: receipt_ocpd.car_date_delivery,
        car_date_delivery_thai: car_date_delivery_thai,
        handbook_date: handbook_date,
        handbook_date_thai: handbook_date_thai,
        startHandbookDate: startHandbookDate,
        full_amount : receipt_ocpd.full_amount,

        some_amount : 0,
        handbook_copy_date: '',
        handbook_copy_date_thai: '',
        startHandbookCopyDate: '',
      })

    }else if(pay_type === 'ชำระบางส่วน' && this.state.receipt_ocpd_id !== null){

      let receipt_ocpd = this.state.receipt_ocpd

      let handbook_copy_date =  ''
      let handbook_copy_date_thai = ''
      let startHandbookCopyDate = null
      if(receipt_ocpd.handbook_copy_date !== null && receipt_ocpd.handbook_copy_date !== '0000-00-00'){
        handbook_copy_date =  moment(receipt_ocpd.handbook_copy_date).format('YYYY-MM-DD')
        handbook_copy_date_thai = DateUtil.convertLongDateThai(handbook_copy_date)
        startHandbookCopyDate = new Date(handbook_copy_date)
      }

      this.setState({
        pay_type: pay_type,
        handbook_copy_date: handbook_copy_date,
        handbook_copy_date_thai: handbook_copy_date_thai,
        startHandbookCopyDate: startHandbookCopyDate,
        some_amount : receipt_ocpd.some_amount,

        car_date_delivery: '',
        car_date_delivery_thai: '',

        handbook_date: '',
        handbook_date_thai: '',
        startHandbookDate: '',

        full_amount : 0
      })

    }else{
      this.setState({
        pay_type: pay_type
      })
    }
  }


  loadDataCarSellPay (car_sell, car_id, customer_id) {

    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' +car_id + '/' + customer_id)
      .then(res => {
        let car_sell_pay_list = res.data
        let sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)

        let real_sell_price = this.state.real_sell_price
        let outstanding = Number(real_sell_price) - sum_pay

        this.setState({
          cash_pledge: sum_pay,
          outstanding: outstanding
        })

      }).catch(error => {
      console.log(error)
    })
  }

  async loadCompanyLogoBase64() {
    let uri =  APIURL + '/company_setting/logoimage/1';
    await axios.get(uri)
        .then(res => {
          let body = res.data.body
          this.setState({ carBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }

  async loadCompanySealBase64() {
    let uri =  APIURL + '/company_setting/companyseal/1';
    await axios.get(uri)
        .then(res => {
          let body = res.data.body
          this.setState({ sealBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }

  saveCarReceiptOcpd(action) {
    let buyer_name = this.state.buyer_name
    let buyer_address = this.state.buyer_address
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let obligation = this.state.obligation
    let possessor = this.state.possessor
    let ownership = this.state.ownership
    let some_amount = this.state.some_amount
    let full_amount = this.state.full_amount
    let pay_type = this.state.pay_type
    //let contract_type = 'sell' // sell, buy

    let seller_name = this.state.seller_name
    let seller_address = this.state.seller_address
    let seller_name2 = this.state.seller_name2
    let seller_address2 = this.state.seller_address2
    let car_brand_name = this.state.car_brand_name
    let car_model_name = this.state.car_model_name
    let car_num_engines = this.state.car_num_engines
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let car_license_plate = this.state.car_license_plate
    let car_number = this.state.car_number
    let car_miles = this.state.car_miles
    let province_name = this.state.province_name

    let receipt_date_ocpd = this.state.receipt_date_ocpd
    let handbook_copy_date = this.state.handbook_copy_date
    let car_date_delivery = this.state.car_date_delivery
    let handbook_date = this.state.handbook_date

    let receipt_date_ocpd_thai = this.state.receipt_date_ocpd_thai
    let handbook_copy_date_thai = this.state.handbook_copy_date_thai
    let car_date_delivery_thai = this.state.car_date_delivery_thai
    let handbook_date_thai = this.state.handbook_date_thai


    if (receipt_date_ocpd_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ออกหลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว');
      return
    }

    receipt_date_ocpd = format(new Date(receipt_date_ocpd), 'yyyy-MM-dd')


    let data = {
      buyer_name : buyer_name,
      buyer_address : buyer_address,
      car_id : car_id,
      customer_id : customer_id,
      car_sell_id : car_sell_id,
      pay_type : pay_type,
      obligation : obligation,
      possessor : possessor,
      ownership : ownership,
      some_amount : some_amount,
      full_amount : full_amount,
      receipt_date_ocpd : receipt_date_ocpd,
      handbook_copy_date : handbook_copy_date,
      car_date_delivery : car_date_delivery,
      handbook_date : handbook_date,
      seller_name : seller_name,
      seller_address : seller_address,
      seller_name2 : seller_name2,
      seller_address2 : seller_address2,
      car_num_engines : car_num_engines,
      car_year_name : car_year_name,
      car_color_name : car_color_name,
      car_license_plate : car_license_plate,
      car_number : car_number,
      car_brand_name : car_brand_name,
      car_model_name : car_model_name,
      car_miles : car_miles,
      province_name : province_name
    }

    this.setState({
      isLoading: true,
    }, () => {
      if (action === 'add') {
        let url = APIURL + '/receipt_ocpd';
        axios.post( url, data)
          .then(res => {
            if (res.status === 200) {
              let resData = res.data
              let receipt_ocpd_id = resData.receipt_ocpd_id;
              console.log(receipt_ocpd_id)
              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
                receipt_ocpd_id: receipt_ocpd_id
                // isExist: true,
              },()=> this.loadCarReceiptOcpd(car_sell_id))
            }
          }).catch(error => {
          console.log('error:', error)
        });
      } else if (action === 'edit') {
        let receipt_ocpd_id = this.state.receipt_ocpd_id
        let editData = {...data, receipt_ocpd_id: receipt_ocpd_id }
        let url = APIURL + '/receipt_ocpd';
        axios.put( url, editData)
          .then(res => {
            if (res.data === 'success') {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
              },()=> this.loadCarReceiptOcpd(car_sell_id))
            }
          }).catch(error => {
          console.log('error:', error)
        });
      }

    });
  }

  printCarReceiptOcpd() {
    const {
      carBase64Img
    } = this.state

    let buyer_name = this.state.buyer_name
    let buyer_address = this.state.buyer_address
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_sell_id = this.state.car_sell_id
    let obligation = this.state.obligation
    let possessor = this.state.possessor
    let ownership = this.state.ownership
    let some_amount = this.state.some_amount
    let full_amount = this.state.full_amount
    let pay_type = this.state.pay_type

    let seller_name = this.state.seller_name
    let seller_address =  this.state.seller_address
    let seller_name2 =  this.state.seller_name2
    let seller_address2 =  this.state.seller_address2

    let car_brand_name = this.state.car_brand_name
    let car_model_name = this.state.car_model_name
    let car_num_engines = this.state.car_num_engines
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let car_license_plate = this.state.car_license_plate
    let car_number = this.state.car_number
    let car_miles = this.state.car_miles
    let province_name = this.state.province_name

    let receipt_date_ocpd = this.state.receipt_date_ocpd
    let handbook_copy_date = this.state.handbook_copy_date
    let handbook_date = this.state.handbook_date
    let car_date_delivery = this.state.car_date_delivery

    let receipt_date_ocpd_thai = this.state.receipt_date_ocpd_thai
    let handbook_copy_date_thai = this.state.handbook_copy_date_thai
    let car_date_delivery_thai = this.state.car_date_delivery_thai
    let handbook_date_thai = this.state.handbook_date_thai

    let headerLeft
    let headerCenter

    let some_text = '..................................................................................'

    if(some_amount > 0){
      some_text = NumberUtil.addCommas(parseInt(some_amount, 10)) + ' '+ ' บาท ' + ' ' + '(...'+ThaiBaht(some_amount)+'...)'
    }

    let full_text = '..................................................................................'

    if(full_amount > 0){
      full_text = NumberUtil.addCommas(parseInt(full_amount, 10)) + ' '+ ' บาท ' + ' ' + '(...'+ThaiBaht(full_amount)+'...)'
    }

    headerLeft = {
      image: carBase64Img, width: 80
    }
    //headerCenter = ' หลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว'

    let tableHeader = {
      table: {
        widths: [ '20%', '60%', '20%' ],
        body: [
          [
            {image: carBase64Img, width: 80, rowSpan: 3, layout: 'border'},
            {text: ' หลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว ', style: 'head'},
            '',
          ],
          [
            '',
            {text: ' ตามประกาศคณะกรรมการว่าด้วยสัญญา เรื่องให้ธุรกิจขายรถยนต์ใช้แล้ว ', style: 'head2'},
            '',
          ],
          [
            '',
            {text: ' เป็นธุรกิจที่ควบคุมรายการในหลักฐานการรับเงิน พ.ศ. ๒๕๕๐ ', style: 'head2'},
            '',
          ],
          [
            {text: 'วันที่ ' + receipt_date_ocpd_thai, style: 'normalRight', colSpan: 3},
            '',
            ''
          ]
        ],
      },
      layout: 'noBorders',
      lineHeight: 0.9,
    }

    let tableSeller = {
      table:{
        widths: [ '48%', '52%' ],
        body: [
          [
            {text: 'ชื่อ/ที่อยู่ของผู้ประกอบธุรกิจ (ผู้ขาย)', style: 'sellerHead', border: [false, false, true, false]},
            {text: 'ชื่อ/ที่อยู่ของผู้มีอำนาจออกหลักฐานการรับเงิน', style: 'sellerHead', border: [false, false, false, false] },
          ],
          [
            {text: 'บริษัท....' + seller_name , style: 'normalMini', width:'48%', border: [false, false, true, false]},
            {text: 'ชื่อ....' + seller_name2 , style: 'normalMini', width:'52%', border: [false, false, false, false]},
          ],
          [
            {text: 'ที่อยู่....' + seller_address , style: 'normalMini', width:'48%', border: [false, false, true, false]},
            {text: 'ที่อยู่....' + seller_address2 , style: 'normalMini', width:'52%', border: [false, false, false, false]},
          ],
        ]
      },
      lineHeight: 0.75,
      //layout: 'noBorders',
      border: [false, true, true, false]
    }

    let tableBuyer = {
      table:{
        widths: [ '100%'],
        body: [
          [
            {text: 'ชื่อ/ที่อยู่ของลูกค้า (ผู้ซื้อ)', style: 'normalBigBold'},
          ],
          [
            {text: 'ชื่อ....' + buyer_name + '......', style: 'normalBig'},
          ],
          [
            {text: 'ที่อยู่....' + buyer_address + '......', style: 'normalBig'},
          ],
        ]
      },
      layout: 'noBorders',
      border: [false, true, false, false],
      margin: [ 8, 0, 0, 0 ]
    }

    let tableCarDetail = {
      table:{
        widths: [ '30%', '70%' ],
        body: [
          [
            {text: 'รายละเอียดรถยนต์', style: 'normalBold'},
            '',
          ],
          [
            {text: 'ยี่ห้อ........' + car_brand_name + '........', style: 'normal', width:'30%'},
            {text: 'หมายเลขเครื่องยนต์........' + car_num_engines + '........', style: 'normal', width:'70%'},
          ],
          [
            {text: 'รุ่น...........' + car_model_name + '........', style: 'normal', width:'30%'},
            {text: 'หมายเลขตัวถัง...............' + car_number + '........', style: 'normal', width:'70%'},
          ],
          [
            {text: 'ปี.............' + car_year_name + '..................', style: 'normal', width:'30%'},
            {text: 'หมายเลขทะเบียน..........' + car_license_plate + '  ' + province_name + '........', style: 'normal', width:'70%'},
          ],
          [
            {text: 'สี.............' + car_color_name + '..................', style: 'normal', width:'32%'},
            {text: 'ระยะทางการใช้รถยนต์..........' + car_miles + '...' + 'กิโลเมตร' + '........', style: 'normal', width:'70%'},
          ],
          [
            {text: 'ภาระผูกพัน (ถ้ามี).......................................' + obligation + '............................................',
            style: 'normal', colSpan:2},
            '',
          ],
        ]
      },
      layout: 'noBorders',
      lineHeight: 0.75,
      border: [false, true, true, false]
    }

    let tablePossessor = {
      table:{
        widths: [ '100%'],
        body: [
          [
            {text: 'ผู้ถือกรรมสิทธิ์......' + ownership + '......', style: 'normalBig', colspan:3},
          ],
          [
            {text: 'ผู้ครอบครอง.........' + possessor + '......', style: 'normalBig', colspan:3},
          ],
        ]
      },
      layout: 'noBorders',
      border: [false, true, false, false],
      margin: [ 8, 32, 0, 0 ]
    }

    let tableSomeAmount = {
        table:{
          widths: [ '90%', '10%' ],
          body: [
            [
              {text: 'กรณีชำระเงินค่ารถยนต์บางส่วน', style: 'normalBold'},
              '',
            ],
            [
              {text: '๑. จำนวนเงิน...' + some_text, style: 'normal', colspan:2},
              '',
            ],
            [
              {text: '๒. ผู้ขายตกลงส่งมอบสำเนาสมุดคู่มือจดทะเบียนรถยนต์ ในวันที่...' + handbook_copy_date_thai + '...', style: 'normal', colspan:2},
              '',
            ],
          ]
        },
        layout: 'noBorders',
        lineHeight: 0.8,
        border: [false, true, true, true]
      }

    let tableFullAmount = {
        table:{
          widths: [ '100%' ],
          body: [
            [
              {text: 'กรณีชำระเงินค่ารถยนต์ครบถ้วน', style: 'normalBold'},

            ],
            [
              {text: '๑. จำนวนเงิน...' + full_text, style: 'normal'},

            ],
            [
              {text: '๒. ผู้ขายตกลงส่งมอบรถยนต์ ในวันที่...' + car_date_delivery_thai + '...', style: 'normal'},

            ],
            [
              {text: '๓. ผู้ขายตกลงส่งมอบสมุดคู่มือทะเบียนรถยนต์พร้อมเอกสารที่จำเป็นสำหรับ การจดทะเบียนรถยนต์ ตามที่กรมการขนส่งทางบกกำหนดให้กับผู้ซื้อ ในวันที่...' + handbook_date_thai + '...', style: 'normal'},

            ],
          ]
        },
        layout: 'noBorders',
        lineHeight: 0.8,
        border: [false, true, false, true]
      }


    // pageMargins : [left, top, right, bottom]
    let docDefinition = {
      pageOrientation: 'landscape',
      pageSize: 'A4',
      pageMargins: [45, 25, 45, 0],
      content: [
        tableHeader,
        {
          table: {
            widths: [ '55%', '45%' ],
            body: [
              [
                tableSeller,
                tableBuyer
              ],
              [
                tableCarDetail ,
                tablePossessor,
              ],
              [
                tableSomeAmount,
                tableFullAmount
              ],
            ],
          },
        },
        {
          columns: [
            {
              style: 'bodyFooter',margin: [ 9, 0, 0, 0 ],
              text: 'ในกรณีที่ไม่สามารถดำเนินการจดทะเบียนรถยนต์ใช้แล้วเป็นชื่อผู้บริโภคหรือไม่สามารถส่งมอบรถยนต์ใช้แล้วให้ผู้บริโภคหรือมีการบอกเลิกสัญญา ขายรถยนต์ใช้แล้วโดยมิใช่ความผิดของผู้บริโภค ผู้ประกอบธุรกิจคืนเงินจำนวนดังกล่าวให้ผู้บริโภค'
            },
          ],
          columnGap: 1,
          lineHeight: 1,
          margin: [0, 8, 0, 0]
        },
        {
          table: {
            widths: [ '33%', '33%', '33%' ],
            body: [
              [
                {text: '', style: 'normalSign', border: [false, false, false, false]},
                {text: '', style: 'normalSign', border: [false, false, false, false]},
                {text: '', style: 'normalSign', border: [false, false, false, false]},
              ],
              [
                {text: '', style: 'normalSign', border: [false, false, false, false]},
                {text: '', style: 'normalSign', border: [false, false, false, false]},
                {text: '', style: 'normalSign', border: [false, false, false, false]},
              ],
              [
                {text: 'ลงชื่อ........................................................................', style: 'normalSign', border: [false, false, false, false]},
                {text: 'ลงชื่อ........................................................................', style: 'normalSign', border: [false, false, false, false]},
                {text: 'ลงชื่อ........................................................................', style: 'normalSign', border: [false, false, false, false]},
              ],
              [
                {text: ' (....................................................................)', style: 'normalSign', border: [false, false, false, false],margin: [ 9, 0, 0, 0 ]},
                {text: ' (....................................................................)', style: 'normalSign', border: [false, false, false, false],margin: [ 9, 0, 0, 0 ]},
                {text: ' (....................................................................)', style: 'normalSign', border: [false, false, false, false],margin: [ 9, 0, 0, 0 ]},
              ],
              [
                {text: ' ผู้ซื้อ ', style: 'normalSign', border: [false, false, false, false]},
                {text: ' ผู้ขาย ', style: 'normalSign', border: [false, false, false, false]},
                {text: ' ผู้มีอำนาจออกหลักฐานการรับเงิน ', style: 'normalSign', border: [false, false, false, false]},
              ],
            ],
          },
          lineHeight: 0.7,
        },
      ],

      styles: {
        bodyhead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        bodyFooter: {
          fontSize: 16,
          bold: true,
          alignment: 'left'
        },
        sellerHead: {
          fontSize: 15,
          bold: true,
        },
        head: {
          fontSize: 19,
          bold: true,
          alignment: 'center'
        },
        head2: {
          fontSize: 17,
          bold: true,
          alignment: 'center'
        },
        normal: {
          fontSize: 15,
        },
        normalMini: {
          fontSize: 14,
        },
        normalSign: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        normalBold: {
          fontSize: 15,
          bold: true,
          //decoration : 'underline',
        },
        normalBig: {
          fontSize: 17,
        },
        normalBigBold: {
          fontSize: 17,
          bold: true,
        },
        normalRight: {
          fontSize: 15,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 15,
          alignment: 'left',
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center',
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    pdfMake.createPdf(docDefinition).open();

  }

  render() {
    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <strong className="text-title">หลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว</strong>
            </div>

            <div className="card-block" style={{paddingLeft: 80, paddingRight: 80, paddingTop: 10}} >

              <div className="row" style={{lineHeight: 3.0}}>

                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td width={'30%'} style={styles.tdContentCenter} >
                        &nbsp;
                      </td>
                      <td style={styles.tdContentCenter}>
                        <strong className="text-title" style={{fontSize: 18}}> หลักฐานการรับเงิน ธุรกิจการขายรถยนต์ใช้แล้ว </strong>
                      </td>
                      <td width={'30%'}>&nbsp;</td>
                    </tr>

                    <tr>
                      <td style={styles.tdContentLeft}  > &nbsp; </td>
                      <td>&nbsp;</td>
                      <td style={styles.tdContentRight}>
                        <div className="row" style={{justifyContent: 'right' }}>
                          วันที่ &nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 style={{width: 170}}
                                 value={this.state.receipt_date_ocpd_thai === null ? '' : this.state.receipt_date_ocpd_thai}
                          />
                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateReceiptOcpd}
                                onChange={this.handleDateReceiptOcpd}
                                dateFormat="yyyy-MM-dd"
                                customInput={<DatePickerIcon />}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <span style={{width: 270, textAlign: 'right'}} >ชื่อ/ที่อยู่ของผู้ประกอบธุรกิจ (ผู้ขาย)
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <input className="form-control ml-2"
                                 style={{width: '20%'}}
                                 name="seller_name"
                                 value={this.state.seller_name}
                                 onChange={this.handleOnChange}
                          />
                                 &nbsp;&nbsp;
                          {/*<span style={{width: 50, textAlign: 'center'}} >ที่อยู่</span>*/}
                          <input className="form-control ml-2"
                                 style={{width: '35%'}}
                                 name="seller_address"
                                 value={this.state.seller_address}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <span style={{width: 270, textAlign: 'right'}} >ชื่อ/ที่อยู่ของผู้มีอำนาจออกหลักฐานการรับเงิน</span>
                          <input className="form-control ml-2"
                                 style={{width: '20%'}}
                                 name="seller_name2"
                                 value={this.state.seller_name2}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          {/*<span style={{width: 50, textAlign: 'center'}} >ที่อยู่</span>*/}
                          <input className="form-control ml-2"
                                 style={{width: '35%'}}
                                 name="seller_address2"
                                 value={this.state.seller_address2}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <span style={{width: 270, textAlign: 'right'}} >ชื่อ/ที่อยู่ของลูกค้า (ผู้ซื้อ)
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                          <input className="form-control ml-2"
                                 style={{width: '20%'}}
                                 name="buyer_name"
                                 value={this.state.buyer_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          {/*<span style={{width: 50, textAlign: 'center'}} >ที่อยู่</span>*/}
                          <input className="form-control ml-2"
                                 style={{width: '35%'}}
                                 name="buyer_address"
                                 value={this.state.buyer_address}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <hr/>
                        <strong className="text-title">รายละเอียดรถยนต์</strong>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2 ml-2">
                          <span style={{width: 50}} >&nbsp;ยี่ห้อ</span>&nbsp;
                          <input className="form-control ml-3"
                                 style={{width: '15%'}}
                                 name="car_brand_name"
                                 value={this.state.car_brand_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50}} >&nbsp;รุ่น</span>
                          <input className="form-control ml-1"
                                 style={{width: '15%'}}
                              name="car_model_name"
                              value={this.state.car_model_name}
                              onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50}} >&nbsp;ปีรถ</span>
                          <input className="form-control"
                                 style={{width: '10%'}}
                                 name="car_year_name"
                                 value={this.state.car_year_name}
                                 onChange={this.handleOnChange}
                                 inputMode={'numeric'}
                                 maxLength={4}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span style={{width: 50}} >&nbsp;สีรถ</span>
                          <input className="form-control"
                                 style={{width: '10%'}}
                                 name="car_color_name"
                                 value={this.state.car_color_name}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2 ml-2">
                          <span style={{width: 100, textAlign: 'center'}} >เลขเครื่องยนต์&nbsp;&nbsp;</span>
                          <input className="form-control ml-2"
                                 style={{width: '12%'}}
                                 name="car_num_engines"
                                 value={this.state.car_num_engines}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;
                          <span style={{width: 100, textAlign: 'center'}} >เลขตัวถัง</span>
                          <input className="form-control ml-2"
                                 style={{width: '12%'}}
                                 name="car_number"
                                 value={this.state.car_number}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;
                          <span style={{width: 100, textAlign: 'center'}} >ทะเบียนรถ</span>
                          <input className="form-control ml-2"
                                 style={{width: '10%'}}
                                 name="car_license_plate"
                                 value={this.state.car_license_plate}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <input className="form-control ml-2"
                                 style={{width: '10%'}}
                                 name="province_name"
                                 value={this.state.province_name}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;
                          <span style={{width: 100, textAlign: 'center'}} >ระยะทาง</span>
                          <input className="form-control"
                                 style={{width: '10%'}}
                                 name="car_miles"
                                 value={this.state.car_miles}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 145, textAlign: 'center'}} >ภาระผูกพัน (ถ้ามี)&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <input className="form-control ml-2"
                                 style={{width: '50%'}}
                                 name="obligation"
                                 value={this.state.obligation}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <hr/>
                      </td>
                    </tr>

                    {/*{กรรมสิทธิ์}*/}
                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2 ml-2">
                          <span style={{width: 100, textAlign: 'center'}} >ผู้ถือกรรมสิทธิ์</span>
                          <input className="form-control ml-2"
                                 style={{width: '25%'}}
                                 name="ownership"
                                 value={this.state.ownership}
                                 onChange={this.handleOnChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{width: 100, textAlign: 'center'}} >ผู้ครอบครอง</span>
                          <input className="form-control ml-2"
                                 style={{width: '25%'}}
                                 name="possessor"
                                 value={this.state.possessor}
                                 onChange={this.handleOnChange}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <hr/>
                        <strong className="text-title">การชำระเงิน</strong>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline">
                          <div className="btn-group colors" style={{height: 36, padding: 10}}>

                            <input type="radio"
                                   name="pay_type"
                                   value="ชำระบางส่วน"
                                   className="btn btn-primary"
                                   checked={this.state.pay_type === 'ชำระบางส่วน'}
                                   onChange={this.onPayTypeChanged}
                            />
                            &nbsp;&nbsp;<span style={{marginTop: -4}}>ชำระเงินค่ารถยนต์บางส่วน</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio"
                                   name="pay_type"
                                   value="ชำระครบถ้วน"
                                   className="btn btn-primary"
                                   checked={this.state.pay_type === 'ชำระครบถ้วน'}
                                   onChange={this.onPayTypeChanged}
                            /> &nbsp;&nbsp;<span style={{marginTop: -4}}>ชำระเงินค่ารถยนต์ครบถ้วน</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <hr/>
                        <strong className="text-title">กรณีชำระเงินค่ารถยนต์บางส่วน</strong>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 100, textAlign: 'center'}}>&nbsp;&nbsp;&nbsp;๑. จำนวนเงิน </span>
                          {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="real_sell_price"
                                 value={this.state.real_sell_price}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                              className="form-control ml-3"
                              style={styles.inputLong}
                              thousandSeparator={true}
                              prefix={''}
                              placeholder=""
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.some_amount}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({some_amount: value}, () => this.calOutstanding())
                              }}
                              disabled={this.state.pay_type === 'ชำระครบถ้วน'}
                          />
                          <span style={{width: 50}} className="ml-1"> &nbsp;&nbsp;บาท&nbsp;&nbsp;</span>
                          <span className="ml-1" >( &nbsp; {ThaiBaht(this.state.some_amount)} &nbsp;)</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 385, textAlign: 'center'}}>
                            &nbsp;&nbsp;&nbsp;
                            ๒. ผู้ขายตกลงส่งมอบสำเนาสมุดคู่มือจดทะเบียนรถยนต์ ในวันที่ </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          <input readOnly={true}
                                   className="form-control"
                                   name="handbook_copy_date_thai"
                                   style={{width: 160, height: 38}}
                                   value={this.state.handbook_copy_date_thai === null ? '' : this.state.handbook_copy_date_thai}
                                   onChange={() => {}}
                                   disabled={this.state.pay_type === 'ชำระครบถ้วน'}
                            />
                          <div style={styles.iconCalendar}>
                          <DatePicker
                              selected={this.state.startHandbookCopyDate}
                              onChange={this.handleHandbookCopyDate}
                              dateFormat="yyyy-MM-dd"
                              disabled={this.state.pay_type === 'ชำระครบถ้วน'}
                              customInput={<DatePickerIcon />}
                          />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <hr/>
                        <strong className="text-title">กรณีชำระเงินค่ารถยนต์ครบถ้วน</strong>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 100, textAlign: 'center'}}>&nbsp;&nbsp;&nbsp;๑. จำนวนเงิน </span>
                          {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="real_sell_price"
                                 value={this.state.real_sell_price}
                                 onChange={() => {}}
                          />*/}
                          <NumberFormat
                              className="form-control ml-3"
                              style={styles.inputLong}
                              thousandSeparator={true}
                              prefix={''}
                              placeholder=""
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.full_amount}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({full_amount: value}, () => this.calOutstanding())
                              }}
                              disabled={this.state.pay_type === 'ชำระบางส่วน'}
                          />
                          <span style={{width: 50}} className="ml-1"> &nbsp;&nbsp;บาท&nbsp;&nbsp;</span>
                          <span className="ml-1" >( &nbsp; {ThaiBaht(this.state.full_amount)} &nbsp;)</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2">
                          <span style={{width: 255, textAlign: 'center'}}>

                            ๒. ผู้ขายตกลงส่งมอบรถยนต์ ในวันที่ </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 name="car_date_delivery_thai"
                                 style={{width: 160, height: 38}}
                                 value={this.state.car_date_delivery_thai === null ? '' : this.state.car_date_delivery_thai}
                                 onChange={() => {}}
                                 disabled={this.state.pay_type === 'ชำระบางส่วน'}
                          />

                          <div style={styles.iconCalendar}>
                            <DatePicker
                                selected={this.state.startDateCarDelivery}
                                onChange={this.handleCarDeliveryDate}
                                dateFormat="yyyy-MM-dd"
                                disabled={this.state.pay_type === 'ชำระบางส่วน'}
                                customInput={<DatePickerIcon />}
                            />
                          </div>

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <div className="form-inline mt-2 ml-4">
                          <span>
                            ๓. ผู้ขายตกลงส่งมอบสมุดคู่มือทะเบียนรถยนต์พร้อมเอกสารที่จำเป็นสำหรับการจดทะเบียนรถยนต์ ตามที่กรมขนส่งทางบกกำหนดให้กับผู้ซื้อ ในวันที่ </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input readOnly={true}
                                 className="form-control"
                                 name="handbook_date_thai"
                                 style={{width: 160, height: 38}}
                                 value={this.state.handbook_date_thai === null ? '' : this.state.handbook_date_thai}
                                 onChange={() => {}}
                                 disabled={this.state.pay_type === 'ชำระบางส่วน'}
                          />
                          <div style={styles.iconCalendar}>
                          <DatePicker
                              selected={this.state.startHandbookDate}
                              onChange={this.handleHandbookDate}
                              dateFormat="yyyy-MM-dd"
                              disabled={this.state.pay_type === 'ชำระบางส่วน'}
                              customInput={<DatePickerIcon />}
                          />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <hr/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-md-12 text-right">
                <div className="card-block">

                  {
                    this.state.receipt_ocpd_id === null ? (
                      <button type="button"
                              className="btn btn-success btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.saveCarReceiptOcpd('add')}
                      >
                        <i className="icon-check"/>
                        &nbsp;บันทึก
                      </button>
                    ) : <button type="button"
                                className="btn btn-warning btn-md mr-2"
                                style={{width: 120}}
                                onClick={() => this.saveCarReceiptOcpd('edit')}
                    >
                      <i className="icon-pencil"/>
                      &nbsp;แก้ไข
                    </button>
                  }

                  {
                    this.state.receipt_ocpd_id === null ? null : (
                      <button type="button"
                              className="btn btn-primary btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.printCarReceiptOcpd()}
                      >
                        <i className="icon-printer"/>  พิมพ์
                      </button>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  table: {
    width: '100%',
    maxWidth: '100%'
  },
  tdContentCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tdContentRight: {
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'right',
  },
  tdContentLeft: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  tableTh:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableTdLeft:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  tableTdCenter:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '70px',
  },
  tableTdRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
  },
  tablePd:{
    paddingLeft: '50px',
    paddingRight: '50px',
    lineHeight: '2.2',
  },
  amountsText:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  amountsTextNoBorder:{
    maxWidth: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  amountsRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
    fontWeight: 'bold',
  },
  buttonHeight: {
    height: 32,
  },
  iconCalendar:{
    width: '50px',
  },
  inputData:{
    borderRadius: '5px',
    width: '450px',
  },
  inputDataNum:{
    borderRadius: '5px',
    width: '100px'
  },
  inputDataWriteAt:{
    borderRadius: '5px',
    width: '180px'
  },
  inputShort: {
    borderRadius: '5px',
    width: '120px'
  },
  inputLong: {
    borderRadius: '1px',
    width: '220px'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarReceiptOcpd)
