import React, { Component } from 'react'


export default class BranchForm extends Component{
  constructor (props){
    super(props)
    this.state = {
        branch : this.props.branch
    }
    this.onChangeBranch = this.onChangeBranch.bind(this);
    this.onSubmitBranch = this.onSubmitBranch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
      if(JSON.stringify(nextProps.branch) !== JSON.stringify(this.props.branch)){
          this.setState({ branch: nextProps.branch });
      }
  }

  onChangeBranch(event) {
    //alert (event.target.name);
    let xname = event.target.name
    let value = event.target.value

    this.setState ({branch:{
        ...this.state.branch,
        [xname]: value
    }})

    /*if(xname==="branch_name"){
      this.setState ({branch:{
          ...this.state.branch,
          branch_name: event.target.value
        }})
    }else if(xname==="branch_address"){
      this.setState ({branch:{
          ...this.state.branch,
          branch_address: event.target.value
        }})
    }else if(xname==="branch_tel"){
      this.setState ({branch:{
          ...this.state.branch,
          branch_tel: event.target.value
      }})
    }else if (xname === 'tax_id') {
      this.setState ({branch:{
          ...this.state.branch,
          tax_id: event.target.value
        }})
    }*/
  }

  onSubmitBranch(e) {
    const { branch } = this.state
    if (this.props.actionType==="แก้ไข") {
      this.props.onBranchSubmit.update(e, branch)
    } else {
      this.props.onBranchSubmit.add(e, branch)
      this.setState({branch: ''})
    }
  }

    render(){
        return (
            <div className="card card-accent-danger">
                <div className="card-header card-customer">
                    <strong className="text-title">เพิ่มสาขา</strong>
                </div>
                <form action="" method="post"
                      onSubmit={ this.onSubmitBranch }
                >

                    <div className="card-block" >

                      <div className="form-group">
                        <label htmlFor="branch_type">ประเภท </label>
                        <select
                          className="form-control"
                          name="branch_type"
                          value={this.state.branch.branch_type || ''}
                          style={styles.inputSearch}
                          onChange={this.onChangeBranch}
                        >
                          <option value='นิติบุคคล'>นิติบุคคล</option>
                          <option value='บุคคลธรรมดา'>บุคคลธรรมดา</option>
                        </select>
                      </div>

                        <div className="form-group">
                            <label htmlFor="branch_name">สาขา </label>
                            <input name="branch_name"
                                   value={this.state.branch.branch_name || ''}
                                   style={styles.inputSearch}
                                   type="text" className="form-control"
                                   placeholder="กรอกชื่อสาขา"
                                   onChange={this.onChangeBranch}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="branch_address">ที่อยู่ </label>
                            <input name="branch_address"
                                   value={this.state.branch.branch_address || ''}
                                   style={styles.inputSearch}
                                   type="text" className="form-control"
                                   placeholder="กรอกที่อยู่สาขา"
                                   onChange={this.onChangeBranch}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="branch_tel">เบอร์โทรศัพท์</label>
                            <input name="branch_tel"
                                   value={this.state.branch.branch_tel || ''}
                                   style={styles.inputSearch}
                                   type="text" className="form-control"
                                   maxLength={100}
                                   placeholder="กรอกเบอร์โทรศัพท์"
                                   onChange={this.onChangeBranch}/>
                        </div>

                        <div className="form-group">
                          <label htmlFor="tax_id">เลขที่ผู้เสียภาษี</label>
                          <input name="tax_id"
                                 value={this.state.branch.tax_id || ''}
                                 style={styles.inputSearch}
                                 type="text"
                                 className="form-control"
                                 placeholder="เลขที่ผู้เสียภาษี"
                                 onChange={this.onChangeBranch}/>
                        </div>

                        <div className="form-group">
                          <label htmlFor="signatory">ผู้มีอำนาจลงนาม</label>
                          <input name="signatory"
                                 value={this.state.branch.signatory || ''}
                                 style={styles.inputSearch}
                                 type="text"
                                 className="form-control"
                                 placeholder="ผู้มีอำนาจลงนาม"
                                 onChange={this.onChangeBranch}/>
                        </div>

                    </div>

                        <div className="card-footer text-right">
                            <button type="button"
                                    className="btn btn-sm btn-danger mr-2"
                                    onClick={this.props.onBranchSubmit.cancel}
                                    style={styles.button}
                            >
                              <i className="fa fa-refresh"/> ยกเลิก
                            </button>
                            <button type="submit"
                                    className={this.props.actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                                    style={styles.button}
                            >
                                <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.props.actionType}
                            </button>
                        </div>

                </form>
            </div>
        );
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },
    inputSearch: {
        borderRadius:5,
    },
}
//export  default BranchForm;