import React, { Component } from 'react'
import {checkGroupPermission} from "../../../services/grouppermission";

export  default class ProfileListRow extends Component {
  render() {

    let profileList = this.props.profile_lists.map((profile,index) => {
      return (
        <tr key={index}>
          <td className="text-center">{ profile.profile_id }</td>
          <td>
            ชื่อ-นามสกุล: {profile.customer_name} {profile.customer_lastname} ({profile.customer_mobile})<br/>
            โปรไฟล์ : {profile.profile_name}  | จาก {profile.profile_from} <br/>
            ที่อยู่ : {profile.profile_address} <br/>
            <hr/>
            F/N : {profile.profile_fn1} F/N : {profile.profile_fn2} F/N : {profile.profile_fn3}<br/>
            NCB1 : {profile.profile_ncb1} <br/>
          </td>
          <td>
            คนค้ำ 1: {profile.profile_guarantor1}<br/>
            NCB คนค้ำ 1: {profile.profile_guarantor_ncb1}<br/>
            <hr/>
            คนค้ำ 2: {profile.profile_guarantor2}<br/>
            NCB คนค้ำ 2: {profile.profile_guarantor_ncb2}<br/>
          </td>
          <td className="text-center">

              {
                  checkGroupPermission(402, this.props.grouppermissions).modified ? (
                      <>
                          <button className="btn btn-sm btn-warning btn-md-width"
                                  onClick={this.props.onProfileEdit.bind(null,profile)}
                          >
                              <i className="fa fa-edit"/> แก้ไข</button>
                          <br/>
                      </>
                  ) : null
              }

              {
                  checkGroupPermission(402, this.props.grouppermissions).deleted ? (
                      <button className="btn btn-sm btn-danger btn-md-width mt-2"
                              onClick={this.props.onProfileDelete.bind(null,profile)}
                      >
                          <i className="fa fa-remove"/> ลบ</button>
                  ) : null
              }

              {
                  checkGroupPermission(402, this.props.grouppermissions).modified === 0
                  && checkGroupPermission(402, this.props.grouppermissions).deleted === 0
                      ? <><i className="icon-close"/>  </> : null
              }


          </td>
        </tr>
       )
    });

    return (
      <div className="card-block">
        <table className="table table-striped table-responsive">
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-left">โปรไฟล์ลูกค้า</th>
            <th className="text-left">คนค้ำประกัน</th>
            <th className="text-center">จัดการ</th>
          </tr>
          </thead>
          <tbody>
          {profileList}
          </tbody>
        </table>
      </div>
    )
  }
}