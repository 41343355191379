/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import 'moment/locale/th';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom'
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format'
import { PORT } from '../../../config/config'
import DebtWarning from '../../Sales/SalesList/DebtWarning'
import DocUtil from '../../../utils/docUtil'
import { checkGroupPermission } from '../../../services/grouppermission'

class DebtorsListRow extends Component{

    constructor (props){
        super(props)
        this.state = {
            'btnClick': '',
            'debtorsSelect': 0,
            debtors_id:'',
        }

    }

    toQueryString(paramsObject) {
        return Object
            .keys(paramsObject)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
            .join('&')
            ;
    }

    handleOnClick(action,debtors){

        var search = this.toQueryString (debtors);

        this.setState({
            'btnClick': action,
            'debtorsSelect': search,
            'debtors_id': debtors.debtors_id,
        });

    }

    render(){

        if (this.state.btnClick==="debtors-payment-detail") {
            var target = "/debtors/payment-detail/?debtors_id=" + this.state.debtors_id;
            return <Redirect push to={target}/>;
        }

        let grouppermissions = this.props.grouppermissions;
        let sumRemain = 0;
        let sumPaid = 0;
        let sumDebt = 0;

        let debtorsList = this.props.debtors_list.map((debtors,index)=> {

           // let interest_rate = debtors.interest_rate
            let interest_type = debtors.interest_type
            let amount_pay_capital_sum = debtors.amount_pay_capital_sum
            let amount_pay_interest_sum = debtors.amount_pay_interest_sum
            let amount_before_interest = debtors.amount_before_interest // ยอดหนี้ยังไม่รวมดอก

          // let debt_status = debtors.debt_status
          // let debt_status_note = debtors.debt_status_note
          // console.log(debt_status, debt_status_note)

          let remain = 0
          let amount_pay_sum = 0
          let amount_of_debt = 0

          if (interest_type === 'ดอกเบี้ยลดต้นลดดอก') {
            // " debtors.amount_before_interest, " +

            amount_pay_capital_sum = parseFloat(String(amount_pay_capital_sum)).toFixed(2)
            amount_pay_interest_sum = parseFloat(String(amount_pay_interest_sum)).toFixed(2)

            remain =  amount_before_interest - amount_pay_capital_sum;
            sumPaid += parseFloat(amount_pay_capital_sum) // รวมต้นจ่าย
            sumDebt += parseFloat(amount_before_interest) // ยอดเงินต้น

          } else {
            // ดอกเบี้ยคงที่
            remain =  debtors.amount_of_debt - debtors.amount_pay_sum ;
            sumPaid += parseFloat(debtors.amount_pay_sum)
            sumDebt += parseFloat(debtors.amount_of_debt)
          }

          sumRemain += parseFloat(remain)
          remain = parseFloat(String(remain)).toFixed(2)
          amount_pay_sum = parseFloat(String(debtors.amount_pay_sum)).toFixed(2)
          amount_of_debt = parseFloat(String(debtors.amount_of_debt)).toFixed(2)



          let car_license_plate = debtors.car_license_plate_new !== '' &&  debtors.car_license_plate_new !==  debtors.car_license_plate_old ?
              debtors.car_license_plate_new : debtors.car_license_plate_old

            let province_name = debtors.car_license_plate_new !== '' &&  debtors.car_license_plate_new !==  debtors.car_license_plate_old ?
                debtors.province_name_new : debtors.province_name_old

            let car_issue_date = moment(debtors.car_issue_date).locale('th').format('LL')
            let car_issue_date_thai = car_issue_date === 'Invalid date' ? '-' : moment(debtors.car_issue_date).locale('th').format('LL')

          let payData = null
          // is_complete = true คือจ่ายครบแล้ว
            let is_complete = false
            if(interest_type === 'ดอกเบี้ยลดต้นลดดอก') {
                if(amount_pay_capital_sum === amount_before_interest) {
                    is_complete = true
                }
              payData = {
                remain: remain,
                amount_pay_sum: amount_pay_capital_sum,
                amount_of_debt: amount_before_interest
              }
            }else {
                if (amount_pay_sum === amount_of_debt){
                    is_complete = true
                }
              payData = {
                remain: remain,
                amount_pay_sum: amount_pay_sum,
                amount_of_debt: amount_of_debt
              }
            }

            let debt_status = debtors.debt_status

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-left">
                      {debtors.debtors_name} {DocUtil.getDebtStatusName(debtors.debt_status)}
                      <br/>

                      ลูกหนี้ : {debtors.customer_name}  {debtors.customer_lastname}

                        {debtors.car_id !== null && debtors.car_id !== '' ?
                            <>
                                <br/>
                                <strong>{debtors.car_name} {' '} {'สี '} {debtors.car_color_name}</strong>
                            </>
                            : null
                        }

                        {car_license_plate !== null && province_name !== '' ?
                            <>
                                <br/>
                                <strong>ทะเบียนรถ :  {car_license_plate} &nbsp; {province_name}</strong>
                            </>
                            : null
                        }

                        {PORT !== 9048 ?
                            <>
                                <br/>
                                ประเภทดอกเบี้ย :  {interest_type}
                            </>
                            : null
                        }

                        {debtors.car_id !== null && debtors.car_id !== '' ?
                            <>
                                <br/>
                                วันที่ออกรถ :  {car_issue_date_thai} &nbsp;&nbsp;
                                ผู้ขาย :  {debtors.sale_name} {debtors.sale_lastname}
                            </>
                            : null
                        }

                        <br/>

                      จำนวนงวด : {debtors.duration_period_name} &nbsp; ยอดผ่อนชำระ/งวด : {debtors.amount_pay_period.toLocaleString()} {' บาท'}

                      {
                        debtors.bank_name !== null ? <>
                        <br/>
                          ช่องทางการรับชำระ(ธนาคาร) :  {debtors.bank_name }
                        </> : null
                      }

                      {
                        debtors.debt_status_note !== null ? <>
                          <hr/>
                          หมายเหตุสถานะหนี้ :  {debtors.debt_status_note }
                        </> : null
                      }

                    </td>

                    <td className="text-center">
                        <NumberFormat value={remain}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>
                    </td>
                    <td className="text-center">
                        {/*<NumberFormat
                           value={interest_type === 'ดอกเบี้ยลดต้นลดดอก' ? amount_pay_capital_sum : debtors.amount_pay_sum}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>*/}

                      {
                        interest_type === 'ดอกเบี้ยลดต้นลดดอก' ? <>
                        เงินต้น : <NumberFormat
                          value={amount_pay_capital_sum}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'}/>
                          <br/>
                        ดอกเบี้ยรับ :  <NumberFormat
                            value={amount_pay_interest_sum}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'฿'}/>
                        </> : (
                          <NumberFormat
                            value={interest_type === 'ดอกเบี้ยลดต้นลดดอก' ? amount_pay_capital_sum : amount_pay_sum}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'฿'}/>
                        )
                      }
                    </td>
                    <td className="text-center">
                        <NumberFormat
                          value={interest_type === 'ดอกเบี้ยลดต้นลดดอก' ? amount_before_interest : amount_of_debt}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'}
                        />
                    </td>
                    <td className="text-center">
                      {debtors.fullname}
                      <br/>
                      {moment(debtors.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}

                        {
                            is_complete === false && <DebtWarning
                                debtors_id={debtors.debtors_id}
                                car_id={debtors.car_id}
                                customer_id={debtors.customer_id}
                                car_sell_id={debtors.car_sell_id}
                                warning_type='debtors'
                            />
                        }

                    </td>
                    <td className="text-right">

                      {
                        checkGroupPermission(1001, grouppermissions).printed ?
                          <button  onClick={()=>this.props.debtorsCallbacks.print(debtors)}
                                   type="button" className="btn btn-secondary btn-sm btn-block">
                            <i className="icon-printer"> พิมพ์ใบผ่อนชำระ</i>
                          </button>
                          : null
                      }


                      {
                        checkGroupPermission(1003, grouppermissions).viewed ?
                          <button  onClick={()=>this.handleOnClick('debtors-payment-detail', debtors)}
                                   type="button" className="btn btn-info btn-sm btn-block">
                            <i className="icon-list"> รายละเอียดชำระหนี้</i></button>
                          : null
                      }


                      {
                        checkGroupPermission(1001, grouppermissions).modified ?
                          <button  onClick={()=>this.props.debtorsCallbacks.bankEdit(debtors)}
                                   type="button" className="btn btn-warning btn-sm btn-block">
                            <i className="icon-pencil"> แก้ไขธนาคาร </i>
                          </button>
                          : null
                      }


                      {
                        checkGroupPermission(1002, grouppermissions).modified ?
                          <button  onClick={()=>this.props.debtorsCallbacks.debtStatusEdit(debtors, payData)}
                                   type="button" className="btn btn-primary btn-sm btn-block">
                            <i className="icon-note"> แก้ไขสถานะลูกหนี้ </i>
                          </button>
                          : null
                      }

                      {
                        checkGroupPermission(1002, grouppermissions).approved ?
                          <button  onClick={()=>this.props.debtorsCallbacks.debtStatusCompleteEdit(debtors)}
                                   type="button" className="btn btn-warning btn-sm btn-block">
                            <i className="icon-check"> ปิดหนี้ </i>
                          </button>
                          : null
                      }


                      {/*{
                        PORT === 9008 && (this.props.user_type === 'admin' || this.props.user_type === 'manager')
                        ? <button
                            onClick={()=>this.props.debtorsCallbacks.del(debtors)}
                            type="button"
                            className={"btn btn-danger btn-sm btn-block"}
                          >
                            <i className="icon-trash"> ลบรายการหนี้</i>
                          </button>
                          :
                          <button
                            onClick={()=>this.props.debtorsCallbacks.del(debtors)}
                            type="button"
                            className={this.props.user_type === 'admin'? "btn btn-danger btn-sm btn-block" : "hidden" }
                          >
                            <i className="icon-trash"> ลบรายการหนี้</i>
                          </button>
                      }*/}

                      {
                        checkGroupPermission(1001, grouppermissions).deleted ?
                          <button
                            onClick={()=>this.props.debtorsCallbacks.del(debtors)}
                            type="button"
                            className={this.props.user_type === 'admin'? "btn btn-danger btn-sm btn-block" : "hidden" }
                          >
                            <i className="icon-trash"> ลบรายการหนี้</i>
                          </button>
                          : null
                      }



                    </td>
                </tr>
            )
        });

      let tbodySum = () => {
          return (
            <tr key="sum">
              <td className="text-right" colSpan={2}>
                <strong>&nbsp;รวม</strong>
              </td>
              <td className="text-center">
                <strong>
                <NumberFormat value={sumRemain.toFixed(2)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'฿'}/>
                </strong>
              </td>
              <td className="text-center">
                <strong>
                  <NumberFormat value={sumPaid.toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'฿'}/>
                </strong>
              </td>
              <td className="text-center">
                <strong>
                  <NumberFormat value={sumDebt.toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'฿'}/>
                </strong>
              </td>
              <td className="text-right" colSpan={2}>
                <strong>&nbsp;</strong>
              </td>
            </tr>
          )
      }

      let tbodyEmpty = ()=>{
        return (
          <tr className="text-center">
            <td colSpan={7}><span className="text-danger">ไม่พบข้อมูล</span></td>
          </tr>
        )};


        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">รายการ</th>
                        <th className="text-center">ยอดค้างชำระ</th>
                        <th className="text-center">ยอดเงินที่ชำระแล้ว</th>
                        <th className="text-center">ยอดหนี้</th>
                        <th className="text-center">ผู้บันทึกรายการ</th>
                        <th className="text-center">จัดการ</th>
                    </tr>
                    </thead>

                    <tbody>
                        {(debtorsList.length>0)? debtorsList: tbodyEmpty()}
                        {(debtorsList.length>0)? tbodySum(): null}
                    </tbody>

                </table>
                {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active"><a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>*/}
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsListRow);
