import React,{ Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Loading from '../Loading'
import CarCheckColorConfirm from './CarCheckColorConfirm'


class CarCheckList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: JSON.parse(this.props.user),
      activeTab: '1',
      isLoading: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render () {
    const { user } = this.state

    return (
      <div className="col-md-12 mb-4">

        <Loading isLoading={this.state.isLoading} />

        <Nav tabs>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              <i className="icon-options-vertical"></i> ประเมินทำสีรถ
            </NavLink>
          </NavItem>

        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
             <CarCheckColorConfirm user={user}/>
          </TabPane>
        </TabContent>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarCheckList);
