import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createRootReducer from '../reducers';

// import routes from '../routes';
// import {reduxReactRouter} from 'redux-router';
// import createHistory from 'history/createBrowserHistory';
//import { logger } from 'redux-logger'

export const history = createBrowserHistory()

export default function configureStore(initialState) {
 /* let createStoreWithMiddleware;
  const middleware = applyMiddleware(thunk);

  createStoreWithMiddleware = compose(
    middleware,
    connectRouter({routes,createHistory})
  );

  const store = createStoreWithMiddleware(createStore)(rootReducer, initialState);

  if (module.hot) {
    module.hot
      .accept('../reducers', () => {
        const nextRootReducer = require('../reducers/index');
        store.replaceReducer(nextRootReducer);
      });
  }

  return store; */

  // const middleware = applyMiddleware(thunk);

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    compose(
      applyMiddleware(
        thunk,
        routerMiddleware(history), // for dispatching history actions
        // connectRouter({routes,history})
        // ... other middlewares ...
      ),
    ),
  )

  return store

}