import React, { Component } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import moment from 'moment';
import { APIURL } from '../../../config/config';
import { AlertSuccess } from '../../Alert/Alert';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import AppointmentEdit from '../AppointmentEdit/AppointmentEdit';


class CalendarManage extends Component{

  constructor (props) {
    super(props)
    this.state = {
      appointments: [],
      appointment_list: [],
      toggle_edit: false,
      appointmentSelect: null,
      user: {},
      user_type: '',
      width: 0,
      height: 0
    }
    this.loadAppointment = this.loadAppointment.bind(this);
    this.loadAppointmentListData = this.loadAppointmentListData.bind(this);
    this.onDeleteAppointment = this.onDeleteAppointment.bind(this);
    this.onToggleEditAppointment = this.onToggleEditAppointment.bind(this);
    this.toggleAppointment = this.toggleAppointment.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount () {
    let user = JSON.parse(this.props.user);
    this.setState({
      user: user ,
      user_type : user.type
    })
  }

  componentDidMount () {
    this.loadAppointment();
    this.loadAppointmentListData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadAppointmentListData () {
    axios.get(APIURL + '/appointment_list/')
      .then(res => this.setState({appointment_list: res.data}))
      .catch(error => {
        this.setState({appointment_list: []})
      })
  }

  loadAppointment() {
    axios.get(APIURL + '/appointment')
      .then(res => {
        let appointments = res.data
        this.setState({appointments: appointments})
      }).catch(error => {
      this.setState({data: []})
    })
  }

  onDeleteAppointment(appointment) {
    let detail = appointment.appointment_detail
    let id = appointment.appointment_id

    Swal.fire({
      title: 'ยืนยันการลบ '+detail ,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL + '/appointment/'+id
        axios.delete( url).then(res => {
          if(res.status === 200) {
            AlertSuccess('ลบข้อมูลเรียบร้อยแล้ว')
            this.loadAppointment()
          }
        }).catch(error => {
          console.log('error:', error)
        })
      }
    })
  }

  onToggleEditAppointment(appointment) {
    // let isShow = !this.state.toggle_edit
    this.setState({
      toggle_edit: true,
      appointmentSelect: appointment
    }, () => {
      // console.log('appointment=', appointment)
    })
  }

  toggleAppointment() {
    this.setState({
      toggle_edit: !this.state.toggle_edit
    })
  }

  render() {
    const { appointments } = this.state
    let user = this.state.user.user
    let width = this.state.width

    let appointment_list = this.state.appointment_list.map((appointment_list,index)=>{
      return(
        <option key={index} value={appointment_list.appointment_list_id}>{appointment_list.appointment_list_name}</option>
      )
    })

     return (
       <div className="card">

         {
           this.state.appointmentSelect !== null && this.state.toggle_edit === true ? null : null
         }

         <Modal isOpen={this.state.toggle_edit}
                toggle={this.toggleAppointment}
                className={'modal-lg'}
                style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
           <ModalBody>
             <AppointmentEdit
               user = {user}
               appointmentSelect={this.state.appointmentSelect}
               toggle_edit={this.state.toggle_edit}
               onToggle={this.toggleAppointment}
               onReload={this.loadAppointment}
             />
           </ModalBody>
         </Modal>

         <div className="card-block">
           <div className="row">
             <div className="col-sm-12 col-md-12">
               <div className="card card-accent-info">
                 <div className="card-header">
                   <strong className="text-title"> การจัดการปฏิทินนัดหมาย </strong>
                 </div>
                 <div className="card-block">

                   {/*<div className="row form-inline mb-2">
                     <div className="form-group col-sm-2">
                       <label htmlFor="appointment_list_id">รายการนัดหมาย :</label>
                     </div>
                     <div className="form-group col-sm-3">
                       <select
                         className="form-control"
                         id="appointment_list_id"
                         ref="appointment_list_id"
                         name="appointment_list_id"
                       >
                         <option value='all'> ทั้งหมด </option>
                         {appointment_list}
                       </select>
                     </div>
                   </div>*/}

                 <table className="table table-striped">
                   <thead>
                     <tr>
                       <th className="text-center">#</th>
                       <th className="text-center">วันที่นัดหมาย</th>
                       <th className="text-center">รายการนัดหมาย</th>
                       <th className="text-center">ข้อความนัดหมาย</th>
                       <th className="text-center">สถานะ</th>
                       <th className="text-center">จัดการ</th>
                     </tr>
                   </thead>
                   <tbody>
                   {
                     appointments.map((appointment, index) => {
                       let date = appointment.appointment_date
                       let appointment_date = moment(date).format('DD-MM-YYYY')
                       let appointment_list_name = appointment.appointment_list_name
                       let appointment_detail = appointment.appointment_detail
                       let status_field = appointment.status_field === 'show' ? 'แสดง' : 'ซ่อน'
                       return (
                         <tr key={index}>
                           <td className="text-center">{index + 1}</td>
                           <td>{appointment_date}</td>
                           <td>{appointment_list_name}</td>
                           <td>{appointment_detail}</td>
                           <td className="text-center">{status_field}</td>
                           <td className="text-center">
                             <button className="btn btn-sm btn-warning btn-md-width mr-2"
                                     onClick={() => this.onToggleEditAppointment(appointment)}
                             >
                               <i className="fa fa-edit"/> แก้ไข
                             </button>

                             <button className="btn btn-sm btn-danger btn-md-width"
                                     onClick={() => this.onDeleteAppointment(appointment)}
                             >
                               <i className="fa fa-remove"/> ลบ
                             </button>

                           </td>
                         </tr>
                       )
                     })
                   }
                   </tbody>
                 </table>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     )
   }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CalendarManage)
