import React, { Component, useState } from 'react'
import { CarUtil as carUtil } from '../../utils/carUtil'
import Loading from '../Loading'
import { APIURL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config'
import axios from 'axios'
import { format } from 'date-fns'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { NumberUtil } from '../../utils/number-util'
import { Link, Redirect } from 'react-router-dom'
import { AlertSuccess } from '../Alert/Alert'


function CheckColorDetail({check_color}) {

  // HH:mm:ss
  let estimate_date = format(new Date(check_color.estimate_date), 'yyyy-MM-dd')
  let updated_at = format(new Date(check_color.updated_at), 'yyyy-MM-dd')

  let details = []
  for(let i = 1 ; i <= 10 ; i++){
    let name = 'estimate_item'+i
    let value = check_color[name]
    if (value !== null && value !=='') {
      let item = <div key={i}>{i}. <span>{value}</span> <br/> </div>
      details.push(item)
    }

  }

  return (
    <div className="ml-1">
      วันที่ประเมิน: ...{estimate_date}.... ราคาประเมิน: ...{check_color.estimate_cost}... ส่งอู่: ...{check_color.center_name}
       <br/>
       รายการประเมิน <br/>
      {details}
      ทำรายการโดย: {check_color.fullname}...เมื่อ: {updated_at}

      {
        check_color.approve_user === null ? null : (
          <>
          <hr/>
            ผู้อนุมัติ:  {check_color.approve_name} วันที่อนุมัติ: {format(new Date(check_color.approve_date), 'yyyy-MM-dd')}
            <br/>
            หมายเหตุผู้อนุมัติ: {check_color.approve_note}
          </>
        )
      }

    </div>
  )
}

function CarCheckBadge({doc_status}){

  let doc_status_name = carUtil.convertDocStatusName(doc_status)

  if (doc_status === 'N') {
    return <span className="ml-1 badge badge-primary">{doc_status_name}</span>
  } else if (doc_status === 'A') {
    return <span className="ml-1 badge badge-success">{doc_status_name}</span>
  } else if (doc_status === 'R') {
    return <span className="ml-1 badge badge-danger">{doc_status_name}</span>
  }
  return null
}

function CarCheckConfirmModal({isOpen, onToggle, actionStatus, user, onSave, check_color }) {

  const [approve_note , setApproveNote] = useState(check_color.approve_note === null ? '': check_color.approve_note)
  const [approve_date , setApproveDate] = useState(format(new Date(), 'yyyy-MM-dd'))

  const confirmData = () => {

    // console.log(check_color)
    // console.log('car_id=', check_color.car_id)

    let data = {
      approve_note,
      approve_date,
      approve_user: user.user,
      doc_status: actionStatus,
      check_color_id: check_color.check_color_id,
      car_id: check_color.car_id,
      center_id: check_color.center_id,
      estimate_cost: check_color.estimate_cost,
    }
    onSave(data)

    setApproveNote('')
    setApproveDate(format(new Date(), 'yyyy-MM-dd'))
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle}
           className={'modal-lg '}
    >
      <ModalHeader toggle={onToggle}>ยืนยันการทำรายการ</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            {check_color.car_name}...
            ทะเบียน...
            {
              check_color.car_license_plate_new !== '' && check_color.car_license_plate_new !== check_color.car_license_plate_old ?
                check_color.car_license_plate_new :
                <> (ใหม่){check_color.car_license_plate_new}...|...(เดิม){check_color.car_license_plate_old} </>
            }
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-3">
            {
              actionStatus === 'A' ? <div className="bg-success font-xl text-center"> อนุมัติเอกสาร  </div> : <div className="bg-danger font-xl text-center"> ไม่อนุมัติเอกสาร   </div>
            }
          </div>
          <div className="col-9">
            ผู้อนุมัติโดย : {user.fullname} ...วันที่อนุมัติ: {approve_date}...
            <br/>ราคาประเมิน: {NumberUtil.addCommas(String(check_color.estimate_cost))}...ส่งอู่...{check_color.center_name}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <textarea
              name="approve_note"
              className="form-control"
              value={approve_note}
              placeholder={'หมายเหตุผู้อนุมัติ'}
              maxLength={255}
              onChange={(e) => setApproveNote(e.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button  color="secondary" onClick={onToggle}>ยกเลิก</Button>
        <Button  color="primary" onClick={() => confirmData()} >ยืนยัน</Button>
      </ModalFooter>
    </Modal>
  )
}

class CarCheckColorConfirm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      doc_status: 'N',
      isLoading: false,
      check_colors: [],
      is_open: false,
      action_status: '',
      check_color: null,
      btnClick: '',
      car_id: ''
    }

    this.onDocStatusChange = this.onDocStatusChange.bind(this)
    this.loadCarCheckColor = this.loadCarCheckColor.bind(this)
    this.onToggleModal = this.onToggleModal.bind(this)
    this.onCarCheckColorRefresh = this.onCarCheckColorRefresh.bind(this)
  }

  componentDidMount () {
    this.loadCarCheckColor()
  }

  onToggleModal() {
    let is_open = !this.state.is_open
    this.setState({is_open: is_open})
  }

  onDocStatusChange(e) {
    const { doc_status } = this.state
    let val = e.target.value

    this.setState({
      doc_status: val
    }, () => {
       if(doc_status !== val) {
         this.loadCarCheckColor();
       }
    })
  }

  // btnClick
  loadCarCheckColor(){
    const { doc_status } = this.state

    this.setState({
      isLoading: true
    }, () => {

      let uri = APIURL+'/car_check/carcolorstatus/'+doc_status
      axios.get(uri).then(res => {

        let data = res.data

        setTimeout(() =>{
          this.setState({
            check_colors: data,
            isLoading: false
          })
        }, 1500)

      }).catch(error => {
        console.log('มีข้อผิดพลาด : ', error)
      })
    })

  }

  saveCarCheckAction(dataConfirm) {
    let uri = APIURL+'/car_check/colorconfirm'
    // console.log(dataConfirm)
    // return
    axios.put(uri, dataConfirm).then(res => {
      // let data = res.data
      // console.log(data)
      if (res.status === 200) {
        AlertSuccess('บันทึกการอนุมัติเรียบร้อยแล้ว')
        this.setState({
          is_open: false,
          check_color: null,
          doc_status: dataConfirm.doc_status
        }, () => this.loadCarCheckColor())
      }
    }).catch(error => {
      console.log('มีข้อผิดพลาด : ', error)
    })
  }

  onCarCheckColorRefresh() {
    this.loadCarCheckColor()
  }

  render () {

    const { check_colors, car_id } = this.state
    const { user } = this.props

    let imgWidth = '180px'

    let target = '';

    if (this.state.btnClick === 'car-repair' && car_id !== '') {

      // http://localhost:3000/#/car-repair/new?car_id=2270

      target = '/car-repair/new?car_id=' + car_id+'&activeTab=2'
      console.log(target)
      return <Redirect push to={target} target={"_blank"}/>
    }

    const emptyRow = <tr><td colSpan={3} className="text-center text-danger">ไม่พบข้อมูล</td></tr>

    let checkColorRow = check_colors.map((check_color, index) => {

      let image_url = ''
      if (check_color.car_upload_filename !== null) {
        if (check_color.car_upload_filename.includes('https')) {
          image_url = check_color.car_upload_filename
        } else {
          image_url = IMAGE_FULL_URL + check_color.car_upload_filename
        }
      } else {
        image_url = IMAGE_NOPHOTO_URL
      }


      return (
        <tr key={index+1}>
          <td className="text-center" >

            <img src={image_url}
                 style={{ 'cursor': 'pointer', 'width': imgWidth, 'height': 'auto' }}
                 alt="car"/>

          </td>
          <td>
            {check_color.car_name}...
            ทะเบียน...
            {
              check_color.car_license_plate_new !== '' && check_color.car_license_plate_new !== check_color.car_license_plate_old ?
              check_color.car_license_plate_new :
              <> (ใหม่){check_color.car_license_plate_new}...|...(เดิม){check_color.car_license_plate_old} </>
            }

            <CarCheckBadge doc_status={check_color.doc_status} />

            <br/>

            {<CheckColorDetail check_color={check_color} key={index+1}  />}

          </td>
          <td className="text-center">

            {
              check_color.doc_status === 'N' ? (
                <>
                  <button
                    type="button"
                    style={{width: 96}}
                    className="btn btn-success"
                    onClick={() => {
                      this.setState({
                        is_open: true,
                        action_status: 'A',
                        check_color: check_color
                      })
                    }}
                  >
                    <i className="icon-check">&nbsp; อนุมัติ </i>
                  </button>
                  <br/>
                 {/* <button
                    type="button"
                    style={{width: 84}}
                    className="btn btn-warning mt-1"
                    onClick={() => {
                      this.setState({
                        btnClick: 'car-repair',
                        car_id: check_color.car_id
                      })
                    }}
                  >
                    แก้ไข
                  </button>*/}

                  <Link to={'/car-repair/new?car_id=' + check_color.car_id + '&activeTab=2'} target='_blank'
                        type="button"
                        className="btn btn-warning mt-1"
                        style={{width: 96}}
                  >
                    <i className="icon-pencil">
                    &nbsp;แก้ไข</i>
                  </Link>

                  <br/>
                  {/*<button
                    type="button"
                    style={{width: 84}}
                    className="btn btn-danger mt-1"
                    onClick={() => {
                      this.setState({
                        is_open: true,
                        action_status: 'R'
                      })
                    }}
                  >
                    ไม่อนุมัติ
                  </button>*/}
                </>
              ) : <i className="icon-close" />
            }

          </td>
        </tr>
      )
    })

    let rows = check_colors.length === 0 ? emptyRow : checkColorRow

    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />


        {
          this.state.check_color === null ? null :
            <CarCheckConfirmModal
            isOpen={this.state.is_open}
            actionStatus={this.state.action_status}
            onToggle={() => this.onToggleModal()}
            user={user}
            check_color={this.state.check_color}
            onSave={(data) => this.saveCarCheckAction(data)}
          />
        }


        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-info">
            <div className="card-header">

              <div className="row">

                <div className="col-6 ">
                   <strong> ประเมินส่งซ่อมรถทำสี </strong>

                </div>

                <div className="col-6 text-right">
                  <button className="btn btn-primary"
                          style={{
                            height: 30,
                            paddingTop:0,
                            paddingBottom:0,
                            display: 'inline',
                            marginTop: 0
                          }}
                          onClick={() => this.onCarCheckColorRefresh()}

                  >
                    <i className="icon-refresh"></i>
                  </button>
                </div>

              </div>

            </div>

            <div className="card-block">

              <div className="form-group row">
                <label className="col-md-2 form-control-label">สถานะเอกสาร</label>
                <div className="form-inline col-md-10">
                  <label className="radio-inline" htmlFor="doc_status_n">
                    <input type="radio"  id="doc_status_n" name="doc_status" value="N"
                           onChange={this.onDocStatusChange}
                           checked={this.state.doc_status === 'N'}
                    />
                    &nbsp;&nbsp;{carUtil.convertDocStatusName('N')}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label className="radio-inline" htmlFor="inline-radio2">
                    <input type="radio"  id="doc_status_a" name="doc_status" value="A"
                           onChange={this.onDocStatusChange}
                           checked={this.state.doc_status === 'A'}
                    />
                    &nbsp;&nbsp;{carUtil.convertDocStatusName('A')}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label className="radio-inline" htmlFor="inline-radio3">
                    <input type="radio"  id="doc_status_a" name="doc_status" value="R"
                           onChange={this.onDocStatusChange}
                           checked={this.state.doc_status === 'R'}
                    />
                    &nbsp;&nbsp;{carUtil.convertDocStatusName('R')}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td width={'5%'} className="text-center">#</td>
                        <td>รายละเอียด</td>
                        <td width={'10%'} className="text-center">จัดการ</td>
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CarCheckColorConfirm
