import React, { Component } from 'react'
import moment from 'moment'
import MaskedInput from "react-maskedinput";

class RetailerForm extends Component{
  constructor (props){
    super(props)
    this.state = {
      //center_name: '',
      //center_address: '',
      //center_tel: '',
      retail: {},
      startDate: moment()
    }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.changeCenter = this.changeCenter.bind(this)
  }

  componentDidMount () {
    this.setState({
      retail: this.props.retail,
    })
  }

  /*componentWillReceiveProps(nextProps) {

    if (this.props.actionType==="บันทึก") {
      this.setState({retail: {} })
      return
    }
    // console.log('nextProps.retail=', nextProps.retail)
    // console.log('this.props.retail=', this.props.retail)
    //
    // if( JSON.stringify(nextProps.retail) !== JSON.stringify(this.props.retail) ) {
    //
    //   if(this.props.actionType==="แก้ไข") {
    //     this.setState({ retail: nextProps.retail });
    //   }
    //
    // }
  }*/

  componentDidUpdate (prevProps, prevState, snapshot) {
    // let prev = prevProps.retail
    // console.log(prev)
    if(this.props.actionType==="แก้ไข") {
      if (prevProps.retail !==  this.props.retail) {
        this.setState({ retail: this.props.retail });
      }
    } else if (this.props.actionType==="บันทึก"){
      if (prevProps.retail !==  this.props.retail) {
        this.setState({retail: {} })
      }
    }
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }

  changeCenter(event){
    event.preventDefault();
    // alert (event.target.name);
    let xname = event.target.name
    if(xname==="retailer_name"){
      this.setState ({retail:{
          ...this.state.retail,
          retailer_name: event.target.value
        }})
    }else if(xname==="retailer_address"){
      this.setState ({retail:{
          ...this.state.retail,
          retailer_address: event.target.value
        }})
    }else if(xname==="retailer_tel"){
      this.setState ({retail:{
          ...this.state.retail,
          retailer_tel: event.target.value
        }})

    }
  }

  render(){
    return (
      <div className={this.props.actionType==="แก้ไข" ? "card card-accent-warning" : "card card-accent-warning" }>
        <div className="card-header card-sparepart">
          <strong className="text-title"> {this.props.actionType==="แก้ไข" ? 'แก้ไขร้านค้าอะไหล่' : 'เพิ่มร้านค้าอะไหล่'} </strong>
        </div>
        <form action="" method="post"
              onSubmit={ (this.props.actionType==="แก้ไข")? this.props.onRetailSubmit.update :this.props.onRetailSubmit.add  }>

          <div className="card-block">
            <div className="form-group">
              <label htmlFor="center_name">ชื่อร้านค้า </label>
              <input name="retailer_name"
                     value={this.state.retail.retailer_name || ''}
                     type="text"
                     className="form-control"
                     placeholder="กรอกชื่อร้านค้า"
                     onChange={this.changeCenter}
                     maxLength={45}
              />
            </div>
            <div className="form-group">
              <label htmlFor="center_address">ที่อยู่ </label>
              <input name="retailer_address"
                     value={this.state.retail.retailer_address || ''}
                     type="text"
                     className="form-control"
                     placeholder="กรอกที่อยู่"
                     onChange={this.changeCenter}
                     maxLength={100}
              />

            </div>
            <div className="form-group">
              <label htmlFor="center_tel">เบอร์โทรศัพท์ </label>
              <input name="retailer_tel_old-bk"
                     // value={this.state.retail.retailer_tel || ''}
                     type="text"
                     className="form-control hidden"
                     placeholder="กรอกเบอร์โทรศัพท์"
                     // onChange={this.changeCenter}
                     maxLength={45}
              />

              <MaskedInput
                  mask="111-111-1111"
                  name="retailer_tel"
                  value={this.state.retail.retailer_tel || ''}
                  type="text" className="form-control"
                  placeholder="กรอกเบอร์โทรศัพท์"
                  onChange={this.changeCenter}
                  maxLength={45}
              />

            </div>
          </div>

          <div className="card-footer text-right">
            <button type="reset"
                    className="btn btn-sm btn-danger mr-2"
                    onClick={() => this.props.onReset()}
            >
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className={this.props.actionType==="แก้ไข"? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
            >
                <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                &nbsp;{this.props.actionType}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default RetailerForm
