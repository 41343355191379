import { IMAGE_NOPHOTO_URL } from '../config/config'

export const CarUtil = {
  convertDepositRegistration(deposit_registration_id){
    switch (deposit_registration_id) {
      case 1:
        return 'มัดจำเล่ม';
      case 2:
        return 'ไม่มัดจำ';
      default:
        return '-'
    }
  },
  convertLicenseIdToName(liciense_status_id) {
    switch (liciense_status_id) {
      case 0:
        return '--ไม่ระบุ--';
      case 1:
        return 'มีเล่มทะเบียน';
      case 2:
        return 'ยังไม่ปิดบัญชี';
      case 3:
        return 'รอเล่มปิดบัญชี';
      case 4:
        return 'มีเล่มอยู่เต็นท์';
      case 5:
        return 'ไม่มีเล่ม';
      default:
        return '-'
    }
  },
  convertVatPersonToName(person_vat_id) {
    switch (person_vat_id) {
      case 0:
        return '-';
      case 1:
        return 'บุคคล/รถบ้าน';
      case 2:
        return 'รถ VAT';
      case 3:
        return 'รถฝากขาย';
    }
  },
  getCarBlamesInit(page) {
    let car_blames = []
    let src = page === 'car_new' ? 'img/add_image.png' : IMAGE_NOPHOTO_URL
    let car_upload_id = '0'
    for (let i=1; i <= 16;i++) {
      let no = String(i);
      let name = i === 16 ? 'assessment_sheet' : 'car_blame'+i;
      // assessment_sheet
      let label = i === 16 ? 'ใบประเมินสภาพ(ภาพ, pdf)' : 'ตำหนิรถ '+i;
      let car_blame = {
        no: no,
        name: name,
        label: label,
        src: src,
        car_upload_id: car_upload_id,
        url: ''
      }
      car_blames.push(car_blame)
    }
    return car_blames
  },
  findFirstNumber(text) {
    for(let i=0;i<text.length;i++){
      if(isNaN(text[i])===false){
        return i
      }
    }
    return 0
  },
  convertDocStatusName(doc_status){
    // COMMENT 'N = New (Add New DOC)\nA = Approve\nR = Reject',
    switch (doc_status) {
      case 'N':
        return 'เอกสารใหม่';
      case 'A':
        return 'อนุมัติ';
      case 'R':
        return 'ยกเลิก';
      default:
        return ''
    }
  }
}
