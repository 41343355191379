// import ReactDOM from 'react-dom';
// import {Editor, EditorState} from 'draft-js';
// import "draft-js/dist/Draft.css";
import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import { connect } from 'react-redux';
import { checkGroupPermission, getGroupPermission } from '../../../services/grouppermission';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class CarFeatureForm extends Component {

  constructor (props) {
    super(props)

    this.state = {
      menuID: 19,
      grouppermissions: [],
      lists: [],
      load_data: true,
      danger: false,
      car_feature: '',
      feature_type: [],
      feature_in: [],
      feature_out: [],
      user: {},
      car_id: this.props.car_id,
      feature: '',
      feature_data: [],
      car_feature_list: [],
      action: '',
      car_feature_other: this.props.car_feature_other,
      // selectedItem:'',
      // editorState: EditorState.createEmpty(),
    }

    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this._handleCarFeatureOther = this._handleCarFeatureOther.bind(this);
    this.handleOnSubmitCarCarFeature = this.handleOnSubmitCarCarFeature.bind(this);
    // this._onChangeCarFeatureOther = this._onChangeCarFeatureOther.bind(this);

    /*this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };
    this.onChange = (editorState) => {
      this.setState({editorState});
    };*/

  }

  _handleCarFeatureOther(e) {
    this.setState({
      car_feature_other: e.target.value
    })
  }

  // _onChangeCarFeatureOther (editorState) {
  //   this.setState({car_feature_other: editorState})
  // }

  handleOnSubmitCarCarFeature (event) {
    event.preventDefault()
    let car_id = this.state.car_id
    let data = []
    let lists = document.getElementsByName('feature_id')

    for (var i = 0; i < lists.length; i++) {
      var checked = (lists[i].checked) ? 1 : 0
      data.push({'feature_id': lists[i].value, 'checked': checked})
    }

    const car_feature_other = this.state.car_feature_other

    if(this.state.action==="insert"){
      axios.post(APIURL + '/car_feature/', {
        car_id: car_id,
        feature_data: data,
        user_created: this.state.user.user,
        user_updated: this.state.user.user,
        car_feature_other: car_feature_other,
      })
        .then(res => {
           alert('บันทึกข้อมูลเรียบร้อยแล้ว');
           this.setState({action: 'update'})
        })
        .catch(function (error) {
           console.log(error)
        })
    }else if(this.state.action==="update"){

        axios.post(APIURL + '/car_feature/update/', {
          car_id: car_id,
          feature_data: data,
          user_created: this.state.user.user,
          user_updated: this.state.user.user,
          car_feature_other: car_feature_other,
        })
        .then(function (response) {
           alert('บันทึกข้อมูลเรียบร้อยแล้ว');
        })
        .catch(function (error) {
          console.log(error)
          //alert("error"+error)
        })
    }
  }

  loadFeatureInData () {
    axios.get(APIURL + '/feature/1')
      .then(res => {
        this.setState({feature_in: res.data})
        //alert (res.data)
      }).catch(error => {
      this.setState({feature_in: []})
    })
  }

  loadFeatureOutData () {
    axios.get(APIURL + '/feature/2')
      .then(res => {
        this.setState({feature_out: res.data})
      }).catch(error => {
      this.setState({feature_out: []})
    })
  }

  loadCarFeatureData () {
    // alert (this.state.car_id)
    axios.get(APIURL + '/car_feature/car_id/' + this.state.car_id)
      .then(res => {
        // console.log("=================loadCarFeatureData", res.data.length)
        if (res.data.length > 0) {
          //console.log(res.data)
          const data = res.data
          const feature_in = data.filter((feature) => {
            return feature.feature_type_id === 1
          })
          const feature_out = data.filter((feature) => {
            return feature.feature_type_id === 2
          })
          /*console.log(feature_in)
           console.log(feature_out)
           this.setState({car_feature_list: res.data})*/
          let car_feature_other = feature_in[0].car_feature_other === null ? '' : feature_in[0].car_feature_other

          this.setState({
            feature_in: feature_in,
            feature_out: feature_out,
            car_feature_other: car_feature_other,
            action: 'update'
          })

        } else {
          this.loadFeatureInData()
          this.loadFeatureOutData()
          this.setState({action: 'insert'})
        }

      }).catch(error => {
      // console.log(error)
      this.setState({car_feature_list: []})
    })
  }

  componentDidMount () {
    this.loadGroupPermission()
    this.loadCarFeatureData()
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID
    // console.log('type=', type)
    // console.log('menuID=', menuID)
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        // console.log(res.data.grouppermissions)
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  render () {
    //const { history,user } = this.props

    var feature_in_list = this.state.feature_in.map((feature, index) => {
      return (
        <div className="form-inline col-sm-3 mb-2" key={'in_' + index}>
          <label htmlFor="feature">
            <input type="checkbox"
                   name={'feature_id'}
                   value={feature.feature_id}
                   defaultChecked={feature.checked}
              /*onChange={this.updateCarFeature}*/
            /> &nbsp;&nbsp;{feature.feature_name}
          </label>
          {/*<p>{msg}</p>*/}
        </div>
      )
    })

    var feature_out_list = this.state.feature_out.map((feature, index) => {
      return (
        <div className="form-inline col-sm-3 mb-2" key={'out_' + index}>
          <label htmlFor="feature">
            <input type="checkbox"
                   name={'feature_id'}
                   value={feature.feature_id}
                   defaultChecked={feature.checked}
              /*onChange={this.updateCarFeature}*/
            /> &nbsp;&nbsp;{feature.feature_name}
          </label>
          {/*<p>{msg}</p>*/}
        </div>
      )
    })

    return (
      <form action="" method="post" onSubmit={this.handleOnSubmitCarCarFeature}>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12" style={{marginTop: 20}}>
              <p><strong>อุปกรณ์ภายใน</strong></p>
            </div>
            {feature_in_list}
          </div>
          <hr/>

          <div className="row">
            <div className="col-sm-12">
              <p><strong>อุปกรณ์ภายนอก</strong></p>
            </div>
            {feature_out_list}
          </div>

          <hr/>
          <div className="row mb-2">
            <div className="col-sm-12">
              <p><strong>รายละเอียดออฟชั่น อื่นๆ</strong></p>
            </div>

            <div className="col-sm-12" style={{minHeight: 180}}>
              <ReactQuill theme="snow"
                          value={this.state.car_feature_other}
                          onChange={(val) => {
                              this.setState({
                                car_feature_other: val
                              })
                          }}
              />

            </div>
          </div>
        </div>

        <div className="card-footer text-right">
          <button type="reset"
                  onClick={this.props.onToggle}
                  className="btn btn-sm btn-danger mr-2">
            <i className="icon-close"/> ยกเลิก
          </button>

          {
            checkGroupPermission(this.state.menuID, this.state.grouppermissions).created || checkGroupPermission(this.state.menuID, this.state.grouppermissions).modified ? (
              <button type="submit"
                      className="btn btn-sm btn-primary">
                <i className="fa fa-save"/> { this.state.action === 'insert' ? 'บันทึก' : 'แก้ไข'}
              </button>
            ) : null
          }

          {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
        </div>
      </form>
    )

  }
}

const styles = {
  editor: {
    border: '1px solid gray',
    minHeight: '6em'
  }
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarFeatureForm)
