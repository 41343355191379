import React, { Component } from 'react';
import axios from 'axios';
import {APIURL, PAGE10, PORT} from '../../../config/config';
import { tr } from 'date-fns/locale';

class DebtWarning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarning: false,
            debtors_id: null,
            car_id: null,
            customer_id: null,
            car_sell_id: null,
            is_load: false,
            warning_type: '',
            debtors: {}
        };
        this.loadDebtorWarning = this.loadDebtorWarning.bind(this);
    }

    componentDidMount () {
        this._isMounted = true;
        this.setState({
            debtors_id: this.props.debtors_id,
            car_id: this.props.car_id,
            customer_id: this.props.customer_id,
            car_sell_id: this.props.car_sell_id,
            warning_type: this.props.warning_type,
        }, () => {
            this._isMounted && this.loadDebtorWarning();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.debtors_id !== this.props.debtors_id && 
            nextProps.car_id !== this.props.car_id &&
            nextProps.customer_id !== this.props.customer_id &&
            nextProps.car_sell_id !== this.props.car_sell_id
            ) {
            
            this.setState({
                debtors_id: nextProps.debtors_id,
                car_id: nextProps.car_id,
                customer_id: nextProps.customer_id,
                car_sell_id: nextProps.car_sell_id,
            }, () => {
                this._isMounted && this.loadDebtorWarning('willreceiveprops car_sell_id='+nextProps.car_sell_id);
            });
        }

    }

    loadDebtorWarning() {
        
        const { debtors_id, car_id, customer_id, car_sell_id, warning_type } = this.state;
        if (debtors_id === null && car_id === null && customer_id === null) {
            this.setState({showWarning: false})
            return
        }
        
        let data = {
            car_sell_id,
            debtors_id,
            car_id,
            customer_id,
            warning_type
        }
        this._isMounted &&axios.post(APIURL + '/debtors/warning', data)
        .then(res => {
        
            if(res.data.length > 0) {
                let debtors = res.data
                this.setState({
                    showWarning: true, 
                    debtors,
                })
            }
            // this.setState({car_gears: res.data})
        }).catch(error => {
            console.log('error=', error)
        })
    }


    // toggleWarning = () => {
    //     this.setState((prevState) => ({
    //         showWarning: !prevState.showWarning,
    //     }));
    // };

    // style={{
    //     color: showWarning ? 'white' : 'transparent',
    //     fontWeight: 'bold',
    //     animation: 'blink 1s infinite',
    // }}
    // style={{backgroundColor: '#f86c6b'}}
    render() {
        const { showWarning, debtors , warning_type } = this.state;

        return (
            <div>
                {showWarning === true && warning_type === 'car_sell' && (
                    <>
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                        ตั้งหนี้ : {debtors[0].debtors_name}
                    </span>
                        <br/>
                    <span
                        className="badge blink"
                        style={{backgroundColor: '#f86c6b'}}
                    >
                       ค้าง {debtors.length} งวด
                    </span> 
                </>
                )}

                {showWarning === true && warning_type === 'debtors' && (
                    <>
                    <span
                        className="badge blink"
                        style={{backgroundColor: '#f86c6b'}}
                    >
                       ค้าง {debtors.length} งวด
                    </span> 
                </>
                )}
            </div>
        );
    }
}

export default DebtWarning;
