import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { APIURL } from '../../config/config';
import { AlertSuccess } from '../Alert/Alert';

class CarQcStatus extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      is_change: false,
      car_id: this.props.car.car_id,
      qc_status_id: this.props.car.qc_status_id,
      qc_status_note: this.props.car.qc_status_note === null ? '' : this.props.car.qc_status_note,
    };

    this.checkQcDataChange = this.checkQcDataChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentWillMount () {

    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount () {
    this.mounted = true
  }

  componentWillUnmount () {
    this.mounted = false
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.car !== this.props.car) {
      this.setState({
        car_id: nextProps.car.car_id,
        qc_status_id: nextProps.car.qc_status_id,
        qc_status_note: nextProps.car.qc_status_note,
        is_change: false
      });
    }
  }

  handleOnSubmit() {
    const {
      qc_status_id,
      qc_status_note,
      car_id
    } = this.state

    let user = this.state.user.user;

    let data = {
      car_id,
      qc_status_id,
      qc_status_note,
      user
    }
    let qc_status = this.props.qc_status
    let qc_status_filter = qc_status.filter((qc)=>{
      return qc.qc_status_id === Number(qc_status_id)
    })

    let qc_status_name = qc_status_filter[0].qc_status_name


    // console.log(data)
    let uri =  APIURL + '/car/check_qc_car_status'
    axios.post(uri, data).then(res => {
      AlertSuccess('บันทึกเรียบร้อยแล้ว');
      let new_data = {...data, qc_status_name: qc_status_name}
      let index = this.props.index
      this.props.onQcStatusUpdate(new_data, index)
      this.setState({is_change: false})
    })
        .catch(error => {
          console.log(error)
        })

  }

  checkQcDataChange() {
    let is_change = false
    const {qc_status_id, qc_status_note} = this.state
      is_change = true;
      this.setState({
        is_change: is_change
      })
  }


  render() {
    //
    const { qc_status_id } = this.state
    let styleBg = null;
    if (qc_status_id === '1' || qc_status_id === 1) {
      styleBg = {backgroundColor: '#F5DEB3', padding: 8}
    } else if (qc_status_id === '2' || qc_status_id === 2) {
      styleBg = {backgroundColor: '#DEB887', padding: 8}
    } else if (qc_status_id === '3' || qc_status_id === 3) {
      styleBg = {backgroundColor: '#F4A460', padding: 8}
    } else {
      styleBg = {padding: 8}
    }

    let car_qc_status_list = this.props.qc_status.map((qc_status, index) => {
      return (
          <option key={index} value={qc_status.qc_status_id}>{qc_status.qc_status_name}</option>
      )
    })

    const { width } = this.props
    let inputWidth = 220
    // console.log('width=', width)
    if (width < 768) {
      inputWidth = 128
    }

    return(

    <div style={styleBg} >
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <strong style={{fontSize: 13}}>สถานะรถตรวจสภาพ QC</strong>
        </div>
      </div>

      <div className="text-center">
        <select className="d-inline form-control"
                id="qc_status_id"
                name='qc_status_id'
                value={this.state.qc_status_id}
                style={{width: inputWidth}}
                onChange={(e) => {
                  let val = e.target.value
                  this.setState({
                    qc_status_id: val
                  }, () => this.checkQcDataChange())
                }}

        >
          {car_qc_status_list}
        </select>
      </div>


      <div className="text-center mt-1">
        <input type="text"
               className="d-inline form-control"
               name="qc_status_note"
               placeholder="หมายเหตุการตรวจสภาพ QC"
               value={this.state.qc_status_note || ''}
               style={{width: inputWidth}}
               onChange={(e)=>{
                 this.setState({
                   qc_status_note: e.target.value
                 }, () => this.checkQcDataChange())
               }}
        />
      </div>

      {
        this.state.is_change === true ? (
            <div className="text-center mt-1">
              <button
                  onClick={() => this.handleOnSubmit()}
                  type="button"
                  style={{width: 200}}
                  className="d-inline btn btn-primary btn-sm btn-block"
              > บันทึก
              </button>
            </div>
        ) : null
      }
    </div>

    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(CarQcStatus);
