import React , { Component } from 'react';
import axios from 'axios';
import { Button, Modal, ModalBody } from 'reactstrap';
import { APIURL } from '../../../config/config';
import { AlertWarning } from '../../Alert/Alert'

const port = Number(window.env.PORT);

class SalesPayInSellCheck extends Component{
  constructor (props) {
    super(props)
    this.state = {
      sum_pay: 0,
      is_load: false,
      remain: 0
    }

    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this)

  }


  componentDidMount () {
    this.loadDataCarSellPay()
  }


  loadDataCarSellPay () {

    // console.log(this.props.car_sell)

    if (this.props.car_id === '' || this.props.customer_id === '') {
      return
    }

    let car_sell = this.props.car_sell

    axios.get(APIURL + '/car_sell_pay/buy/' + this.props.car_id + '/' + this.props.customer_id)
      .then(res => {
        // car_sell_pay.car_sell_pay_count
        let car_sell_pay_list = res.data
        let sum_pay = 0

        if (car_sell_pay_list.length > 0) {
          sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)
        }

        // ค่างวดล่างหน้าธนาคาร
        // let bank_advance_payments = car_sell_pay_list.filter(pay => pay.car_sell_list_pay_name === 'ค่างวดล่วงหน้า')
        // let is_bank_advance_payment = bank_advance_payments.length > 0 ? true : false

        let insurance_price = (car_sell.insurance_buy ==='ประกันแถม') ? 0 : car_sell.insurance_price //ราคาประกัน
        let rate_finance = car_sell.rate_finance
        let real_sell_price = car_sell.real_sell_price
        let cost_finance = car_sell.cost_finance
        let cost_transfer = car_sell.cost_transfer
        let pay_advance_finance = car_sell.pay_advance_finance
        let insurance_pa = car_sell.insurance_pa
        let sell_discount = car_sell.sell_discount
        let gearbox_amount = Number(car_sell.gearbox_amount)
        let debt_insurance_amount = Number(car_sell.debt_insurance_amount)

        // console.log('sum_pay=', sum_pay)
        // console.log('insurance_price=', insurance_price)
        // console.log('rate_finance=', rate_finance)
        // console.log('real_sell_price=', real_sell_price)
        // console.log('cost_finance=', cost_finance)
        // console.log('cost_transfer=', cost_transfer)
        // console.log('pay_advance_finance=', pay_advance_finance)
        // console.log('insurance_pa=', insurance_pa)
        // console.log('sell_discount=', sell_discount)
        // console.log('gearbox_amount=', gearbox_amount)
        // console.log('debt_insurance_amount=', debt_insurance_amount)
        if (port === '9005' || port === 9005) {
          // pisan ไม่รวม pa ในรวมออกรถ
          insurance_pa = 0
        }

        let total_outof_car = (Number(real_sell_price) - Number(rate_finance) - Number(sell_discount)) + Number(insurance_price) + Number(cost_finance) + Number(cost_transfer) + Number(pay_advance_finance) + Number(insurance_pa) + gearbox_amount + debt_insurance_amount

        let bank_finance_id = this.props.bank_finance_id
        // let total_outof_car = car_sell.total_outof_car

        // ยอดที่จ่ายเข้ามาทั้งหมด
        let balance_debt = this.props.balance_debt

        // console.log('total_outof_car=', total_outof_car)
        // console.log('balance_debt=', balance_debt)
        // console.log("isShowText =",isShowText)
        // let remain = balance_debt - sum_pay

        let remain = total_outof_car - sum_pay
        // console.log('remain=', remain)
        let insurance_buy = car_sell.insurance_buy
        // let insurance_price = car_sell.insurance_price
        // let pay_advance_finance  = car_sell.pay_advance_finance
        // let insurance_pa = car_sell.insurance_pa

        // remain += Number(insurance_pa)
        // remain += Number(pay_advance_finance)
        // sompongyontakan จ่ายงวดแรกของให้ finance
        // //  ค่างวดล่างหน้าธนาคาร ถ้ามี เอาไปคิดเงินคงเหลือด้วย
        // if (is_bank_advance_payment) {
        //   remain += Number(pay_advance_finance)
        // }

        // รวมจ่าย PA
        if (insurance_buy === 'ซื้อเอง') {
          // remain += Number(insurance_price)
        }

        if (bank_finance_id === 1) {
          remain = balance_debt
        }

        if (total_outof_car === sum_pay) {
          remain = 0
        }

        if (sum_pay === real_sell_price) {
          remain = 0
        }

        console.log('sum_pay=', sum_pay);
        console.log('remain=', remain);

        this.setState({
          sum_pay: sum_pay,
          is_load: true,
          remain: remain
        }, () => {
          // update ยาก
          // this.props.setCarSellRemain(remain, car_sell.car_sell_id)
        })
      }).catch(error => {
      console.log(error)
      // this.setState({car_sell_pay_list: []})
    })
  }

  render () {
    const { remain } = this.state

    return (
      <>
        {
          remain > 0 ? (
            <Button type="button" onClick={() => this.props.onCarSellPayAdd()}
                    className="btn btn-info float-right">
              <i className="icon-plus"/>&nbsp;&nbsp;เพิ่มการชำระเงิน
            </Button>
          ) : (
            <Button type="button" onClick={() => {
              AlertWarning('ไม่มียอดคงเหลือแล้ว กรุณาตรวจสอบ');
            }}
                    className="btn btn-secondary float-right">
              <i className="icon-plus"/>&nbsp;&nbsp;เพิ่มการชำระเงิน
            </Button>
          )
        }
      </>
    )
  }
}

export default SalesPayInSellCheck;
