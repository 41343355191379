import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { APIURL } from '../../config/config';
import { AlertSuccess } from '../Alert/Alert';

class CarCheckStatus extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      is_change: false,
      car_id: this.props.car.car_id,
      check_car_status: this.props.car.check_car_status === null ? '' : this.props.car.check_car_status,
      check_car_status_old: this.props.car.check_car_status, // ข้อมูลเดิม
      check_car_note: this.props.car.check_car_note === null ? '' : this.props.car.check_car_note,
      check_car_note_old: this.props.car.check_car_note === null ? '' : this.props.car.check_car_note, // ข้อมูลเดิม
    };

    this.loadCarCheckStatus = this.loadCarCheckStatus.bind(this);
    this.checkDataChange = this.checkDataChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount () {
    this.mounted = true
    // this.loadCarDepositRefund()
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.car !== this.props.car) {
      this.setState({
        car_id: nextProps.car.car_id,
        check_car_status: nextProps.car.check_car_status,
        check_car_status_old: nextProps.car.check_car_status_old,
        check_car_note: nextProps.car.check_car_note,
        check_car_note_old: nextProps.car.check_car_note_old,
        is_change: false
      });
    }
  }

  componentWillUnmount () {
    this.mounted = false
  }

  loadCarCheckStatus () {
    if (this.mounted) {
      let uri =  APIURL + '/car/car_check_status/'+this.props.car.car_id;
    }
  }

  checkDataChange() {
    let is_change = false
    const {
      check_car_status,
      check_car_status_old,
      check_car_note,
      check_car_note_old
    } = this.state
    if (check_car_status !== check_car_status_old || check_car_note !== check_car_note_old ) {
      is_change = true;
      this.setState({is_change: is_change})
    }
  }

  handleOnSubmit() {
    const {
      check_car_status,
      check_car_note,
      car_id
    } = this.state

    let user = this.state.user.user;

    // console.log('car_id=', car_id)
    // console.log('user=', user)
    // console.log(check_car_status)
    // console.log(check_car_note)

    let data = {
      car_id,
      check_car_status,
      check_car_note,
      user
    }

    // console.log(data)
    let uri =  APIURL + '/car/check_car_status'
    axios.post(uri, data).then(res => {
      AlertSuccess('บันทึกเรียบร้อยแล้ว');
      this.setState({is_change: false})
    })
      .catch(error => {
        console.log(error)
      })

  }

  render() {
    //
    const { check_car_status } = this.state
    let styleBg = null;
    if (check_car_status === 'ตรวจสภาพแล้ว') {
      // 48A70B , deeee9 , 96AA8F
      styleBg = {backgroundColor: '#96AA8F', padding: 8}
    } else if (check_car_status === 'ยังไม่ตรวจสภาพ') {
      styleBg = {backgroundColor: '#F5B0B0', padding: 8}
    } else {
      styleBg = {padding: 8}
    }

    return(
    <div style={styleBg} >

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <strong style={{fontSize: 13}}>สถานะการตรวจสภาพรถ (ขนส่ง)</strong>
        </div>
      </div>

      <div className="text-center">
        <select className="d-inline form-control"
                name='check_car_status'
                value={this.state.check_car_status || ''}
                style={{width: 400}}
                onChange={(e) => {
                  let val = e.target.value
                  this.setState({
                    check_car_status: val
                  }, () => this.checkDataChange())
                }}

        >
          <option value="ไม่ระบุ">--ไม่ระบุ--</option>
          <option value="ตรวจสภาพแล้ว">ตรวจสภาพแล้ว</option>
          <option value="ยังไม่ตรวจสภาพ">ยังไม่ตรวจสภาพ</option>
        </select>
      </div>

      <div className="text-center mt-1">
        <input type="text"
               className="d-inline form-control"
               name="check_car_note"
               placeholder="หมายเหตุการตรวจสภาพ"
               value={this.state.check_car_note || ''}
               style={{width: 400}}
               onChange={(e)=>{
                 this.setState({
                   check_car_note: e.target.value
                 }, () => this.checkDataChange())
               }}
        />
      </div>

      {
        this.state.is_change === true ? (
          <div className="text-center mt-1">
              <button
                onClick={() => this.handleOnSubmit()}
                type="button"
                style={{width: 200}}
                className="d-inline btn btn-primary btn-sm btn-block"
              > บันทึก
              </button>
          </div>
        ) : null
      }
    </div>
    )
  }

}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(CarCheckStatus);
