/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import axios from 'axios'
import { APIURL, PORT } from '../../../config/config'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import NumberFormat from 'react-number-format'
import { LineNotify } from '../../../config/lineNotify'
import { NumberUtil } from '../../../utils/number-util'
import { AlertWarning } from '../../Alert/Alert'
import { Modal, ModalBody } from 'reactstrap'

class DebtorsPay extends Component {

  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      branch: [],
      customer: {},
      debtors_list: [],
      customer_list: [],
      customer_select: false,
      customer_id: '',
      debtors_id: 0,
      debtors: '',
      car_id: '',
      car: '',
      date_pay: new Date(),
      duration_period: [],
      count_period: [],
      amount_pay: 0,
      amount_pay_period: 0,
      amount_pay_before: 0,
      amount_pay_capital: 0,
      amount_pay_interest: 0,
      note: '',
      user: {}
    }
    this.handleDatePay = this.handleDatePay.bind(this)
    this.calPayCapitalAndInterest = this.calPayCapitalAndInterest.bind(this)
  }


  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }

  handleDatePay (date) {
    this.setState({
      date_pay: date
    })
  }

  formatDate (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  componentDidMount () {
    // const  debtors_detail_id = this.props.debtors_detail.debtors_detail_id
    const amount_pay_before = this.props.debtors_detail.amount_pay

    // const interest_type = this.props.interest_type // ประเภทดอกเบี้ย
    // const amount_before_interest = this.props.amount_before_interest // ยอดหนี้ตั้งต้นก่อนคิดดอกเบี้ย
    // const amount_pay_capital_all = this.props.amount_pay_capital_all // รวมจ่ายต้น
    // const amount_pay_interest_all = this.props.amount_pay_interest_all // รวมจ่ายดอก
    // load line token

    this.setState({
      amount_pay_before: amount_pay_before
    })

  }

  calPayCapitalAndInterest() {
    const interest_type = this.props.interest_type
    const amount_before_interest = this.props.amount_before_interest
    const interest_rate = this.props.interest_rate

    // interest_type => ดอกเบี้ยคงที่ | ดอกเบี้ยลดต้นลดดอก
    if (interest_type === 'ดอกเบี้ยลดต้นลดดอก') {
      // ลดต้นลดดอก = ยอดเต็มคำนวณอาจไม่ตรง ถ้าจ่ายงวดเข้ามาเกิน
      // amount_before_interest

      let amount_dept = this.props.debtors_detail.amount_dept - this.state.amount_pay_before
      let amount_pay = this.state.amount_pay
      // ยอดงวดที่ต้องชำระ
      let amount_pay_period = amount_pay === 0 ? amount_dept : amount_pay // ยอดเงินที่จ่าย
      let sum_capital = this.props.amount_pay_capital_all // ยอดเงินต้นที่จ่ายไปแล้ว

      let count_period_month = this.props.count_period_month // ระยะเวลาต่องวด เช่น จ่ายทุก 4 เดือน
      let duration_period_month = this.props.duration_period_month // จำนวนงวด

      let count_month = (duration_period_month * count_period_month) // จำนวนเดือน
      let count_year = parseFloat(count_month/12.0).toFixed(2) // จำนวนปี

      let capital_remain = amount_before_interest - sum_capital
      let interest_period_percent = interest_rate/(12/count_period_month)

      let interest_period_bath = Number(capital_remain)*(Number(interest_period_percent)/100)
      let interest_period_bath_fix2 = parseFloat(String(interest_period_bath)).toFixed(2)

      let capital = amount_pay_period - interest_period_bath_fix2
      let capital_fix2 = parseFloat(String(capital)).toFixed(2)

      // *** for check
      // amount_dept_capital   เงินต้นคำนวณ
      // amount_dept_interest  ดอกเบี้ยคำนวณ
      // let amount_dept_capital = this.props.debtors_detail.amount_dept_capital
      // let amount_dept_interest = this.props.debtors_detail.amount_dept_interest

      // no set คำนวณตอนกรอกยอดเสร็จ
      // amount_pay_capital amount_pay_interest
      this.setState({
        amount_pay_capital: Number(capital_fix2),
        amount_pay_interest: Number(interest_period_bath_fix2)
      })

    } else {
      // ดอกเบี้ยคงที่
      /*let amount_dept = this.props.debtors_detail.amount_dept - this.state.amount_pay_before
      if (Number(this.state.amount_pay) === Number(amount_dept)) {

        let amount_dept_capital = this.props.debtors_detail.amount_dept_capital
        let amount_dept_interest = this.props.debtors_detail.amount_dept_interest
        this.setState({
          amount_pay_capital: amount_dept_capital,
          amount_pay_interest: amount_dept_interest
        })
      }*/

      this.setState({
        amount_pay_capital: 0,
        amount_pay_interest: 0
      })

    }
  }

  handleOnSubmitDebtorsPay (event) {
    event.preventDefault()

    //let that = this
    const debtors_detail = this.props.debtors_detail

    const debtors_name = debtors_detail.debtors_name
    const debtors_detail_id = debtors_detail.debtors_detail_id
    const debtors_id = debtors_detail.debtors_id
    const debtors_detail_order = debtors_detail.debtors_detail_order
    const date_dept = debtors_detail.date_dept

    const amount_pay = this.state.amount_pay // ยอดชำระ
    const note = this.state.note
    const date_pay = moment(this.state.date_pay).format('YYYY-MM-DD')
    // this.props.debtors_detail.amount_dept - this.state.amount_pay_before
    const amount_dept = this.props.debtors_detail.amount_dept - this.state.amount_pay_before
    const amount_pay_before = this.state.amount_pay_before

    const fullname = debtors_detail.customer_name + ' ' + debtors_detail.customer_lastname
    const amount_of_debt = debtors_detail.amount_of_debt // หนี้ทั้งหมด
    const date_pay_message = moment(this.state.date_pay).format('DD/MM/YYYY')
    // ยอดชำระ ： 8,000 บาท
    const remain_message = Number(amount_of_debt) - Number(amount_pay)
    // const fullname_user = debtors_detail.fullname // ผุ้ทำรายการ

    const amount_pay_capital = this.state.amount_pay_capital // ยอดเงินต้น
    const amount_pay_interest = this.state.amount_pay_interest // ยอดดอกเบี้ย

    if (amount_pay === 0) {
      AlertWarning('กรุณากรอกข้อมูลการชำระเงิน')
      return;
    }

    const fullname_user = this.state.user.fullname // ผุ้ทำรายการ

    let message = 'ชำระค่างวด : '+debtors_name + '\n' +
      'ลูกค้า : ' + fullname +'\n' +
      'ยอดหนี้ ： '+NumberUtil.addCommas(amount_of_debt)+' บาท\n' +
      'ยอดชำระ ： '+NumberUtil.addCommas(amount_pay)+' บาท\n' +
      'วันที่ชำระ ： '+date_pay_message+'\n' +
      'ค้างชำระ ： '+NumberUtil.addCommas(remain_message)+' บาท\n' +
      'ผู้บันทึกรายการ ： '+fullname_user ;

    // LineNotify.sendNotify(message)
    // return

    //  debtors_id , debtors_detail_order, amount_dept = 0, date_dept , date_pay, amount_pay
    const data = {
      debtors_id: debtors_id,
      debtors_detail_order: debtors_detail_order,
      date_dept: date_dept,
      amount_dept: amount_dept,
      amount_pay: amount_pay,
      date_pay: date_pay,
      note: note,
      amount_pay_before: amount_pay_before,
      username: this.state.user.user,
      amount_pay_capital: amount_pay_capital,
      amount_pay_interest: amount_pay_interest
    }

    axios.put(APIURL + '/debtors_detail/pay/'+debtors_detail_id, data)
      .then(res => {
        if(res.status === 200) {
          if (PORT === 9000 || PORT === 9021 ||  PORT > 9056 ) {
            LineNotify.sendDebtPayNotify(message)
          } else {
            LineNotify.sendNotify(message)
          }

          this.props.onLoadDebtorsDetailById()
          this.props.onToggle()
        }
      })
      .catch(function (error) {
        console.log('error: ', error)
      })
  }

  render () {
    // const debtors_detail = this.props.debtors_detail


    return (
      <Modal isOpen={this.props.is_toggle} toggle={this.props.toggleDebtPay}
             className={'modal-lg'}>
        <ModalBody>
           <div className="card card-accent-primary">
              <form action="" method="post" onSubmit={this.handleOnSubmitDebtorsPay.bind(this)}>
                <div className="card-header">
                  <strong className="text-title">ข้อมูลการชำระเงิน</strong>
                </div>

                <div className="card-block">
                  <div className="row">
                    <div className="card-block">

                      <div className="row">
                        <div className="form-group col-md-12">
                          <span className="h6">
                            {this.props.debtors_detail.debtors_name} <br/>
                            วันที่ต้องชำระ: {this.props.debtors_detail.date_dept}
                            &nbsp;&nbsp;&nbsp;จำนวนเงินที่ต้องชำระ:
                            &nbsp;&nbsp; {this.props.debtors_detail.amount_dept - this.state.amount_pay_before}
                            &nbsp; บาท
                          </span>
                        </div>

                      </div>

                      <div className="row">

                        <div className="form-group col-md-6">
                          <label htmlFor="amount_of_debt">จำนวนเงินที่ชำระ</label>
                          <input type="text"
                                 className="form-control hidden"
                                 id="amount_pay"
                                 ref="amount_pay"
                                 placeholder=""
                                 value={this.state.amount_pay}
                                 onChange={()=>{}}
                          />
                          <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({amount_pay: value})
                            }}
                            onBlur={(e) => {
                              this.calPayCapitalAndInterest();
                            }}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label htmlFor="date_pay_start">วันชำระ</label>
                          <br/>
                          <DatePicker
                            selected={this.state.date_pay}
                            onChange={this.handleDatePay}
                            dateFormat="yyyy-MM-dd"
                            type="text"
                            className="form-control"
                            id="date_pay"
                            name="date_pay"
                          />
                        </div>

                      </div>


                      <div
                        className={
                          this.state.amount_pay > 0 &&
                          this.state.amount_pay_capital > 0 &&
                          this.props.interest_type === 'ดอกเบี้ยลดต้นลดดอก' ? "row": "hidden"}

                      >

                        <div className="form-group col-md-6">
                          <label htmlFor="amount_of_debt">เงินต้น</label>
                          <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            displayType='text'
                            allowNegative={false}
                            value={this.state.amount_pay_capital}
                            onValueChange={(values) => {
                              // const {value} = values
                              // this.setState({amount_pay: value})
                            }}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label htmlFor="amount_of_debt">เงินดอก</label>
                          <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            displayType='text'
                            allowNegative={false}
                            value={this.state.amount_pay_interest}
                            onValueChange={(values) => {
                              // const {value} = values
                              // this.setState({amount_pay: value})
                            }}
                          />
                        </div>

                      </div>

                      <div className="row">
                        <div className="form-group col-sm-12">
                          <label htmlFor="profile_name">หมายเหตุ</label>
                          <textarea
                            name="profile_name"
                            className="form-control"
                            value={this.state.note}
                            onChange={(e) => {
                              this.setState({
                                note: e.target.value
                              })
                            }}
                            />
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="card-footer text-right">
                  <button type="reset"
                          onClick={this.props.onToggle}
                          className="btn btn-sm btn-danger mr-2">
                    <i className="fa fa-refresh"/> ยกเลิก
                  </button>
                  <button type="submit"
                          className="btn btn-sm btn-primary"
                  >
                    <i className="fa fa-save"/> บันทึก
                  </button>

                  <input type="hidden" id="ปปป" name="ปปป"/>

                </div>

              </form>
           </div>
        </ModalBody>
      </Modal>
    )
  }
}

/*const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  }
}*/

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsPay)
