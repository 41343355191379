import React, { Component } from 'react'
import { APIURL } from '../config/config'
import axios from 'axios'

class SettingCarType extends Component{
  constructor (props) {
    super(props);

    this.state = {
      actionType: "บันทึก",
      car_types: [],
      car_type_name: ''
    }

    this.loadCarType = this.loadCarType.bind(this);
    this.saveCarType = this.saveCarType.bind(this);

  }

  componentDidMount () {
    console.log('--- SettingCarType --- componentDidMount')
    this.loadCarType()
  }

  loadCarType() {
    let url = APIURL+'/car_type/admin'
    axios.get(url).then(res => {
      // console.log(res.data)
      this.setState({
        car_types: res.data
      })
    })
  }

  saveCarType() {
    let username = this.props.username
    let actionType = this.state.actionType
    let car_type_name = this.state.car_type_name
    console.log('actionType=', actionType)
    console.log('car_type_name=', car_type_name)
    console.log('user=', username)

    let data= {
      car_type_name,
      username
    }
    if(actionType === "บันทึก") {
      let url = APIURL+'/car_type'
      axios.post(url, data).then(res => {
        console.log(res.data)
      })
    }

  }

  render () {

    return (
      <div className="row">
        <div className="col-sm-7">
          <div className="card card-accent-info">
            <div className="card-header">
              <strong className="text-title">
                ประเภทรถ (แสดงในใบ สคบ.)
              </strong>
            </div>
            <div className="card-block">
              <table className="table table-striped table-responsive">
                <thead>
                <tr>
                  <th className="text-left">#</th>
                  <th className="text-left">ชื่อประเภทรถ</th>
                  <th className="text-center">ใช้งาน</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.car_types.map((car_type, index) => (
                    <tr key={index}>
                      <td style={{width: 10}}>{ index+1 }</td>
                      <td className="text-nowrap" style={{width: 80}}>{ car_type.car_type_name }</td>
                      <td className="text-nowrap text-center" style={{width: 80}}>
                        {
                          car_type.status_field
                        }
                      </td>
                      <td className="text-center" style={{width: 80}}>
                        <button type="button" className="btn btn-sm btn-warning btn-md-width mr-2 mb-2">
                          <i className="fa fa-edit"/> แก้ไข
                        </button>
                        <button type="button" className="btn btn-sm btn-danger btn-md-width mr-2 mb-2">
                          <i className="fa fa-remove"/> ลบ
                        </button>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card card-accent-success">
            <div className="card-header card-customer">
              <strong className="text-title">เพิ่มประเภทรถ</strong>
            </div>

            <div className="card-block">
              <div className="form-group">
                <label htmlFor="bank_name">ชื่อประเภทรถ</label>
                <input
                  value={this.state.car_type_name}
                  type="text"
                  className="form-control"
                  name="car_type_name"
                  placeholder="ชื่อประเภทรถ"
                  style={styles.inputSearch}
                  onChange={(e) => {
                    this.setState({
                      car_type_name: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            <div className="card-footer text-right">

              <button type="button"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=>{
                        this.setState({
                          car_type_name: ''
                        })
                      }}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="button"
                      className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}
                      onClick={() => {
                        this.saveCarType()
                      }}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
              </button>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
}

export default SettingCarType
