import {PORT} from "../config/config";

class UserUtil {
  convertUserTypeToText(type) {
    if(type === undefined) {
      return '-'
    }
    let typeName = '';
    switch (type){
      case 'admin':
        typeName = 'ผู้ดูแลระบบ';
        break;
      case 'headsale':
        typeName =  PORT === 9056 ? 'แอดมินขาย' :  'หัวหน้าฝ่ายขาย';
        break;
      case 'sale':
        typeName = 'พนักงานขาย';
        break;
      case 'technician':
        typeName = PORT === 9056 ? 'ปรับสภาพ' : 'ช่างเทคนิค';
        break;
      case 'account':
        typeName = 'บัญชี';
        break;
      case 'accountx':
        typeName = 'บัญชี';
        break;
      case 'manager':
        typeName =  'ผู้จัดการ';
        break;
      case 'stock':
        typeName = 'สต็อค';
        break;
      case 'store':
        typeName = 'สโตร์';
        break;
      case 'transport':
        typeName = 'ขนส่ง';
        break;
      default :
        typeName = '';
        break
    }
    return typeName
  }
}

export default new UserUtil()
