import React, {Component} from "react";
import moment from "moment";
import MaskedInput from "react-maskedinput";
import NumberFormat from "react-number-format";
import BDatePicker from "../../BDatePicker";
import DatePicker from "react-datepicker";
import axios from "axios";
import {APIURL} from "../../../config/config";



export  default class CustomerStoreFrontEdit extends Component{

    constructor(props){
        super(props);
        this.state={
            lists: [],
            load_data: true,
            danger: false,
            action: "บันทึก",
            customer: '' ,
            employee:[],
            walkin_level_list:[
                {walkin_level_id: 'A', walkin_level_name: 'A'},
                {walkin_level_id: 'B', walkin_level_name: 'B'},
                {walkin_level_id: 'C', walkin_level_name: 'C'},
                {walkin_level_id: 'D', walkin_level_name: 'D'},
                {walkin_level_id: 'E', walkin_level_name: 'E'}
            ],

            customer_id: this.props.customer.customer_id,
            customer_name: this.props.customer.customer_name,
            customer_lastname:this.props.customer.customer_lastname,
            customer_card_id:this.props.customer.customer_card_id,
            customer_mobile:this.props.customer.customer_mobile,

            walkin_date_mask: this.props.customer.walkin_date === null || this.props.customer.walkin_date === '0000-00-00' ? '' : this.convertCCtoBB(moment(this.props.customer.walkin_date).format("YYYY-MM-DD")),
            walkin_date: this.props.customer.walkin_date === null || this.props.customer.walkin_date === '0000-00-00' ? '' : moment(this.props.customer.walkin_date).format("YYYY-MM-DD"),
            walkin_level: this.props.customer.walkin_level === '' ? '' : this.props.customer.walkin_level,
            walkin_note: this.props.customer.walkin_note,
            employee_id: this.props.customer.employee_id,

            startDateCustomerEditStoreFront: moment(),
        }

        this.onInputChangeCustomerStoreFront = this.onInputChangeCustomerStoreFront.bind(this)
        this.handleDateCustomerEditStoreFront = this.handleDateCustomerEditStoreFront.bind(this)
        this.onMaskedDateCustomerEditStoreFront = this.onMaskedDateCustomerEditStoreFront.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        this.convertCCtoBB =  this.convertCCtoBB.bind(this)
        this.handleWalkInDate = this.handleWalkInDate.bind(this);
        this.loadEmployeeData = this.loadEmployeeData.bind(this)
    }


    onMaskedDateCustomerEditStoreFront(e){
        let buddhist_date = e.target.value ;

        if(buddhist_date.indexOf('_') === -1 ) {
            if (buddhist_date.length === 10) {
                let cc_date = this.convertBBtoCC(buddhist_date)
                this.handleDateCustomerEditStoreFront(cc_date)
            }
        }
    }

    handleDateCustomerEditStoreFront(date) {
        //alert (date);
        this.setState({
            startDateCustomerEditStoreFront: moment(date),
            walkin_date : date
        });
    }

    loadEmployeeData () {
        axios.get(APIURL + '/employee/')
            .then(res => {
                this.setState({employee: res.data})
            }).catch(error => {
            this.setState({employee: []})
        })
    }

    convertBBtoCC(buddhist_date){
        // dd-mm-yyyy to yyyy-mm-dd
        let date_arr = buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = Number(date_arr[2])-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    convertCCtoBB(cc_date) { // "1972-10-31"
        let date_arr=cc_date.split('-');
        let dd = date_arr[2];
        let mm = date_arr[1];
        let yyyy = Number(date_arr[0])+543;

        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return dd+'-'+mm+'-'+yyyy ;
        }
        return moment() ;
    }

    handleWalkInDate(date){
        this.setState({
            startDateWalkIn: (date),
            walkin_date: (date)
        });
    }

    onInputChangeCustomerStoreFront(event) {
        event.preventDefault()

        let name = event.target.value

        if (event.target.id === 'customer_name') {
            this.setState({customer_name: name});
        } else if (event.target.id === 'customer_lastname') {
            this.setState({customer_lastname: name})
        } else if (event.target.id === 'customer_card_id') {
            this.setState({customer_card_id: name})
        } else if (event.target.id === 'customer_mobile') {
            this.setState({customer_mobile: name})
        }else if (event.target.id === 'walkin_date') {
            this.setState({walkin_date: name})
        }else if (event.target.id === 'walkin_level') {
            this.setState({walkin_level: name})
        }else if (event.target.id === 'walkin_note') {
            this.setState({walkin_note: name})
        }
    }

    componentDidMount(){
        this.loadEmployeeData()
    }

    render(){

        let employee_list = this.state.employee.map((employee, index) => {
            return (
                <option key={index+1} value={employee.employee_id}>{employee.employee_name}</option>
            )
        })

        let walkin_level_list = this.state.walkin_level_list.map((walkin_level, index) =>{
            return (
                <option key={index} value={walkin_level.walkin_level_name}> {walkin_level.walkin_level_name} </option>
            )
        })

        return(
            <form action="" method="post"
                  onSubmit={this.props.onCustomerStoreFrontSubmit.update}>

                <div className="card-header card-edit">
                    <strong className="text-title">แก้ไขข้อมูลลูกค้าหน้าร้าน</strong>
                </div>

                <div className="card-block">
                    <div className="row">
                        <input type="hidden"
                               className="form-control"
                               id="customer_id"
                               placeholder=""
                               value={this.state.customer_id}
                               onChange={this.onInputChangeCustomerStoreFront}
                        />

                        <div className="form-group col-sm-4">
                            <label htmlFor="walkin_date">วันที่เข้ามาติดต่อ</label>
                            <DatePicker
                                selected={this.state.startDateWalkIn}
                                onChange={this.handleWalkInDate}
                                dateFormat="yyyy-MM-dd"
                                type="text"
                                className="form-control col-sm-12"
                                id="walkin_date"
                                name="walkin_date"
                                ref="walkin_date"
                                value={this.state.walkin_date}
                            />
                            {/*<MaskedInput
                                mask="11-11-1111"
                                name="walkin_date_text"
                                placeholder="dd-mm-yyyy"
                                onChange={this.onMaskedDateCustomerEditStoreFront}
                                className="form-control"
                                value={this.state.walkin_date_mask}
                            />
                            <input className="form-control hidden"
                                   type="text"
                                   id="walkin_date"
                                   name="walkin_date"
                                   ref="walkin_date"
                                   value={this.state.walkin_date} onChange={()=>{}}
                            />*/}
                        </div>

                        <div className="form-group col-sm-4">
                            <label htmlFor="customer_name">ชื่อ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_name"
                                   placeholder=""
                                   value={this.state.customer_name}
                                   onChange={this.onInputChangeCustomerStoreFront}
                            />
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="customer_lastname">นามสกุล</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_lastname"
                                   placeholder=""
                                   ref="customer_lastname"
                                   value={this.state.customer_lastname}
                                   onChange={this.onInputChangeCustomerStoreFront}
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="customer_card_id_old-bk"
                                   placeholder=""
                                   ref="customer_card_id_old-bk"
                            />

                            <MaskedInput
                                mask="1-1111-11111-11-1"
                                id="customer_card_id"
                                name="customer_card_id"
                                ref="customer_card_id"
                                placeholder=""
                                className="form-control"
                                maxLength="20"
                                value={this.state.customer_card_id}
                                onChange={this.onInputChangeCustomerStoreFront}
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="customer_mobile_old-bk"
                                   placeholder=""
                                   ref="customer_mobile_old-bk"
                            />

                            <MaskedInput
                                mask="111-111-1111"
                                id="customer_mobile"
                                name="customer_mobile"
                                ref="customer_mobile"
                                placeholder=""
                                className="form-control"
                                value={this.state.customer_mobile}
                                onChange={this.onInputChangeCustomerStoreFront}
                            />
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="walkin_level">ระดับความต้องการของลูกค้า</label>
                            {/*<input type="text"
                                   className="form-control"
                                   id="walkin_level"
                                   placeholder=""
                                   ref="walkin_level"
                                   value={this.state.walkin_level}
                                   onChange={this.onInputChangeCustomerStoreFront}
                            />*/}
                            <select
                                className="form-control"
                                id="walkin_level"
                                name="walkin_level"
                                ref="walkin_level"
                                value={this.state.walkin_level}
                                onChange={(e)=>{
                                    this.setState({walkin_level: e.target.value});
                                }}
                            >
                            <option value=""> ระบุระดับความต้องการ </option>
                            {walkin_level_list}
                        </select>
                        </div>

                        <div className="form-group col-sm-3">
                            <label htmlFor="employee">เซลล์ผู้ดูแล</label>
                            <select className="form-control"
                                    id="employee_id"
                                    name="employee_id"
                                    value={this.state.employee_id}
                                    onChange={(e) => {
                                        this.setState({
                                            employee_id: e.target.value
                                        })
                                    }}

                            >
                                <option key={0} value={0}>ไม่ระบุ</option>
                                {employee_list}
                            </select>
                        </div>

                        <div className="form-group col-sm-12">
                            <label htmlFor="walkin_note">หมายเหตุ</label>
                            <input type="text"
                                   className="form-control"
                                   id="walkin_note"
                                   placeholder=""
                                   ref="walkin_note"
                                   value={this.state.walkin_note}
                                   onChange={this.onInputChangeCustomerStoreFront}
                            />
                        </div>
                    </div>
                </div>

                <div className="card-footer text-right">

                    <button type="reset"
                            onClick={this.props.onToggle}
                            className="btn btn-sm btn-danger mr-2">
                        <i className="fa fa-close"/> ปิดหน้านี้
                    </button>

                    <button type="submit"
                            className="btn btn-sm btn-warning">
                        <i className="fa fa-save"/> แก้ไข
                    </button>
                </div>
            </form>

        )
    }
}