import React from 'react'
import {
  Document, Page, PDFViewer, PDFDownloadLink,
  StyleSheet, View, Text
} from '@react-pdf/renderer'


const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row',
  },
  section_right: {
    margin: 10,
    padding: 10,
    paddingTop: 20,
    width: '75%',
  },
})
// ยังไม่ได้ใช้ pdf แบบใหม่ realtime render
const SellReceipt = ({ car_sell }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section_right}>
          <Text>{car_sell.car_name}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default SellReceipt