/**
 * Created by Tukta on 20/7/2560.
 */
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput  from 'react-maskedinput';
import 'moment/locale/th';
import axios from 'axios';
import { APIURL } from '../../../config/config';
moment.locale('th');

export  default class EmployeeForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            employee_id:0,
            action: "บันทึก",
            startDateEmployeeStartWork: '',
            employee_salary: 0,
            employee_name: '',
            employee_lastname: '',
            employee_address: '',
            employee_tel: '',
            employee_position: '',
            employee_card_id: '',
            employee_code: '',
            employee: this.props.employee,
            startwork_bb: '',
          branchs: [],
          branch_id: 0,
        }
        this.handleDateEmployeeStartWork = this.handleDateEmployeeStartWork.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        this.onMaskedDateEmployeeStartWork = this.onMaskedDateEmployeeStartWork.bind(this)
        this.onSubmitUpdate = this.onSubmitUpdate.bind(this)
        this.onSubmitAdd = this.onSubmitAdd.bind(this)
        this.onChangeEmployeeHandle = this.onChangeEmployeeHandle.bind(this)
       this.loadBranchData = this.loadBranchData.bind(this)
    }

    componentDidMount () {
      this.loadBranchData()
    }

  loadBranchData() {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branchs: res.data})
      }).catch(error => {
      this.setState({branchs: []})
    })
  }

  onSubmitAdd(event) {
        // event.target.employee_startwork.value = ''
        this.props.onEmployeeSubmit.add(event);
        // this.onMaskedDateEmployeeStartWork(event.target.employee_startwork);
         this.setState({
             employee_salary: 0,
             startwork_bb: '',
             employee: {},
             startDateEmployeeStartWork: moment('').format('YYYY-MM-DD')
         },()=>{
             this.forceUpdate()
         });
    }

    onSubmitUpdate(event){
        this.props.onEmployeeSubmit.update(event);
        this.setState({
            employee_salary: 0,
            startwork_bb: '',
        });
    }

    handleDateEmployeeStartWork(date) {
        // console.log("date="+date)
        if(date==='')  return;

        this.setState({
            startDateEmployeeStartWork: moment(date).format('YYYY-MM-DD')
        });
    }

    onMaskedDateEmployeeStartWork (e){
        let buddhist_date = e.target.value ;
        //alert('date='+date);
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.setState({startwork_bb: buddhist_date})
            this.handleDateEmployeeStartWork(cc_date)
        }
    }

    convertBBtoCC(buddhist_date){
      // dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-')
        let dd = date_arr[0]
        let mm = date_arr[1]
        let yyyy = date_arr[2]-543
        let cc_date= yyyy+'-'+mm+'-'+dd
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date)
            return cc_date
        }
        return moment().format('YYYY-MM-DD')
    }

    convertCCtoBB(cc_date){ //2018-01-27
        let date_arr=cc_date.split('-');
        let yyyy = Number(date_arr[0])+543;
        let mm = date_arr[1];
        let dd = date_arr[2];
        return dd+'-'+mm+'-'+yyyy
    }

    /*handleOnChange() {
        var employee_salary = this.state.employee_salary ;
        this.setState({
            employee_salary: employee_salary
        });
    }*/

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (JSON.stringify(nextProps.employee) !== JSON.stringify(this.props.employee)) {
            this.setState({ employee: nextProps.employee },()=>{
                this.updateSalary();
                this.updateStartWork();
            });
        }
    }

    updateSalary(){
        if(this.props.actionType==="แก้ไข"){
            var employee_salary = this.state.employee.employee_salary
            this.setState({
                employee_salary: employee_salary
            });
        }
    }

    updateStartWork(){
        if(this.props.actionType==="แก้ไข"){
            let employee_startwork = this.state.employee.employee_startwork
            if(employee_startwork!==undefined) {
                let startwork = moment(employee_startwork).format('YYYY-MM-DD');
                let startwork_bb = this.convertCCtoBB(startwork);
                this.setState({
                    startDateEmployeeStartWork: startwork,
                    startwork_bb: startwork_bb
                });
            }
        }
    }

  onChangeEmployeeHandle(event) {
        let xname = event.target.name
        if(xname === "employee_name") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_name: event.target.value
                }})
        }else if(xname === "employee_lastname") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_lastname: event.target.value
                }})
        }else if(xname === "employee_address") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_address : event.target.value
                }})
        }else if(xname === "employee_tel") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_tel : event.target.value
                }})
        }else if(xname === "employee_position") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_position : event.target.value
                }})
        }else if(xname === "employee_startwork") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_startwork : event.target.value
                }})
        }else if(xname === "employee_salary") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_salary : event.target.value
                }})
        }else if(xname === "employee_card_id") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_card_id : event.target.value
                }})
        }else if(xname === "employee_code") {
            this.setState ({employee:{
                    ...this.state.employee,
                    employee_code : event.target.value
                }})
        }
    }

  render(){
        //const { history } = this.props

    let car_branch_list = this.state.branchs.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

        return(
            <div className="card card-accent-success">
                <div className="card-header card-customer">
                    <strong className="text-title">เพิ่มข้อมูลพนักงาน</strong>
                </div>
                <form action=""
                      method="post"
                      onSubmit={(this.props.actionType==="แก้ไข")? this.onSubmitUpdate : this.onSubmitAdd}>

                    <div className="card-block">
                        <div className="form-group">
                            <label htmlFor="employee_code">รหัสพนักงาน</label>
                            <input name="employee_code"
                                   value={this.state.employee.employee_code || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   placeholder="กรอกรหัสพนักงาน"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                      <div className="form-group">
                        <label htmlFor="employee_name">สาขา</label>
                        <select className="form-control"
                                id="branch_id"
                                name="branch_id"
                                ref="branch_id"
                                value={this.state.employee.branch_id || ''}
                                onChange={(e) => {
                                  let val = e.target.value
                                  this.setState ({employee:{
                                      ...this.state.employee,
                                      branch_id : val
                                    }})
                                }}
                        >
                          <option value={''}>เลือกสาขา</option>
                          {car_branch_list}
                        </select>
                      </div>

                        <div className="form-group">
                            <label htmlFor="employee_name">ชื่อ</label>
                            <input name="employee_name"
                                   value={this.state.employee.employee_name || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="employee_lastname">นามสกุล</label>
                            <input name="employee_lastname"
                                   value={this.state.employee.employee_lastname || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   //placeholder="กรอกรหัสพนักงาน"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="employee_card_id">เลขบัตรประจำตัวประชาชน</label>
                            <input name="employee_card_id"
                                   value={this.state.employee.employee_card_id || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   //placeholder="กรอกรหัสพนักงาน"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="employee_address">ที่อยู่</label>
                            <input name="employee_address"
                                   value={this.state.employee.employee_address || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   //placeholder="กรอกรหัสพนักงาน"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="employee_tel">เบอร์โทรศัพท์</label>
                            <input name="employee_tel"
                                   value={this.state.employee.employee_tel || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   //placeholder="กรอกรหัสพนักงาน"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="employee_tel">ตำแหน่ง</label>
                            <input name="employee_position"
                                   value={this.state.employee.employee_position || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   //placeholder="กรอกรหัสพนักงาน"
                                   onChange={this.onChangeEmployeeHandle}/>
                        </div>

                      {this.props.user_type === 'admin' ?
                        <div className="form-group">
                          <label htmlFor="employee_salary">เงินเดือน</label>
                          <input name="employee_salary"
                                 value={this.state.employee.employee_salary || ''}
                                 style={styles.inputSearch}
                                 type="text"
                                 className="form-control hidden"
                                 onChange={()=>{}}
                          />
                          <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            placeholder="เงินเดือนพนักงาน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.employee.employee_salary || ''}
                            style={styles.inputSearch}
                            onValueChange = {(values) => {
                              const {value} = values;
                              if(value!==0) {
                                this.setState ({employee:{
                                    ...this.state.employee,
                                    employee_salary: value
                                  }})
                              }
                            }}
                          />
                        </div> : <input name="employee_salary"
                                        value={this.state.employee.employee_salary || ''}
                                        style={styles.inputSearch}
                                        type="text"
                                        className="form-control hidden"
                                        onChange={()=>{}}
                        />
                      }

                        <div className="form-group">
                            <label htmlFor="employee_startwork">วันที่เริ่มทำงาน (วัน-เดือน-ปี พ.ศ.)</label>
                            <MaskedInput
                                mask="11-11-1111"
                                name="employee_startwork"
                                placeholder="วัน-เดือน-ปี พ.ศ."
                                onChange={this.onMaskedDateEmployeeStartWork}
                                className="form-control"
                                value={this.state.startwork_bb || ''}
                                style={styles.inputSearch}

                            />
                            <input name="employee_startwork_text"
                                   value={this.state.startDateEmployeeStartWork || ''}
                                   style={styles.inputSearch}
                                   type="hidden"
                                   className="form-control hidden"
                                   onChange={()=>{}}
                            />

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                className="btn btn-sm btn-danger mr-2"
                                onClick={()=>{
                                    this.setState({
                                        employee: {}
                                    })
                                }}
                                style={styles.button}>
                          <i className="fa fa-refresh"/> ยกเลิก
                        </button>
                        <button type="submit"
                                className={ this.props.actionType==="แก้ไข" ? "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                                style={styles.button}
                        >
                            <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                            &nbsp;{this.props.actionType}
                        </button>
                    </div>
                </form>
            </div>

        )
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },
    inputSearch: {
        borderRadius:5,
    },
}
