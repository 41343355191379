import React, { Component } from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Switch from "react-switch";
import axios from "axios";
import {APIURL} from "../../../config/config";
import {AlertSuccess, AlertWarning} from "../../Alert/Alert";

class ProfileFromModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
            profile_froms: [],
            is_update: false,
            profile_from_id: '',
            profile_from_name: '',
            action: 'add'
        };

        this.onToggle = this.onToggle.bind(this);
        this.onChangeSwitch = this.onChangeSwitch.bind(this);
        this.loadProfileFromAll = this.loadProfileFromAll.bind(this);
        this.saveProfileFrom = this.saveProfileFrom.bind(this);
        this.selectProfileFrom = this.selectProfileFrom.bind(this);
    }

    componentDidMount() {
        this.loadProfileFromAll();
    }

    loadProfileFromAll() {
        axios.get(APIURL + '/profile_from/all')
            .then(res => {
                this.setState({profile_froms: res.data})
            }).catch(error => {
            this.setState({profile_froms: []})
        });
    }

    saveProfileFrom(e){
        e.preventDefault()
        let profile_from_name = this.state.profile_from_name
        let action = this.state.action
        if (profile_from_name === ''){
            AlertWarning('กรุณากรอกชื่อที่มา');
            return
        }

        profile_from_name = profile_from_name.trim()

        let profile_froms = this.state.profile_froms
        let profile_from_exist = profile_froms.filter(p=> p.profile_from_name === profile_from_name)

        if(profile_from_exist.length >=1 ){
            AlertWarning('รายการซ้ำกรุณาตรวจสอบ');
            return
        }

        // add
        let data = {
            profile_from_name: profile_from_name
        }

        if (action === 'add') {
            axios.post(APIURL + '/profile_from', data).then(res => {
                if (res.status === 200) {
                    AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
                    this.setState({
                        profile_from_name: '',
                        is_update: true
                    }, () => {
                        this.loadProfileFromAll()
                    })
                }
            }).catch(error => {
                console.log('error:', error)
            })
        } else if(action === 'edit'){
            let profile_from_id = this.state.profile_from_id
            axios.put(APIURL + '/profile_from/name/'+profile_from_id,  data)
                .then(res => {

                    if (res.status === 200) {
                        AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                        this.setState({
                            is_update: true,
                            profile_from_id: '',
                            profile_from_name: '',
                            action: 'add'
                        }, () => {
                            this.loadProfileFromAll()
                        })
                    }

                }).catch(error => {
                console.log('error:', error)
            })
        }
    }

    selectProfileFrom(profile_from) {

        this.setState({
            profile_from_id: profile_from.profile_from_id,
            profile_from_name: profile_from.profile_from_name,
            action: 'edit'
        })
    }

    onToggle(e){
        let is_update = this.state.is_update;
        this.props.toggleModal(e, is_update);
    }


    onChangeSwitch(checked, profile_from){
        let profile_from_update = profile_from

        if(checked === false){
            profile_from_update.status_field = 'hidden'
        }else {
            profile_from_update.status_field = 'show'
        }

        let profile_from_id = profile_from_update.profile_from_id

        axios.put(APIURL + '/profile_from/status/'+profile_from_id, profile_from_update)
            .then(res => {

                this.setState({
                    is_update: true
                },() => {
                    this.loadProfileFromAll()
                })

            }).catch(error => {

        })


    }

    render() {

        let profile_froms_list = this.state.profile_froms.map((profile_from,index)=>{
            return(
              <tr key={index}>
                  <td >{index+1}</td>
                  <td >{profile_from.profile_from_name}</td>
                  <td className="text-center">

                      <span style={{width: 48}}> {profile_from.status_field === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' } </span>
                  </td>
                  <td className="text-center">
                      <Switch height = {20} width ={48}
                              onChange={(checked) => this.onChangeSwitch(checked, profile_from)}
                              checked={profile_from.status_field === 'show'}
                      />
                  </td>
                  <td>
                      <button className="btn btn-success"
                              type="button"
                              onClick={()=> this.selectProfileFrom(profile_from)}
                      >
                          เลือก
                      </button>
                  </td>
              </tr>
            );
        })
        return(
            <Modal className="none modal-dialog"
                   isOpen={this.props.is_open}
                // style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
            >
                <ModalHeader toggle={this.onToggle}>ข้อมูลแแหล่งที่มาของลูกค้า</ModalHeader>
                <ModalBody>
                    <div className="card-block">
                      <div className="row mb-2">
                          <strong className="text-title">ชื่อที่มา</strong>

                          <input
                              name="profile_from_name"
                              value={this.state.profile_from_name}
                              onChange={(e)=> {
                                  let val = e.target.value
                                  this.setState({
                                      profile_from_name: val
                                  })
                              }}
                              style={styles.inputSearch}
                              className="form-control"
                              placeholder="กรอกชื่อที่มา"
                              type="text"
                          />&nbsp;&nbsp;&nbsp;

                          {
                              this.state.action === 'add' ?
                                  <button className="btn btn-head-bar btn-primary" type="button"
                                                                    style={styles.buttonHeight}
                                                                    onClick={this.saveProfileFrom}

                              >
                                  <i className="icon-plus"/> &nbsp; เพิ่ม
                              </button>
                                  :
                                  <button className="btn btn-head-bar btn-warning " type="button"
                                          style={styles.buttonHeight}
                                          onClick={this.saveProfileFrom}

                                  >
                                      <i className="icon-pencil"/> &nbsp; แก้ไข
                                  </button>
                          }

                      </div>
                      <div className="row">
                          <table className={(this.state.profile_froms.length>0)?'table table-striped ' : 'table table-striped hidden'}>
                              <thead>
                              <tr>
                                  <th>#</th>
                                  <th>ชื่อ</th>
                                  <th colSpan={2} className="text-center">ใช้งาน</th>
                                  <th>จัดการ</th>
                              </tr>
                              </thead>
                              <tbody>
                               {profile_froms_list}
                              </tbody>
                          </table>
                      </div>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <Button color="danger" onClick={this.onToggle}>&nbsp;&nbsp;ปิด&nbsp;&nbsp;</Button>

                </ModalFooter>
            </Modal>
        )
    }
}

const styles = {
    inputSearch: {
        marginLeft: 15,
        width: 250,
        borderRadius:5,
        display: 'inline'
    },
}

export default ProfileFromModal