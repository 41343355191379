import React, {Component} from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import axios from 'axios';
import {APIURL} from "../../config/config";
import { AlertSuccess, AlertWarning } from '../Alert/Alert'

class FinanceModal extends Component{
  constructor (props){
    super(props)
    this.state = {
      //agent_list: [],
      bank_list: [],
      is_bank_modal: false,
      bank_selected: null,
      bank_id: 0,
      bank_name: '',
      bank_name_full: '',
      bank_address_line1: '',
      bank_address_line2: '',
      bank_tax_id: '',
    }

    this.onSelectBank = this.onSelectBank.bind(this)
    this.onRefresh = this.onRefresh.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.openBankModal = this.openBankModal.bind(this)
    this.closeBankModal = this.closeBankModal.bind(this)
    this.onChangeBank = this.onChangeBank.bind(this)
  }

  componentDidMount () {
    this.loadFinanceData()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadFinanceData()
  }

  onSearch(){
    let search_text = this.refs.input_search.value

    if(search_text==='') {
      return;
    }

    if(search_text.length < 3){
      return;
    }

    axios.get( APIURL + '/bank/search/'+search_text )
      .then(res => {
        this.setState({bank_list: res.data})
      }).catch(error => {
      this.setState({bank_list: []})
    })
  }

  loadFinanceData() {
    axios.get( APIURL + '/bank/').then(res => {
      this.setState({
        bank_list: res.data
      })
    }).catch(error => {

    })
  }

  onSelectBank(bank) {
    if (bank.bank_name_full === null || bank.bank_address_line1 === null) {
      AlertWarning('ข้อมูลแบบเต็มของไฟแนนท์ไม่ถูกต้อง กรุณากดแก้ไขข้อมูล')
      return
    }
    if (bank.bank_tax_id === null || bank.bank_tax_id === '') {
      AlertWarning('ข้อมูลเลขผู้เสียภาษีไม่ถูกต้อง')
      return
    }
    this.props.toggleCustomerFinanceModal(false, bank)
  }

  openBankModal(bank) {
    this.setState({
      bank_selected: bank,
      is_bank_modal: true,
      bank_id: bank.bank_id,
      bank_name: bank.bank_name,
      bank_name_full: bank.bank_name_full === null ? '' : bank.bank_name_full,
      bank_address_line1: bank.bank_address_line1 === null ? '' : bank.bank_address_line1,
      bank_address_line2: bank.bank_address_line2 === null ? '' : bank.bank_address_line2,
      bank_tax_id: bank.bank_tax_id === null ? '' : bank.bank_tax_id,
    })
  }

  closeBankModal() {
    this.setState({
      is_bank_modal: false
    })
  }

  onChangeBank(e) {
    let name = e.target.name
    let val = e.target.value
    this.setState({
      [name]: val
    })
  }

  updateBankInvoice() {
    let bank_id = this.state.bank_id
    let bank_name_full = this.state.bank_name_full
    let bank_address_line1 = this.state.bank_address_line1
    let bank_address_line2 = this.state.bank_address_line2
    let bank_tax_id = this.state.bank_tax_id
    let username = this.props.username

    let data = {
      bank_id: bank_id,
      bank_name_full: bank_name_full,
      bank_address_line1: bank_address_line1,
      bank_address_line2: bank_address_line2,
      bank_tax_id: bank_tax_id,
      username: username
    }

    axios.put(APIURL+'/bank/invoice/'+bank_id, data)
      .then(res => {
        if (res.status === 200) {
          AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว')
          setTimeout(() => {
            this.loadFinanceData()
            this.closeBankModal()
          }, 1500)
        }

      }).catch(error => {
        console.log('error: ', error)
    })

  }

  render () {

    const emptyData = <tr><td colSpan={6} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
    const bank_list = this.state.bank_list.map((bank, index) => (
      <tr key={index}>
        <td>{ bank.bank_id }</td>
        <td className="text-nowrap">
          { bank.bank_name } <br/>
          { bank.bank_name_full } <br/>
          { bank.bank_address_line1 } <br/>
          { bank.bank_address_line2 } <br/>
          เลขประจำตัวผู้เสียภาษี : { bank.bank_tax_id }
        </td>

        <td className="text-center" >
          <button className="btn btn-sm btn-block btn-success"
                  style={{width: 100}}
                  onClick={() => {
                    this.onSelectBank(bank)
                  }}
          >
             เลือก
          </button>

          <button className="btn btn-warning btn-sm btn-block mt-2"
                  style={{width: 100}}
                  onClick={() => {
                    this.openBankModal(bank)
                  }}
          >
            <i className="fa fa-pencil"></i> แก้ไข
          </button>

        </td>
      </tr>
    ))

    const bankList = this.state.bank_list.length > 0 ? bank_list : emptyData
    // toggle={this.props.toggleAgentModal}
    // <i className="fa icon-handbag"></i>

    // className={'modal-lg ' + this.props.className}

    return (
      <>

        <Modal isOpen={this.state.is_bank_modal}  >
          <ModalBody>
            <div className={"card card-accent-primary"}>
              <div className="card-header">
                <strong className="text-title">แก้ไข ธนาคาร/ไฟแนนซ์ {this.state.bank_name}</strong>
              </div>
              <div className="card-block">

                  <div className="form-group">
                    <label htmlFor="bank_name_full">ชื่อเต็มธนาคาร (สำหรับออกใบกำกับภาษีให้ธนาคาร)</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.bank_name_full || ''}
                      name="bank_name_full"
                      placeholder="กรอกชื่อเต็มธนาคาร"
                      onChange={this.onChangeBank}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="bank_address_line1">ที่อยู่ธนาคาร บรรทัด 1</label>
                    <input
                      value={this.state.bank_address_line1 || ''}
                      type="text"
                      className="form-control"
                      name="bank_address_line1"
                      placeholder="กรอกที่อยู่ธนาคาร"
                      onChange={this.onChangeBank}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="bank_address_line2">ที่อยู่ธนาคาร บรรทัด 2</label>
                    <input
                      value={this.state.bank_address_line2 || ''}
                      type="text"
                      className="form-control"
                      name="bank_address_line2"
                      placeholder="กรอกที่อยู่ธนาคาร"
                      onChange={this.onChangeBank}
                    />

                  </div>

                  <div className="form-group">
                      <label htmlFor="bank_tax_id">เลขผู้เสียภาษี</label>
                      <input
                        value={this.state.bank_tax_id || ''}
                        type="text"
                        className="form-control"
                        name="bank_tax_id"
                        placeholder="กรอกเลขผู้เสียภาษี"
                        onChange={this.onChangeBank}
                      />

                    </div>

              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="warning"
                    onClick={() => this.updateBankInvoice()}> แก้ไข
            </Button>
            {' '}
            <Button color="secondary"
                    onClick={this.closeBankModal}>ยกเลิก
            </Button>
          </ModalFooter>

        </Modal>

        <Modal isOpen={this.props.isFinanceModal}  className={'modal-lg ' + this.props.className}>
          <ModalBody>
            <div className={"card card-accent-primary"}>

              <div className="card-header">
                <strong className="text-title">เลือกธนาคารไฟแนนซ์</strong>
              </div>

              <div className="card-block">
                <div className="row">

                  <div className="col-12">
                    <input
                      onChange={this.onSearch.bind(this)}
                      ref="input_search"
                      style={styles.inputSearch}
                      className="form-control"
                      placeholder="ค้นหาตามชื่อ"
                      type="text"
                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="btn btn-head-bar"
                            style={styles.buttonHeight}
                            onClick={this.onRefresh.bind(this)}
                    >
                      <i className="icon-refresh"/>
                    </button>
                  </div>

                  <div className="col-12 mt-2">
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">ธนาคาร</th>
                        <th className="text-center">เลือก</th>
                      </tr>
                      </thead>
                      <tbody>
                      {bankList}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <div className="card-footer text-right">
                <button type="button" onClick={() => this.props.toggleCustomerFinanceModal(false, null)}
                        className="btn btn-sm btn-danger">
                  &nbsp;<i className="fa fa-close"></i> &nbsp;&nbsp;ปิด&nbsp;&nbsp;
                </button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }

}

const styles = {
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },

  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}

export default FinanceModal
