import React, { Component, useState } from 'react'
import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format'
import axios from 'axios'
import { format } from 'date-fns'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { APIURL } from '../../config/config'
import { AlertSuccess, AlertWarning } from '../Alert/Alert'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import 'moment/locale/th'
import { CarUtil as carUtil } from '../../utils/carUtil'
import { NumberUtil } from '../../utils/number-util'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
moment.locale('th')

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

function CarDetail({car}) {

  let car_license_new_old = '(ใหม่)'+ car.car_license_plate_new +'...|...(เดิม)'+car.car_license_plate_old+'...'

  return (
     <strong>
        ประเภท...{car.car_structure_name}... ยี่ห้อ...{car.car_brand_name}... รุ่น...{car.car_sub_model_name}...
        ทะเบียน...{car.car_license_plate_new !== '' && car.car_license_plate_new === car.car_license_plate_old ? car.car_license_plate_new :  car_license_new_old }...
     </strong>
  )
}

function CarCheckBadge({doc_status}){

  let doc_status_name = carUtil.convertDocStatusName(doc_status)

  if (doc_status === 'N') {
    return <span className="ml-1 badge badge-primary">{doc_status_name}</span>
  } else if (doc_status === 'A') {
    return <span className="ml-1 badge badge-success">{doc_status_name}</span>
  } else if (doc_status === 'R') {
    return <span className="ml-1 badge badge-danger">{doc_status_name}</span>
  }
  return null
}


function CarCheckConfirmModal({isOpen, onToggle, actionStatus, user, onSave, check_color }) {

  const [approve_note , setApproveNote] = useState(check_color.approve_note === null ? '': check_color.approve_note)
  const [approve_date , setApproveDate] = useState(format(new Date(), 'yyyy-MM-dd'))

  const confirmData = () => {

    let data = {
      approve_note,
      approve_date,
      approve_user: user.user,
      doc_status: actionStatus,
      check_color_id: check_color.check_color_id,
      car_id: check_color.car_id,
      center_id: check_color.center_id,
      estimate_cost: check_color.estimate_cost,
    }

    onSave(data)

    setApproveNote('')
    setApproveDate(format(new Date(), 'yyyy-MM-dd'))
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle}
           className={'modal-lg '}
    >
      <ModalHeader toggle={onToggle}>ยืนยันการทำรายการ</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            {check_color.car_name}...
            ทะเบียน...
            {
              check_color.car_license_plate_new !== '' && check_color.car_license_plate_new !== check_color.car_license_plate_old ?
                check_color.car_license_plate_new :
                <> (ใหม่){check_color.car_license_plate_new}...|...(เดิม){check_color.car_license_plate_old} </>
            }
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-3">
            {
              actionStatus === 'A' ? <div className="bg-success font-xl text-center"> อนุมัติเอกสาร  </div> : <div className="bg-danger font-xl text-center"> ไม่อนุมัติเอกสาร   </div>
            }
          </div>
          <div className="col-9">
            ผู้อนุมัติโดย : {user.fullname} ...วันที่อนุมัติ: {approve_date}...
            <br/>ราคาประเมิน: {NumberUtil.addCommas(String(check_color.estimate_cost))}...ส่งอู่...{check_color.center_name}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <textarea
              name="approve_note"
              className="form-control"
              value={approve_note}
              placeholder={'หมายเหตุผู้อนุมัติ'}
              maxLength={255}
              onChange={(e) => setApproveNote(e.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button  color="secondary" onClick={onToggle}>ยกเลิก</Button>
        <Button  color="primary" onClick={() => confirmData()} >ยืนยัน</Button>
      </ModalFooter>
    </Modal>
  )
}

// doc_status
// N = New (Add New DOC)
// A = Approve
// R = Reject

class CarCheckColor extends Component {

  constructor (props) {
    super(props);
    this.state = {
      estimate_date: new Date(),
      estimate_cost: '',
      estimate_note: '',
      estimate_item1: '',
      estimate_item2: '',
      estimate_item3: '',
      estimate_item4: '',
      estimate_item5: '',
      estimate_item6: '',
      estimate_item7: '',
      estimate_item8: '',
      estimate_item9: '',
      estimate_item10: '',
      approve_note: '',
      center_id: '',
      //
      center_list: [],
      //
      check_color_id: 0,
      approve_user: null,
      user_created_name: '',
      estimate: null, // for print
      doc_status: '',
      //
      is_open: false,
      action_status: '',
    }

    this.handleEstimateDate = this.handleEstimateDate.bind(this)
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this)
    this.generateEstimateInput = this.generateEstimateInput.bind(this)
    this.loadCenterData = this.loadCenterData.bind(this)
    this.addEstimateColor = this.addEstimateColor.bind(this)
    this.loadCarCheckColor = this.loadCarCheckColor.bind(this)
    this.loadCarCheckColorByCar = this.loadCarCheckColorByCar.bind(this)
    this.setEstimateData = this.setEstimateData.bind(this)
    this.editEstimateColor = this.editEstimateColor.bind(this)
    this.printCarCheckColor = this.printCarCheckColor.bind(this)

    //
    this.onToggleModal = this.onToggleModal.bind(this)
    this.saveCarCheckAction = this.saveCarCheckAction.bind(this)
  }

  componentDidMount () {
    this.loadCenterData()
    this.loadCarCheckColorByCar()
  }

  onToggleModal() {
    let is_open = !this.state.is_open
    this.setState({is_open: is_open})
  }

  handleEstimateDate(date) {
    this.setState({
      estimate_date: date
    })
  }

  handleOnChangeInput(e){
    let value = e.target.value
    let name = e.target.name
    this.setState({
      [name]: value
    })
  }

  loadCenterData(){
    axios.get(APIURL + '/center')
      .then(res => {
        this.setState({center_list: res.data })
      }).catch(error => {
      // alert("error"+error)
      this.setState({center_list: []})
    })
  }

  generateEstimateInput() {
    let rows = []
    for(let i = 1 ; i <= 10 ; i++){
      let name = "estimate_item"+i
      let value = this.state[name]

      let tr = <tr key={'estimate'+i}>
        <td width={'5%'} className="text-center p-0 pt-1" > {i} </td>
        <td width={'95%'} className="p-0" >
          <input type="text"
                 className="form-control"
                 name={name}
                 value={value}
                 placeholder={'ประเมินซ่อมสีรายการ '+i}
                 maxLength={255}
                 onChange={this.handleOnChangeInput}
          />
        </td>
      </tr>
      rows.push(tr)
    }
    return rows
  }

  handleOnChangeNoSet (event) {

  }

  addEstimateColor(e) {
    e.preventDefault();

    const { car, user } = this.props

    // let center_id = e.target.center_id.value
    let user_created = user.user
    let car_id = car.car_id
    let center_id = this.state.center_id
    let estimate_cost = this.state.estimate_cost
    let estimate_date = format(this.state.estimate_date, 'yyyy-MM-dd')
    // let estimate_date = moment(this.state.estimate_date).format("YYYY-MM-DD")

    let estimate_item1 = this.state.estimate_item1
    let estimate_item2 = this.state.estimate_item2
    let estimate_item3 = this.state.estimate_item3
    let estimate_item4 = this.state.estimate_item4
    let estimate_item5 = this.state.estimate_item5
    let estimate_item6 = this.state.estimate_item6
    let estimate_item7 = this.state.estimate_item7
    let estimate_item8 = this.state.estimate_item8
    let estimate_item9 = this.state.estimate_item8
    let estimate_item10 = this.state.estimate_item10
    let estimate_note = this.state.estimate_note


    if (estimate_cost === '' || estimate_cost === '0' || estimate_cost === 0){
      AlertWarning('กรุณาระบุราคาประเมิน')
      return
    }

    if (center_id === ''){
      AlertWarning('กรุณาเลือกอู่สี')
      return
    }

    let data = {
      car_id,
      center_id,
      estimate_cost,
      estimate_date,
      estimate_item1,
      estimate_item2,
      estimate_item3,
      estimate_item4,
      estimate_item5,
      estimate_item6,
      estimate_item7,
      estimate_item8,
      estimate_item9,
      estimate_item10,
      estimate_note,
      user_created,
      doc_status: 'N'
    }

    let uri = APIURL+'/car_check/color'
    axios.post(uri, data)
      .then(res => {
        let resultData = res.data

        if (resultData.result === 'success') {
          AlertSuccess('เพิ่มรายการประเมินสีแล้ว')
          let check_color_id = resultData.check_color_id
          this.loadCarCheckColor(check_color_id)
        }

      })
      .catch(error => {
        console.log('มีข้อผิดพลาด : ', error)
      });
  }

  editEstimateColor(e) {
     e.preventDefault();
     let doc_status = this.state.doc_status

     if(doc_status === 'A'){
       // AlertWarning('')
     } else if (doc_status === 'R') {
       // AlertWarning('')
     }

     // allow edit only doc_status = 'N'

    const { user } = this.props
    let user_updated = user.user
    let check_color_id = this.state.check_color_id
    let center_id = this.state.center_id
    let estimate_cost = this.state.estimate_cost
    let estimate_date = format(this.state.estimate_date, 'yyyy-MM-dd')
    let estimate_item1 = this.state.estimate_item1
    let estimate_item2 = this.state.estimate_item2
    let estimate_item3 = this.state.estimate_item3
    let estimate_item4 = this.state.estimate_item4
    let estimate_item5 = this.state.estimate_item5
    let estimate_item6 = this.state.estimate_item6
    let estimate_item7 = this.state.estimate_item7
    let estimate_item8 = this.state.estimate_item8
    let estimate_item9 = this.state.estimate_item8
    let estimate_item10 = this.state.estimate_item10
    let estimate_note = this.state.estimate_note

    if (estimate_cost === '' || estimate_cost === '0' || estimate_cost === 0){
      AlertWarning('กรุณาระบุราคาประเมิน')
      return
    }

    if (center_id === ''){
      AlertWarning('กรุณาเลือกอู่สี')
      return
    }

    let data = {
      check_color_id,
      center_id,
      estimate_cost,
      estimate_date,
      estimate_item1,
      estimate_item2,
      estimate_item3,
      estimate_item4,
      estimate_item5,
      estimate_item6,
      estimate_item7,
      estimate_item8,
      estimate_item9,
      estimate_item10,
      estimate_note,
      user_updated,
    }

    let uri = APIURL+'/car_check/color'
    axios.put(uri, data)
      .then(res => {
        let resultData = res.data
        if (resultData.result === 'success') {
          AlertSuccess('แก้ไขรายการประเมินสีแล้ว')
          let check_color_id = resultData.check_color_id
          this.loadCarCheckColor(check_color_id)
        }
      })
      .catch(error => {
        console.log('มีข้อผิดพลาด : ', error)
      });
  }

  loadCarCheckColor(check_color_id) {
    let uri = APIURL+'/car_check/color/'+check_color_id
      axios.get(uri).then(res => {
        let data = res.data
        if (data.length === 1) {
          let estimate = data[0]

          this.setEstimateData(estimate)
        }
      })
      .catch(error => {
        console.log('มีข้อผิดพลาด : ', error)
      })
  }

  loadCarCheckColorByCar() {
    const { car } = this.props
    let car_id = car.car_id
    let uri = APIURL+'/car_check/carcolor/'+car_id
    axios.get(uri).then(res => {
      let data = res.data
      if (data.length === 1) {
        let estimate = data[0]
        this.setEstimateData(estimate)
      }
    })
      .catch(error => {
        console.log('มีข้อผิดพลาด : ', error)
      })
  }

  setEstimateData(estimate){

    let estimate_date = format(new Date(estimate.estimate_date), 'yyyy-MM-dd')

    this.setState({
      estimate: estimate,
      check_color_id: estimate.check_color_id,
      // car_id: estimate.car_id ,
      center_id: estimate.center_id ,
      doc_status: estimate.doc_status ,
      estimate_cost: estimate.estimate_cost ,
      estimate_date: new Date(estimate_date) ,
      estimate_item1: estimate.estimate_item1 ,
      estimate_item2: estimate.estimate_item2 ,
      estimate_item3: estimate.estimate_item3 ,
      estimate_item4: estimate.estimate_item4 ,
      estimate_item5: estimate.estimate_item5 ,
      estimate_item6: estimate.estimate_item6 ,
      estimate_item7: estimate.estimate_item7 ,
      estimate_item8: estimate.estimate_item8 ,
      estimate_item9: estimate.estimate_item9 ,
      estimate_item10: estimate.estimate_item10 ,
      estimate_note: estimate.estimate_note,
      approve_user: estimate.approve_user,
      approve_note: estimate.approve_note,
      approve_name: estimate.approve_name,
      user_created_name: estimate.user_created_name,
    })
  }

  printCarCheckColor() {

    // this.state.doc_status === 'A'
    const { estimate, doc_status } = this.state
    const { car } = this.props

    if (doc_status === 'N'){
      AlertWarning('กรุณาอนุมัติเอกสารก่อนพิมพ์ใบประเมิน');
      return
    }

    if (estimate === null) {
      return
    }

    const estimate_date = format(new Date(estimate.estimate_date), 'dd-MM-yyyy')

    let car_license_new_old = '(ใหม่)'+ car.car_license_plate_new +'...|...(เดิม)'+car.car_license_plate_old+'...'
    let car_license = car.car_license_plate_new !== '' && car.car_license_plate_new === car.car_license_plate_old ? car.car_license_plate_new :  car_license_new_old

    let car_info = 'ประเภท...'+car.car_structure_name+'...ยี่ห้อ...'+car.car_brand_name+'...'
    car_info += ' รุ่น...'+car.car_sub_model_name
    car_info += ' ทะเบียน..'+car_license+'...'

    let estimate_cost = NumberUtil.addCommas(String(estimate.estimate_cost))
    let approve_name = estimate.approve_name
    let center_name = estimate.center_name

    let carColorTable = []

    let colorTitleRow = [
      {
        text: 'รายการประเมิน', style: 'textTitleTable', colSpan: 2,
      },
      ''
    ]

    carColorTable.push(colorTitleRow)

    for(let i = 1 ; i <= 10 ; i++){
      let name = "estimate_item"+i
      let value = estimate[name]

      let estimateRows = [
        {
          text: i, style: 'textNormalCenter'
        },
        {
          text: value, style: 'textNormal'
        },
      ]

      carColorTable.push(estimateRows)

    }

    // margin: [left, top, right, bottom]
    const docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [50, 35, 35, 35],
      // background: { image: 'book', width: 600 },
      content: [
        {text: 'ใบประเมินราคาก่อนส่งซ่อมรถทำสี', style: 'textHeaderCenter', lineHeight: 1.3},
        {text: 'วันที่ประเมิน...'+estimate_date+'...', style: 'textHeaderLeft', lineHeight: 1.3},
        {text: car_info, style: 'textHeaderLeft', lineHeight: 1.3},
        {
          table: {
            widths: ['5%','95%'],
            body: carColorTable
          }
        },
        {
          text: 'ราคา...'+estimate_cost+'...', style: 'textHeaderLeft', lineHeight: 1.0,
          margin: [20, 20, 0, 0]
        },
        {
          text: 'ผู้อนุมัติ...'+approve_name+'...', style: 'textHeaderLeft', lineHeight: 1.0,
          margin: [20, 0, 0, 0]
        },
        {
          text: 'อู่สีส่งรถ...'+center_name+'...', style: 'textHeaderLeft', lineHeight: 1.0,
          margin: [20, 0, 0, 0]
        },
        {
          text: '*หมายเหตุผู้ประเมิน...'+estimate.estimate_note+'...', style: 'textNormal', lineHeight: 1.0,
          margin: [20, 8, 0, 0]
        },
        {
          text: '**หมายเหตุผู้อนุมัติ...'+estimate.approve_note+'...', style: 'textNormal', lineHeight: 1.0,
          margin: [20, 8, 0, 0]
        },
      ],
      styles: {
        textHeaderCenter: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        textHeaderLeft: {
          fontSize: 18,
        },
        textNormal: {
          fontSize: 16
        },
        textNormalCenter: {
          fontSize: 16,
          alignment: 'center'
        },
        textTitleTable: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }

    pdfMake.createPdf(docDefinition).open()

  }


  saveCarCheckAction(dataConfirm) {
    let check_color_id = dataConfirm.check_color_id

    let uri = APIURL+'/car_check/colorconfirm'
    axios.put(uri, dataConfirm).then(res => {
      // let data = res.data
      if (res.status === 200) {
        AlertSuccess('บันทึกการอนุมัติเรียบร้อยแล้ว')
        this.setState({
          is_open: false,
          check_color: null,
          estimate: null,
          doc_status: dataConfirm.doc_status
        }, () => this.loadCarCheckColor(check_color_id))
      }
    }).catch(error => {
      console.log('มีข้อผิดพลาด : ', error)
    })
  }


  render () {
    const { car, user } = this.props

    let center_list = this.state.center_list.map((center,index)=>{
      return( <option key={index} value={center.center_id}>{center.center_name}</option> )
    })

    return (
      <>

        {
          this.state.estimate === null ? null :
            <CarCheckConfirmModal
              isOpen={this.state.is_open}
              actionStatus={this.state.action_status}
              onToggle={() => this.onToggleModal()}
              user={user}
              check_color={this.state.estimate}
              onSave={(data) => this.saveCarCheckAction(data)}
            />
        }


        <form action="" method="post" onSubmit={
          this.state.check_color_id === 0 ?
            this.addEstimateColor :
            this.editEstimateColor
          } >
            <div className="row">
              <div className="col-2 text-right pt-2">
                <label className="mr-1" htmlFor="car_date_in">วันที่ประเมิน: </label>
              </div>
              <div className="col-2">
                <DatePicker
                  type="text"
                  className="form-control col-sm-12"
                  name="estimate_date"
                  placeholder=""
                  selected={this.state.estimate_date}
                  onChange={this.handleEstimateDate}
                  dateFormat="yyyy-MM-dd"
                  value={this.state.estimate_date}
                />
              </div>
              <div className="col-8 pt-2">
                {
                  Object.keys(car).length === 0 ? null : <CarDetail car={car} />
                }
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12">
                <table className="table table-bordered">
                  <thead>
                   <tr>
                     <td colSpan={2} className="text-center">
                       <span className="font-lg">ใบประเมินราคาก่อนส่งซ่อมรถทำสี </span>
                       <CarCheckBadge doc_status={this.state.doc_status}  />
                     </td>
                   </tr>
                  </thead>
                  <tbody>
                    {this.generateEstimateInput()}

                    <tr>
                      <td colSpan={2} className="text-center p-0">
                        <input type="text"
                               className="form-control"
                               placeholder='หมายเหตุผู้ประเมิน'
                               name='estimate_note'
                               value={this.state.estimate_note}
                               maxLength={255}
                               onChange={this.handleOnChangeInput}
                        />
                      </td>
                    </tr>

                    {
                      this.state.approve_user === null ? null : (
                        <tr>
                        <td colSpan={2} className="text-center p-0">
                            <input type="text"
                            className="form-control"
                            placeholder='หมายเหตุผู้อนุมัติ'
                            name='estimate_note'
                            value={'หมายเหตุผู้อนุมัติ: '+this.state.approve_note}
                            maxLength={255}
                            disabled={true}
                            onChange={this.handleOnChangeInput}
                            />
                      </td>
                      </tr>
                      )
                    }

                  </tbody>
                </table>

                {/*{*/}
                {/*  this.state.approve_user !== null && this.state.doc_status==='A' ?*/}
                {/*    <span className="text-success">***เอกสารอนุมัติแล้ว ไม่สามารถแก้ไขได้</span>:*/}
                {/*    <span className="text-danger">***เอกสารไม่อนุมัติ ไม่สามารถแก้ไขได้</span>*/}
                {/*}*/}

              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-2">
                <div className="form-group">
                  <label htmlFor="estimate_cost">ผู้ทำรายการ</label>
                  <input type="text"
                         className="form-control"
                         value={ this.state.user_created_name === '' ? user.fullname : this.state.user_created_name}
                         readOnly={true}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label htmlFor="estimate_cost">ราคาประเมิน</label>
                  <input type="text"
                         className="form-control hidden "
                         name="estimate_cost"
                         placeholder=""
                         value={this.state.estimate_cost}
                         onChange={this.handleOnChangeNoSet}
                  />
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={true}
                    prefix={'฿'}
                    placeholder="ราคาประเมิน"
                    min={0}
                    max={1000000000}
                    step={1000}
                    size={10}
                    allowNegative={false}
                    value={this.state.estimate_cost}
                    onValueChange={(values) => {
                      const {value} = values
                      this.setState({estimate_cost: value})
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label htmlFor="center_id">อู่สีส่งรถ</label>
                  <select
                    className="form-control"
                    name="center_id"
                    value={this.state.center_id}
                    onChange={(e) => {
                      this.setState({
                        center_id: e.target.value
                      })
                    }}
                  >
                    <option value="">เลือกศูนย์ซ่อม</option>
                    {center_list}
                  </select>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label htmlFor="estimate_cost">ผู้อนุมัติ</label>
                  <input type="text"
                         className="form-control"
                         value={this.state.approve_user === null ? '-' : this.state.approve_name}
                         readOnly={true}
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label htmlFor="estimate_cost">สถานะเอกสาร</label>
                  <input type="text"
                         className="form-control"
                         name="doc_status"
                         value={carUtil.convertDocStatusName(this.state.doc_status)}
                         readOnly={true}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 text-center">

                {
                  this.state.check_color_id === 0 ? (
                    <button type="submit"
                            className="btn btn-primary btn-md mr-2"
                            style={{width: 160}}
                    >
                      <i className="icon-plus"/>  เพิ่มการประเมินสี
                    </button>
                  ) : (
                    <>
                      <button type="submit"
                              className= {  "btn btn-warning btn-md mr-2"  }
                              style={{width: 160}}
                      >
                        <i className="icon-pencil"/> แก้ไขการประเมินสี
                      </button>

                      {
                        user.type === 'admin'? (
                          <>
                          <button
                            type="button"
                            style={{width: 84}}
                            className="btn btn-success btn-md mr-2"
                            onClick={() => {

                              let estimate_cost_new = Number(this.state.estimate_cost)
                              let estimate_cost_old = Number(this.state.estimate.estimate_cost)

                              // console.log('estimate_cost_new=', estimate_cost_new)
                              // console.log('estimate_cost_old=', estimate_cost_old)

                              if (estimate_cost_new !== estimate_cost_old) {
                                AlertWarning('ราคาประเมินมีการแก้ไข กรุณากดปุ่มแก้ไขการประเมินสี')
                                return
                              }
                              //

                              this.setState({
                                is_open: true,
                                action_status: 'A',
                                // check_color: check_color
                              })
                            }}
                          >
                            อนุมัติ
                          </button>
                            <button
                              type="button"
                              style={{width: 84}}
                              className="btn btn-danger btn-md mr-2"
                              onClick={() => {
                                this.setState({
                                  is_open: true,
                                  action_status: 'R'
                                })
                              }}
                            >
                              ไม่อนุมัติ
                            </button>

                          </>
                        ) : null
                      }

                      {
                        <button
                          type="button"
                          style={{width: 160}}
                          className={"btn btn-primary btn-md mr-2"}
                          onClick={() => this.printCarCheckColor()}
                        >
                          <i className="icon-printer"/> พิมพ์ใบประเมิน
                        </button>
                      }
                    </>
                  )
                }

              </div>
            </div>
        </form>
      </>
    )
  }
}

export default CarCheckColor
