import React, {Component} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import {APIURL} from "../../config/config";

class CustomerModal extends Component{
  constructor (props){
    super(props)
    this.state = {
      //agent_list: [],
      bank_list: []
    }

    this.onSelectBank = this.onSelectBank.bind(this)
    this.onRefresh = this.onRefresh.bind(this)
    this.onSearch = this.onSearch.bind(this)
  }

  componentDidMount () {
    this.loadFinanceData()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

  }

  onRefresh(){
    this.refs.input_search.value = ''
    this.loadFinanceData()
  }

  onSearch(){
    var search_text = this.refs.input_search.value

    if(search_text==='') {
      return;
    }

    if(search_text.length < 3){
      return;
    }

    axios.get( APIURL + '/customer/search/'+search_text )
      .then(res => {
        this.setState({bank_list: res.data})
      }).catch(error => {
      this.setState({bank_list: []})
    })
  }

  loadFinanceData() {
    axios.get( APIURL + '/customer/page/1').then(res => {
      // console.log(res.data)
      let customers = res.data.customers !== null ? res.data.customers : []
        this.setState({
        bank_list: customers
      })
    }).catch(error => {

    })
  }

  onSelectBank(bank) {
    console.log(bank)
  }

  render () {

    const emptyData = <tr><td colSpan={6} className="text-center text-danger"> ไม่พบข้อมูล </td></tr>
    const bank_list = this.state.bank_list.map((bank, index) => (
      <tr key={index}>
        <td>{ bank.bank_id }</td>
        <td className="text-nowrap">
          { bank.customer_name } {' '} {bank.customer_lastname} <br/>
          { bank.customer_address } <br/>
          เลขประจำตัวผู้เสียภาษี : { bank.customer_card_id }
        </td>

        <td className="text-center" >
          <button className="btn btn-sm btn-success"
                  onClick={() => this.props.toggleCustomerFinanceModal(false, bank)}
          >
             เลือก
          </button>
        </td>
      </tr>
    ))

    const bankList = this.state.bank_list.length > 0 ? bank_list : emptyData
    // toggle={this.props.toggleAgentModal}
    // <i className="fa icon-handbag"></i>

    return (
      <>
        <Modal isOpen={this.props.isFinanceModal}  className={'modal-lg ' + this.props.className}>
          <ModalBody>
            <div className={"card card-accent-primary"}>

              <div className="card-header">
                <strong className="text-title">เลือกลูกค้า</strong>
              </div>

              <div className="card-block">
                <div className="row">

                  <div className="col-12">
                    <input
                      onChange={this.onSearch.bind(this)}
                      ref="input_search"
                      style={styles.inputSearch}
                      className="form-control"
                      placeholder="ค้นหาตามชื่อ"
                      type="text"
                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="btn btn-head-bar"
                            style={styles.buttonHeight}
                            onClick={this.onRefresh.bind(this)}
                    >
                      <i className="icon-refresh"/>
                    </button>
                  </div>

                  <div className="col-12 mt-2">
                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">ธนาคาร</th>
                        <th className="text-center">เลือก</th>
                      </tr>
                      </thead>
                      <tbody>
                      {bankList}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <div className="card-footer text-right">
                <button type="button" onClick={() => this.props.toggleCustomerFinanceModal(false, null)}
                        className="btn btn-sm btn-danger">
                  &nbsp;<i className="fa fa-close"></i> &nbsp;&nbsp;ปิด&nbsp;&nbsp;
                </button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }

}

const styles = {
  inputSearch: {
    marginLeft: 20,
    width: 300,
    display: 'inline'
  },

  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}

export default CustomerModal
