import React, { Component } from 'react'
import { connect } from 'react-redux'
import { APIURL, WEBURL } from '../../../config/config'
import axios from 'axios/index'
import * as moment from 'moment';
import 'moment/locale/th';

class ProfileBySale extends Component {
  constructor (props) {
    super(props)
    this.state = {
      profile_lists: [],
      customer_docs: [],
    }
  }

  componentDidMount () {
    var user = this.props.sale.user;

   if(user!==''){

     this.loadCustomerProfile();
     this.loadCustomerDoc();
   }

  }

  componentWillReceiveProps(nextProps) {

      var user = nextProps.sale.user
      var id = nextProps.sale.id

      if(user==='' && id==='') {
        this.setState({
          profile_lists: [],
          customer_docs: [],
        });
      } else if(nextProps.sale.id === this.props.saleId) {
        this.loadCustomerProfile(user);
        this.loadCustomerDoc(user);
      }

  }


  loadCustomerProfile(user) {
    axios.get(APIURL + '/profile/sale/'+user)
      .then(res => {
        this.setState({profile_lists: res.data})
      }).catch(error => {
      this.setState({profile_lists: []})
    })
  }

  loadCustomerDoc(user) {
    // customer_docs
    axios.get(APIURL+'/customer_doc/sale/'+user)
      .then((res) => {
        this.setState({customer_docs: res.data})
      })
  }

  render () {
    let profileList = this.state.profile_lists.map((profile,index) => {
      return (
        <div key={index}>
          <span className="badge bg-primary">โปรไฟล์ {profile.customer_id} </span>
          &nbsp;&nbsp;
          ชื่อ-นามสกุล: {profile.customer_name} {profile.customer_lastname}
          ({profile.customer_mobile})
          &nbsp;&nbsp;
          <i className="icon-clock"></i> {moment(profile.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}<br/>
          โปรไฟล์ : {profile.profile_name}  | จาก {profile.profile_from} <br/>

           <CustomerCarBook
             customer_id = {profile.customer_id}
           />

        </div>
      )
    });

    let customerDocList = this.state.customer_docs.map((customer_doc,index) => {
      return (
        <p key={index}>
          <span className="badge bg-danger">เอกสาร</span>
          &nbsp;&nbsp;
          ชื่อ-นามสกุล: {customer_doc.customer_name} {customer_doc.customer_lastname}
          ({customer_doc.customer_mobile})
          &nbsp;&nbsp;
          <i className="icon-clock"></i> {moment(customer_doc.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}<br/>
          {customer_doc.doc_detail}
        </p>
      )
    });
    return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {profileList}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {customerDocList}
        </div>
      </div>
    </div>
    )
  }
}

/* การจองของลูกค้ารายนั้นๆ */
class CustomerCarBook extends Component{
  constructor (props) {
    super(props)
    this.state = {
      car_book_list: []
    }

    this.loadCustomerCarBook = this.loadCustomerCarBook.bind(this)
  }

  componentDidMount () {
    var customer_id = this.props.customer_id
    this.loadCustomerCarBook(customer_id);
  }

  /*componentWillReceiveProps(nextProps) {
    var customer_id = nextProps.customer_id
    if(customer_id === ''){
      this.setState({
        car_book_list: []
      });
    } else if(nextProps.customer_id === this.props.customer_id) {
      this.loadCustomerCarBook(nextProps.customer_id)
    }
  }*/

  loadCustomerCarBook(customer_id){
    var url = APIURL+'/car_book/customer/'+customer_id
    axios.get(url)
      .then(res => {
        this.setState({car_book_list: res.data})

      }).catch(err => {
      this.setState({car_book_list: []})
    })
  }

  render() {

    let carBookList = this.state.car_book_list.map((car_book, index) => {
      return (
        <div key={index}>
          <button className="btn-outline-success"
           onClick={()=>{
             var urlOpen = WEBURL+'#/cars/book/?car_id='+car_book.car_id
             window.open(urlOpen, "_blank")
           }}
          > ดูรายละเอียด </button>
          &nbsp;&nbsp;
          {car_book.car_name} &nbsp; ทะเบียน {car_book.car_license_plate_new } &nbsp;&nbsp;
          <i className="icon-clock"></i> {moment(car_book.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}<br/>
        </div>
      )
    })

    return (
      <div style={{marginLeft: 32, marginBottom: 16}}>
        {carBookList}
      </div>
    )
  }

}


const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ProfileBySale)