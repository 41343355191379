import React, { Component } from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, TabPane} from 'reactstrap';
import {APIURL, IMAGE_FULL_URL, PORT} from '../../../config/config';
import PreviewImage from '../../../views/PreviewImage/';
import * as moment from 'moment';
import 'moment/locale/th';
// import { checkGroupPermission } from '../../../services/grouppermission'
import ReactToPrint, {PrintContextConsumer} from "react-to-print";
import axios from "axios";
import { checkGroupPermission, getGroupPermission } from '../../../services/grouppermission'
import CarDocAdd from "../CarDocAdd";
import {connect} from "react-redux";
import CarDocAddMulti from "../CarDocAddMulti/CarDocAddMulti";

class CarDocList extends Component {

  constructor (props){
    super(props)
    this.state = {
      preview_image_url: '',
      preview_image_select: false,
      lightboxState: true,
      preview_ligthbox: false,
      car_doc_list:[],
      toggle_delete_car_doc:false,
      car_doc_delete:null,
      toggle_car_doc: false,
      user: {},
    }
    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.onPreviewLightbox = this.onPreviewLightbox.bind(this)
    this.handlePrintImage = this.handlePrintImage.bind(this)
    this.loadCarDocData = this.loadCarDocData.bind(this)
    this.deleteCarDoc = this.deleteCarDoc.bind(this)
    this.toggleDeleteCarDoc = this.toggleDeleteCarDoc.bind(this)
    this.deleteCarDocConfirm = this.deleteCarDocConfirm.bind(this)
    this.addCarDoc = this.addCarDoc.bind(this)
    this.toggleCarDocAdd = this.toggleCarDocAdd.bind(this)
    this.getDocTypeName = this.getDocTypeName.bind(this)
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount() {
    this.loadCarDocData()
  }

  loadCarDocData(){
    let car_id = this.props.car_id
    let car_doc_type = this.props.car_doc_type
    axios.get(APIURL + '/car_doc/car/'+car_id+'/'+car_doc_type)
        .then(res => {
          this.setState({car_doc_list: res.data })
        }).catch(error => {
      this.setState({car_doc_list: []})
    })
  }

  onCarDocDelete(car_doc){
     this.deleteCarDocConfirm(car_doc)
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

    onPreviewLightbox(url,e){
      // console.log(url)
        let docId = e.target.name
        this.setState({
            preview_image_url: url,
            preview_ligthbox: true
        }, () => {
        });

    }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  handlePrintImage(url, handlePrint) {
    this.setState({
      preview_image_url: url
    },() => handlePrint());
  }

  deleteCarDoc(car_doc){
    //alert("deleteCarDoc="+car_doc.car_doc_id)
    this.setState({
      car_doc_delete: car_doc
    },()=>{
      this.toggleDeleteCarDoc()
    })
  }

  toggleDeleteCarDoc(){
    this.setState({
      toggle_delete_car_doc: !this.state.toggle_delete_car_doc
    });
  }

  deleteCarDocConfirm(car_doc) {
    axios.put(APIURL + '/car_doc/delete/'+car_doc.car_doc_id,{
      'car_doc_file': car_doc.car_doc_file,
      'car_doc_key': car_doc.car_doc_key,
    })
        .then(res=>{
          if (res.status === 200) {
            this.loadCarDocData()
            this.toggleDeleteCarDoc()
          }
        })
  }

  toggleCarDocAdd(){
    this.setState({
      toggle_car_doc: !this.state.toggle_car_doc
    });
  }

  addCarDoc(event){
    event.preventDefault();
    // var that = this ;
    // let car_id = event.target.car_id.value
    let car_id = this.props.car_id
    let car_doc_name = event.target.car_doc_name.value
    let car_doc_type = this.props.car_doc_type

    // console.log('car_id=', car_id)
    // console.log('car_doc_name=', car_doc_name)
    // console.log('car_doc_type=', car_doc_type)
    // return

    const upload_type = 'single'
    const data = new FormData();
    data.append('file', event.target.car_doc_file.files[0], event.target.car_doc_file.files[0].name);
    data.append('car_id', car_id);
    data.append('car_doc_name', car_doc_name);
    data.append('car_doc_type', car_doc_type);
    data.append('user_created', this.state.user.user);
    data.append('upload_type', upload_type);

    axios.post(APIURL+'/car_doc2',data)
        .then( res => {
          this.loadCarDocData()
          this.toggleCarDocAdd()
        })

  }

  getDocTypeName() {
    let car_doc_type = this.props.car_doc_type
    if(car_doc_type === 'normal') {
      return 'เอกสารรถ'
    } else if (car_doc_type === 'secret') {
      return 'เอกสารลับ'
    } else if (car_doc_type === 'sell_doc') {
      return 'เอกสารขาย'
    }
  }

  render(){

    let that = this;
    let car_doc_list = this.state.car_doc_list.map((car_doc,index)=>{

      let car_doc_url = ''

      if (car_doc.car_doc_file.includes('https')) {
        car_doc_url = car_doc.car_doc_file
      } else {
        car_doc_url =  IMAGE_FULL_URL + car_doc.car_doc_file
      }

      let docType = car_doc_url.indexOf('.pdf') !== -1 ? 'pdf' : 'image'

      return(
        <tr key={index} >
          <td className="text-center">{index+1}</td>
          <td className="text-center">
            {
              docType === 'pdf' ?
                <a href={car_doc_url} target='_blank' rel="noopener noreferrer">เอกสารรถ(pdf)</a>

                  :
                  <img
                  id={'doc'+car_doc.car_doc_id}
                  name={'doc'+car_doc.car_doc_id}
                  src={car_doc_url}
                  style={{ 'cursor': 'pointer', 'width': '120px', 'height': 'auto' }}
                  onClick={(e) => this.onPreviewSelected(car_doc_url)}
                  alt="เอกสาร"
                />

            }
          </td>
          <td className="text-center">{car_doc.car_doc_name}</td>
          <td className="text-center">
            {car_doc.fullname}<br/>
            {moment(car_doc.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
            </td>
          <td className="text-center">

            {
              checkGroupPermission(this.props.menuID, this.props.grouppermissions).deleted ? (
                <button className="btn btn-sm btn-danger btn-md-width"
                        onClick={()=>this.deleteCarDoc(car_doc)}
                >
                  <i className="fa fa-remove"/> ลบ
                </button>
              ) : '-'
            }

            <br/>

            {
              docType === 'pdf' ?
                 null
                  :

                  <ReactToPrint
                      content={() => this.componentRef}
                  >
                    <PrintContextConsumer>
                      {({ handlePrint }) => (
                          <button className="btn btn-sm btn-primary btn-md-width mt-2"
                                  onClick={()=> this.handlePrintImage(car_doc_url, handlePrint)}>
                            <i className="fa fa-print"/> พิมพ์
                          </button>
                      )}
                    </PrintContextConsumer>
                  </ReactToPrint>
            }

          </td>
        </tr>
      )
    })

    return(
        <div className="row">

          <Modal isOpen={this.state.toggle_car_doc}
                 toggle={this.toggleCarDocAdd}
                 className={'modal-lg '}
                 style={this.props.width<=980 ?{maxWidth:800}:{maxWidth:1200}}>
            <ModalBody>

              {
                PORT === 9043 ?
                    <>
                      <CarDocAddMulti
                          onToggle={this.toggleCarDocAdd}
                          onReload={this.loadCarDocData}
                          onCarDocSubmit={
                            {
                              cancel: this.toggleCarDocAdd
                            }
                          }
                          car={this.props.car}
                          car_doc_type={this.props.car_doc_type}
                          username={this.state.user.user}
                      />
                    </>
                    :
                    <CarDocAdd
                        onToggle={this.toggleCarDocAdd}
                        onCarDocSubmit={
                          {
                            add: this.addCarDoc.bind(this),
                            cancel: this.toggleCarDocAdd
                          }
                        }
                        car={this.props.car}
                        car_doc_type={this.props.car_doc_type}
                    />
              }

            </ModalBody>
          </Modal>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong className="text-title">
                  {this.getDocTypeName()}
                  </strong>
                {
                  checkGroupPermission(this.props.menuID, this.props.grouppermissions).created ? (
                      <button className="btn btn-info float-right"  onClick={this.toggleCarDocAdd} >
                        <i className="icon-plus"/>&nbsp; เพิ่มเอกสาร
                      </button>
                  ) : null
                }
              </div>
              <div className="card-block">
                 <div>

              <Modal isOpen={this.state.toggle_delete_car_doc} toggle={this.toggleDeleteCarDoc.bind(this)}
                     className={'modal-danger '}>
                <ModalHeader toggle={this.toggleDeleteCarDoc.bind(this)}>ลบเอกสารรถ</ModalHeader>
                <ModalBody>
                  <strong> คุณต้องการลบข้อมูล ใช่หรือไม่ ?? </strong>
                  <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
                </ModalBody>
                <ModalFooter>
                  <Button color="primary"
                          onClick={this.deleteCarDocConfirm.bind(this, this.state.car_doc_delete)}>ตกลง</Button>{' '}
                  <Button color="secondary" onClick={this.toggleDeleteCarDoc.bind(this)}>ยกเลิก</Button>

                </ModalFooter>
              </Modal>

              <div style={{ display: "none" }}>
                <PreviewImage
                    imageUrl={this.state.preview_image_url} ref={el => (this.componentRef = el)}
                />
              </div>


                <table className={(this.state.car_doc_list.length>0)?'table table-striped ' : 'table table-striped hidden'}>
                <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">ไฟล์เอกสาร</th>
                  <th className="text-center">ชื่อเอกสาร</th>
                  <th className="text-center">ผู้ทำรายการ</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                 {car_doc_list}
                </tbody>
              </table>

              <div className={this.state.car_doc_list.length===0?' text-center  ' : 'hidden'} >
                <strong className="text-danger">ไม่พบรายการเอกสารรถ</strong>
              </div>

              <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                <ModalBody>
                  <PreviewImage
                    imageUrl={this.state.preview_image_url}
                  />
                </ModalBody>
              </Modal>

            </div>
              </div>
            </div>
          </div>
        </div>
          )
  }

}



const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarDocList);
