import React, { Component } from 'react'
import { Progress } from 'reactstrap'
import { APIURL } from '../../config/config'
import CalendarList from '../../components/Calendar/CalendarList/CalendarList'
import ProfileBySale from '../../components/Profile/ProfileBySale/ProfileBySale'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

var elements = 27
var data1 = []
var data2 = []
var data3 = []

for (var i = 0; i <= elements; i++) {
  data1.push(random(50, 200))
  data2.push(random(80, 100))
  data3.push(65)
}

class Dashboard2 extends Component {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      car_status_lists: [],
      car_pay_list: [],
      expense_list: [],
      car_book_count: 0,
      car_sell_count: 0,
      car_pay_sum: 0,
      expense_sum: 0,
      car_repair_sum: 0,
      report_by: 'today',
      dropdownOpen: false,
      token: localStorage.getItem('token'),
      user: {},
      user_type: '',
      // profile_lists: [],
      // customer_docs: [],
      accountSale: [],
      selectSale: {
        id: '',
        user: ''
      },
    }
    this.onSelectSale = this.onSelectSale.bind(this)
    this.onSelectSaleHidden = this.onSelectSaleHidden.bind(this)
    this.hiddenAlert  = this.hiddenAlert.bind(this)
    this.loadCarAll   = this.loadCarAll.bind(this)
  }

  toggle () {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  componentDidMount () {

    var user = JSON.parse(this.props.user);
    // 9005 pisan กำหนด admin sale
     if(user.type !== 'admin' && user.type !== 'sale'){
      this.props.history.push('/cars/list');
    }
    // var user = JSON.parse(this.props.user);
    this.setState({
      user: user,
      user_type : user.type
    }, ()=> {
      // this.getReportDataBy('today')
      this.loadCarAll();
    });

    this.loadCustomerProfile();
    this.loadCustomerDoc();
    this.loadAccountSale();
  }

  loadCarAll() {
    var url = APIURL+'/report/car_status_all/'
    axios.get(url).then(res => {
      var carData = res.data
      // console.log(carData)
      this.setState({car_status_lists: carData});
    })
  }

  loadReportCarStatus() {
    axios.get(APIURL + '/report/car_status/' + this.state.report_by)
      .then(res => {
        this.setState({car_status_lists: res.data}, () => {
          // eslint-disable-next-line
          this.state.car_status_lists.map((car_status) => {
            if (car_status.car_status_id === 7) {//จอง
              this.setState({
                car_book_count: car_status.car_count
              })
            } else if (car_status.car_status_id === 9) {//ขายแล้ว
              this.setState({
                car_sell_count: car_status.car_count
              })
            }
          })
        })
      }).catch(error => {
      this.setState({car_status_lists: []})
    })
  }

  loadReportCarSellPay() {
    axios.get(APIURL + '/report/car_sell_pay/' + this.state.report_by)
      .then(res => {
        this.setState({car_pay_list: res.data}, () => {
          if (this.state.car_pay_list.length > 0) {
            var car_pay_sum = this.state.car_pay_list.map(car_pay => car_pay.car_sell_sum).reduce((prev, car_sell_sum) => prev + car_sell_sum)
            this.setState({car_pay_sum: car_pay_sum})
          }else{
            this.setState({car_pay_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({car_pay_list: []})
    })
  }

  loadReportExpense () {
    axios.get(APIURL + '/report/expense/' + this.state.report_by)
      .then(res => {
        this.setState({expense_list: res.data}, () => {
          if (this.state.expense_list.length > 0) {
            var sum = this.state.expense_list.map(expense => expense.expense_sum).reduce((prev, expense_sum) => prev + expense_sum)
            this.setState({expense_sum: sum})
          }else{
            this.setState({expense_sum: 0})
          }
        })
      }).catch(error => {
        this.setState({expense_list: []})
    })
  }

  //car_repair_sum
  loadReportCarRepairSum () {
    axios.get(APIURL + '/report/car_repair/' + this.state.report_by)
      .then(res => {
        this.setState({car_repair_list: res.data}, () => {
          if (this.state.car_repair_list.length > 0) {

            var sum = this.state.car_repair_list.map(car_repair => car_repair.car_repair_sum).reduce((prev, car_repair_sum) => prev + car_repair_sum)

            this.setState({car_repair_sum: sum})
          }else{
            this.setState({car_repair_sum: 0})
          }
        })
      }).catch(error => {
      this.setState({car_repair_list: []})
    })
  }

  getReportByName () {
    var by = this.state.report_by
    if (by === 'today') {
      return 'ข้อมูลวันนี้'
    } else if (by === '7day') {
      return 'ข้อมูล 7 วัน'
    } else if (by === 'month') {
      return 'ข้อมูล 30 วัน'
    } else if (by === '3month') {
      return 'ข้อมูล 3 เดือน'
    }
  }

  getReportDataBy (by) {
    this.setState({report_by: by}, () => {
      this.loadReportCarStatus()
      this.loadReportCarSellPay()
      this.loadReportExpense()
      this.loadReportCarRepairSum()
    })
  }

  loadCustomerProfile() {
    axios.get(APIURL + '/profile/')
      .then(res => {
        this.setState({profile_lists: res.data})
      }).catch(error => {
      this.setState({profile_lists: []})
    })
  }

  loadCustomerDoc() {
    // customer_docs
    axios.get(APIURL+'/customer_doc')
      .then((res) => {
        //console.log('====loadCustomerDoc')
        //console.log(res.data)
        this.setState({customer_docs: res.data})
        //that.loadCarDocData()
      })
  }

  loadAccountSale() {
    var userType = 'sale';
    axios.get(APIURL+'/account/type/'+userType)
    .then((res) => {
       //console.log('===loadAccountSale')
       //console.log(res.data)
       this.setState({accountSale: res.data})
    })
  }

  onSelectSale(sale) {
    // console.log('===onSelectSale')
    // console.log(sale.user)
    this.setState({
      selectSale: {
        id: '',
        user: ''
      }
    },()=>{
      this.setState({
        selectSale: sale
      },()=>{
        this.hiddenAlert(sale);
      });
    });

  }

  onSelectSaleHidden() {
    this.setState({
      selectSale: {
        id: '',
        user: ''
      }
    });
  }

  hiddenAlert(sale) {
    var url = APIURL+'/customer_profile_update/hidden/'+sale.user
    axios.put(url)
      .then((res) => {
        var accountSaleExist = this.state.accountSale;
        const updateAccountSale = accountSaleExist.map(account => account.id===sale.id ? {...account, count_update: 0  } : account )
        this.setState({
          accountSale: updateAccountSale
        });
      })
  }

  render () {

    var sumCar = this.state.car_status_lists.reduce((sum, carCount) =>  sum + carCount.car_count , 0)

    var car_status_li = this.state.car_status_lists.map((car_status, index) => {
      return (
        <li key={index}>
          <i className="icon-arrow-right"></i>
          <span className="title">{car_status.car_status_name}</span>
          <span className="value">
              <span className="badge"
                    style={{backgroundColor: car_status.car_status_color}}>{car_status.car_count}</span>
            &nbsp;&nbsp;&nbsp;
              <span className="text-muted small">
                   <NumberFormat
                     fixedDecimalScale={true} decimalScale={0}
                     value={car_status.car_count / sumCar * 100}
                     displayType={'text'} thousandSeparator={true}
                   />%
              </span>
            </span>
          <div className="bars">
            <Progress className="progress-xs"
                      value={ car_status.car_count / sumCar * 100}/>
          </div>
        </li>
      )
    })

    // this.state.car_status_lists.length 4b8b3b

    var car_status_all = (
      <li>
        <i className="icon-arrow-right"></i>
        <span className="title">รวมทังหมด</span>
        <span className="value">
              <span className="badge"
                    style={{backgroundColor: '#4b8b3b'}}>{sumCar}</span>
              &nbsp;&nbsp;&nbsp;
              <span className="text-muted small">
                 <NumberFormat
                   fixedDecimalScale={true} decimalScale={0}
                   value={100}
                   displayType={'text'}
                   thousandSeparator={true}/>%
              </span>
            </span>
        <div className="bars">
          <Progress className="progress-xs"
                    value={100}/>
        </div>
      </li>
    )


    var car_pay_li = this.state.car_pay_list.map((car_pay, index) => {
      return (
        <div className="row p-1" key={index}>
          <div className="col-md-3">
            <i className="icon-arrow-right"></i> <strong>{car_pay.car_sell_type_name}</strong>

          </div>
          <div className="col-md-4 text-right" style={styles.textGreen}>
            <strong><NumberFormat fixedDecimalScale={true} decimalScale={0}
                                  value={car_pay.car_sell_sum} displayType={'text'}
                                  thousandSeparator={true}/></strong>
          </div>
        </div>
      )
    })

    var expense_li = this.state.expense_list.map((expense, index) => {
      return (
        <div className="row p-1" key={index}>
          <div className="col-md-5">
            <i className="icon-arrow-right"></i> <strong>{expense.expense_type_name}</strong>

          </div>
          <div className="col-md-4 text-right" style={styles.textRed}>
            <strong><NumberFormat fixedDecimalScale={true} decimalScale={0}
                                  value={expense.expense_sum} displayType={'text'}
                                  thousandSeparator={true}/></strong>
          </div>
        </div>
      )
    })

    var expense_all_sum = this.state.expense_sum + this.state.car_repair_sum

    let accountSale = this.state.accountSale.map((sale, index) => {

      return (
        <div key={index} className={this.state.user.type === 'admin' ? 'row' : 'hidden' }>
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-6 d-inline">
                    <h6 className="d-inline">
                      <strong> {sale.fullname} ({sale.mobile}) </strong>
                      &nbsp;&nbsp;
                    </h6>
                    <span className={ sale.count_update===0 ? 'hidden' : 'd-inline'}>
                        <CustomBlinkingTxt text={sale.count_update} />
                    </span>
                  </div>
                  <div className="col-md-6 text-right">
                    <span style={{cursor: "pointer"}} className={this.state.selectSale.id !== sale.id? 'text-success' : 'hidden'}  onClick={() => this.onSelectSale(sale)}>แสดง</span>
                    <span style={{cursor: "pointer"}} className={this.state.selectSale.id === sale.id? 'text-danger'  : 'hidden'}  onClick={() => this.onSelectSaleHidden()}>ซ่อน</span>
                  </div>
                </div>

                <ProfileBySale

                  saleId={sale.id}
                  sale={this.state.selectSale}

                />

              </div>
            </div>
          </div>
        </div>
      )
    });

    //car.car_status_id===7
    var reserve_status = [7, 10, 12]
    var carReserve = this.state.car_status_lists.filter(car => reserve_status.includes(car.car_status_id) ).map((car, index) => {
      return car
    })

    //console.log(carReserve)
    var sumReserve = 0
    // eslint-disable-next-line
    carReserve.map(car => {
      sumReserve += car.car_count;
    })


    return (
      <div className="animated fadeIn">

        {accountSale}

        {/*<div className={this.state.user.type === 'admin' ? 'hidden' : 'hidden' }>
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-12">
                    <h6><strong> โปรไฟล์/เอกสาร ลูกค้า </strong></h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {profileList}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {customerDocList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-6">
                    <h6><strong>ข้อมูลรถ</strong></h6>
                  </div>
                  <div className="col-md-6 text-right">
                    <div className="btn-group hidden" role="group" aria-label="tap">
                      <button type="button"
                              className={this.state.report_by === 'today' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('today')
                              }}
                      >วันนี้
                      </button>
                      <button type="button"
                              className={this.state.report_by === '7day' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('7day')
                              }}
                      >7 วัน
                      </button>
                      <button type="button"
                              className={this.state.report_by === 'month' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('month')
                              }}
                      >30 วัน
                      </button>
                      <button type="button"
                              className={this.state.report_by === '3month' ? 'btn btn-secondary btn-sm active' : 'btn btn-secondary btn-sm'}
                              style={{width: 64}}
                              onClick={() => {
                                this.getReportDataBy('3month')
                              }}
                      >3 เดือน
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginTop: 20}}>
                  <div className="col-md-4">
                    <strong>จำนวนรถตามสถานะ</strong>
                    <ul className="horizontal-bars type-2">
                      {car_status_li}
                      {car_status_all}
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <strong>ยอดจอง(คัน) [ติดจอง + อยู่ระหว่างจัดไฟแนนซ์ + ไฟแนนซ์อนุมัติ]</strong>
                    <div className="callout callout-warning">
                      <small className="text-muted">จอง</small>
                      <br/>
                      <strong className="h4">
                        <NumberFormat
                          value={ carReserve.length > 0 ? sumReserve : 0}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      </strong>
                      <div className="chart-wrapper">
                        <canvas id="sparkline-chart-3" width="100" height="30"></canvas>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 hidden">
                    <strong>ยอดขาย(คัน)</strong>
                    <div className="callout callout-success">
                      <small className="text-muted">ขาย</small>
                      <br/>
                      <strong className="h4">
                        <NumberFormat value={this.state.car_sell_count} displayType={'text'}
                                      thousandSeparator={true}/>
                      </strong>
                      <div className="chart-wrapper">
                        <canvas id="sparkline-chart-3" width="100" height="30"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.user.type === 'admin' ? 'row' : 'hidden' }>
          <div className="col-md-12 hidden">
            <div className="card">
              <div className="card-block">
                <div className="row">
                  <div className="col-md-12">
                    <h6><strong>{this.getReportByName()}</strong></h6>
                  </div>
                </div>

                <br/>

                <div className="row">
                  <div className="col-md-6">
                    <h6 style={styles.textGreen}><strong>รายรับ</strong></h6>
                    {this.state.car_pay_list.length > 0 ? car_pay_li : ' -'}

                    <div className={this.state.car_pay_sum > 0 ? 'row p-1' : 'hidden' }>
                      <div className="col-md-3">
                        <i className="icon-arrow-right"></i> <strong>รวม</strong>
                      </div>
                      <div className="col-md-4 text-right" style={styles.textGreen}>
                        <strong><u><NumberFormat fixedDecimalScale={true} decimalScale={0}
                                                 value={this.state.car_pay_sum} displayType={'text'}
                                                 thousandSeparator={true}/></u></strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h6 style={styles.textRed}><strong>รายจ่าย</strong></h6>

                    {this.state.expense_list.length > 0 ? expense_li : ' -'}


                    <div className={this.state.car_repair_sum > 0 ? 'row p-1' : 'hidden' }>
                      <div className="col-md-5">
                        <i className="icon-arrow-right"></i> <strong>ค่าซ่อมรถ</strong>

                      </div>
                      <div className="col-md-4 text-right" style={styles.textRed}>
                        <strong><NumberFormat fixedDecimalScale={true} decimalScale={0}
                                              value={this.state.car_repair_sum} displayType={'text'}
                                              thousandSeparator={true}/></strong>
                      </div>
                    </div>

                    <div className={ (this.state.expense_sum ===0 && this.state.car_repair_sum === 0) ? 'hidden' : 'row p-1' }>
                      <div className="col-md-5">
                        <i className="icon-arrow-right"></i> <strong>รวม</strong>

                      </div>
                      <div className="col-md-4 text-right" style={styles.textRed}>
                        <strong>
                          <u>
                          <NumberFormat
                            fixedDecimalScale={true} decimalScale={0}
                            value={expense_all_sum}  displayType={'text'}
                            thousandSeparator={true}
                          />
                          </u>
                        </strong>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CalendarList />

      </div>
    )
  }
}

const styles = {
  textGreen: {
    color: '#24933c'
  },
  textRed: {
    color: '#ff0000'
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

class CustomBlinkingTxt extends Component {
  constructor(props) {
    super(props);
    this.state = { showText: true };
  }
  componentDidMount() {
    this.myInterval = setInterval(this.onBlinkText.bind(this),1200);
  }

  onBlinkText(){
    this.setState(previousState => {
      return { showText: !previousState.showText };
    });
  }

  render() {
    let display = this.state.showText ? this.props.text : '';
    return (
      <span className="badge badge-danger" style={{padding: 3}}>{display}</span>
    );
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
}

export default connect(mapStateToProps)(Dashboard2)
