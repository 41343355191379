import React, { Component } from 'react';
import Loading from '../../Loading';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { APIURL } from '../../../config/config';
import * as moment from 'moment';
import { MonthUtil } from '../../../utils/monthUtil'
import { NumberUtil } from '../../../utils/number-util'
import { format } from 'date-fns'
import { AlertSuccess } from '../../Alert/Alert'

class CarHistory extends Component {
  constructor (props) {
    super(props);
    this.printRef = React.createRef();

    this.state = {
      car_history_id: 0,
      car_id: '',
      isLoading: false,
      car: {},
      car_image: '',
      owner_order: 'มือเดียวออกห้าง',
      owner_order_number: '',
      car_options: '',
      car_entered_center: '',
      car_entered_center_miles: '',
      car_tire_brand: '',
      car_tire_year: '',
      car_tire_size: '',
      car_tire_condition: '', // สภาพ
      car_tire_status: 'พร้อมใช้งาน', // พร้อมใช้งาน / ต้องเปลี่ยนใหม่
      car_accident: 'ไม่มีเลย', // ไม่มีเลย , มีเล็กน้อย , มีมาก
      car_overall_condition: '5',
      car_center_name: '', // ศูนย์ออกรถ
      car_center_branch: '', // สาขา
      car_center_price: '940,000', // ราคาออกห้่าง
      car_center_insurance: '', // ประกันศูนย์ มี ไม่มี insurance_company_id
      insurance_end_date: '', // วันที่สิ้นสุดประกัน
      car_manual: '',  // คู่มือ มี ไม่มี car_manual
      car_spare_key: '',  // คู่มือ มี ไม่มี
      // อุปกรณ์ตกแต่งเพิ่มเติม
      add_on: '',
      add_on_value: '',
      other1: '',
      other1_value: '',
      other2: '',
      other2_value: '',
      note: '',
      carBase64Img: ''
    }

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleDownloadImage = this.handleDownloadImage.bind(this);
    this.loadCarById = this.loadCarById.bind(this);
    this.setDataToForm = this.setDataToForm.bind(this);
    this.getCarLicense = this.getCarLicense.bind(this);
    this.loadCarHistory = this.loadCarHistory.bind(this);
    this.saveCarHistory = this.saveCarHistory.bind(this);
    this.makeCarImgBase64 = this.makeCarImgBase64.bind(this);
    this.loadCarImageBase64 = this.loadCarImageBase64.bind(this);

  }

  componentWillMount () {
    const queryString = require('query-string');
    let car = queryString.parse(this.props.location.search);
    let car_id = car.car_id
    this.setState({
      car_id: car_id
    })
  }

  async componentDidMount() {
   await this.loadCarById()
   await this.loadCarHistory()
  }

  async loadCarHistory() {
    const { car_id } = this.state
    this.setState({
      isLoading: true
    })
    await axios.get(APIURL+'/car_history/car/'+car_id).then(res => {

      let data = res.data
      if (data.length === 1) {

        let car_history = data[0]
        let car_history_id = car_history.car_history_id
        let car_history_detail_str = car_history.car_history_detail
        let car_history_detail = JSON.parse(car_history_detail_str)

        this.setState({
          isLoading: false,
          car_history_id: car_history_id,
          owner_order: car_history_detail.owner_order,
          owner_order_number: car_history_detail.owner_order_number,
          car_options: car_history_detail.car_options,
          car_entered_center: car_history_detail.car_entered_center,
          car_entered_center_miles: car_history_detail.car_entered_center_miles,
          car_tire_brand: car_history_detail.car_tire_brand,
          car_tire_year: car_history_detail.car_tire_year,
          car_tire_size: car_history_detail.car_tire_size,
          car_tire_condition: car_history_detail.car_tire_condition,
          car_tire_status: car_history_detail.car_tire_status,
          car_accident: car_history_detail.car_accident,
          car_overall_condition: car_history_detail.car_overall_condition,
          car_center_name: car_history_detail.car_center_name,
          car_center_branch: car_history_detail.car_center_branch,
          car_center_price: car_history_detail.car_center_price,
          car_center_insurance: car_history_detail.car_center_insurance,
          insurance_end_date: car_history_detail.insurance_end_date,
          add_on: car_history_detail.add_on,
          add_on_value: car_history_detail.add_on_value,
          other1: car_history_detail.other1,
          other1_value: car_history_detail.other1_value,
          other2: car_history_detail.other2,
          other2_value: car_history_detail.other2_value,
          note: car_history_detail.note,
        })
      } else {
        this.setState({
          isLoading: false
        })
      }
    }).catch(err => {
      console.log('err:', err)
    })
  }

  saveCarHistory(action) {
    const {
      car,
      car_image,
      owner_order,
      owner_order_number,
      car_options,
      car_entered_center,
      car_entered_center_miles,
      car_tire_brand,
      car_tire_year,
      car_tire_size,
      car_tire_condition,
      car_tire_status,
      car_accident,
      car_overall_condition,
      car_center_name,
      car_center_branch,
      car_center_price,
      car_center_insurance,
      insurance_end_date ,
      car_manual,
      car_spare_key,
      add_on ,
      add_on_value,
      other1,
      other1_value,
      other2 ,
      other2_value ,
      note
    } = this.state

    let car_id = car.car_id

    let reqData = {
      car_id,
      owner_order,
      owner_order_number,
      car_options,
      car_entered_center,
      car_entered_center_miles,
      car_tire_brand,
      car_tire_year,
      car_tire_size,
      car_tire_condition,
      car_tire_status,
      car_accident,
      car_overall_condition,
      car_center_name,
      car_center_branch,
      car_center_price,
      car_center_insurance,
      add_on ,
      add_on_value,
      other1,
      other1_value,
      other2 ,
      other2_value ,
      note
    }

    // '/api/v1/car_history'

    this.setState({
      isLoading: true
    }, () => {

      if (action === 'save') {
        axios.post(APIURL+'/car_history', reqData).then(res => {
          let data = res.data
          let car_history_id = data.car_history_id

          this.setState({
            car_history_id,
            isLoading: false
          })
          AlertSuccess('บันทึกประวัติรถเรียบร้อยแล้ว')

        }).catch(err => {
          console.log('err: ', err)
        })
      } else if (action === 'edit') {
        let car_history_id = this.state.car_history_id
        // let dataUpdate = {...reqData, }
        axios.put(APIURL+'/car_history/'+ car_history_id, reqData).then(res => {
          this.setState({
            isLoading: false
          })
          AlertSuccess('แก้ไขประวัติรถเรียบร้อยแล้ว')
        })
      }



    })



  }

  async handleDownloadImage () {

    const {car} = this.state
    let car_license = this.getCarLicense(car)

    const element = this.printRef.current;

    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      let file_name = 'ประวัติรถ_'+car_license+'.jpg'
      link.href = data;
      // link.download = 'ประวัติรถ.jpg';
      link.download = file_name;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  }

  handleOnChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value
    })
  }

  async loadCarById(){
    await axios.get(APIURL + '/car/'+this.state.car_id)
      .then(res => {
        let car = res.data[0]
        this.setState({
          car: car,
        }, async () => {
           this.setDataToForm()
           await this.makeCarImgBase64()
        })
      }).catch(error => {
      // alert("error"+error)
      this.setState({car: {}})
    })
  }

  async makeCarImgBase64() {
    try {
      let car = this.state.car
      await this.loadCarImageBase64(car.car_upload_id)
    } catch (e) {
      // console.log('----makeCarImgBase64 error')
      console.log(e)
    }
  }

  async loadCarImageBase64(car_upload_id) {
    let uri =  APIURL + '/car/image/' + car_upload_id;
    await axios.get(uri)
      .then(res => {
        let body = res.data.body
        this.setState({ carBase64Img: body })
      }).catch(error => {
        console.log('error:', error)
      })
  }

  setDataToForm() {
    const { car } = this.state
    // car_center_insurance: car.insurance_company_id !== 0 && car.insurance_company_id !== 99 ? 'มี' : 'ไม่มี',
    let insurance_end_date = car.insurance_end_date === '0000-00-00' || car.insurance_end_date === null ? '-' : format(new Date(car.insurance_end_date), 'dd/MM/yyyy')

    this.setState({
      car_image: car.car_upload_filename,
      car_manual: car.car_manual === '' ? 'ไม่มี' :  car.car_manual,
      car_spare_key: car.car_spare_key === '' ? 'ไม่มี' :  car.car_spare_key,
      insurance_end_date: insurance_end_date,
    })

  }

  getSubModelName(car_model_name, car_sub_model_name) {
    let new_name = car_sub_model_name.replace(car_model_name, '')
    return new_name.trim()
  }

  getDateRegis(car) {
    if (Object.keys(car).length === 0) {
      return '-'
    }

    if (car.car_date_regis === '0000-00-00') {
      return '-'
    } else {
      let car_date_regis_cc = moment(car.car_date_regis).locale('th').format('LL')
      let arr = car_date_regis_cc.split(' ')
      let dd = arr[0]
      let month_thai_abb = MonthUtil.convertMonthThaiToAbb(arr[1])
      let year_th = Number(arr[2]) + 543
      // Invalid NaN
      return dd+' '+month_thai_abb+' '+year_th
    }

  }

  getCarLicense(car) {
    // && car.car_license_plate_old !== '' && car.car_license_plate_new !== car.car_license_plate_old
    return car.car_license_plate_new !== '' ? car.car_license_plate_new : car.car_license_plate_old
  }

  getCarMileAverage(car) {

    if (car.car_miles == undefined) {
      return '-'
    }

    let car_year = Number(car.car_year_name)
    let current_year = format(new Date(), 'yyyy')
    let car_miles = 0

    if (isNaN(car.car_miles)) {
      if (car.car_miles=== '' || car.car_miles === null) {
        return '-'
      } else {
         // .replace(/_/g, "-");
        let car_miles_tmp = car.car_miles
        let arr = car_miles_tmp.split(',')

        if (arr.length === 2) {
          // let num = isNaN(arr[0]) ? arr[0] : Number(arr[0])
          // let xxx = isNaN(arr[1]) ? arr[1] : arr[1]

          if (!isNaN(arr[0]) && isNaN(arr[1])) {
            let num = Number(arr[0])
            let xxx = arr[1]
            let avg = parseInt(num/(current_year-car_year), 10)
            return String(avg+','+xxx)
          }

        }


        return '-'
      }
    } else {
      car_miles = Number(car.car_miles)
    }

    return NumberUtil.addCommas(String(parseInt(car_miles/(current_year-car_year), 10)))

  }

  getCarOverallCondition(car_overall_condition) {
    if (car_overall_condition === '') {
     return ''
    }

    let length = Number(car_overall_condition)

    // <img src="../img/star.png" style={{width: 19, marginLeft: 235, marginTop: 2}} />
    let overall = []
    // let marginLeft = 235
    for (let i=1; i <= length; i++) {
      let yPosition ;
      if (i === 1) {
        yPosition = 235
      } else {
        yPosition = 20
      }
      let image =  <img key={i} src="../img/star.png" style={{width: 19, marginLeft: yPosition, marginTop: 2}} />
      overall.push(image)
    }

    return overall
  }

  render () {
    const { car } = this.state

    return (
      <>

          <div className="row" style={{overflow: 'scroll'}}>

            <Loading isLoading={this.state.isLoading} />

            <div className="col-6">
              <div className="card" style={{minHeight: 650}}>
                <div className="card-header">
                 ประวัติรถ

                  <button className="float-right mb-0 btn btn-secondary"
                          type="button"
                          onClick={()=>this.handleDownloadImage()}

                  >
                    ดาวน์โหลด
                  </button>

                </div>
                <div className="card-block">
                  <strong>ข้อมูลรถ</strong>
                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6}}>ลำดับเจ้าของ</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="owner_order"
                           value="มือเดียวออกห้าง"
                           className="btn btn-primary"
                           checked={this.state.owner_order === 'มือเดียวออกห้าง'}
                           onChange={(e) => {
                              let value = e.target.value
                               this.setState({
                                 owner_order_number: '',
                                 owner_order: value
                               })
                           }}
                    /> &nbsp;<span style={{marginTop: 6}}>มือเดียวออกห้าง</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="owner_order"
                           value="มือที่"
                           className="btn btn-primary"
                           checked={this.state.owner_order === 'มือที่'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: 6}}>มือที่</span>
                    &nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{width: 56, marginTop: 3, height: 30}}
                      name="owner_order_number"
                      value={this.state.owner_order_number}
                      maxLength={2}
                      onChange={(e) => {
                        let value = e.target.value
                         if (this.state.owner_order === 'มือที่' && value !== '') {
                           this.setState({owner_order_number: e.target.value})
                         }else if (this.state.owner_order === 'มือเดียวออกห้าง' && value !== '') {
                           this.setState({
                             owner_order_number: e.target.value,
                             owner_order: 'มือที่'
                           })
                         }
                      }}
                    />

                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6}}>ออฟชั่น</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: '80%',minWidth: 340, maxWidth: 520, marginTop: 3, height: 30}}
                      name="car_options"
                      value={this.state.car_options}
                      onChange={this.handleOnChange}
                    />
                  </div>

                  <hr/>

                  <strong>ประวัติการใช้รถ</strong>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6}}>เข้าศูนย์ล่าสุดเมื่อไหร่</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: '40%',minWidth: 100, maxWidth: 220, marginTop: 3, height: 30}}
                      name="car_entered_center"
                      value={this.state.car_entered_center}
                      onChange={this.handleOnChange}
                      placeholder={'25/03/2022'}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>เลขไมล์</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 100,minWidth: 80, maxWidth: 200, marginTop: 3, height: 30}}
                      name="car_entered_center_miles"
                      value={this.state.car_entered_center_miles}
                      onChange={this.handleOnChange}
                      placeholder={''}
                    />
                  </div>
                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10, marginTop: 10}}
                  >
                    <span style={{marginTop: 6}}>ยางยี่ห้อ</span>
                    &nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: '35%',minWidth: 100, maxWidth: 220, marginTop: 3, height: 30}}
                      name="car_tire_brand"
                      value={this.state.car_tire_brand}
                      onChange={this.handleOnChange}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>ปี</span>
                    &nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 70, maxWidth: 120, marginTop: 3, height: 30}}
                      name="car_tire_year"
                      maxLength={4}
                      value={this.state.car_tire_year}
                      onChange={this.handleOnChange}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>ขอบ</span>
                    &nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 70, maxWidth: 120, marginTop: 3, height: 30}}
                      name="car_tire_size"
                      maxLength={2}
                      value={this.state.car_tire_size}
                      onChange={this.handleOnChange}
                    />

                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10, marginTop: 0, textAlign: 'center'}}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>สภาพ</span>
                    &nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 60, maxWidth: 120, marginTop: 3, height: 30}}
                      name="car_tire_condition"
                      maxLength={2}
                      value={this.state.car_tire_condition}
                      onChange={this.handleOnChange}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="car_tire_status"
                           value="พร้อมใช้งาน"
                           className="btn btn-primary"
                           checked={this.state.car_tire_status === 'พร้อมใช้งาน'}
                           onChange={(e) => {
                             let value = e.target.value
                             this.setState({
                               car_tire_status: value
                             })
                           }}
                    /> &nbsp;<span style={{marginTop: 6}}>พร้อมใช้งาน</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="car_tire_status"
                           value="ต้องเปลี่ยนใหม่"
                           className="btn btn-primary"
                           checked={this.state.car_tire_status === 'ต้องเปลี่ยนใหม่'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: 6}}>ต้องเปลี่ยนใหม่</span>

                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10, marginTop: 0,}}
                  >

                    <span style={{marginTop: 6}}>การชน</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="car_accident"
                           value="ไม่มีเลย"
                           className="btn btn-primary"
                           checked={this.state.car_accident === 'ไม่มีเลย'}
                           onChange={(e) => {
                             let value = e.target.value
                             this.setState({
                               car_accident: value
                             })
                           }}
                    /> &nbsp;<span style={{marginTop: 6}}>ไม่มีเลย</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="car_accident"
                           value="มีเล็กน้อย"
                           className="btn btn-primary"
                           checked={this.state.car_accident === 'มีเล็กน้อย'}
                           onChange={(e) => {
                             let value = e.target.value
                             this.setState({
                               car_accident: value
                             })
                           }}
                    /> &nbsp;<span style={{marginTop: 6}}>มีเล็กน้อย</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="car_accident"
                           value="มีมาก"
                           className="btn btn-primary"
                           checked={this.state.car_accident === 'มีมาก'}
                           onChange={(e) => {
                             let value = e.target.value
                             this.setState({
                               car_accident: value
                             })
                           }}
                    /> &nbsp;<span style={{marginTop: 6}}>มีมาก</span>
                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10, marginTop: 0,}}
                  >
                    สภาพโดยรวม (เต็ม 5 ดาว) &nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      className="form-control"
                      name="car_overall_condition"
                      value={this.state.car_overall_condition}
                      onChange={this.handleOnChange}
                      style={{width: 128}}
                    >
                      <option value=''>-- เลือก --</option>
                      <option value='1'>1 ดาว</option>
                      <option value='2'>2 ดาว</option>
                      <option value='3'>3 ดาว</option>
                      <option value='4'>4 ดาว</option>
                      <option value='5'>5 ดาว</option>
                    </select>
                  </div>

                  <hr/>
                  <strong>ข้อมูลศูนย์</strong>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6}}>ศูนย์ออกรถ</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 140,minWidth: 140, maxWidth: 220, marginTop: 3, height: 30}}
                      name="car_center_name"
                      value={this.state.car_center_name}
                      onChange={this.handleOnChange}
                    />

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>สาขา</span>
                    <input
                      className="form-control"
                      style={{ width: 140,minWidth: 140, maxWidth: 220, marginTop: 3, marginLeft: 4, height: 30}}
                      name="car_center_branch"
                      value={this.state.car_center_branch}
                      onChange={this.handleOnChange}
                    />

                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6}}>ราคาออกห้าง</span>
                    <input
                      className="form-control"
                      style={{ width: 100,minWidth: 100, maxWidth: 220, marginTop: 3, marginLeft: 4 , height: 30}}
                      name="car_center_price"
                      value={this.state.car_center_price}
                      onChange={this.handleOnChange}
                    />
                  </div>


                  <hr/>

                  <strong>อุปกรณ์ตกแต่งเพิ่มเติม</strong>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6, width: 120}}>ของแต่งเพิ่ม</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 170,minWidth: 170, maxWidth: 220, marginTop: 3, height: 30}}
                      name="add_on"
                      value={this.state.add_on}
                      onChange={this.handleOnChange}
                    />

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>มูลค่า</span>
                    <input
                      className="form-control"
                      style={{ width: 120,minWidth: 120, maxWidth: 220, marginTop: 3, marginLeft: 4, height: 30}}
                      name="add_on_value"
                      value={this.state.add_on_value}
                      onChange={this.handleOnChange}
                    />

                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6, width: 120}}>อื่นๆ</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 170,minWidth: 170, maxWidth: 220, marginTop: 3, height: 30}}
                      name="other1"
                      value={this.state.other1}
                      onChange={this.handleOnChange}
                    />

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>มูลค่า</span>
                    <input
                      className="form-control"
                      style={{ width: 120,minWidth: 120, maxWidth: 220, marginTop: 3, marginLeft: 4, height: 30}}
                      name="other1_value"
                      value={this.state.other1_value}
                      onChange={this.handleOnChange}
                    />
                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6, width: 120}}>อื่นๆ</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 170,minWidth: 170, maxWidth: 220, marginTop: 3, height: 30}}
                      name="other2"
                      value={this.state.other2}
                      onChange={this.handleOnChange}
                    />

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{marginTop: 6}}>มูลค่า</span>
                    <input
                      className="form-control"
                      style={{ width: 120,minWidth: 120, maxWidth: 220, marginTop: 3, marginLeft: 4, height: 30}}
                      name="other2_value"
                      value={this.state.other2_value}
                      onChange={this.handleOnChange}
                    />
                  </div>

                  <div
                    className="btn-group colors col-lg-12"
                    style={{height: 40, padding: 10}}
                  >
                    <span style={{marginTop: 6, width: 120}}>หมายเหตุ</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-control"
                      style={{ width: 290,minWidth: 290, maxWidth: 360, marginTop: 3, height: 30}}
                      name="note"
                      value={this.state.note}
                      onChange={this.handleOnChange}
                    />
                  </div>

                  <hr/>

                  <div className="col-lg-12" style={{marginTop: 16, textAlign: 'right', position: 'relative'}} >


                    {
                      this.state.car_history_id === 0 ? (
                        <button className="float-right mb-0 btn btn-success"
                                type="button"
                                style={{width: 100}}
                                onClick={()=>this.saveCarHistory('save')}

                        >
                          บันทึก
                        </button>
                      ) : (
                        <button className="float-right mb-0 btn btn-warning"
                                type="button"
                                style={{width: 100}}
                                onClick={()=>this.saveCarHistory('edit')}

                        >
                          แก้ไข
                        </button>
                      )
                    }


                    <button className="float-right mb-0 btn btn-secondary mr-1"
                            type="button"
                            style={{width: 100}}
                            onClick={()=>this.handleDownloadImage()}

                    >
                      ดาวน์โหลด
                    </button>



                  </div>


                </div>


              </div>

            </div>
            <div className="col-6">
              <div className="card" style={{minHeight: 1024}}>
                <div className="card-header">
                  ตัวอย่าง
                </div>
                <div className="card-block" ref={this.printRef}
                     style={{ backgroundImage:`url(img/car_at_form.jpg)`,height: 1024, width: 667 }}>

                  <div style={{height: 200, width: 230, marginTop: 32 }} >
                    {
                      this.state.carBase64Img !== '' ? <img src={this.state.carBase64Img} style={{width: 230}} /> : null
                    }
                  </div>

                  <div style={{height: 32}} >
                     &nbsp;
                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: -4}} >
                    <span style={{...styles.textContent, marginLeft: 35, width: 96, }}>
                      {this.state.car.car_brand_name || ''}
                    </span>

                    <span style={{...styles.textContent, marginLeft: 30,  width: 140,}}>
                      {this.state.car.car_model_name || ''}
                    </span>

                    <span style={{...styles.textContent, marginLeft: 66, width: 146, }}>
                      {
                        Object.keys(car).length === 0 ? '':
                          this.getSubModelName(car.car_model_name, car.car_sub_model_name)
                      }
                    </span>

                    <span style={{...styles.textContent, marginLeft: 30, width: 50, }}>
                       {car.car_year_name || ''}
                    </span>

                  </div>


                  <div className="form-inline" style={{height: 32, marginTop: 0}} >
                    <span style={{...styles.textContentThai, marginLeft: 10, width: 70}}>
                      {car.car_color_name || '-'}
                    </span>

                    {
                      this.getDateRegis(car) === '-' ?
                        <span style={{...styles.textContentThai, marginLeft: 84 , width: 105, textAlign: 'center'}}>
                         {this.getDateRegis(car)}
                        </span>
                        :
                        <span style={{...styles.textContentThai, marginLeft: 84 , width: 105}}>
                          {this.getDateRegis(car)}
                        </span>
                    }


                    {
                      this.state.owner_order === 'มือเดียวออกห้าง' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 105, marginTop: 0}}  />
                      : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 105, marginTop: 0}}  />
                    }

                    {
                      this.state.owner_order === 'มือที่' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 137, marginTop: 0}}  />
                      : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 134, marginTop: 0}}  />
                    }

                    <span style={{...styles.textContentThai, marginLeft: 48, width: 30}}>
                      {this.state.owner_order_number}
                    </span>

                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 0}} >
                    <span style={{...styles.textContentThaiCenter, marginLeft: 62, width: 105}}>
                      {this.getCarLicense(car)}
                    </span>

                    <span style={{...styles.textContentCenter, marginLeft: 75, width: 110}}>
                      {NumberUtil.addCommas(String(car.car_miles)) || '-'}
                    </span>


                    <span style={{...styles.textContentCenter, marginLeft: 125, width: 110}}>
                      { this.getCarMileAverage(car) }
                    </span>

                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 3}} >
                    <span style={{...styles.textContentThai, marginLeft: 62, width: 560}}>
                      {this.state.car_options}
                    </span>
                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 44}} >
                        <span style={{...styles.textContentCenter, marginLeft: 166 , width: 220}}>
                         {this.state.car_entered_center}
                        </span>

                        <span style={{...styles.textContentCenter, marginLeft: 84 , width: 90}}>
                         {this.state.car_entered_center_miles}
                        </span>
                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 3}} >

                   <span style={{...styles.textContentCenter, marginLeft: 84 , width: 80, fontSize: this.state.car_tire_brand.length>=11 ? 12: 14}}>
                      {this.state.car_tire_brand}
                   </span>

                    <span style={{...styles.textContentCenter, marginLeft: 16 , width: 34}}>
                         {this.state.car_tire_year}
                    </span>

                    <span style={{...styles.textContentCenter, marginLeft: 44 , width: 22}}>
                         {this.state.car_tire_size}
                    </span>

                    <span style={{...styles.textContentCenter, marginLeft: 52 , width: 22}}>
                         {this.state.car_tire_condition}
                    </span>

                    {
                      this.state.car_tire_status === 'พร้อมใช้งาน' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 25, marginTop: 0}}  />
                        : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 25, marginTop: 0}}  />
                    }

                    {
                      this.state.car_tire_status === 'ต้องเปลี่ยนใหม่' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 100, marginTop: 0}}  />
                        : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 96, marginTop: 0}}  />
                    }

                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 1}} >

                    {
                      this.state.car_accident === 'ไม่มีเลย' ? <>

                        {
                          this.state.car_accident === 'ไม่มีเลย' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 65, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 66, marginTop: 2}}  />
                        }

                        {
                          this.state.car_accident === 'มีเล็กน้อย' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 85, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 80, marginTop: 2}}  />
                        }

                        {
                          this.state.car_accident === 'มีมาก' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 95, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 98, marginTop: 2}}  />
                        }

                      </> : null
                    }

                    {
                      this.state.car_accident === 'มีเล็กน้อย' ? <>

                        {
                          this.state.car_accident === 'ไม่มีเลย' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 65, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 66, marginTop: 2}}  />
                        }

                        {
                          this.state.car_accident === 'มีเล็กน้อย' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 85, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 80, marginTop: 2}}  />
                        }

                        {
                          this.state.car_accident === 'มีมาก' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 95, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 93, marginTop: 2}}  />
                        }

                      </> : null
                    }

                    {
                      this.state.car_accident === 'มีมาก' ? <>

                        {
                          this.state.car_accident === 'ไม่มีเลย' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 65, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 66, marginTop: 2}}  />
                        }

                        {
                          this.state.car_accident === 'มีเล็กน้อย' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 85, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 85, marginTop: 2}}  />
                        }

                        {
                          this.state.car_accident === 'มีมาก' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 96, marginTop: 0}}  />
                            : <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 93, marginTop: 2}}  />
                        }

                      </> : null
                    }
                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 1}} >
                    {
                      this.getCarOverallCondition(this.state.car_overall_condition)
                    }
                  </div>


                  <div className="form-inline" style={{height: 32, marginTop: 48}} >

                    <span style={{...styles.textContentCenter, marginLeft: 90 , width: 138}}>
                         {this.state.car_center_name}
                    </span>

                    <span style={{...styles.textContentThaiCenter, marginLeft: 55 , width: 105}}>
                         {this.state.car_center_branch}
                    </span>

                    <span style={{...styles.textContentCenter, marginLeft: 115 , width: 120}}>
                         {this.state.car_center_price}
                    </span>

                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 0}} >

                    {
                      this.state.car_center_insurance === 'มี' ? <>

                        <img src="../img/check_mark.png" style={{width: 19, marginLeft: 92, marginTop: 0}}  />

                        <span style={{...styles.textContentCenter, marginLeft: 125 , width: 110}}>
                         {this.state.insurance_end_date}
                        </span>

                        <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 12, marginTop: 0}}  />

                      </> : null
                    }

                    {
                      this.state.car_center_insurance === 'ไม่มี' ? <>
                        <img src="../img/uncheck_mark.png" style={{width: 14, marginLeft: 93, marginTop: 2}}  />

                        <span style={{...styles.textContentCenter, marginLeft: 128 , width: 110}}>
                         {this.state.insurance_end_date}
                        </span>

                        <img src="../img/check_mark.png" style={{width: 19, marginLeft: 12, marginTop: 0}}  />

                      </> : null
                    }
                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 1}} >
                    {
                      this.state.car_manual === 'มี' ? <>

                        <img src="../img/check_mark.png" style={{width: 19, marginLeft: 170, marginTop: 0}}  />

                        {
                          this.state.car_spare_key === 'มี' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 292, marginTop: 0}}  />
                            : null
                        }

                        {
                          this.state.car_spare_key === 'ไม่มี' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 352, marginTop: 0}}  />
                            : null
                        }

                      </> : null
                    }

                    {
                      this.state.car_manual === 'ไม่มี' ? <>

                        <img src="../img/check_mark.png" style={{width: 19, marginLeft: 241, marginTop: 0}}  />

                        {
                          this.state.car_spare_key === 'มี' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 222, marginTop: 0}}  />
                            : null
                        }

                        {
                          this.state.car_spare_key === 'ไม่มี' ? <img src="../img/check_mark.png" style={{width: 19, marginLeft: 282, marginTop: 0}}  />
                            : null
                        }

                      </> : null
                    }

                  </div>


                  <div className="form-inline" style={{height: 32, marginTop: 44}} >

                    <span style={{...styles.textContentThai, marginLeft: 104, width: 220}}>
                      {this.state.add_on}
                   </span>

                    <span style={{...styles.textContentCenter, marginLeft: 64, width: 220}}>
                      {this.state.add_on_value}
                   </span>

                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 2}} >

                    <span style={{...styles.textContentThai, marginLeft: 60, width: 264}}>
                      {this.state.other1}
                    </span>

                    <span style={{...styles.textContentCenter, marginLeft: 64, width: 220}}>
                      {this.state.other1_value}
                    </span>

                  </div>

                  <div className="form-inline" style={{height: 32, marginTop: 3}} >

                    <span style={{...styles.textContentThai, marginLeft: 60, width: 264}}>
                      {this.state.other2}
                    </span>

                    <span style={{...styles.textContentCenter, marginLeft: 64, width: 220}}>
                      {this.state.other2_value}
                    </span>

                  </div>


                  <div className="form-inline" style={{height: 32, marginTop: 0}} >

                    <span style={{...styles.textContentThai, marginLeft: 84, width: 525}}>
                      {this.state.note}
                   </span>

                  </div>

                </div>


              </div>
            </div>
          </div>

      </>
    )
  }

}

// ...styles.textContent,
// white-space: nowrap ;
const styles = {
  textContent: {
    fontFamily: 'Mitr',
    color: '#ff0000',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    // backgroundColor: '#0000ff',
  },
  textContentCenter: {
    fontFamily: 'Mitr',
    color: '#ff0000',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    // backgroundColor: '#0000ff',
    textAlign: 'center'
  },
  textContentThai: {
    fontFamily: 'Mitr',
    color: '#ff0000',
    // backgroundColor: '#ffff00',
    fontSize: 16,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative'
  },
  textContentThaiCenter: {
    fontFamily: 'Mitr',
    color: '#ff0000',
   // backgroundColor: '#ffff00',
    fontSize: 16,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center'
  }
}


export default CarHistory
