import { createReducer } from '../utils';
import createConstants from '../constants';

const initialStateSparePart = {
  activeTab: localStorage.getItem('stateSpareActiveTab')===null? '1': localStorage.getItem('stateSpareActiveTab')
};

export default createReducer (initialStateSparePart, {
  [createConstants.SPARE_PART_TAB]: (state, payload) => {

    let stateSpareActiveTab = localStorage.getItem('stateSpareActiveTab');//old

    if (stateSpareActiveTab === null || stateSpareActiveTab!==payload.activeTab ) {
      localStorage.setItem('stateSpareActiveTab', payload.activeTab);
    }

    return Object.assign({}, state, {
      activeTab:  payload.activeTab,
    });

  },
});