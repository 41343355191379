import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { APIURL } from '../../config/config';
import NumberFormat from 'react-number-format';
import { AlertSuccess, AlertWarning } from '../Alert/Alert';
import ReactQuill from 'react-quill'

class FinancePendingEditModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {},
      width: 0,
      //
      branch: [],
      employees: [],
      bank: [],
      finance_statuses: [],
      //
      car_name: '',
      car_license_plate: '',
      province_name: '',
      finance_date: null,
      finance_pending_id: null,
      branch_id: null,
      finance_contract: '',
      normal_sell_price: 0,
      rate_finance: 0,
      sum_down: 0,
      monthly_installment: 0,
      finance_status_id: 0,
      finance_detail: '',
      isLoading: false,
      finance_comment: ''
    }

    this.loadBranchData = this.loadBranchData.bind(this);
    this.loadEmployeeData = this.loadEmployeeData.bind(this);
    this.loadBankData = this.loadBankData.bind(this);
    this.loadFinanceStatus = this.loadFinanceStatus.bind(this);
    //
    this.handleFinanceDate = this.handleFinanceDate.bind(this);

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentWillMount () {
    //const queryString = require('query-string');
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount () {
    this.loadBranchData()
    this.loadEmployeeData()
    this.loadBankData()
    this.loadFinanceStatus()

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.finance_pending !== null) {
      if(prevProps.finance_pending !== this.props.finance_pending) {
        let finance_pending = this.props.finance_pending
        let car_name = finance_pending.car_name
        let car_license_plate = finance_pending.car_license_plate_new === '' ?  finance_pending.car_license_plate_old : finance_pending.car_license_plate_new
        let province_name = finance_pending.car_license_plate_new === '' ?  finance_pending.province_name_old : finance_pending.province_name_new

        this.setState({
          car_name,
          car_license_plate,
          province_name,
          finance_date: new Date(finance_pending.finance_date),
          finance_pending_id: finance_pending.finance_pending_id,
          customer_id: finance_pending.customer_id,
          car_id: finance_pending.car_id,
          branch_id: finance_pending.branch_id,
          sale_by_id: finance_pending.sale_by_id,
          bank_finance_id: finance_pending.bank_finance_id,
          finance_contract: finance_pending.finance_contract,
          normal_sell_price: finance_pending.car_sale_price,
          rate_finance: finance_pending.rate_finance,
          sum_down: finance_pending.sum_down,
          monthly_installment: finance_pending.monthly_installment,
          finance_status_id: finance_pending.finance_status_id,
          finance_detail: finance_pending.finance_detail,
          finance_comment: finance_pending.finance_comment,
        })
      }
    }
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branch: res.data})
      }).catch(error => {
      this.setState({branch: []})
    })
  }

  loadEmployeeData() {
    axios.get(APIURL + '/employee/')
      .then(res => {
        if(res.status === 200) {
          let data = res.data
          if (data.length > 0) {
            let dataFilter = data.filter(d => d.employee_position.indexOf('ขาย') !== -1)
            this.setState({ employees: dataFilter })
          } else {
            this.setState({employees: [] })
          }
        }
      }).catch(error => {
      // console.log(error);
      this.setState({employees: [] })
    })
  }

  loadBankData () {
    axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({bank: res.data})
      }).catch(error => {
      this.setState({bank: []})
    })
  }

  loadFinanceStatus() {
    axios.get(APIURL + '/finance_status/')
      .then(res => {
        this.setState({finance_statuses: res.data})
      }).catch(error => {
      this.setState({finance_statuses: []})
    })
  }

  handleFinanceDate (date) {
    this.setState({
      finance_date: date
    })
  }


  handleOnSubmit(event) {
    event.preventDefault()

    let car_id = this.state.car_id
    let finance_pending_id = this.state.finance_pending_id
    let customer_id = this.state.customer_id
    let branch_id = event.target.branch_id.value
    let sale_by_id = event.target.sale_by_id.value
    let finance_date = event.target.finance_date.value
    let bank_finance_id = event.target.bank_finance_id.value
    // let rate_finance = event.target.rate_finance.value
    let finance_status_id = event.target.finance_status_id.value
    let rate_finance = event.target.rate_finance.value
    let finance_contract = event.target.finance_contract.value
    let finance_detail = event.target.finance_detail.value
    let sum_down = event.target.sum_down.value === '' ? 0 : event.target.sum_down.value
    let monthly_installment = event.target.monthly_installment.value === '' ? 0 : event.target.monthly_installment.value
    let user = this.state.user.user
    let finance_comment = this.state.finance_comment

    if (finance_date === '') {
      AlertWarning('กรุณาเลือกวันที่จัดรถ');
      return
    }

    if (branch_id === '') {
      AlertWarning('กรุณาเลือกสาขาที่จัด')
      this.refs.branch_id.focus()
      return
    }

    if (sale_by_id === '') {
      AlertWarning('กรุณาเลือกผู้จัด');
      return
    }

    // bank_finance_id
    if (bank_finance_id === '') {
      AlertWarning('กรุณาเลือกไฟแนนซ์');
      return
    }


    let data = {
      car_id,
      customer_id,
      branch_id,
      sale_by_id,
      finance_date,
      bank_finance_id,
      finance_contract,
      rate_finance,
      sum_down,
      monthly_installment,
      finance_status_id,
      finance_detail,
      user,
      finance_pending_id,
      finance_comment
    }

    axios.put(APIURL + '/finance_pending', data)
      .then(res => {
        if (res.status === 200) {
          AlertSuccess('แก้ไขข้อมูลการจัดเรียบร้อยแล้ว')
          setTimeout(() => {
            // let target = '/finance-pending/list/?car_id=' + this.state.car_id
            // <Redirect push to={target}/>
            // this.props.history.push(target)
            // close modal and reload data
            this.props.onReload(car_id)

          }, 1500)
        }
      }).catch(error => {
        // console.log(error)
        this.setState({ isLoading: false })
    })

  }

  render () {
    let {
      car_name,
      car_license_plate,
      province_name
    } = this.state
    let width = this.state.width

    let branch_list = this.state.branch.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    let employee_list = this.state.employees.map((employee, index) => {
      return (
        <option key={index} value={employee.employee_id}> {employee.employee_name}&nbsp;&nbsp;{employee.employee_lastname} </option>
      )
    })

    let bank_finance_list = this.state.bank.map((bank, index) => {
      return (
        <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    })

    let finance_statuses_list = this.state.finance_statuses.map((finance_status, index) => {
      return (
        <option key={index} value={finance_status.finance_status_id}>{finance_status.finance_status_name}</option>
      )
    })

    return (
      <>
        <Modal isOpen={this.props.isModal}
               toggle={this.props.toggleModal}
               className={'modal-lg ' + this.props.className}
               style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
        >
          <ModalBody>
            <form action="" method="post" onSubmit={this.handleOnSubmit}>
              <div className="card card-accent-warning">
                <div className="card-header">
                  <strong className="text-title">แก้ไขข้อมูลการจัด</strong>
                </div>

                <div className="card-block">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-3 ">
                          <strong> ชื่อรถ </strong></label>
                        <div className="col-md-8">
                          <p className="text-justify text-primary">
                            <strong> {car_name} </strong>
                          </p>
                        </div>

                        {/*<label className="col-md-3"><strong> ยี่ห้อ </strong></label>
                        <div className="col-md-3">
                          <p className="text-justify text-primary">
                            <strong> {car_brand_name} </strong></p>
                        </div>*/}
                      </div>

                      <div className=" form-group row" style={styles.bottomLine}>
                        <label className="col-md-3"><strong> ป้ายทะเบียนรถ </strong></label>
                        <div className="col-md-3">
                          <p className="text-justify text-primary">
                            <strong> {car_license_plate} </strong></p>
                        </div>

                        <label className="col-md-3"><strong> จังหวัด </strong></label>
                        <div className="col-md-3">
                          <p className="text-justify text-primary">
                            <strong> {province_name} </strong></p>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-3">
                      <label htmlFor="finance_date">วันที่จัดรถ </label> <br/>
                      <DatePicker
                        selected={this.state.finance_date}
                        onChange={this.handleFinanceDate}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        id="finance_date"
                        name="finance_date"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="branch">สาขาที่จัด</label>
                      <select className="form-control"
                              id="branch_id"
                              ref="branch_id"
                              value={this.state.branch_id}
                              onChange={(e) => {
                                let branch_id = e.target.value
                                this.setState({
                                  branch_id: branch_id
                                })
                              }}
                      >
                        <option value=''> เลือกสาขาที่จัด </option>
                        {branch_list}
                      </select>
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="car_sell_type">จัดโดย</label>
                      <select
                        className="form-control"
                        id="sale_by_id"
                        name="sale_by_id"
                        value={this.state.sale_by_id}
                        onChange={(e) => {
                          this.setState({
                            sale_by_id: e.target.value
                          })
                        }}
                      >
                        <option value=''> เลือกพนักงานผู้จัด </option>
                        {employee_list}
                      </select>
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="bank_finance_id">ไฟแนนซ์</label>
                      <select
                        className="form-control"
                        id="bank_finance_id"
                        name="bank_finance_id"
                        ref="bank_finance_id"
                        value={this.state.bank_finance_id}
                        onChange={(e) => {
                          this.setState({
                            bank_finance_id: e.target.value
                          })
                        }}
                      >
                        <option value=""> เลือกไฟแนนซ์</option>
                        {bank_finance_list}
                      </select>
                    </div>

                    <div className="form-group col-sm-3">
                      <label htmlFor="finance_contract">ผู้รับจด</label>
                      <input style={styles.toUpper}
                             type="text"
                             className="form-control"
                             name="finance_contract"
                             placeholder="ผู้ติดต่อของไฟแนนซ์"
                             value={this.state.finance_contract}
                             onChange={(e) => {
                                this.setState({
                                  finance_contract: e.target.value
                                })
                             }}
                      />
                    </div>

                  </div>

                  <div className="row">

                    <div className="form-group col-sm-3">
                      <label htmlFor="normal_sell_price">ราคาขายปกติ</label>
                      <input type="text"
                             className="form-control hidden"
                             id="normal_sell_price"
                             name="normal_sell_price"
                             placeholder=""
                             ref="normal_sell_price"
                             value={this.state.normal_sell_price}
                             onChange={()=>{}}
                      />
                      <NumberFormat
                        className="form-control text-primary"
                        thousandSeparator={true}
                        prefix={'฿'}
                        //placeholder="กรอกค่าโอน"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.normal_sell_price}
                        displayType="text"
                      />
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="rate_finance">ยอดจัดไฟแนนซ์</label>
                      <input type="text"
                             className="form-control hidden"
                             id="rate_finance"
                             name="rate_finance"
                             placeholder=""
                             ref="rate_finance"
                             value={this.state.rate_finance}
                             onChange={()=>{}}
                      />
                      <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.rate_finance}
                        onValueChange={(values) => {
                          const {value} = values
                          this.setState({rate_finance: value})
                        }}
                      />
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="sum_down">ยอดดาวน์</label>
                      <input type="text"
                             className="form-control hidden"
                             id="sum_down"
                             name="sum_down"
                             placeholder=""
                             ref="sum_down"
                             value={this.state.sum_down}
                             onChange={()=>{}}
                      />
                      <NumberFormat
                        className="form-control text-primary"
                        thousandSeparator={true}
                        prefix={'฿'}
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.sum_down}
                        onValueChange={(values) => {
                          const {value} = values
                          this.setState({sum_down: value})
                        }}
                      />
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="monthly_installment">ค่างวด</label>
                      <input type="text"
                             className="form-control hidden"
                             id="monthly_installment"
                             name="monthly_installment"
                             placeholder=""
                             ref="monthly_installment"
                             value={this.state.monthly_installment}
                             onChange={()=>{}}
                      />
                      <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        //placeholder="กรอกค่าโอน"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        value={this.state.monthly_installment}
                        onValueChange={(values) => {
                          const {value} = values
                          this.setState({monthly_installment: value})
                        }}
                      />
                    </div>

                    <div className="form-group col-sm-2">
                      <label htmlFor="finance_status_id">สถานะจัด</label>
                      <select className="form-control"
                              id="finance_status_id"
                              name="finance_status_id"
                              value={this.state.finance_status_id}
                              onChange={(e)=> {
                                this.setState({
                                  finance_status_id: e.target.value
                                })
                              }}
                      >
                        {finance_statuses_list}
                      </select>
                    </div>

                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="finance_detail">รายละเอียดการจัด</label>
                      <input style={styles.toUpper}
                             type="text"
                             className="form-control"
                             name="finance_detail"
                             placeholder="รายละเอียดการจด"
                             value={this.state.finance_detail}
                             onChange={(e) => {
                               this.setState({
                                 finance_detail: e.target.value
                               })
                             }}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="form-group col-sm-12">
                      <label htmlFor="finance_comment">หมายเหตุการจัด</label>
                      <ReactQuill theme="snow"
                                  value={this.state.finance_comment}
                                  onChange={(val) => {
                                    this.setState({
                                      finance_comment: val
                                    })
                                  }}
                      />
                    </div>
                  </div>

                </div>

                <div className="card-footer text-right">

                  <button type="button"
                          onClick={() => this.props.toggleModal()}
                          className="btn btn-sm btn-danger mr-2"
                  >
                    <i className="fa fa-refresh"/> ยกเลิก
                  </button>

                  <button type="submit"
                          className="btn btn-sm btn-warning">
                    <i className={"fa fa-edit"}/> แก้ไข
                  </button>

                </div>

              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    )
  }

}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  bottomLineAndMargin: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    paddingTop: 8
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(FinancePendingEditModal)
