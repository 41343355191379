/**
 * Created by devmaster on 7/16/2017 AD.
 */

import React, {Component} from 'react'
import NumberFormat from 'react-number-format'
import {IMAGE_THUMB_URL, IMAGE_NOPHOTO_URL, PORT} from '../../../config/config'

const port = Number(window.env.PORT);

export default  class CarInfo extends  Component {

  render(){
    const car = this.props.car

    if(car.car_id===''){
      return null ;
    }else {

      let image_url = ''

      if (car.car_upload_filename === 'undefined') {
        image_url = IMAGE_NOPHOTO_URL
      } else if (car.car_upload_filename.includes('https')) {
        image_url = car.car_upload_filename
      } else {
        image_url = IMAGE_THUMB_URL+car.car_upload_filename
      }

      return (
        <div className="col-sm-12 col-md-12 card card-inverse card-info text-center">
          <div className="card-block">
            <table>
              <tbody>
              <tr>
                <td width="15%">
                  <img
                    src={image_url}
                    style={{width: 200, height: 200}}
                    className="img-cars"
                    alt="carinfo"
                  />

                </td>
                <td width="15%">
                  <strong> {car.car_title} </strong>
                  <span className="badge badge-danger">{car.car_status_name}</span>
                </td>
                <td width="50%" className="text-justify" >
                  <div className="h5  mb-0 mt-2">
                    {car.car_detail}
                     <br/>
                    ทะเบียน {car.car_license_plate_new}
                  </div>
                   </td>

              </tr>
              </tbody>
            </table>
            <div className="row mt-4">

              <div className={this.props.userType==='admin'? 'col-6 col-lg-3': 'col-6 col-lg-3 hidden'}>
                <div className="card">
                  <div className="card-block p-3 clearfix">
                    <i className="fa fa-bell bg-danger p-3 font-2xl mr-3 float-left"/>
                    <div className="h5 text-danger mb-0 mt-2">
                      <NumberFormat
                         value={car.car_cost}
                         displayType={'text'}
                         thousandSeparator={true}
                         prefix={'฿'}/>
                    </div>
                    <div className="text-gray-dark text-uppercase font-weight-bold font-xs">
                      ราคาทุน
                    </div>
                  </div>
                </div>
              </div>

              <div className={port === 9040 && this.props.userType !== 'admin'? 'hidden' : "col-6 col-lg-3"}>
                <div className="card">
                  <div className="card-block p-3 clearfix">
                    <i className="fa fa-recycle bg-danger p-3 font-2xl mr-3 float-left"></i>
                    <div className="h5 text-danger mb-0 mt-2">
                      <NumberFormat value={car.car_cost_repair}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'฿'}/></div>
                    <div className="text-gray-dark text-uppercase font-weight-bold font-xs">
                      ค่าซ่อม
                    </div>
                  </div>
                </div>
              </div>

              {
                this.props.userType === 'technician' && PORT === 9056 ? null : (
                    <div className="col-6 col-lg-3">
                      <div className="card">
                        <div className="card-block p-3 clearfix">
                          <i className="fa fa-moon-o bg-warning p-3 font-2xl mr-3 float-left"></i>
                          <div className="h5 text-warning mb-0 mt-2">
                            <NumberFormat value={car.car_sale_price}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          prefix={'฿'}/></div>
                          <div className="text-gray-dark text-uppercase font-weight-bold font-xs">
                            ราคาขาย
                          </div>
                        </div>
                      </div>
                    </div>
                )
              }


              <div className={this.props.userType==='admin'? 'col-6 col-lg-3': 'col-6 col-lg-3 hidden'}>
                <div className="card">
                  <div className="card-block p-3 clearfix">
                    <i className="fa fa-laptop bg-info p-3 font-2xl mr-3 float-left"></i>
                    <div className="h5 text-info mb-0 mt-2">
                      <NumberFormat value={car.car_net_profit}
                                                                          displayType={'text'}
                                                                          thousandSeparator={true}
                                                                          prefix={'฿'}/></div>
                    <div className="text-gray-dark text-uppercase font-weight-bold font-xs">กำไร
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      )
    }
  }
}
