import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {format} from 'date-fns'
import axios from 'axios'
import { connect } from 'react-redux'
import { save } from 'save-file';
import { APIURL } from '../../config/config'
import Loading from '../Loading'
import { NumberUtil } from '../../utils/number-util'
import { AlertWarning } from '../Alert/Alert'


class TaxInvoiceReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),

      lists: [],
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadTaxInvoiceData = this.loadTaxInvoiceData.bind(this)
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadTaxInvoiceData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    // let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    // let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let start_date = format(new Date(this.state.date_start), 'yyyy-MM-dd')
    let end_date = format(new Date(this.state.date_end), 'yyyy-MM-dd')

    /*const data = {
      start_date: start_date,
      end_date: end_date,
    }
   */

    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/report/taxinvoice/'+start_date+'/'+end_date)
        .then(res => {
          this.setState({
            lists: res.data,
            isLoading: false
          })
        }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  exportTaxInvoiceData() {

    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    // let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    // let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    let start_date = format(new Date(this.state.date_start), 'yyyy-MM-dd')
    let end_date = format(new Date(this.state.date_end), 'yyyy-MM-dd')

    this.setState({
      isLoading: true
    }, () => {
      axios.get(APIURL + '/report/taxinvoicereport/'+start_date+'/'+end_date)
        .then( async res => {
          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานใบกำกับภาษี_export_'+currentDatetime+'.xlsx'
          this.setState({
            isLoading: false
          })
          await save(excelBuffer, fileName)

        }).catch(error => {
        console.log('error:', error)
        // this.setState({lists: []})
      })
    })

  }

  render () {
    const {
      lists
      } = this.state

    const emptyBody = <tr><td colSpan={13} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {
      const tax_invoice_date = moment(car.invoice_date).locale('th').format('LL') //วันที่ออกใบกำกับ
      const tax_invoice_number = car.invoice_number // เลขที่ใบกำกับ
      const car_name = car.car_name + ' ' + 'สี' + car.car_color_name//ชื่อรถ + สีรถ
      const car_license_plate_old = car.car_license_plate_old //ทะเบียนเก่า
      const province_name_old = car.province_name_old //จังหวัดเก่า
      const car_license_plate_new = car.car_license_plate_new //ทะเบียนใหม่
      const province_name_new = car.province_name_new //จังหวัดใหม่
      const customer_fullname = car.customer_name + ' ' + car.customer_lastname //ชื่อลูกค้า
      const tax_invoice_name = car.invoice_name //ไฟแนนซ์
      const tax_invoice_amount = car.amount // ราคาขายตามใบกำกับภาษี
      const tax_invoice_vat = parseFloat(car.amount_vat).toFixed(2) // vat ขาย
      const tax_invoice_total = car.total // รวมเงิน
      const invoice_type = car.invoice_type
      const invoice_status = car.invoice_status

      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center">{index+1}</td>
          <td style={{width: 140}} >{tax_invoice_date}</td>
          <td style={{width: 130}} className="text-center">{tax_invoice_number}</td>
          <td style={{width: 180}} >{customer_fullname}</td>
          <td style={{width: 220}}>{car_name}</td>
          <td style={{width: 120}} className="text-center">{car_license_plate_old} <br/> {province_name_old}</td>
          <td style={{width: 120}} className="text-center">{car_license_plate_new} <br/> {province_name_new}</td>
          <td style={{width: 180}} >{tax_invoice_name}</td>
          <td style={{width: 120}} align="right" >{tax_invoice_amount.toLocaleString()}</td>
          <td style={{width: 120}} align="right" >{NumberUtil.addCommas(tax_invoice_vat)}</td>
          <td style={{width: 120}} align="right" >{tax_invoice_total.toLocaleString()}</td>
          <td style={{width: 100}}>{invoice_type}</td>
          <td style={{width: 80}} className="text-center">
            {
               invoice_status === 'cancel' ? <span className="text-danger text-center"> ยกเลิก </span> : <span className="text-success text-center"> ปกติ </span>
            }
          </td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานใบกำกับภาษี</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="col-1 mt-1 text-right">
                      จาก
                    </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="mt-1 text-right">
                      ถึง
                    </div>

                    <div className="col-2 ml-3">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadTaxInvoiceData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportTaxInvoiceData()} > Excel </button>
                    </div>

                    <div className="col-4 text-right pt-2">

                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1, minHeight: 250}} >
                        <thead>
                          <tr>
                            <th  style={styles.headerTable} >ลำดับ</th>
                            <th style={{...styles.headerTable,...{width: 140}}} >วันที่ออกใบกำกับ</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >เลขที่ใบกำกับ</th>
                            <th style={{...styles.headerTable,...{width: 180}}} >ชื่อลูกค้า</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >รายละเอียดรถ</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ทะเบียนเก่า</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ทะเบียนใหม่</th>
                            <th style={{...styles.headerTable,...{width: 180}}} >ไฟแนนซ์ / ลูกค้า</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ราคา</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >Vat ขาย</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >รวม</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ประเภทรายการ</th>
                            <th style={{...styles.headerTable,...{width: 80}}} >สถานะ</th>
                          </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}


const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(TaxInvoiceReport);
