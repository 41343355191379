import React, { Component } from 'react';
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { save } from 'save-file'
import NumberFormat from "react-number-format";

class ReceiptsReport extends Component{
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      data_by: 'byDate', // all, byDate
      lists:[],
      branches: [],
      branch_id: ''
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.loadReceiptsData = this.loadReceiptsData.bind(this)
    this.exportReceiptsData = this.exportReceiptsData.bind(this)

  }

  componentDidMount () {
    this.loadBranchData()
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data}, () => this.loadReceiptsData())
      }).catch(error => {
      this.setState({branches: []})
    })
  }

  loadReceiptsData() {
    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    // console.log(data)

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/receipts', data)
        .then(res => {
          // console.log(res.data)
          this.setState({
            isLoading: false,
            lists: res.data
          }, () => {
            // this.showBranchSummary()
          })

        }).catch(error => {
        console.log('error:', error)
      })
    })

    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
  }

  async exportReceiptsData() {
    // console.log()

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(APIURL + '/report/receiptsexcel', data)
        .then(async res => {

          const excelBuffer = res.data.excelBuffer
          const currentDatetime = res.data.currentDatetime
          const fileName = 'รายงานรายรับของเต็นท์รถ_export'+'.xlsx'

          this.setState({
            isLoading: false
          })

          await save(excelBuffer, fileName)

        }).catch(error => {
        console.log('error carinexport:', error)
      })
    })

  }

  render () {
    const {
      lists
    } = this.state

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    })

    const emptyBody = <tr><td colSpan={6} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((receipts, index) => {

      let receipts_date = moment(receipts.receipts_date).format('YYYY-MM-DD')

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td className="text-center text-nowrap" width={150}>{receipts_date}
            <br/> <p className="text-left mt-1">เลขที่บิล : {receipts.receipts_no} <br/> รับจาก : {receipts.receipts_company}</p>
          </td>
          <td className="text-center text-nowrap" width={300}>{receipts.branch_name}</td>
          <td className="text-center text-nowrap" width={300}>{receipts.receipts_name}</td>
          <td className="text-center text-nowrap" width={200}>
            <NumberFormat value={receipts.receipts_amount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'฿'}/>
          </td>
          <td className="text-center text-nowrap" width={300}>{receipts.fullname}
            <br/>
            {moment(receipts.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
          </td>
        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="">
            <div className="card card-accent-primary">
              <div className="card-header">
                <div className="row col-12 mt-1">
                  <div className="col-6">
                    <strong className="text-title">รายงานรายรับของเต็นท์รถ</strong>
                  </div>
                  <div className="col-3">

                  </div>

                  {
                    this.state.lists.length === 0 ? '': (
                      <div className="col-3 text-center pt-2 pb-1 bg-primary">
                        <h5>
                          พบ&nbsp; {this.state.lists.length} &nbsp;รายการ
                        </h5>
                      </div>
                    )
                  }

                </div>
              </div>

              <div className="card-block">

                <div className="row mt-1">
                  <div className="mt-1 ml-4">
                    จาก
                  </div>

                  <div className="form-group col-2 ml-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>
                  {/* <div className="col-1 text-center">
                      ถึง
                    </div>*/}
                  <div className="form-group col-2">
                    <DatePicker
                      selected={this.state.date_end}
                      onChange={this.handleDateEnd}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="form-group col-2">
                    <select className="form-control" id="branch_id" name="branch_id"
                            onChange={(e) => {
                              this.setState({
                                branch_id: e.target.value
                              })
                            }}
                    >
                      <option value=''> ตามสาขา </option>
                      {car_branch_list}
                    </select>
                  </div>

                  <div className="form-group mt-1 ml-3">
                    <input type="radio"
                           name="data_by"
                           value="byDate"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'byDate'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ตามวันที่</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="data_by"
                           value="all"
                           className="btn btn-primary"
                           checked={this.state.data_by === 'all'}
                           onChange={this.handleOnChange}
                    /> &nbsp;<span style={{marginTop: -4}}>ทั้งหมด</span>
                  </div>



                  <div className="col-1 ml-4 mr-1">
                    <button type="button" style={{width: 80}} className="btn btn-sm btn-primary" onClick={() => this.loadReceiptsData()} > ตกลง </button>
                  </div>

                  <div className="col-1 ml-1">
                    <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportReceiptsData()} > Excel </button>
                  </div>

                </div>

                <div className="row mt-1">
                  <div className="col-12">

                    <table className="table table-striped table-responsive">
                      <thead>
                      <tr>
                        <th style={{width: 20}} className="text-center text-nowrap">ลำดับ</th>
                        <th style={{width: 80}} className="text-center text-nowrap">วันที่รับเงิน</th>
                        <th style={{width: 150}} className="text-center text-nowrap">สาขา</th>
                        <th style={{width: 200}} className="text-center text-nowrap">รายละเอียด</th>
                        <th style={{width: 80}} className="text-center text-nowrap">ยอดรายรับ</th>
                        <th style={{width: 50}} className="text-center text-nowrap">ผู้บันทึก</th>
                      </tr>
                      </thead>
                      <tbody>
                       {tableRows}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}

const styles = {
  overflowY: {
    overflow: 'scroll',
  }
}

export default ReceiptsReport
