import {format, parse, isValid} from "date-fns";
import { enGB } from 'date-fns/locale';

export const DateUtil = {
  convertShortDateThai(date) {
    // ddmmyyyy
    try {
      let arrDate = date.split('/')
      let dd = arrDate[0]
      let mm = arrDate[1]
      let yyyy = parseInt(arrDate[2], 10) + 543
      return  dd+'/'+mm+'/'+yyyy
    } catch (e) {
      return "InvalidDate"
    }
  },
  convertShortDateThaiDash(date) {
    // 2023-11-31 => dd/mm/yyyy
    try {
      let arrDate = date.split('-')
      let dd = arrDate[2]
      let mm = arrDate[1]
      let yyyy = parseInt(arrDate[0], 10) + 543
      return  dd+'/'+mm+'/'+yyyy
    } catch (e) {
      return "InvalidDate"
    }
  },
  convertLongDateThai(data) {
    // 2022-03-31
    let splitData = data.split('-');
    let date = splitData[2];
    let month = parseInt(splitData[1], 10);
    let year = splitData[0];
    let yearBudd = parseInt(year, 10) + 543
    let monthName = ''
    switch (month) {
      case 1: monthName = 'มกราคม'; break;
      case 2: monthName = 'กุมภาพันธ์'; break;
      case 3: monthName = 'มีนาคม'; break;
      case 4: monthName = 'เมษายน'; break;
      case 5: monthName = 'พฤษภาคม'; break;
      case 6: monthName = 'มิถุนายน'; break;
      case 7: monthName = 'กรกฎาคม'; break;
      case 8: monthName = 'สิงหาคม'; break;
      case 9: monthName = 'กันยายน'; break;
      case 10: monthName = 'ตุลาคม'; break;
      case 11: monthName = 'พฤศจิกายน'; break;
      case 12: monthName = 'ธันวาคม'; break;
      default:
        monthName = ''
    }
    return date+' ' + monthName+' '+yearBudd
  },
  calculateAge (birthDate) {

    birthDate = new Date(birthDate);
    // otherDate = new Date(otherDate);
    let otherDate = format(new Date(),'yyyy-MM-dd')
    otherDate = new Date(otherDate);

    let years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() ||
        otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
      years--;
    }

    return years;
  },
  isValidDate(day, month, year) {
    const parsedDate = parse(`${day}/${month}/${year}`, 'P', new Date(), { locale: enGB })
    const formattedDate = format(parsedDate, 'yyyy-MM-dd');
    return {
      isValid: isValid(parsedDate),
      formatDate: isValid(parsedDate) ? formattedDate : null
    }
  }
}
