/**
 * พิมพ์สัญญาซื้อรถ
 * **/

import React, { Component } from 'react'
import axios from 'axios'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Loading from '../../Loading';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import { DateUtil } from '../../../utils/dateUtil';
import { APIURL } from '../../../config/config';
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format';
import { AlertSuccess } from '../../Alert/Alert';
import { NumberUtil } from '../../../utils/number-util';

import "react-datepicker/dist/react-datepicker.css";
import DatePickerIcon from '../../DatePickerIcon'
import { format } from 'date-fns'
import stringUtil from "../../../utils/stringUtil";
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";


pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const port = window.env.PORT;

class CarBuyContract extends Component {
  constructor (props) {
    super(props)
    this.state = {
      car: {},
      user: {},
      contract: null,
      contract_id: null,
      contract_no: '',
      write_at: '',
      // date
      startDateContract: new Date(),
      isOpenDateContract: false,
      contract_date_thai: null,
      contract_date: null,
      // end date
      seller_name: '', // ผู้ขาย
      seller_age: '' ,
      seller_mobile: '' ,
      seller_address: '' ,

      card_type: 'บัตรประชาชน',
      card_code: '',
      card_out_at: '',
      card_out_date: '' ,

      //
      car_name: '',
      car_license_plate: '',
      car_num_engines: '',
      car_number: '', // เลขตัวรถ
      car_year_name: '', // ปี
      car_color_name: '', // สี
      car_equipment: '', // อุปกรณ์
      //
      // ขายให้แก่
      buyer_name: '', // สี
      car_buy_cost: 0,
      pay_method: '', // โดยชำระเป็น
      // buyer_age: '',
      // buyer_address: '',
      // buyer_mobile: '',
      // real_sell_price: 0,
      cash_pledge: 0,
      remain_amount: 0,
      // date
      startPaidDueDate: new Date(),
      isOpenPaidDueDate: false,
      paid_due_date_thai: null,
      paid_due_date: null,
      // end date
      isExist: false,
      car_id: '',
      contract_note: '',
      province_name : '',
      menuID: [26],
      grouppermissions: [],
    }

    this.handleDateContract = this.handleDateContract.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCarById = this.loadCarById.bind(this);
    // this.loadCarSellById = this.loadCarSellById.bind(this);
    this.loadCarBuyContract = this.loadCarBuyContract.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this);
    this.calOutstanding = this.calOutstanding.bind(this);
    this.handlePaidDueDate = this.handlePaidDueDate.bind(this);
    this.saveCarContract = this.saveCarContract.bind(this);
    this.printCarContract = this.printCarContract.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.manageAddEditButton = this.manageAddEditButton.bind(this);
    this.managePrintButton = this.managePrintButton.bind(this);

  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  manageAddEditButton() {

    if (this.state.contract_id === null && checkGroupPermission(26, this.state.grouppermissions).created) {
      return (
          <button type="button"
                  className="btn btn-success btn-md mr-2"
                  style={{width: 120}}
                  onClick={() => this.saveCarContract('add')}
          >
            <i className="icon-check"/>
            &nbsp;บันทึก
          </button>
      )
    } else if (this.state.contract_id !== null && checkGroupPermission(26, this.state.grouppermissions).modified) {
      return (
          <button type="button"
                  className="btn btn-warning btn-md mr-2"
                  style={{width: 120}}
                  onClick={() => this.saveCarContract('edit')}
          >
            <i className="icon-pencil"/>
            &nbsp;แก้ไข
          </button>
      )
    }
  }

  managePrintButton() {
    if (this.state.contract_id !== null && checkGroupPermission(26, this.state.grouppermissions).printed) {
      return <button type="button"
                     className="btn btn-primary btn-md mr-2"
                     style={{width: 120}}
                     onClick={() => this.printCarContract()}
      >
        <i className="icon-printer"/>  พิมพ์
      </button>
    }
    return null
  }


  componentWillMount () {
    const queryString = require('query-string')
    let car = queryString.parse(this.props.location.search)
    let car_id = car.car_id

    this.setState({
      user: JSON.parse(this.props.user),
      car_id: car_id,
    });

  }

  async componentDidMount () {
    this.mounted = true

    this.loadGroupPermission();
    await this.loadCompanySetting();
    await this.loadCarBuyContract();
    await this.loadCarById();

    let contract_id = this.state.contract_id

    if(contract_id === null) {
      let startDateContract = new Date();
      let contract_date = format(startDateContract, 'yyyy-MM-dd')
      let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

      this.setState({
        contract_date,
        contract_date_thai
      })
    }
    // await this.loadCarContract(car_sell_id);
    // await this.loadCarSellById(car_sell_id);

  }

  componentWillUnmount() {
    this.mounted = false
    // window.removeEventListener('resize', this.updateWindowDimensions);
    this.setState({
      user: JSON.parse(this.props.user)
    })
  }


  async loadCompanySetting () {
    await axios.get(APIURL + '/company_setting/1')
      .then(res => {
        let data = res.data
        let company_setting = data.company_setting
        if (company_setting !== null) {
          this.setState({
            company_setting: company_setting,
            buyer_name: company_setting.company_name
          })
        }
      })
  }

  async loadCarBuyContract () {

    const { car_id } = this.state
    let url = APIURL + '/buy_contract/car/' + car_id

    await axios.get(url)
      .then(res => {
        let data = res.data

        if (data.length > 0){
          let contract = data[0]

          let contract_date = contract.contract_date !== null ? format(new Date(contract.contract_date), 'yyyy-MM-dd') : ''
          let paid_due_date = contract.paid_due_date !== null ? format(new Date(contract.paid_due_date), 'yyyy-MM-dd') : ''

          let contract_date_thai = contract.contract_date !== null ? DateUtil.convertLongDateThai(contract_date) : ''
          let paid_due_date_thai = contract.paid_due_date !== null ? DateUtil.convertLongDateThai(paid_due_date) : ''

          let province_name = contract.car_license_plate_new !== '' &&  contract.car_license_plate_new !==  contract.car_license_plate_old ? contract.province_name_new : contract.province_name_old

          this.setState({
            contract: contract ,
            contract_date_thai: contract_date_thai,
            paid_due_date_thai: paid_due_date_thai,
            contract_id: contract.contract_id,
            buyer_name: contract.buyer_name ,
            car_cost: contract.car_buy_cost ,
            car_color_name: contract.car_color_name ,
            car_equipment: contract.car_equipment ,
            car_id: contract.car_id ,
            car_license_plate: contract.car_license_plate ,
            car_name: contract.car_name ,
            car_num_engines: contract.car_num_engines ,
            car_number: contract.car_number ,
            car_year_name: contract.car_year_name ,
            card_code: contract.card_code ,
            card_out_at: contract.card_out_at ,
            card_out_date: contract.card_out_date ,
            card_type: contract.card_type ,
            cash_pledge: contract.cash_pledge ,
            contract_date: contract_date ,
            contract_no: contract.contract_no ,
            contract_note: contract.contract_note ,
            contract_type: contract.contract_type ,
            paid_due_date: paid_due_date ,
            pay_method: contract.pay_method ,
            seller_address: contract.seller_address ,
            seller_age: contract.seller_age ,
            seller_mobile: contract.seller_mobile ,
            seller_name: contract.seller_name ,
            write_at: contract.write_at ,
            province_name : province_name,

          })

        }

      })
      .catch(err => {
        console.log('err: ', err)
      })

    /*await axios.get(APIURL + '/contract/car_sell/' + car_sell_id)
      .then(res => {
        let data = res.data
        if(data.length === 1){
          let contract = data[0];
          // let contract_id = contract.contract_id
          // contract_id: contract_id
          let contract_date =  moment(contract.contract_date).format('YYYY-MM-DD')
          let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

          let paid_due_date =  moment(contract.paid_due_date).format('YYYY-MM-DD')
          let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

          this.setState({
            contract_date_thai: contract_date_thai,
            paid_due_date_thai: paid_due_date_thai,

            contract_id : contract.contract_id,
            contract_no : contract.contract_no,
            contract_date : contract.contract_date,
            contract_type : contract.contract_type,
            buyer_address : contract.buyer_address,
            buyer_age : contract.buyer_age,
            buyer_mobile : contract.buyer_mobile,
            buyer_name : contract.buyer_name,
            car_color_name : contract.car_color_name,
            car_id : contract.car_id,
            car_license_plate : contract.car_license_plate,
            car_name : contract.car_name,
            car_num_engines : contract.car_num_engines,
            car_number : contract.car_number,
            car_sell_id : contract.car_sell_id,
            car_year_name : contract.car_year_name,
            card_code : contract.card_code,
            card_out_at : contract.card_out_at,
            card_type : contract.card_type,
            cash_pledge : contract.cash_pledge,
            customer_id : contract.customer_id,
            paid_due_date : contract.paid_due_date,
            real_sell_price : contract.real_sell_price,
            seller_name : contract.seller_name,
            write_at : contract.write_at
          })
        }
      })
     */
  }

  async loadCarById () {

    const { contract_id } = this.state

    if(contract_id  !== null) {
      return
    }

    await axios.get(APIURL + '/car/'+this.state.car_id)
      .then(res => {
        let car = res.data[0]

        let seller_name = car.car_name_owner_old +' '+car.car_lastname_owner_old
        let seller_mobile = car.car_telephone_old
        let seller_address = car.car_address_old
        let card_code = car.car_id_card

        // let car_name = car.car_name
        let car_name = ''
        let car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.car_license_plate_new : car.car_license_plate_old
        let car_num_engines = car.car_num_engines // เลขเครื่องยนต์
        let car_number = car.car_number // เลขตัวรถ
        let car_year_name = car.car_year_name // ปี
        let car_color_name = car.car_color_name // สี

        let car_brand_name = car.car_brand_name
        let car_model_name = car.car_model_name
        let car_sub_model_name = car.car_sub_model_name

        if (car_sub_model_name.indexOf(car_model_name) !== -1) {
          car_name = car_brand_name +' '+ car_sub_model_name
        } else {
          car_name = car_brand_name +' '+ car_model_name+ ' '+car_sub_model_name
        }

        let province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ? car.province_name_new : car.province_name_old

        let vat_buy = car.vat_buy

        let car_cost = car.car_cost
        if(vat_buy > 0){
          car_cost = car_cost + vat_buy
        }

        this.setState({
          car: car,
          seller_name: seller_name,
          seller_mobile: seller_mobile,
          seller_address: seller_address,
          card_code: card_code,
          //
          car_name,
          car_license_plate,
          car_num_engines,
          car_number,
          car_year_name,
          car_color_name,
          province_name,
          car_cost,
        })
      }).catch(error => {
      // alert("error"+error)
      this.setState({car: {}})
    })
  }

  /*async loadCarSellById (car_sell_id) {

    if (this.state.contract_id !== null) {
      // มีรายการสัญญาแล้ว
      return
    }

    await axios.get(APIURL + '/car_sell/' + car_sell_id)
      .then(res => {
        if(this.mounted) {
          let car_sell = res.data[0]
          let car_sell_date = car_sell.car_sell_date
          let date = new Date(car_sell_date)

          let car_name = car_sell.car_name
          let car_license_plate = car_sell.car_license_plate_new
          let car_num_engines = car_sell.car_num_engines // เลขเครื่องยนต์
          let car_number = car_sell.car_number // เลขตัวรถ
          let car_year_name = car_sell.car_year_name // ปี
          let car_color_name = car_sell.car_color_name // สี

          let customer_lastname = car_sell.customer_lastname
          let buyer_name = car_sell.customer_name +' ' + customer_lastname // ผู้ซื้อ
          let buyer_age = car_sell.customer_age // ผู้ซื้อ
          let buyer_address = car_sell.customer_address // ผู้ซื้อ
          let buyer_mobile = car_sell.customer_mobile // ผู้ซื้อ
          let card_code = car_sell.customer_card_id // ผู้ซื้อ

          let real_sell_price = car_sell.real_sell_price
          let car_id = car_sell.car_id
          let customer_id = car_sell.customer_id

          this.setState({
            car_name,
            car_license_plate,
            car_num_engines,
            car_number,
            car_year_name,
            car_color_name,
            buyer_name,
            buyer_age,
            buyer_address,
            buyer_mobile,
            card_code,
            real_sell_price,
            car_id,
            customer_id,
            car_sell_id
          }, () => {
            this.loadDataCarSellPay(car_sell, car_id, customer_id)
          })
        }
      }).catch(error => {
        console.log ('error:', error)
      })
  }*/

  handleDateContract (date) {
    // let contract_date =  moment(date).format('YYYY-MM-DD')
    let contract_date =  format(date, 'yyyy-MM-dd')
    let contract_date_thai = DateUtil.convertLongDateThai(contract_date)

    this.setState({
      startDateContract: date,
      isOpenDateContract: false,
      contract_date: contract_date,
      contract_date_thai: contract_date_thai
    })
  }

  handlePaidDueDate (date) {
    let paid_due_date =  moment(date).format('YYYY-MM-DD')
    let paid_due_date_thai = DateUtil.convertLongDateThai(paid_due_date)

    this.setState({
      startPaidDueDate: date,
      isOpenPaidDueDate: false,
      paid_due_date: paid_due_date,
      paid_due_date_thai: paid_due_date_thai
    })
  }

  handleOnChange(e) {
    let name = e.target.name;
    let val = e.target.value;

    this.setState({
      [name]: val
    })
  }

  calOutstanding() {
    let car_cost = this.state.car_cost
    let cash_pledge = this.state.cash_pledge
    let remain_amount = Number(car_cost) - Number(cash_pledge)

    this.setState({
      remain_amount: remain_amount,
    })
  }


  loadDataCarSellPay (car_sell, car_id, customer_id) {

    if (car_id === '' || customer_id === '') {
      return
    }

    axios.get(APIURL + '/car_sell_pay/buy/' +car_id + '/' + customer_id)
      .then(res => {
        let car_sell_pay_list = res.data
        let sum_pay = car_sell_pay_list.reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)

        let real_sell_price = this.state.real_sell_price
        let outstanding = Number(real_sell_price) - sum_pay

        this.setState({
          cash_pledge: sum_pay,
          outstanding: outstanding
        })

      }).catch(error => {
      console.log('error:', error)
    })
  }

  saveCarContract(action) {

    let contract_no = this.state.contract_no
    let write_at = this.state.write_at
    let contract_date = this.state.contract_date
    let seller_name = this.state.seller_name
    let car_name = this.state.car_name
    let car_license_plate = this.state.car_license_plate
    let car_num_engines = this.state.car_num_engines
    let car_number = this.state.car_number
    let car_year_name = this.state.car_year_name
    let car_color_name = this.state.car_color_name
    let buyer_name = this.state.buyer_name

    let card_type = this.state.card_type
    let card_code = this.state.card_code
    let card_out_at = this.state.card_out_at

    let paid_due_date = this.state.paid_due_date
    let car_id = this.state.car_id

    let car_equipment = this.state.car_equipment
    let car_buy_cost = this.state.car_cost
    let cash_pledge = this.state.cash_pledge
    let pay_method = this.state.pay_method // โดยชำระเป็น
    let contract_note = this.state.contract_note // หมายเหตุ

    let contract_type = 'buy' // sell, buy

    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai

    let seller_age = this.state.seller_age
    let seller_mobile = this.state.seller_mobile
    let seller_address = this.state.seller_address
    let card_out_date = this.state.card_out_date


    if (contract_no === '') {
      AlertSuccess('กรุณากรอกเลขสัญญา');
      return
    }

    if(write_at === '') {
      AlertSuccess('กรุณากรอกเขียนที่');
      return
    }

    if (contract_date_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ทำสัญญา');
      return
    }

    if (paid_due_date_thai === null) {
      AlertSuccess('กรุณาเลือกวันที่ครบกำหนดชำระ');
      return
    }

    let username = this.state.user.user

    let data = {
      contract_no : contract_no,
      write_at : write_at,
      contract_date : contract_date,
      seller_name : seller_name,
      car_name : car_name,
      car_license_plate : car_license_plate,
      car_num_engines : car_num_engines,
      car_number : car_number,
      car_year_name : car_year_name,
      car_color_name : car_color_name,
      buyer_name : buyer_name,
      card_type : card_type,
      card_code : card_code,
      card_out_at : card_out_at,
      cash_pledge : cash_pledge,
      paid_due_date : paid_due_date,
      car_id : car_id,
      contract_type : contract_type,
      car_equipment : car_equipment,
      car_buy_cost,
      pay_method,
      contract_note,
      username,
      seller_age,
      seller_mobile,
      seller_address,
      card_out_date
    }

    this.setState({
      isLoading: true,
    }, () => {
      if (action === 'add') {
        let url = APIURL + '/buy_contract';
        axios.post( url, data)
          .then(res => {
            if (res.status === 200) {
              let resData = res.data
              let contract_id = resData.contract_id;

              AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
                contract_id: contract_id
                // isExist: true,
              }, () => this.loadCarBuyContract())
            }
          }).catch(error => {
          console.log('error:', error)
        });
      } else if (action === 'edit') {
        let contract_id = this.state.contract_id
        let editData = {...data, contract_id: contract_id }
        let url = APIURL + '/buy_contract';

        axios.put( url, editData)
          .then(res => {
            if (res.data === 'success') {
              AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
              this.setState({
                isLoading: false,
              }, () => this.loadCarBuyContract())
            }
          }).catch(error => {
          console.log('error:', error)
        });
      }

    });

  }

  printCarContract() {
    let contract = this.state.contract;
    let contract_no = contract.contract_no;
    let write_at = contract.write_at;
    let seller_name = contract.seller_name;
    let car_name = contract.car_name;
    let car_license_plate = contract.car_license_plate;
    let car_num_engines = contract.car_num_engines;
    let car_number = contract.car_number;
    let car_year_name = contract.car_year_name;
    let car_color_name = contract.car_color_name;
    let buyer_name = contract.buyer_name;
    let card_type = contract.card_type;
    let card_code = contract.card_code;
    let card_out_at = contract.card_out_at;
    let cash_pledge = contract.cash_pledge;
    let car_equipment = contract.car_equipment;
    let car_buy_cost = contract.car_buy_cost;
    let pay_method = contract.pay_method;
    // let contract_note = contract.contract_note === '' ? '...-...' : contract.contract_note ;
    let contract_note = contract.contract_note;
    let seller_age = contract.seller_age;
    let seller_mobile = contract.seller_mobile;
    let seller_address = contract.seller_address;
    let card_out_date = contract.card_out_date; // fill text
    //
    let contract_id = contract.contract_id;
    let contract_date = contract.contract_date;
    let paid_due_date = contract.paid_due_date;
    let car_id = contract.car_id;
    let contract_type = contract.contract_type;
    let username = contract.username;

    let contract_date_thai = this.state.contract_date_thai
    let paid_due_date_thai = this.state.paid_due_date_thai
    let outstanding =  Number(car_buy_cost) - Number(cash_pledge)

    let province_name = this.state.province_name

    let seller_name_pad = stringUtil.addSpacePaddingFill(seller_name, 42)
    let seller_age_pad = stringUtil.addSpacePaddingFill(seller_age, 10)
    let seller_address_pad = stringUtil.addSpacePaddingFill(seller_address, 80)
    let seller_mobile_pad = stringUtil.addSpacePaddingFill(seller_mobile, 36)
    let card_code_pad = stringUtil.addSpacePaddingFill(card_code, 28)
    let card_out_at_pad = stringUtil.addSpacePaddingFill(card_out_at, 38)
    let card_out_date_pad = stringUtil.addSpacePaddingFill(card_out_date, 28)
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 60)
    let car_year_name_pad = stringUtil.addSpacePaddingFill(car_year_name, 18)
    let car_color_name_pad = stringUtil.addSpacePaddingFill(car_color_name, 22)
    let car_equipment_pad = stringUtil.addSpacePaddingFill(car_equipment, 74)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license_plate, 40)
    let province_name_pad = stringUtil.addSpacePaddingFill(province_name, 24)
    let car_num_engines_pad = stringUtil.addSpacePaddingFill(car_num_engines, 44)
    let car_number_pad = stringUtil.addSpacePaddingFill(car_number, 40)
    let buyer_name_pad = stringUtil.addSpacePaddingFill(buyer_name, 60)
    let pay_method_pad = stringUtil.addSpacePaddingFill(pay_method, 124)
    let paid_due_date_thai_pad = stringUtil.addSpacePaddingFill(paid_due_date_thai, 28)

    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [48, 35, 40, 0],
      content: [
        {
          columns: [
            { width: '100%', style: 'normal',
              text: '',
            },
          ],
          columnGap: 0,
          lineHeight:1.5
        },
        {
          table: {
            widths: [ '30%', '40%', '30%' ],
            body: [
              [
                {text: '', style: 'normal'},
                {
                  text: 'สัญญาซื้อรถยนต์',
                  style: 'bodyhead', margin: [0,0,0,5]
                },
                {text: '', style: 'normal'},
              ],
              [
                {text: 'No.'+ contract_no, style: 'normal'},
                {text: 'เขียนที่ ' + write_at, style: 'normalRight', colSpan: 2},
                ''
              ],
              [
                {text: 'วันที่ ' + contract_date_thai, style: 'normalRight', colSpan: 3, margin: [0,0,0,5]},
                '',
                ''
              ],
              [
                {text: '', style: 'normalRight', colSpan: 3},
                '',
                ''
              ],
            ],
          },
          layout: 'noBorders'
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 40, style: 'normal', text: 'ข้าพเจ้า ',},
            { width: '33%', style: ['normal', 'bottomLine'], text: seller_name_pad,},
            { width: 25, style: 'normal', text: 'อายุ ',},
            { width: 14, style: ['normalLeft', 'bottomLine'], text: seller_age_pad,},
            { width: 12, style: 'normal', text: 'ปี',},
            { width: 68, style: 'normal', text: 'เบอร์โทรศัพท์',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: seller_mobile_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: 60, style: 'normal', text: 'อยู่บ้านเลขที่',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: seller_address_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: 60, style: 'normal', text: card_type,},
            { width: 50, style: 'normal', text: 'เลขที่บัตร',},
            { width: '22%', style: ['normalLeft', 'bottomLine'], text: card_code_pad,},
            { width: 60, style: 'normal', text: 'ออกให้ ณ',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: card_out_at_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: 42, style: 'normal', text: 'เมื่อวันที่',},
            { width: '20%', style: ['normalLeft', 'bottomLine'], text: card_out_date_pad,},
            { width: '18%', style: 'normal', text: 'ได้ตกลงนำรถยนต์',},
            { width: '40%', style: ['normalLeft', 'bottomLine'], text: car_name_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: 30, style: 'normal', text: 'ปีรถ',},
            { width: 44, style: ['normalLeft', 'bottomLine'], text: car_year_name_pad,},
            { width: 30, style: 'normal', text: 'สีรถ',},
            { width: 60, style: ['normalLeft', 'bottomLine'], text: car_color_name_pad,},
            { width: 40, style: 'normal', text: 'อุปกรณ์',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: car_equipment_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: 80, style: 'normal', text: 'หมายเลขทะเบียน',},
            { width: 50, style: ['normalLeft', 'bottomLine'], text: car_license_pad,},
            { width: '18%', style: ['normalLeft', 'bottomLine'], text: province_name_pad,},
            { width: '20%', style: 'normal', text: 'หมายเลขเครื่องยนต์',},
            { width: '20%', style: ['normalLeft', 'bottomLine'], text: car_num_engines_pad,},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: '15%', style: 'normal', text: 'หมายเลขตัวถัง',},
            { width: '20%', style: ['normalLeft', 'bottomLine'], text: car_number_pad,},
          ],
          columnGap: 10,
          lineHeight:1,
          margin:[0,0,0,6]
        },

        {
          columns: [
            { width: 12, style: 'normal', text: ' ',},
            { width: '100%', style: 'normal', text: 'ซึ่งข้าพเจ้าขอรับรองว่าเป็นกรรมสิทธิ์ของข้าพเจ้าแต่ผู้เดียว โดยชอบด้วยกฎหมาย และมิได้มีการติดพันใด ๆ ทั้งสิ้น',},
          ],
          columnGap: 10,
          lineHeight:1
        },
        {
          columns: [
            { width: '15%', style: 'normal', text: 'ตกลงขาย ให้แก่',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: buyer_name_pad,},
          ],
          columnGap: 10,
          lineHeight:1,
          margin:[0,0,0,6]
        },

        {
          columns: [
            { width: '28%',
              text: ' เป็นจำนวนเงินทั้งสิ้น', style: 'normal',
            },
            { width: '20%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(car_buy_cost, 10)),
            },
            { width: '6%', style: 'normal',
              text: 'บาท',
            },
            { width: '55%', style: 'normal',
              text: '('+ThaiBaht(car_buy_cost)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '29%',
              text: ' ข้าพเจ้าได้รับมัดจำแล้วเป็นเงิน', style: 'normal',
            },
            { width: '19%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(cash_pledge, 10)),
            },
            { width: '6%', style: 'normal',
              text: 'บาท',
            },
            { width: '55%', style: 'normal',
              text: '('+ThaiBaht(cash_pledge)+')',
            },
          ],
          columnGap: 0,
          lineHeight:1
        },
        {
          columns: [
            { width: '15%', style: 'normal', text: 'โดยชำระเป็น',},
            { width: '*', style: ['normalLeft', 'bottomLine'], text: pay_method_pad,},
          ],
          columnGap: 0,
          lineHeight: 1.0
        },
        {
          columns: [
            { width: '28%',
              text: ' ส่วนที่เหลืออีกเป็นจำนวนเงิน', style: 'normal',
            },
            { width: '20%', style: 'normalCenter',
              text: NumberUtil.addCommas(parseInt(outstanding, 10)),
            },
            { width: '6%', style: 'normal',
              text: 'บาท',
            },
            { width: '55%', style: 'normal',
              text: '('+ThaiBaht(outstanding)+')',
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin:[0,0,0,6]
        },
        {
          columns: [
            { width: '5%', style: 'normal', text: ' ',},
            { width: '*', style: 'normal', text: 'ผู้ซื้อจะชำระให้เมื่อข้าพเจ้าได้นำทะเบียนรถยนต์พร้อมหลักฐานการโอนกรรมสิทธิ์รถยนต์คันดังกล่าวมามอบให้แก่ผู้ซื้อ',},
          ],
          columnGap: 0,
          lineHeight: 1.0
        },
        {
          columns: [
            { width: '13%', style: 'normal', text: 'ภายในวันที่',},
            { width: '20%', style: ['normalLeft', 'bottomLine'], text: paid_due_date_thai_pad,},
            { width: '*', style: 'normal', text: 'ถ้าหากรถยนต์คันดังกล่าวนี้ ปรากฎในภายหลังว่าไม่ใช่รถยนต์ของข้าพเจ้า หรือผู้ซื้อ',},
          ],
          columnGap: 0,
          lineHeight: 1.0
        },
        {
          columns: [
            { width: '*', style: 'normal', text: 'ไม่สามารถโอนได้ด้วยเหตุใด ๆ ข้าพเจ้ายินยอมให้ดำเนินคดีได้ทันที และหากผู้ซื้อได้รับความเสียหายแล้ว ข้าพเจ้ายอมคืนเงินทั้งหมดที่ได้รับจากผู้ซื้อ พร้อมทั้งดอกเบี้ยตามกฎหมาย ตลอดจนค่าใช้จ่ายใด ๆ ที่ผู้ซื้อต้องเสียไปอีกทั้งสิ้น',},
          ],
          columnGap: 0,
          lineHeight: 1.0
        },

        {
          columns: [
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)..............................................................................ผู้ขาย'
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)..............................................................................ผู้ซื้อ'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 15, 0, 0,]
        },

        {
          columns: [
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)..............................................................................พยาน'
            },
            {
              width: '50%',
              style: 'normal',
              text: '(ลงนาม)..............................................................................พยาน'
            }
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 8, 0, 0,]
        },

        {
          columns: [
            {
              width: '50%',
              style: 'normal',
              text: 'เบอร์โทรศัพท์.....................................................................ผู้ขาย'
            },
            {
              width: '50%',
              style: 'normal',
              text: 'เบอร์โทรศัพท์.....................................................................ผู้ซื้อ'
            }
          ],
          columnGap: 4,
          lineHeight: 1,
          margin: [0, 8, 0, 10,]
        },

        {
          table: {
            widths: [ '*' ],
            body: [
              [
                {
                  columns: [
                    {
                      width: '10%',
                      style: 'textBold',
                      text: 'หมายเหตุ'
                    },
                    {
                      width: '90%',
                      style: 'normal',
                      text: 'ถ้าหากปรากฎในภายหลังว่า'
                    }
                  ],
                  margin: [8, 8, 8, 0,],
                  border: [false, true, false, false],
                  fillColor: '#cccccc',
                },
              ],
              [
                {
                  columns: [
                    {
                      width: '10%',
                      style: 'textBold',
                      text: ''
                    },
                    {
                      width: '90%',
                      style: 'normal',
                      text: '1. รถคันดังกล่าว ได้รับใบสั่งของเจ้าพนักงานจราจรเป็นค่าปรับไม่ว่ากรณีใด ๆ ก็ตามจนกระทั่งถึงวันขาย'
                    }
                  ],
                  margin: [8, 0, 16, 0,],
                  border: [false, true, false, false],
                  fillColor: '#cccccc',
                },
              ],
              [
                {
                  columns: [
                    {
                      width: '10%',
                      style: 'textBold',
                      text: ''
                    },
                    {
                      width: '90%',
                      style: 'normal',
                      text: '2. หากรถคันดังกล่าวมิได้เสียภาษีประจำปี (รวมทั้งค่าปรับที่ขาดต่อ) จนกระทั่ง ถึงวันที่ขายดังนั้นตามข้อ 1 และ ข้อ 2 ข้าพเจ้า เจ้าของรถยนต์ หรือผู้ขาย ยินดีรับผิดชอบและชดใช้ทั้งสิ้น'
                    }
                  ],
                  margin: [8, 0, 16, 0,],
                  border: [false, true, false, false],
                  fillColor: '#cccccc',
                },
              ],
              [
                {
                  columns: [
                    {
                      width: '100%',
                      style: 'textLargeBold',
                      text: port === 9038 || port === 9039 || port === 9000 ? '' : '* หากผู้ขายไม่นำรถยนต์มาให้ผู้ซื้อในเวลาที่กำหนด ผู้ขายยินยอมให้ปรับเป็น 5 เท่าของเงินมัดจำ *'
                    },
                  ],
                  margin: [8, 8, 16, 16,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '100%',
                      style: 'normalCenter',
                      text: 'ลงชื่อ.......................................................ผู้ขาย/เจ้าของรถยนต์'
                    }
                  ],
                  margin: [8, 0, 8, 2,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
              [
                {
                  columns: [
                    {
                      width: '30%',
                      style: 'normal',
                      text: ''
                    },
                    {
                      width: '70%',
                      style: 'normal',
                      text: '(.......................................................)'
                    }
                  ],
                  margin: [8, 0, 6, 10,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],
              /*[
                {
                  columns: [
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)__________________________พยาน'
                    },
                    {
                      width: '50%',
                      style: 'normal',
                      text: '(ลงนาม)____________________________พยาน'
                    },
                  ],
                  margin: [8, 0, 8, 8,],
                  border: [true, false, true, false],
                  fillColor: '#cccccc',
                }
              ],*/
            ]
          },
          layout: 'noBorders'
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: contract_note === '' || contract_note === null ? 'หมายเหตุ ______________________________________________________________________________________'
                :
                'หมายเหตุ ' + ' ' + contract_note
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 10, 0, 0,]
        },
        {
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: contract_note === '' || contract_note === null ? '______________________________________________________________________________________________'
                  : ' '
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 10, 0, 0,]
        },
        /*{
          columns: [
            {
              width: '100%',
              style: 'normal',
              text: '______________________________________________________________________________________________'
            },
          ],
          columnGap: 0,
          lineHeight: 1,
          margin: [0, 10, 0, 0,]
        },*/
      ],
      styles: {
        bodyhead: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        normal: {
          fontSize: 15,
        },
        normalRight: {
          fontSize: 15,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 15,
          alignment: 'left',
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center',
        },
        textBold: {
          fontSize: 15,
          bold: true,
        },
        textLargeBold: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        }

      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    pdfMake.createPdf(docDefinition).open();

  }

  render() {
    return (
      <div className="row">

        <Loading isLoading={this.state.isLoading} />

        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <strong className="text-title">สัญญาซื้อรถยนต์</strong>
            </div>

            <div className="card-block" style={{paddingLeft: 80, paddingRight: 80, paddingTop: 10}} >

              <div className="row" style={{lineHeight: 2.0}}>

                <table style={styles.table}>
                  <tbody>
                  <tr>
                    <td width={'30%'} style={styles.tdContentCenter} >
                      &nbsp;
                    </td>
                    <td style={styles.tdContentCenter}>
                      <span style={{fontSize: 18}}> สัญญาซื้อรถยนต์</span>
                    </td>
                    <td width={'30%'}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td style={styles.tdContentLeft}  >
                      <div className="form-inline mt-5 mb-5">
                        No.
                        <input className="form-control ml-4"
                               style={styles.inputDataNum}
                               value={this.state.contract_no}
                               onChange={(e) => {
                                 this.setState({
                                   contract_no: e.target.value
                                 })
                               }}
                        />
                      </div>
                    </td>
                    <td>&nbsp;</td>
                    <td style={styles.tdContentRight} >
                      <div className="form-inline mt-5 mb-3" style={{justifyContent: 'right' }}>
                        เขียนที่ <input className="form-control ml-4"
                                        style={styles.inputDataWriteAt}
                                        value={this.state.write_at}
                                        onChange={(e) => {
                                          this.setState({
                                            write_at: e.target.value
                                          })
                                        }}
                      />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style={styles.tdContentLeft}  > &nbsp; </td>
                    <td>&nbsp;</td>
                    <td style={styles.tdContentRight} >
                      <div className="row" style={{justifyContent: 'right'}}>
                        วันที่ &nbsp;&nbsp;&nbsp;

                        <input readOnly={true}
                               className="form-control"
                               style={{width: 162}}
                               value={this.state.contract_date_thai === null ? '' : this.state.contract_date_thai}
                        />

                        <div style={styles.iconCalendar}>
                          <DatePicker
                            selected={this.state.startDateContract}
                            onChange={this.handleDateContract}
                            dateFormat="yyyy-MM-dd"
                            customInput={<DatePickerIcon />}
                            //popperPlacement="left"
                          />
                        </div>

                      </div>

                    </td>
                  </tr>

                  {/*{ผู้ขาย}*/}
                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-3">
                        <span style={{width: 80, textAlign: 'left'}} >ข้าพเจ้า</span>
                        <input className="form-control ml-2"
                               style={styles.inputLong}
                               name="seller_name"
                               value={this.state.seller_name}
                               onChange={this.handleOnChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 30, textAlign: 'left'}} >อายุ</span>
                        <input className="form-control ml-4"
                               style={styles.inputShort}
                               name="seller_age"
                               value={this.state.seller_age}
                               onChange={this.handleOnChange}
                               inputMode={'numeric'}
                               maxLength={2}
                        />
                        &nbsp;&nbsp; ปี &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 50, textAlign: 'left'}} >โทรศัพท์</span>
                        <input className="form-control ml-4"
                               style={styles.inputLong}
                               name="seller_mobile"
                               value={this.state.seller_mobile}
                               onChange={this.handleOnChange}
                        />

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 80, textAlign: 'left'}} >ที่อยู่</span>
                        <input className="form-control ml-2"
                               style={{width: '50%'}}
                               name="seller_address"
                               value={this.state.seller_address}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 80}}  >บัตร</span>
                        <input className="form-control ml-2"
                               style={{
                                 borderRadius: '5px',
                                 width: '130px'
                               }}
                               name="card_type"
                               value={this.state.card_type}
                               onChange={this.handleOnChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 80, textAlign: 'left'}}> เลขที่บัตร </span>
                        <input className="form-control ml-2"
                               style={{
                                 borderRadius: '1px',
                                 width: '190px'
                               }}
                               name="card_code"
                               value={this.state.card_code}
                               onChange={this.handleOnChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 80, textAlign: 'left'}}> ออกให้ ณ </span>
                        <input className="form-control ml-4"
                               style={{
                                 borderRadius: '1px',
                                 width: '190px'
                               }}
                               name="card_out_at"
                               value={this.state.card_out_at}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 80, textAlign: 'left'}}> เมื่อวันที่ </span>
                        <input className="form-control ml-2"
                               style={{
                                 borderRadius: '1px',
                                 width: '220px'
                               }}
                               name="card_out_date"
                               value={this.state.card_out_date}
                               onChange={this.handleOnChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 120}} >ได้ตกลงนำรถยนต์</span>
                        <input className="form-control ml-4"
                               style={{width: '24%'}}
                               name="car_name"
                               value={this.state.car_name}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 80, textAlign: 'left'}}>ปีรถ</span>
                        <input className="form-control ml-2"
                               style={{
                                 borderRadius: '1px',
                                 width: '135px'
                               }}
                               name="car_year_name"
                               value={this.state.car_year_name}
                               onChange={this.handleOnChange}
                               inputMode={'numeric'}
                               maxLength={4}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 50, textAlign: 'left'}}>สีรถ</span>
                        <input className="form-control ml-2"
                               style={{
                                 borderRadius: '1px',
                                 width: '140px'
                               }}
                               name="car_color_name"
                               value={this.state.car_color_name}
                               onChange={this.handleOnChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 50, textAlign: 'left'}}>อุปกรณ์</span>
                        <input className="form-control ml-4"
                               style={{width: '20%'}}
                               name="car_equipment"
                               value={this.state.car_equipment}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 128}} >หมายเลขทะเบียน</span>
                        <input className="form-control ml-3"
                               style={{
                                 borderRadius: '1px',
                                 width: '180px'
                               }}
                               name="car_license_plate"
                               value={this.state.car_license_plate}
                               onChange={this.handleOnChange}
                        />

                        <input className="form-control ml-2"
                               style={{width: 190}}
                               name="province_name"
                               disabled
                               value={this.state.province_name}
                            //onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 128}} >เลขเครื่องยนต์</span>
                        <input className="form-control ml-3"
                               style={{
                                 borderRadius: '1px',
                                 width: '180px'
                               }}
                               name="car_num_engines"
                               value={this.state.car_num_engines}
                               onChange={this.handleOnChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{width: 100}}  >หมายเลขตัวรถ</span>
                        <input className="form-control ml-4"
                               style={{
                                 borderRadius: '1px',
                                 width: '180px'
                               }}
                               name="car_number"
                               value={this.state.car_number}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>

                  {/*<tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        &nbsp;&nbsp;
                        <span style={{width: 30, textAlign: 'center'}}>ปี</span>
                        <input className="form-control ml-2"
                               style={styles.inputShort}
                               name="car_year_name"
                               value={this.state.car_year_name}
                               onChange={this.handleOnChange}
                               inputMode={'numeric'}
                               maxLength={4}
                        />
                        &nbsp;&nbsp;
                        <span style={{width: 30, textAlign: 'center'}}>สี</span>
                        <input className="form-control ml-2"
                               style={styles.inputShort}
                               name="car_color_name"
                               value={this.state.car_color_name}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>*/}

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span >ซึ่งข้าพเจ้าขอรับรองว่าเป็นกรรมสิทธิ์ของข้าพเจ้าแต่ผู้เดียว โดยชอบด้วยกฎหมาย และมิได้มีการติดพันใด ๆ ทั้งสิ้น ตกลงขาย ให้แก่</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">

                        <input className="form-control mr-2"
                               style={{width: '56%'}}
                               name="buyer_name"
                               value={this.state.buyer_name}
                               onChange={this.handleOnChange}
                        />

                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">

                        <span style={{width: 190}} >เป็นจำนวนเงินทั้งสิ้น</span>
                        <NumberFormat
                          className="form-control ml-2"
                          style={styles.inputLong}
                          thousandSeparator={true}
                          prefix={''}
                          placeholder=""
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_cost}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({car_cost: value}, () => this.calOutstanding())
                          }}
                        />
                        <span style={{width: 30}} className="ml-1" >บาท</span>
                        <span className="ml-1" >({ThaiBaht(this.state.car_cost)})</span>

                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 190}} >ข้าพเจ้าได้รับมัดจำแล้วเป็นเงิน</span>

                        {/* <input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="cash_pledge"
                                 value={this.state.cash_pledge}
                                 onChange={() => {}}
                          />*/}
                        <NumberFormat
                          className="form-control ml-2"
                          style={styles.inputLong}
                          thousandSeparator={true}
                          prefix={''}
                          placeholder=""
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.cash_pledge}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({cash_pledge: value}, () => this.calOutstanding())
                          }}
                        />
                        <span style={{width: 30}} className="ml-1" >บาท</span>
                        <span className="ml-1" >({ThaiBaht(this.state.cash_pledge)})</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 190}} >โดยชำระเป็น</span>

                        <input className="form-control ml-2"
                               style={{width: '43%'}}
                               name="pay_method"
                               value={this.state.pay_method}
                               onChange={this.handleOnChange}
                        />

                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 190}} >ส่วนที่เหลืออีกเป็นจำนวนเงิน</span>
                        {/*<input type="text"
                                 className="form-control hidden"
                                 style={{display: 'none'}}
                                 name="outstanding"
                                 value={this.state.outstanding}
                                 onChange={() => {}}
                          />*/}
                        <NumberFormat
                          className="form-control ml-2"
                          style={styles.inputLong}
                          thousandSeparator={true}
                          prefix={''}
                          placeholder=""
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.remain_amount}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({remain_amount: value}, () => this.calOutstanding())
                          }}
                        />
                        <span style={{width: 30}} className="ml-1" >บาท</span>
                        <span className="ml-1" >({ThaiBaht(this.state.remain_amount)})</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                          <span  >ผู้ซื้อจะชำระให้เมื่อข้าพเจ้าได้นำทะเบียนรถยนต์พร้อมหลักฐานการโอนกรรมสิทธิ์รถยนต์คันดังกล่าวมามอบให้แก่ผู้ซื้อ </span>

                        <span className="mr-1">&nbsp;ภายในวันที่</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <input readOnly={true}
                               className="form-control ml-1"
                               name="paid_due_date_thai"
                               style={{width: 162, height: 38}}
                               value={this.state.paid_due_date_thai === null ? '' : this.state.paid_due_date_thai}
                               onChange={() => {}}
                        />

                        <div style={styles.iconCalendar}>
                          <DatePicker
                            selected={this.state.startPaidDueDate}
                            onChange={this.handlePaidDueDate}
                            dateFormat="yyyy-MM-dd"
                            customInput={<DatePickerIcon />}
                            popperPlacement="right"
                          />
                        </div>

                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">

                        <span>
                           ถ้าหากรถยนต์คันดังกล่าวนี้ ปรากฎในภายหลังว่าไม่ใช่รถยนต์ของข้าพเจ้า หรือผู้ซื้อไม่สามารถโอนได้ด้วยเหตุใด ๆ ข้าพเจ้ายินยอมให้ดำเนินคดีได้ทันที &nbsp;
                           และหากผู้ซื้อได้รับความเสียหายแล้ว ข้าพเจ้ายอมคืนเงินทั้งหมดที่ได้รับจากผู้ซื้อ พร้อมทั้งดอกเบี้ยตามกฎหมาย ตลอดจนค่าใช้จ่ายใด ๆ ที่ผู้ซื้อต้องเสียไปอีกทั้งสิ้น
                        </span>

                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                          <span >

                          </span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-3">
                        <table style={styles.table}>
                          <tbody>
                          <tr className="mt-3">
                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                              (ลงนาม)________________________________________ผู้ขาย
                            </td>
                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                              (ลงนาม)________________________________________ผู้ซื้อ
                            </td>
                          </tr>

                          <tr>
                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                              <div className="mt-3"> (ลงนาม)________________________________________พยาน </div>
                            </td>
                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                              <div className="mt-3"> (ลงนาม)________________________________________พยาน </div>
                            </td>
                          </tr>

                          <tr >
                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                              <div className="mt-3"> เบอร์โทรศัพท์__________________________________ผู้ขาย </div>
                            </td>
                            <td style={{...styles.tdContentLeft, width: '50%'}} >
                              <div className="mt-3"> เบอร์โทรศัพท์__________________________________ผู้ซื้อ </div>
                            </td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style={{width: '100%'}} colSpan={3}>
                      <div className="mt-3" style={{border: '1px solid #000', padding: '6px' ,backgroundColor: '#ccc', width: '100%', minHeight: 250}} >
                        <table style={styles.table}>
                          <tbody>
                          <tr>
                            <td>
                              <div className="form-inline mt-2">
                                <span style={{width: 80, textAlign: 'left',fontWeight: "bold"}} >หมายเหตุ</span>
                                <span style={{width: 200, textAlign: 'left'}} >ถ้าหากปรากฎในภายหลังว่า</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-inline mt-2">
                                <span style={{width: 80, textAlign: 'left',fontWeight: "bold"}} >&nbsp;</span>
                                <span style={{width: '90%', textAlign: 'left'}} >
                                  1. รถคันดังกล่าว ได้รับใบสั่งของเจ้าพนักงานจราจรเป็นค่าปรับไม่ว่ากรณีใด ๆ ก็ตามจนกระทั่งถึงวันขาย
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-inline mt-2">
                                <span style={{width: 80, textAlign: 'left',fontWeight: "bold"}} >&nbsp;</span>
                                <span style={{width: '90%', textAlign: 'left'}} >
                                  2. หากรถคันดังกล่าวมิได้เสียภาษีประจำปี (รวมทั้งค่าปรับที่ขาดต่อ) จนกระทั่ง ถึงวันที่ขายดังนั้นตามข้อ 1 และข้อ 2 ข้าพเจ้า เจ้าของรถยนต์
                                  หรือผู้ขาย ยินดีรับผิดชอบและชดใช้ทั้งสิ้น
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className={port === 9038 || port === 9039 || port === 9000 ? 'hidden' : "d-flex mt-2"}>
                                <span style={{fontSize: '18px', fontWeight: 'bold', margin: 'auto'}}>
                                * หากผู้ขายไม่นำรถยนต์มาให้ผู้ซื้อในเวลาที่กำหนด ผู้ขายยินยอมให้ปรับเป็น 5 เท่าของเงินมัดจำ *
                                </span>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="form-inline mt-5">
                                <table style={styles.table}>
                                  <tbody>
                                  {/*<tr>
                                    <td style={{...styles.tdContentCenter, width: '100%'}} >
                                      <div style={{width: '100%', backgroundColor: 'red' }} className="mt-3"> (ลงนาม)__________________________________ผู้ขาย/เจ้าของรถยนต์ </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{...styles.tdContentCenter, width: '100%'}} >
                                      <div className="mt-2" style={{backgroundColor: 'green'}}>
                                       (__________________________________)
                                      </div>
                                    </td>
                                  </tr>*/}

                                  <tr>
                                    <td style={{...styles.tdContentCenter, width: '100%'}} >
                                      <div className="form-inline mt-2">
                                        <span style={{width: 310, textAlign: 'center', }} >&nbsp;</span>
                                        <span style={{width: 450,textAlign: 'center'}} >
                                          (ลงนาม)__________________________________ผู้ขาย/เจ้าของรถยนต์
                                        </span>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{...styles.tdContentLeft, width: '100%'}} >
                                      <div className="form-inline mt-1">
                                        <span style={{width: 275, textAlign: 'center', }} >&nbsp;</span>
                                        <span style={{width: 450,textAlign: 'center'}} >
                                           (__________________________________)
                                        </span>
                                      </div>
                                    </td>
                                  </tr>

                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3}>
                      <div className="form-inline mt-2">
                        <span style={{width: 90, textAlign: 'left'}} > หมายเหตุ </span>
                        <input className="form-control"
                               style={{width: '90%'}}
                               name="contract_note"
                               value={this.state.contract_note}
                               onChange={this.handleOnChange}
                        />
                      </div>
                    </td>
                  </tr>
                 {/* <tr>
                    <td style={{width: '100%'}} colSpan={3}>

                    </td>
                  </tr>*/}

                  <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>

                  </tbody>
                </table>
              </div>

              <div className="col-md-12 text-right">
                <div className="card-block">

                  {/*<button type="button"
                          className="btn btn-success btn-md mr-2"
                          style={{width: 120}}
                          onClick={() => this.saveCarContract('add')}
                  >
                    <i className="icon-check"/>
                    &nbsp;บันทึก
                  </button>*/}

                  {/*{
                    this.state.contract_id === null ? (
                        <button type="button"
                              className="btn btn-success btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.saveCarContract('add')}
                      >
                        <i className="icon-check"/>
                        &nbsp;บันทึก
                      </button>
                    ) :
                        <button type="button"
                                className="btn btn-warning btn-md mr-2"
                                style={{width: 120}}
                                onClick={() => this.saveCarContract('edit')}
                    >
                      <i className="icon-pencil"/>
                      &nbsp;แก้ไข
                    </button>
                  }*/}

                  {this.manageAddEditButton()}
                  {this.managePrintButton()}

                  {/*{
                    this.state.contract_id === null ? null : (
                      <button type="button"
                              className="btn btn-primary btn-md mr-2"
                              style={{width: 120}}
                              onClick={() => this.printCarContract()}
                      >
                        <i className="icon-printer"/>  พิมพ์
                      </button>
                    )
                  }*/}

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  table: {
    width: '100%',
    maxWidth: '100%'
  },
  tdContentCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tdContentRight: {
    textAlign: 'right',
    verticalAlign: 'middle',
    justifyContent: 'right',
  },
  tdContentLeft: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  tableTh:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableTdLeft:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'top',
  },
  tableTdCenter:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'left',
    verticalAlign: 'middle',
    paddingLeft: '70px',
  },
  tableTdRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
  },
  tablePd:{
    paddingLeft: '50px',
    paddingRight: '50px',
    lineHeight: '2.2',
  },
  amountsText:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  amountsTextNoBorder:{
    maxWidth: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  amountsRigth:{
    maxWidth: '100%',
    borderColor: '#626262',
    borderWidth: '1px',
    borderStyle: 'solid',
    textAlign: 'right',
    verticalAlign: 'top',
    fontWeight: 'bold',
  },
  buttonHeight: {
    height: 32,
  },
  inputData:{
    borderRadius: '5px',
    width: '450px',
  },
  inputDataNum:{
    borderRadius: '5px',
    width: '100px'
  },
  inputDataWriteAt:{
    borderRadius: '5px',
    width: '220px'
  },
  inputShort: {
    borderRadius: '5px',
    width: '110px'
  },
  inputLong: {
    borderRadius: '1px',
    width: '220px'
  },
  iconCalendar: {
    width: '45px'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarBuyContract)
