import React, { Component } from 'react'
import axios from "axios";
import {APIURL} from "../../../config/config";
import {AlertError, AlertSuccess} from "../../Alert/Alert";
import Switch from "react-switch";

class BankListRowSetting extends Component {
    constructor (props) {
        super(props);
        this.state = {
            banks_items:[],
            search: ''
        }

        this.loadBankData = this.loadBankData.bind(this);
        this.onChangeSwitch = this.onChangeSwitch.bind(this);
        this.onChangeSwitchIsLeasing = this.onChangeSwitchIsLeasing.bind(this);
        this.onChangeSwitchIsBank = this.onChangeSwitchIsBank.bind(this);
        this.onBankSearch = this.onBankSearch.bind(this);
    }

    componentDidMount () {
        this.loadBankData()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {

      let isBankUpdate = this.props.isBankUpdate

      if (this.props.isBankUpdate === true && prevProps.isBankUpdate !==  this.props.isBankUpdate) {
        // console.log('isBankUpdate=', isBankUpdate)
        this.loadBankData()
        this.props.onSetBankUpdate(false)
      }
    }

    loadBankData () {
        axios.get(APIURL + '/bank/')
            .then(res => {
                this.setState({banks_items: res.data, load_data: false, bank_id: 0, danger: false, bank:{}})
            }).catch(error => {
            this.setState({banks_items: [], load_data: true, bank_id: 0, danger: false})
        })
    }

    onBankSearch() {
       let search = this.state.search
       // console.log('search=', search)
       if (search === '') {
         return
       }

       let uri = APIURL + '/bank/search/'+search
        axios.get(uri).then(res => {
          let data = res.data
          this.setState({
            banks_items: data
          })
        }).catch(err => {
           console.log('err:', err)
        })
    }

    onChangeSwitch(checked,item){

        let item_update = item
        let bank_name = item.bank_name
        let banks_items =  this.state.banks_items

        let banks_item_index = banks_items.findIndex(data => data.bank_id=== item.bank_id)

        if(checked === false){
            item_update.status_field = 'hidden'
            item_update.is_banks = 0
            item_update.is_leasing = 0
        }else {
            item_update.status_field = 'show'
            item_update.is_banks = 1
            item_update.is_leasing = 1
        }

        axios.post(APIURL + '/bank/status', item_update)
            .then(res => {
                let affectedRows = res.data.affectedRows
                if (affectedRows === 1) {
                    if(checked === false){
                        AlertError('ปิดการใช้งาน '+bank_name+' เรียบร้อยแล้ว')
                    }else {
                        AlertSuccess('เปิดการใช้งาน '+bank_name+' เรียบร้อยแล้ว')
                    }
                }
            }).catch(error => {
        })

        banks_items[banks_item_index] = item_update

        this.setState({
            banks_items: banks_items
        })
    }

    onChangeSwitchIsLeasing(checked,item){

        let item_update = item
        let banks_items =  this.state.banks_items

        let banks_item_index = banks_items.findIndex(data => data.bank_id=== item.bank_id)

        if(checked === false){
            item_update.is_leasing = 0
        }else {
            item_update.is_leasing = 1
        }

        axios.post(APIURL + '/bank/is_leasing', item_update)
            .then(res => {
            }).catch(error => {
        })

        banks_items[banks_item_index] = item_update

        this.setState({
            banks_items: banks_items
        })
    }

    onChangeSwitchIsBank(checked,item){

        let item_update = item
        let banks_items =  this.state.banks_items

        let banks_item_index = banks_items.findIndex(data => data.bank_id=== item.bank_id)

        if(checked === false){
            item_update.is_banks = 0
        }else {
            item_update.is_banks = 1
        }

        axios.post(APIURL + '/bank/is_banks', item_update)
            .then(res => {
            }).catch(error => {
        })

        banks_items[banks_item_index] = item_update

        this.setState({
            banks_items: banks_items
        })
    }



  render(){

      const {banks_items} = this.state

      let emptyList = <tr>
          <td colSpan={5} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td>
      </tr>

      let itemList = banks_items.map((item, index) => {

          return (
              <tr key={index}>
                  <td>{ index+1 }</td>
                  <td className="text-nowrap" style={{width: 80}}>{ item.bank_name }</td>
                  <td className="text-center" >

                      { item.is_banks === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}

                      <br/>

                      <Switch height = {20} width ={48}
                              onChange={(checked) => this.onChangeSwitchIsBank(checked, item)}
                              checked={item.is_banks === 1}
                      />

                  </td>
                  <td className="text-center" >
                      { item.is_leasing === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}

                      <br/>

                      <Switch height = {20} width ={48}
                              onChange={(checked) => this.onChangeSwitchIsLeasing(checked, item)}
                              checked={item.is_leasing === 1}
                      />

                  </td>
                  <td className="text-center" >
                      { item.status_field === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                      <br/>

                      <Switch height = {20} width ={48}
                              onChange={(checked) => this.onChangeSwitch(checked, item)}
                              checked={item.status_field === 'show'}
                      />

                  </td>
              </tr>
          )
      })

      let bank_list_setting = banks_items.length === 0 ? emptyList : itemList

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
              <div className="card card-accent-info">
                  <div className="card-header">
                    <strong className="text-title">ธนาคาร</strong>
                    <input
                      onChange={(e) => {
                        let val = e.target.value
                        this.setState({
                          search: val
                        })
                      }}
                      style={styles.inputSearch}
                      value={this.state.search}
                      className="form-control"
                      placeholder="ค้นหาธนาคารตามชื่อ"
                      type="text"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-head-bar"
                            style={styles.buttonHeight}
                            onClick={this.onBankSearch}
                    >
                      ค้นหา
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-head-bar"
                            style={styles.buttonHeight}
                            onClick={() => {
                              this.setState({
                                search: ''
                              }, () => this.loadBankData())
                            }}
                    >
                      <i className="icon-refresh"/>
                    </button>
                  </div>

                  <div className="card-block">
                      <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                          <th className="text-left">#</th>
                          <th className="text-left">ชื่อธนาคาร</th>
                          <th className="text-center text-nowrap">ช่องเลือกชำระเงิน</th>
                          <th className="text-center text-nowrap">ช่องเลือกไฟแนนซ์/ลิสซิ่ง</th>
                          <th className="text-center" >สถานะ</th>
                        </tr>
                      </thead>
                      <tbody>
                        { bank_list_setting }
                      </tbody>
                      </table>
                  </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },
    inputSearch: {
      marginLeft: 15,
      width: 250,
      borderRadius:5,
      display: 'inline'
    },
    buttonHeight: {
      height: 36,
      paddingTop:0,
      paddingBottom:0,
      borderRadius:5,
      display: 'inline',
      marginTop: '-0.35rem'
    }
}

export default BankListRowSetting
