import React from 'react'

const DatePickerIcon = React.forwardRef(({value, onClick},ref)=>{
   // <i className="icon-calendar"/>  {value}
    return <button className="btn btn-primary ml-1" onClick={onClick} ref={ref}>
        <i className="icon-calendar"/>
    </button>
})

export default DatePickerIcon
