/**
 * Created by Tukta on 19/7/2560.
 */
import React, { Component } from 'react'

import CarFinanceListRow from "../CarFinanceListRow/CarFinanceListRow";



export  default class CarFinanceList extends Component{

    getCarFinanceList(){
        var list = [
            {"carfinance_id": "1","carfinance_name": "นายรัก", "carfinance_lastname": "สะอาด","carfinance_address": "269 ถ.อุปราช ต.ในเมือง อ.เมือง จ.อุบลราชธานี 34000",
                "carfinance_card_id": "1234567891234","carfinance_birth": "3","carfinance_mobile": "088-123-1234"},
        ]
        return list
    }

    render(){
        return (
            <div className="col-sm-12 col-md-12">
                <div className="card card-accent-primary">
                    <div className="card-header">
                        <strong> รายชื่อลูกหนี้ผ่อนรถ</strong>
                        <button className="btn btn-success float-right"><i className="icon-plus"></i> เพิ่มข้อมูลลูกหนี้ผ่อนรถ</button>
                    </div>
                    <CarFinanceListRow  carfinance_list={this.getCarFinanceList()} />
                </div>
            </div>
        );
    }
}