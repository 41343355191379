import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import html2canvas from "html2canvas";

// import {APIURL} from "../../../config/config";
// import axios from "axios";
// import PreviewImage from '../../../views/PreviewImage/';
// import {Modal, ModalBody} from "reactstrap";

// const port = window.env.PORT;

export  default class CarQuotation extends Component {

  constructor (props) {
    super(props)
    this.printRef = React.createRef();
    //this.handleOnSubmitCustomer.bind(this);
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      car_id: 0,
      action: 'บันทึก',
      car: this.props.car,
      real_sell_price: 0,
      down_payment: 0,
      interest: 0,
      period: 0,
      car_net_profit: 0,
      car_sale_price: 0,
      percent_down: 0,
      yord_jud: 0,
      yord_jud_interest: 0,
      month_payment: 0,
      month_payment_vat: 0,
      installment_balance_zero:0,
      preview_image_url: '',
      preview_image_select: false,
      interest_topup : 0,
      interest_new : 0,

      total_out_car : 0,
      insurance : 0,
      insurance_floor: '',
      name_customer : ''
    }

    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.onPreviewSelected = this.onPreviewSelected.bind(this)
    this.handleDownloadImage = this.handleDownloadImage.bind(this)
  }

  componentDidMount () {
    this.setState({car_sale_price: this.state.car.car_sale_price})
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  async handleDownloadImage () {
    const element = this.printRef.current;
    //console.log(element)
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'ใบเสนอราคา.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  }

  render () {

    let car_license_plate = this.state.car.car_license_plate_new === '' ?  this.state.car.car_license_plate_old : this.state.car.car_license_plate_new
    let province_name = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ? this.state.car.province_name_new : this.state.car.province_name_old

    // style={{ backgroundImage:`url(img/car_history.jpg)` }}
    return (
      <>
        <div
          className="card card-accent-info" ref={this.printRef}
        >
          <form action="" method="post">
            <div className="card-header text-center">
              <strong className="text-title">ใบเสนอราคา</strong>
            </div>

            <div className="card-block">
                  <div className="row pl-3">
                    <div className="row p-3 col-sm-12">
                      <strong>ยี่ห้อรถ : </strong>
                       <strong className="text-danger font-weight-bold">&nbsp;&nbsp;{this.state.car.car_name}&nbsp;( {car_license_plate}&nbsp;{province_name} )</strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row p-3">

                      <div className="form-group col-sm-4">
                        <strong>ชื่อลูกค้า :</strong>
                      </div>

                      <div className="form-group col-sm-8">
                        <input type="text"
                               className="form-control font-weight-bold"
                               ref={this.state.name_customer}
                               onChange={()=>{}}
                        />
                      </div>

                      <div className="form-group col-sm-4">
                        <strong>ราคารถยนต์ :</strong>
                      </div>
                      <div className="form-group col-sm-8">
                        <input type="text"
                               ref="real_sell_price"
                               value={this.state.car_sale_price}
                               className="hidden"
                               onChange={()=>{}}
                        />
                        <NumberFormat
                            className="form-control text-danger font-weight-bold"
                            thousandSeparator={true}
                            //prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.car_sale_price}
                        />
                      </div>

                      <div className="form-group col-sm-4">
                        <strong>ยอดจัดไฟแนนซ์ :</strong>
                      </div>
                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="yord_jud"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>
                      {/*<div className="form-group col-sm-3">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            //prefix={'฿'}
                            decimalScale={2}
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.yord_jud}
                        />
                      </div>*/}

                      <div className="form-group col-sm-4">
                        <strong>เงินดาวน์ :</strong>
                      </div>
                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="down_payment"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>
                      {/*<div className="form-group col-sm-3">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            //prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.down_payment}
                        />
                      </div>*/}

                      <div className="form-group col-sm-4">
                        <strong>ประกันรถยนต์ :</strong>
                      </div>
                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="insurance_floor"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>
                      {/*<div className="form-group col-sm-2">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            //prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.insurance}
                        />
                      </div>*/}

                      {/*<div className="form-group col-sm-4">
                        <div
                            className="btn-group colors"
                            style={{height: 36, padding: 10}}
                        >

                          <input type="radio"
                                 style={{marginTop: -6}}
                                 name="insurance_included"
                                 value="รวมในยอดจัด"
                                 className="btn btn-primary"
                                 defaultChecked={this.state.insurance_buy === 'รวมในยอดจัด'}
                                 //onChange={this.onInsuranceBuyChanged}
                          /> &nbsp;&nbsp;<span>รวมในยอดจัด</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="radio"
                                 style={{marginTop: -6}}
                                 name="insurance_buy_more"
                                 value="ซื้อเพิ่ม"
                                 className="btn btn-primary"
                                 defaultChecked={this.state.insurance_buy === 'ซื้อเพิ่ม'}
                                 //onChange={this.onInsuranceBuyChanged}
                          /> &nbsp;&nbsp;<span>ซื้อเพิ่ม</span>
                        </div>
                      </div>*/}

                      <div className="form-group col-sm-4">
                        <strong>ค่าจัดโอน / ย้าย :</strong>
                      </div>
                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="transfer_fee"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>
                      {/*<div className="form-group col-sm-3">
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            //prefix={'฿'}
                            //placeholder="กรอกค่าโอน"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.transfer_fee}
                            onChange={()=>{}}
                        />
                      </div>*/}
                      {/*<div className="form-group col-sm-4">
                        <div
                            className="btn-group colors"
                            style={{height: 36, padding: 10}}
                        >
                          <input type="radio"
                                 style={{marginTop: -6}}
                                 name="transfer_free"
                                 value="แถมฟรี"
                                 className="btn btn-primary"
                              //defaultChecked={this.state.insurance_buy === 'แถมฟรี'}
                              //onChange={this.onInsuranceBuyChanged}
                          /> &nbsp;&nbsp;<span>แถมฟรี</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="radio"
                                 style={{marginTop: -6}}
                                 name="transfer_not_free"
                                 value="มีค่าดำเนินการ"
                                 className="btn btn-primary"
                                 //defaultChecked={this.state.insurance_buy === 'มีค่าดำเนินการ'}
                                 //onChange={this.onInsuranceBuyChanged}
                          /> &nbsp;&nbsp;<span>มีค่าดำเนินการ</span>
                        </div>
                      </div>*/}

                      <div className="form-group col-sm-4 mt-0">
                        <strong>รวมออกรถ :</strong>
                      </div>
                      <div className="form-group col-sm-8">
                        <NumberFormat
                            className="form-control text-danger font-weight-bold"
                            thousandSeparator={true}
                            displayType={'input'}
                           // prefix={'฿'}
                            decimalScale={2}
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.total_out_car}
                        />
                      </div>


                      <div className="form-group col-sm-4">
                        <strong>ค่างวด :</strong>
                      </div>

                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="period_1"
                               placeholder="ค่างวด * จำนวนงวด"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>

                      <div className="form-group col-sm-4">
                        <strong>ค่างวด :</strong>
                      </div>

                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="period_2"
                               placeholder="ค่างวด * จำนวนงวด"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>

                      <div className="form-group col-sm-4">
                        <strong>จัดไฟแนนซ์ธนาคาร :</strong>
                      </div>

                      <div className="form-group col-sm-8">
                        <input type="text"
                               name="finance_bank"
                               className="form-control text-danger font-weight-bold"
                               onChange={()=>{}}
                        />
                      </div>

                      <div className="form-group col-sm-4">
                        <strong>หมายเหตุ :</strong>
                      </div>

                      <div className="form-group col-sm-8">
                        <input type="text"
                               className="form-control text-danger font-weight-bold"
                               name="quo_note"
                               onChange={()=>{}}
                        />
                      </div>
                    </div>
                  </div>




                </div>
          </form>
        </div>

        <div>
          <button className="float-right mb-5 btn btn-secondary"
                  type="button"
                  onClick={()=>this.handleDownloadImage()}

          >
            ดาวน์โหลดใบเสนอราคา.jpg
          </button>
        </div>

        {/*<Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
          <ModalBody>
            <PreviewImage
              imageUrl={this.state.preview_image_url}
            />
          </ModalBody>
        </Modal>*/}

      </>
    )
  }
}
