import React, { Component } from 'react'
import userUtil from "../../../utils/userUtil";


export  default class AccountListRow extends Component{

    /*convertUserTyptToText(type) {
        let typeName = ''
        switch (type){
          case 'admin':
            typeName = 'ผู้ดูแลระบบ'
            break
          case 'sale':
            typeName = 'พนักงานขาย'
            break
          case 'technician':
            typeName = 'ช่างเทคนิค'
            break
          case 'manager':
            typeName = 'ผู้จัดการ'
            break
          case 'account':
            typeName = 'บัญชี'
            break
          default :
            typeName = ''
            break
        }
        return typeName
    }*/

    render(){
       let isAccountBranch = this.props.isAccountBranch
        let branchList;

        if (this.props.branch_list.length === 0){
            branchList = <tr><td colSpan={5} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td></tr>
        } else {
            branchList = this.props.branch_list.map((account, index) => {
                return (
                    <tr key={index}>
                      <td className="text-left">
                          <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                  onClick={this.props.branchsCallbacks.edit.bind(null,account)}
                                  style={styles.button}
                          >
                              <i className="fa fa-edit"/> แก้ไข
                          </button>

                        <button className={ account.type !== "admin" ? "btn btn-sm btn-danger btn-md-width mb-2": 'hidden'}
                                onClick={this.props.branchsCallbacks.delete.bind(null,account)}
                                style={styles.button}
                        >
                          <i className="fa fa-remove"/> ลบ
                        </button>

                        {isAccountBranch === 1 ?
                          <button className={ "btn btn-sm btn-primary btn-md-width mb-2" }
                                  onClick={() => this.props.openBranchPermission(account, true)}
                                  style={{...styles.button, width: 100}}
                          >
                            <i className="fa fa-user"/> สิทธิ์สาขา
                          </button>
                          : null}


                      </td>
                        <td className="text-left">{ account.user }</td>
                        <td className="text-nowrap">
                          { account.fullname } <br/>
                          สิทธิ์ : { userUtil.convertUserTypeToText(account.type) } <br/>
                          สาขา : {account.branch_name}
                        </td>
                        <td>
                           อีเมล์: { account.email } <br/>
                           โทร: { account.mobile }
                        </td>
                    </tr>
                )
            });
        }

        return (
            <div className="card card-accent-info">
                <div className="card-header">
                    <strong className="text-title">ข้อมูลผู้ใช้งาน</strong>
                </div>
                <div className="card-block" style={styles.overflowY}>
                    <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                            <th className="text-center" style={{width: 100}} >จัดการ</th>
                            <th className="text-center">username</th>
                            <th className="text-nowrap">ชื่อ-นามสกุล</th>
                            <th className="text-center">อีเมล์/เบอร์โทรศัพท์</th>
                        </tr>
                        </thead>
                        <tbody>
                        { branchList }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
        width: 100
    },
    overflowY: {
        overflow: 'scroll',
    }
}
