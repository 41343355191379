import React, { Component } from 'react'
import axios from 'axios'
import {APIURL, PORT} from '../../../config/config'

export default class AccountForm extends Component{
    constructor (props){
        super(props)
        this.state = {
            branch_name: '',
            branch_address: '',
            branch_tel: '',
            account: this.props.account,
            branchs: [],
            branch_id: 0,
            groupaccounts: []
        }
        this.onChangeHandle = this.onChangeHandle.bind(this)
        this.onUserChangeHandle = this.onUserChangeHandle.bind(this)
      this.loadBranchData = this.loadBranchData.bind(this)
      this.getRoleOptions = this.getRoleOptions.bind(this)
      this.loadGroupAccount = this.loadGroupAccount.bind(this)
    }

  /* componentDidMount() {
    console.log('===componentDidMount')
    console.log(this.state.account)
  }*/

  componentDidMount () {
    this.loadGroupAccount()
    this.loadBranchData()
  }

   componentWillReceiveProps(nextProps) {
    let accountNew = nextProps.account
    let accountOld = this.props.account

    if(JSON.stringify(accountNew) !== JSON.stringify(accountOld)) {
      this.setState({account: accountNew})
    }
  }

  loadBranchData() {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branchs: res.data})
      }).catch(error => {
      this.setState({branchs: []})
    })
  }

    loadGroupAccount() {
        axios.get(APIURL + '/groupaccount/')
            .then(res => {
                this.setState({groupaccounts: res.data})
            }).catch(error => {
            this.setState({groupaccounts: []})
        })
    }


    onChangeHandle (event) {
     event.preventDefault()
    //alert (event.target.name);
    let xname = event.target.name
    if (xname === 'fullname') {
      this.setState({
        account: {
          ...this.state.account ,
          fullname: event.target.value }
      })
    } else if (xname === 'email') {
      this.setState({
        account: {
          ...this.state.account ,
          email: event.target.value
        }
      })
    } else if (xname === 'mobile') {
      this.setState({
        account: {
          ...this.state.account ,
          mobile: event.target.value
        }
      })
    } else if (xname === 'address') {
      this.setState({
        account: {
          ...this.state.account ,
          address: event.target.value
        }
      })
    } else if(xname === 'type') {
      this.setState({
        account: {
          ...this.state.account ,
          type: event.target.value
        }
      })
    }
    //
  }

  onUserChangeHandle(event) {
    event.preventDefault()
    if(this.props.actionType==="บันทึก") {
      this.setState({
        account: {
          ...this.state.account,
          user: event.target.value
        }
      })
    }
  }

  getRoleOptions() {
      let groupaccounts = this.state.groupaccounts
      let options = groupaccounts.map((groupaccount, index) => (
          <option key={index} value={groupaccount.group_name}>{groupaccount.group_thai}</option>
      ))
      return options
  }

    render(){

      let car_branch_list = this.state.branchs.map((branch, index) => {
        return (
          <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
        )
      })

        return (
            <div className="card card-accent-success">
                <div className="card-header card-customer">
                    <strong className="text-title">เพิ่มข้อมูลผู้ใช้</strong>
                </div>
                <form action="" method="post"
                      onSubmit={ (this.props.actionType==="แก้ไข")? this.props.onSubmit.update :this.props.onSubmit.add  }>

                    <div className="card-block">
                        <div className="form-group">
                            <label htmlFor="user">username</label>
                            <input name="user"
                                   id="user"
                                   value={this.state.account.user || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   placeholder="username ภาษาอังกฤษเท่านั้น"
                                   onChange={this.onUserChangeHandle}
                                   />
                        </div>

                        <div className="form-group">
                            <label htmlFor="pass">password</label>
                            <input name="pass"
                                   type="password"
                                   className="form-control"
                                   id="pass"
                                   style={styles.inputSearch}
                                   />
                        </div>

                      <div className="form-group">
                        <label htmlFor="employee_name">สาขา</label>
                        <select className="form-control"
                                id="branch_id"
                                name="branch_id"
                                ref="branch_id"
                                value={this.state.account.branch_id || ''}
                                onChange={(e) => {
                                  let val = e.target.value
                                  this.setState ({account:{
                                      ...this.state.account,
                                      branch_id : val
                                    }})
                                }}
                        >
                          <option value={''}>เลือกสาขา</option>
                          {car_branch_list}
                        </select>
                      </div>

                        <div className="form-group">
                            <label htmlFor="fullname">ชื่อ-นามสกุล </label>
                            <input
                                   name="fullname"
                                   type="text"
                                   className="form-control"
                                   placeholder="ชื่อ-นามสกุล"
                                   value={this.state.account.fullname || ''}
                                   style={styles.inputSearch}
                                   onChange={this.onChangeHandle}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">อีเมล์</label>
                            <input name="email"
                                   value={this.state.account.email || ''}
                                   style={styles.inputSearch}
                                   type="text"
                                   className="form-control"
                                   id="email"
                                   placeholder="กรอกเบอร์อีเมล์"
                                   onChange={this.onChangeHandle}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="mobile">เบอร์มือถือ</label>
                            <input name="mobile"
                                   ref="mobile"
                                   value={this.state.account.mobile || ''}
                                   style={styles.inputSearch}
                                   type="text" className="form-control"
                                   id="mobile"
                                   placeholder="กรอกเบอร์มือถือ"
                                   onChange={this.onChangeHandle}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">ที่อยู่</label>
                            <input name="address"
                                   ref="address"
                                   value={this.state.account.address || ''}
                                   style={styles.inputSearch}
                                   type="text" className="form-control"
                                   id="address"
                                   placeholder="กรอกที่อยู่"
                                   onChange={this.onChangeHandle}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="type">สิทธิ์การใช้งาน</label>
                            {/*<input name="type"
                                   ref="type"
                                   value={this.props.actionType==="แก้ไข"? this.props.account.type:"officer"}
                                   type="text" className="form-control"
                                   id="type"
                                   placeholder="สิทธิ์การใช้งาน"
                             />
                             */}
                              <select
                                className="form-control"
                                id="type"
                                name="type"
                                ref="type"
                                value={this.state.account.type || ''}
                                style={styles.inputSearch}
                                onChange={this.onChangeHandle}
                              >
                                  {this.getRoleOptions()}
                               {/* <option  value="sale">
                                    {PORT === 9056 ? 'แอดมินขาย' : 'พนักงานขาย'}
                                </option>

                                  {
                                      PORT === 9043 || PORT === 9056 ? (
                                          <option  value="headsale">หัวหน้าฝ่ายขาย</option>
                                      ) : null
                                  }


                                <option  value="technician">
                                    {PORT === 9056 ? 'ปรับสภาพ' : 'ช่างเทคนิค'}
                                </option>
                                  {
                                      PORT === 9008 ? <>
                                          <option  value="store">สโตร์</option>
                                          <option  value="transport">ขนส่ง</option>
                                      </> : null
                                  }
                                <option  value="account">บัญชี</option>
                                <option  value="manager">
                                    {PORT === 9056 ? 'สต็อค' : 'ผู้จัดการ'}
                                </option>
                                <option  value="admin">ผู้ดูแลระบบ</option>*/}

                              </select>
                        </div>
                    </div>

                        <div className="card-footer text-right">
                            <button type="reset" className="btn btn-sm btn-danger mr-2"
                                    style={styles.button}
                                    onClick={()=>{
                                        this.setState({
                                            account:{}
                                        })
                                    }}
                            >
                                <i className="fa fa-refresh"/> ยกเลิก</button>

                            <button type="submit"
                                    className="btn btn-sm btn-primary"
                                    style={styles.button}
                            >
                                <i className={this.props.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                                &nbsp;{this.props.actionType}
                            </button>
                        </div>

                </form>
            </div>
        );
    }
}

const styles = {
    button: {
        borderRadius:5,
        height: 36,
    },

    inputSearch: {
        borderRadius:5,
        display: 'inline'
    }
}
//export  default BranchForm;
